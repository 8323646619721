import { TFunction } from 'i18next';

// eslint-disable-next-line no-shadow
enum STEPS_WORKSITE_MAR_INIT {
  BIND_CUSTOMER,
  CUSTOMER_CREATION,
  CONTRACT_CREATION,
}

// eslint-disable-next-line no-shadow
enum STEPS_WORKSITE_MAR_COMPLETION {
  TYPOLOGY_OF_HOUSING = 1,
  USER_INFORMATION,
  ENERGY_AUDIT,
  HOUSING_ANALYSIS,
  SCENARIOS_AND_CALCULATIONS,
  PROFESSIONALS,
  QUOTES,
  ADMINISTRATIVE_MANDATE,
  FINANCIAL_MANDATE,
  CEE_ANAH,
  APPLICATION_DEPOSIT,
  GRANT_NOTIFICATION,
  WORK_IN_PROGRESS,
  END_OF_WORK,
  AFTER_WORK,
  PAYMENT_LETTER,
  END,
}

const sidebarStepsWithTranslation = (t: TFunction) => {
  return {
    PRE_WORKSITE_ANALYSIS: {
      label: t(
        'mar.worksite_creation.worksite_completion.folder_details.sidebar_steps.pre_worksite_analysis'
      ),
      steps: [
        STEPS_WORKSITE_MAR_COMPLETION.TYPOLOGY_OF_HOUSING,
        STEPS_WORKSITE_MAR_COMPLETION.USER_INFORMATION,
        STEPS_WORKSITE_MAR_COMPLETION.ENERGY_AUDIT,
        STEPS_WORKSITE_MAR_COMPLETION.HOUSING_ANALYSIS,
        STEPS_WORKSITE_MAR_COMPLETION.SCENARIOS_AND_CALCULATIONS,
      ],
    },
    CHOICE_OF_PROVIDERS: {
      label: t(
        'mar.worksite_creation.worksite_completion.folder_details.sidebar_steps.choice_of_providers'
      ),
      steps: [
        STEPS_WORKSITE_MAR_COMPLETION.PROFESSIONALS,
        STEPS_WORKSITE_MAR_COMPLETION.QUOTES,
      ],
    },
    FOLDER_ANAH: {
      label: t(
        'mar.worksite_creation.worksite_completion.folder_details.sidebar_steps.folder_anah'
      ),
      steps: [
        STEPS_WORKSITE_MAR_COMPLETION.ADMINISTRATIVE_MANDATE,
        STEPS_WORKSITE_MAR_COMPLETION.FINANCIAL_MANDATE,
        STEPS_WORKSITE_MAR_COMPLETION.CEE_ANAH,
        STEPS_WORKSITE_MAR_COMPLETION.APPLICATION_DEPOSIT,
        STEPS_WORKSITE_MAR_COMPLETION.GRANT_NOTIFICATION,
      ],
    },
    WORKSITE: {
      label: t(
        'mar.worksite_creation.worksite_completion.folder_details.sidebar_steps.worksite'
      ),
      steps: [
        STEPS_WORKSITE_MAR_COMPLETION.WORK_IN_PROGRESS,
        STEPS_WORKSITE_MAR_COMPLETION.END_OF_WORK,
        STEPS_WORKSITE_MAR_COMPLETION.AFTER_WORK,
      ],
    },
    BALANCE: {
      label: t(
        'mar.worksite_creation.worksite_completion.folder_details.sidebar_steps.balance'
      ),
      steps: [STEPS_WORKSITE_MAR_COMPLETION.PAYMENT_LETTER],
    },
  };
};

const progressStepsWithTranslation = (t: TFunction) => {
  return {
    PRE_WORKSITE_ANALYSIS: {
      label: t(
        'mar.worksite_creation.worksite_completion.folder_details.sidebar_steps.pre_worksite_analysis'
      ),
      steps: [
        STEPS_WORKSITE_MAR_COMPLETION.TYPOLOGY_OF_HOUSING,
        STEPS_WORKSITE_MAR_COMPLETION.USER_INFORMATION,
        STEPS_WORKSITE_MAR_COMPLETION.ENERGY_AUDIT,
        STEPS_WORKSITE_MAR_COMPLETION.HOUSING_ANALYSIS,
        STEPS_WORKSITE_MAR_COMPLETION.SCENARIOS_AND_CALCULATIONS,
      ],
    },
    CHOICE_OF_PROVIDERS: {
      label: t(
        'mar.worksite_creation.worksite_completion.folder_details.sidebar_steps.choice_of_providers'
      ),
      steps: [
        STEPS_WORKSITE_MAR_COMPLETION.PROFESSIONALS,
        STEPS_WORKSITE_MAR_COMPLETION.QUOTES,
      ],
    },
    FOLDER_ANAH: {
      label: t(
        'mar.worksite_creation.worksite_completion.folder_details.sidebar_steps.putting_together_folder_anah'
      ),
      steps: [
        STEPS_WORKSITE_MAR_COMPLETION.ADMINISTRATIVE_MANDATE,
        STEPS_WORKSITE_MAR_COMPLETION.FINANCIAL_MANDATE,
        STEPS_WORKSITE_MAR_COMPLETION.CEE_ANAH,
        STEPS_WORKSITE_MAR_COMPLETION.APPLICATION_DEPOSIT,
        STEPS_WORKSITE_MAR_COMPLETION.GRANT_NOTIFICATION,
      ],
    },
    PROCESSING_FOLDER_ANAH: {
      label: t(
        'mar.worksite_creation.worksite_completion.folder_details.sidebar_steps.processing_folder_anah'
      ),
      steps: [STEPS_WORKSITE_MAR_COMPLETION.GRANT_NOTIFICATION],
    },
    WORK_IN_PROGRESS: {
      label: t(
        'mar.worksite_creation.worksite_completion.folder_details.work_in_progress.title'
      ),
      steps: [STEPS_WORKSITE_MAR_COMPLETION.WORK_IN_PROGRESS],
    },
    END_OF_WORK: {
      label: t('mar.progress.card.end_of_work'),
      steps: [STEPS_WORKSITE_MAR_COMPLETION.END_OF_WORK],
    },
    AFTER_WORK: {
      label: t('mar.progress.card.after_work'),
      steps: [STEPS_WORKSITE_MAR_COMPLETION.AFTER_WORK],
    },
    BALANCE: {
      label: t(
        'mar.worksite_creation.worksite_completion.folder_details.sidebar_steps.balance'
      ),
      steps: [STEPS_WORKSITE_MAR_COMPLETION.PAYMENT_LETTER],
    },
  };
};

const HOUSING_TYPES = {
  HOUSE: 1,
  APARTMENT: 2,
};

const OCCUPATION_TYPES = {
  OWNER_OCCUPANT: 1,
  TENANT: 2,
  LANDLORD: 3,
  OTHER: 4,
};

const AUDITOR_TYPES = {
  ENGINEERING_OFFICE: 'engineering_office',
  MYSELF: 'myself',
  COLLABORATOR: 'collaborator',
};

const MANDATORY_TYPES = {
  ADMINISTRATIF_SIGNATURE: 1,
  ADMINISTRATIF_AIDE: 2,
  FINANCIER: 3,
};

const PERSON_TYPES = {
  LEGAL: '1',
  NATURAL: '2',
};

const personTypesOptions = (t: TFunction) => ({
  LEGAL: {
    value: PERSON_TYPES.LEGAL,
    label: t(
      'mar.worksite_creation.worksite_completion.folder_details.mandate.legal_person'
    ),
  },
  NATURAL: {
    value: PERSON_TYPES.NATURAL,
    label: t(
      'mar.worksite_creation.worksite_completion.folder_details.mandate.natural_person'
    ),
  },
});

const CIVILITY_MAR = {
  MRS: '1',
  MR: '2',
};

export {
  STEPS_WORKSITE_MAR_INIT,
  STEPS_WORKSITE_MAR_COMPLETION,
  sidebarStepsWithTranslation,
  HOUSING_TYPES,
  OCCUPATION_TYPES,
  AUDITOR_TYPES,
  MANDATORY_TYPES,
  PERSON_TYPES,
  personTypesOptions,
  CIVILITY_MAR,
  progressStepsWithTranslation,
};
