import { useTranslation } from 'react-i18next';
import { TextWithRadio } from '@components/atomic/inputs/controls/TextWithRadio';
import { useFormContext } from 'react-hook-form';
import { CIVILITY_MAR } from '@models/mar/utils/enums';

interface IRadioCivility {
  fieldName: string;
}
function RadioCivility({ fieldName }: IRadioCivility) {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext();

  const civilityFormValue = watch(fieldName);

  return (
    <div className="flex space-x-[.5rem] w-full">
      <TextWithRadio
        label={t('forms.civility.mr')}
        value={CIVILITY_MAR.MR}
        setSelectedValue={(val: string) => setValue(fieldName, val)}
        isChecked={
          civilityFormValue ? civilityFormValue === CIVILITY_MAR.MR : false
        }
        selectValue={civilityFormValue}
        withoutName
        addClass="w-full"
      />
      <TextWithRadio
        label={t('forms.civility.mrs')}
        value={CIVILITY_MAR.MRS}
        setSelectedValue={(val: string) => setValue(fieldName, val)}
        isChecked={
          civilityFormValue ? civilityFormValue === CIVILITY_MAR.MRS : false
        }
        selectValue={civilityFormValue}
        withoutName
        addClass="w-full"
      />
    </div>
  );
}

export default RadioCivility;
