import { useTranslation } from 'react-i18next';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { useNavigate } from 'react-router-dom';
import { AUTH_ROUTES } from '@utils/routesUrls';

interface IAccountToActivateNoIdNowProps {
  formLayout?: boolean;
}

function AccountToActivateNoIdNow({
  formLayout,
}: IAccountToActivateNoIdNowProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col space-y-8 lg:w-[40rem] items-center">
      <div>
        <p className="mb-2 text-[1.6875rem] leading-[2.5rem]">
          {t('auth.registration.account_to_activate.message_line_1')}
        </p>
        <p className="text-[.875rem]">
          {t('auth.registration.account_to_activate.message_line_2')}
        </p>
      </div>
      {formLayout ? (
        <div className="w-full my-6">&nbsp;</div>
      ) : (
        <ButtonOpx
          addClass="w-full my-6"
          label={t('auth.registration.account_to_activate.back_to_login')}
          type="primary"
          onClick={() => navigate(AUTH_ROUTES.LOGIN)}
        />
      )}
    </div>
  );
}

export { AccountToActivateNoIdNow };

AccountToActivateNoIdNow.defaultProps = {
  formLayout: false,
};
