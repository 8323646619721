import { LoaderDivSkeleton } from '@components/loaders/LoaderDivSkeleton';
import { borderGrey } from '@assets/color';

function LoaderInvoice() {
  return (
    <>
      <LoaderDivSkeleton
        height="2rem"
        width="20rem"
        backgroundColor={borderGrey}
      />
      <div className="flex flex-col space-y-2">
        <LoaderDivSkeleton
          height="1rem"
          width="10rem"
          backgroundColor={borderGrey}
        />
        <LoaderDivSkeleton
          height="2rem"
          width="100%"
          backgroundColor={borderGrey}
        />
      </div>
      <div className="flex flex-col space-y-2">
        <LoaderDivSkeleton
          height="1rem"
          width="10rem"
          backgroundColor={borderGrey}
        />
        <LoaderDivSkeleton
          height="2rem"
          width="100%"
          backgroundColor={borderGrey}
        />
      </div>
      <LoaderDivSkeleton
        height="2rem"
        width="15rem"
        backgroundColor={borderGrey}
      />
      <div className="flex flex-col space-y-2">
        <LoaderDivSkeleton
          height="1rem"
          width="10rem"
          backgroundColor={borderGrey}
        />
        <LoaderDivSkeleton
          height="2rem"
          width="100%"
          backgroundColor={borderGrey}
        />
      </div>
      <div className="flex flex-col space-y-2">
        <LoaderDivSkeleton
          height="1rem"
          width="10rem"
          backgroundColor={borderGrey}
        />
        <LoaderDivSkeleton
          height="2rem"
          width="100%"
          backgroundColor={borderGrey}
        />
      </div>
      <LoaderDivSkeleton
        height="2rem"
        width="22rem"
        backgroundColor={borderGrey}
      />
      <div className="w-full grid grid-cols-3 gap-2 items-end">
        <div className="flex flex-col space-y-2">
          <LoaderDivSkeleton
            height="1rem"
            width="5rem"
            backgroundColor={borderGrey}
          />
          <LoaderDivSkeleton
            height="2rem"
            width="100%"
            backgroundColor={borderGrey}
          />
        </div>
        <div className="flex flex-col space-y-2">
          <LoaderDivSkeleton
            height="1rem"
            width="5rem"
            backgroundColor={borderGrey}
          />
          <LoaderDivSkeleton
            height="2rem"
            width="100%"
            backgroundColor={borderGrey}
          />
        </div>
        <LoaderDivSkeleton
          height="3rem"
          width="100%"
          backgroundColor={borderGrey}
        />
      </div>
    </>
  );
}

export default LoaderInvoice;
