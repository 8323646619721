import {
  PositionIcon,
  BeneficiariesIcon,
  InfoCircleIcon,
  ContactPageIcon,
  HomeIcon,
  PersonIcon,
  MultipleFilesIcon,
  ContractsIcon,
} from '@assets/images/svgComponents';
import { GlobalContext } from '@context/globalContext';
import { ROLES } from '@utils/roles';
import { useContext, useEffect, useMemo, useState } from 'react';
import { WorksitesContext } from '@models/worksites/utils/worksitesContext';
import { useTranslation } from 'react-i18next';
import { CardInformations } from '@components/informations/CardInformations';
import { partnerOrInstallerDatas } from '@models/worksites/utils/tab';
import { useNavigate } from 'react-router-dom';
import { IUserType } from '@models/auth/utils/types';
import { getContacts } from '@models/users/apiRequests/userRequests';
import { AuthContext } from '@context/authContext';
import { CardContacts } from '@models/partners/components/informations/cards/CardContacts';
import { CardWorksiteInformations } from '@models/worksites/components/worksiteDetails/worksiteInformations/CardWorksiteInformations';
import { CardWorksiteCustomFields } from '@models/worksites/components/worksiteDetails/worksiteInformations/CardWorksiteCustomFields';
import {
  IWorksiteOperation,
  IWorksiteReferentType,
} from '@models/worksites/utils/worksitesTypes';
import { CardHousing } from '@models/worksites/components/worksiteDetails/worksiteInformations/CardHousing';
import { CardBeneficiary } from '@models/worksites/components/worksiteDetails/worksiteInformations/CardBeneficiary';
import { PARTNERS_ROUTES_WITH_ID } from '@utils/routesUrls';

type IWorksiteDetailsInfosProps = {
  isLoading: boolean;
  refresh: () => Promise<void>;
};

function WorksiteDetailsInfos({
  isLoading,
  refresh,
}: IWorksiteDetailsInfosProps): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { roleUser } = useContext(GlobalContext);
  const { user } = useContext(AuthContext);
  const { worksiteDetails } = useContext(WorksitesContext);

  const iconsList = {
    beneficiary: <BeneficiariesIcon />,
    info: <InfoCircleIcon />,
    position: <PositionIcon />,
    infos: <InfoCircleIcon />,
    contact: <ContactPageIcon />,
    housing: <HomeIcon />,
    person: <PersonIcon />,
    file: <MultipleFilesIcon />,
    contract: <ContractsIcon />,
  };

  const [contactList, setContactList] = useState<IUserType[]>([]);
  const [referent, setReferent] = useState<IWorksiteReferentType | null>(
    worksiteDetails.worksite_referent
  );

  const getUserContacts = async () => {
    const resContacts = await getContacts();

    if (resContacts) {
      setContactList(resContacts);
    }
  };

  const renderElement = useMemo(() => {
    switch (roleUser) {
      case ROLES.PRODUCTION:
        return (
          <CardInformations
            title={t('worksites.partner.title')}
            layout="oneColumn"
            data={partnerOrInstallerDatas(
              t,
              worksiteDetails,
              roleUser,
              ROLES,
              iconsList,
              navigate
            )}
            loading={isLoading}
            customLabelHeaderButton={String(t('worksites.show_profil'))}
            onClickHeaderButton={() =>
              navigate(
                PARTNERS_ROUTES_WITH_ID(worksiteDetails.entity_to.id)
                  .PARTNER_VIEW
              )
            }
            dataTestId="worksite_details_partner"
          />
        );
      case ROLES.GESTION:
        return (
          <CardInformations
            title={t('worksites.installer')}
            layout="oneColumn"
            data={partnerOrInstallerDatas(
              t,
              worksiteDetails,
              roleUser,
              ROLES,
              iconsList,
              navigate
            )}
            loading={isLoading}
            customLabelHeaderButton={String(t('worksites.show_profil'))}
            onClickHeaderButton={() => {
              navigate(
                PARTNERS_ROUTES_WITH_ID(worksiteDetails.installer.id)
                  .INSTALLER_VIEW
              );
            }}
            dataTestId="worksite_details_installer"
          />
        );
      /* case ROLES.PILOT:
        return <CardBeneficiary iconsList={iconsList} />; */
      default:
        return <div />;
    }
  }, [roleUser]);

  const canEditContact = useMemo(
    () =>
      user &&
      ![
        'installateur-commercial',
        'installateur-admin',
        'installateur-technique',
      ].includes(user.role_name),
    [user]
  );

  useEffect(() => {
    getUserContacts();
  }, [user]);
  const payload =
    worksiteDetails.payload && JSON.parse(worksiteDetails.payload);
  const withHousing =
    payload && payload['general.age'] && worksiteDetails.housing;

  const operationsWithoutHousingCard = [
    'bat-en-102',
    'bat-en-101',
    'bat-th-112',
    'bat-th-116',
    'res-ch-103',
    'res-ch-104',
    'res-ec-104',
    'ind-ut-114',
  ];

  const noNeedHousingCard = (): boolean => {
    return worksiteDetails.worksites_operations.every(
      (worksiteOperation: IWorksiteOperation) =>
        operationsWithoutHousingCard.includes(
          worksiteOperation.operation.code.toLowerCase()
        )
    );
  };

  return (
    <div className="w-full flex items-start space-x-[1.5rem]">
      <div className="flex flex-col items-start space-y-[1.5rem] w-2/3 max-w-2/3">
        <CardWorksiteInformations
          loading={isLoading}
          refresh={refresh}
          iconsList={iconsList}
        />
        {worksiteDetails.custom_fields &&
          worksiteDetails.custom_fields.length > 0 && (
            <CardWorksiteCustomFields
              customFields={worksiteDetails.custom_fields}
              refresh={refresh}
              worksiteId={worksiteDetails.id}
            />
          )}
        {withHousing && !noNeedHousingCard() && (
          <CardHousing iconsList={iconsList} />
        )}
        <CardBeneficiary iconsList={iconsList} />
      </div>
      <div className="flex flex-col items-start space-y-[1.5rem] w-1/3 max-w-1/3">
        {/* {roleUser === ROLES.PILOT ? (
          <div className="w-full">
            <CardLocalisation address={address} loading={isLoading} />
          </div>
        ) : ( */}
        <CardContacts
          title={t('worksites.intern_referent.title')}
          contactList={contactList}
          loading={isLoading}
          referent={referent}
          setReferent={setReferent}
          canEdit={canEditContact || false}
          dataTestId="worksite_details_referent"
        />
        {/* )} */}
        {renderElement}
      </div>
    </div>
  );
}

export default WorksiteDetailsInfos;
