import { IWorksiteDetails } from '@models/worksites/utils/worksitesTypes';
import { Card } from '@components/Card';
import {
  housingIcons,
  operationIcons,
  worksiteIcons,
} from '@models/beneficiaries/components/view/InfosIcons';
import { InfosWithIcon } from '@components/atomic/InfosWithIcon';
import { v4 } from 'uuid';
import { blueOpx } from '@assets/color';
import { t } from 'i18next';
import { useContext } from 'react';
import { GlobalContext } from '@context/globalContext';
import { removalToDisplay } from '@models/worksiteCreation/utils/functions';
import { OperationTypeEnum } from '@utils/enums';

interface WorksiteInfosProps {
  worksiteDatas: IWorksiteDetails;
}

function WorksiteInfos({ worksiteDatas }: WorksiteInfosProps) {
  const { globalEnum } = useContext(GlobalContext);

  const removal = removalToDisplay(worksiteDatas.worksites_operations);

  return (
    <div className="flex flex-col gap-5">
      {worksiteDatas.housing?.age_type < 4 && (
        <Card title={t('worksites.housing.title')}>
          <div className="grid grid-cols-2 gap-5">
            {housingIcons(worksiteDatas, globalEnum, removal).map((icon) =>
              icon ? (
                <InfosWithIcon
                  key={v4()}
                  loading={false}
                  infos={[
                    {
                      title: icon.title,
                      subtitle: icon.subtitle,
                    },
                  ]}
                  icon={icon.icon || <div />}
                  colorIcon={icon.icon ? blueOpx : 'transparent'}
                />
              ) : null
            )}
          </div>
        </Card>
      )}
      <Card title={t('worksites.worksite_information')}>
        <div>
          <div className="grid grid-cols-2 border border-transparent border-b-1 border-b-borderGrey">
            {worksiteIcons(worksiteDatas)
              .infos.filter(
                (elt) => elt && elt.subtitle !== '' && elt.subtitle !== 'null'
              )
              .map((icon) => {
                return (
                  <InfosWithIcon
                    key={v4()}
                    loading={false}
                    infos={[
                      {
                        title: icon.title,
                        subtitle: icon.subtitle,
                      },
                    ]}
                    icon={icon.icon || undefined}
                    colorIcon={icon.icon ? blueOpx : 'transparent'}
                    addClass={icon.addClass}
                  />
                );
              })}
          </div>
          <div>
            {operationIcons(worksiteDatas).map((op, index) => (
              <div
                key={v4()}
                className={`grid grid-cols-2 ${
                  index < worksiteDatas.worksites_operations.length - 1 &&
                  'border border-transparent border-b-1 border-b-borderGrey '
                } mt-5`}
              >
                {op
                  .filter((elt) =>
                    worksiteDatas.operation_type === OperationTypeEnum.B2B
                      ? elt?.title !== t('worksites.housing.precarity')
                      : elt
                  )
                  .map((icon) => {
                    if (
                      icon?.subtitle === '' ||
                      (icon?.subtitle && icon.subtitle !== 'null')
                    )
                      return (
                        <InfosWithIcon
                          key={v4()}
                          loading={false}
                          infos={[
                            {
                              title: icon?.title,
                              subtitle: icon.subtitle,
                            },
                          ]}
                          icon={icon.icon || <div />}
                          colorIcon={icon?.icon ? blueOpx : 'transparent'}
                          addClass="mb-5"
                        />
                      );
                    return null;
                  })}
              </div>
            ))}
          </div>
        </div>
      </Card>
    </div>
  );
}

export { WorksiteInfos };
