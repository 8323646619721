import { Card } from '@components/Card';
import { IAccountEntite, IAccountInfos } from '@models/users/utils/userTypes';
import { useTranslation } from 'react-i18next';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { Dispatch, SetStateAction, useContext, useRef, useState } from 'react';
import { updateEntityInfos } from '@models/entities/apiRequests/entitiesRequests';
import { GlobalContext } from '@context/globalContext';

interface CardLogoProps {
  infoEntite: IAccountEntite;
  setData: Dispatch<SetStateAction<IAccountInfos>> | undefined;
}

function CardLogo({ infoEntite, setData }: CardLogoProps): JSX.Element {
  const { t } = useTranslation();
  const { updateUserView } = useContext(GlobalContext);

  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const inputFile = useRef(null);

  const handleChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const preview = file ? URL.createObjectURL(file) : infoEntite.logo || '';

  const onSubmit = async (action = 'add') => {
    setIsLoading(true);

    const res = await updateEntityInfos(
      infoEntite.id,
      { logo: action === 'add' ? file : null },
      updateUserView,
      true
    );

    if (res.data && setData) {
      setData((prevState) => {
        return {
          ...prevState,
          informations_entite: {
            ...prevState.informations_entite,
            logo: res.data.logo,
          },
        };
      });
      setFile(null);
      setIsEditMode(false);
    }

    setIsLoading(false);
  };

  return (
    <Card title="" hidePanel>
      <div className="flex justify-between items-start">
        <div className="w-1/2 flex flex-col gap-y-5">
          <div>
            {preview !== '' ? (
              <img
                alt="entityLogo"
                src={preview}
                className="h-[10rem] rounded-default"
              />
            ) : (
              <div className="bg-borderGrey h-[10rem] rounded-default flex items-center justify-center">
                <div>{t('global.logo')}</div>
              </div>
            )}
          </div>
          {isEditMode && (
            <div className="flex flex-col gap-2">
              <ButtonOpx
                label={t('my_account.update_logo')}
                type="primary"
                small
                onClick={() => {
                  (inputFile.current as any).click();
                }}
              />
              <ButtonOpx
                label={t('my_account.delete_logo')}
                type="secondary"
                color="red"
                small
                onClick={() => {
                  setFile(null);
                  onSubmit('delete');
                }}
              />
            </div>
          )}
        </div>
        <div className="flex flex-col h-full justify-between items-end">
          <input
            className="absolute opacity-0"
            onChange={handleChange}
            type="file"
            ref={inputFile}
            style={{ width: 1, height: 1 }}
          />
          <div className="mb-5 flex">
            {isEditMode && (
              <ButtonOpx
                onClick={() => {
                  setIsEditMode(false);
                  setFile(null);
                }}
                label={t('buttons.cancel')}
                type="secondary"
                small
                addClass="!border-transparent"
              />
            )}
            <ButtonOpx
              onClick={() => {
                if (isEditMode && file) onSubmit();
                else setIsEditMode(!isEditMode);
              }}
              label={t(isEditMode ? 'buttons.save' : 'buttons.update')}
              addClass={isEditMode ? '' : '!border-transparent'}
              type={isEditMode ? 'primary' : 'tierciary'}
              isLoading={isLoading}
              small
            />
          </div>
        </div>
      </div>
    </Card>
  );
}

export { CardLogo };
