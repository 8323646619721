import LayoutMarDetailsStep from '@models/mar/components/worksiteCompletion/LayoutMarDetailsStep';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import { STEPS_WORKSITE_MAR_COMPLETION } from '@models/mar/utils/enums';

function Professionals() {
  const { t } = useTranslation();
  const { updateStepCompletion } = useContext(WorksiteCreationMarContext);

  const [buttonNextLoading, setButtonNextLoading] = useState(false);

  const handleValidateStep = async () => {
    setButtonNextLoading(true);
    updateStepCompletion(STEPS_WORKSITE_MAR_COMPLETION.QUOTES);
    setButtonNextLoading(false);
  };

  return (
    <LayoutMarDetailsStep
      onValidate={handleValidateStep}
      buttonNextLoading={buttonNextLoading}
    >
      <div className="flex flex-col space-y-6">
        {t(
          'mar.worksite_creation.worksite_completion.folder_details.professionals.not_available'
        )}
      </div>
    </LayoutMarDetailsStep>
  );
}

export default Professionals;
