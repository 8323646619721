import React, { useContext } from 'react';
import { SidebarProgressCard } from '@models/worksiteCreation/components/sidebarProgress/SidebarProgressCard';
import { StatusStepEnum } from '@models/worksiteCreation/utils/enums';
import { GlobalContext } from '@context/globalContext';
import { useTranslation } from 'react-i18next';
import FolderMarDetailsContent from '@models/mar/components/worksiteCompletion/FolderMarDetailsContent';
import FolderMarDetailsRecap from '@models/mar/components/worksiteCompletion/rightPart/FolderMarDetailsRecap';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import { FormProvider, useForm } from 'react-hook-form';
import { sidebarStepsWithTranslation } from '@models/mar/utils/enums';

function FolderMarDetails() {
  const { t } = useTranslation();
  const { globalEnum } = useContext(GlobalContext);
  const { stepCompletion, worksiteData, updateStepCompletion } = useContext(
    WorksiteCreationMarContext
  );
  const stepsArray = Object.entries(globalEnum.mar_worksite_steps).map(
    ([key, value]) => ({
      label: value,
      value: Number(key),
    })
  );
  const sidebarSteps = sidebarStepsWithTranslation(t);

  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <form id="form-worksite-mar">
        <div className="w-full flex space-x-3">
          <div className="w-[15.2vw] flex flex-col space-y-2">
            {Object.entries(sidebarSteps).map(([key, { label, steps }]) => {
              const filteredSteps = steps
                .map((step) => stepsArray.find((s) => s.value === step))
                .filter((step) => step !== undefined) as {
                label: string;
                value: number;
              }[];
              return (
                <SidebarProgressCard
                  key={key}
                  title={label}
                  status={StatusStepEnum.IN_PROGRESS} // Vous pouvez ajuster le statut selon votre logique
                  steps={filteredSteps}
                  valueAsIndex
                  nameStepActive={stepsArray[stepCompletion - 1].label}
                  onClickStep={(step) => {
                    if (worksiteData && step <= worksiteData?.step) {
                      updateStepCompletion(step);
                    }
                  }}
                />
              );
            })}
          </div>
          <div className="w-[40.2vw]">
            <FolderMarDetailsContent />
          </div>
          <div className="w-[26.66vw]">
            <FolderMarDetailsRecap />
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

export default FolderMarDetails;
