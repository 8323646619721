import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';

function LoaderIncomes() {
  return (
    <div className="flex flex-col space-y-[1rem] pt-[3rem]">
      <div className="w-[15rem]">
        <LoaderSkeleton height="1rem" />
      </div>
      <div className="w-[20rem]">
        <LoaderSkeleton height="1rem" />
      </div>
      <div>
        <LoaderSkeleton height="2rem" />
      </div>
      <div>
        <LoaderSkeleton height="2rem" />
      </div>
      <div>
        <LoaderSkeleton height="2rem" />
      </div>
      <div>
        <LoaderSkeleton height="2rem" />
      </div>
    </div>
  );
}

export default LoaderIncomes;
