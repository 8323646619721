/* eslint-disable no-nested-ternary */
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { StepsWorksiteCreationEnum } from '@models/worksiteCreation/utils/enums';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalRenovation } from '@models/worksiteCreation/components/globalRenovation/GlobalRenovation';
import { InfoLogement } from '@models/worksiteCreation/components/createWorksite/infoLogement/InfoLogement';
import {
  validateWorksiteAfterUploadFiles,
  validateWorksiteCreation,
} from '@models/worksiteCreation/apiRequests/worksiteCreationRequests';
import { useNavigate } from 'react-router-dom';
import { OperationTypeEnum } from '@utils/enums';
import { ISimulatorData } from '@models/worksiteCreation/utils/types/SimulationTypes';
import { calculateDateOfExpiration } from '@models/worksiteCreation/utils/functions';
import StepCustomPrices from '@models/worksiteCreation/components/simulation/stepCustomPrices/StepCustomPrices';
import { StepDocuments } from '../createWorksite/documents/StepDocuments';
import { InfoBeneficiary } from '../createWorksite/infoBeneficiary/InfoBeneficiary';
import { StepMaterial } from '../createWorksite/material/StepMaterial';
import { StepSizingNote } from '../createWorksite/sizingNote/StepSizingNote';
import { StepSubcontractor } from '../createWorksite/subcontractors/StepSubcontractor';
import { ChoiceOperations } from '../simulation/stepChoiceOperation/ChoiceOperations';
import { StepDetailOperation } from '../simulation/stepDetailOperation.tsx/StepDetailOperation';
import { StepHabitationSimulation } from '../simulation/stepHabitationSimulation/StepHabitationSimulation';
import { Summary } from '../simulation/summary/Summary';
import { StepBeneficiarySimulation } from '../simulation/stepBeneficiarySimulation/StepBeneficiarySimulation';

const worksiteCreationButtonIds = {
  withoutSendToBeneficiary:
    'button_validate_worksite_creation_without_send_to_beneficiary',
  withSendToBeneficiary:
    'button_validate_worksite_creation_with_send_to_beneficiary',
};

function ContainerMiddleScreen() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    isLoading,
    updateIsLoading,
    stepActiveWorksiteCreation,
    payloadSizingNote,
    sizingNote,
    worksiteOperationType,
    updateStepActiveWorksiteCreation,
    workflowSidebar,
    disabledNextButton,
    simulatorDataOperations,
    worksiteDatas,
    beneficiary,
    conventionActive,
    simulatorData,
    graphOperation,
    updateWorksiteDatas,
    updateIsModals,
    isModals,
    updateWorkflowSidebar,
    updateErrorValidateSimulation,
    signedQuotesToSend,
    readOnly,
  } = useContext(WorksiteCreationContext);

  const scrollContainerRef = useRef<HTMLDivElement>(null); // Référence pour accéder au conteneur de défilement
  const [containerHeight, setContainerHeight] = useState(0); // État pour stocker la hauteur du conteneur

  const [renderComponent, setRenderComponent] = useState<JSX.Element>(<div />);
  const [idFormButton, setIdFormButton] = useState<string>('sendGraphGeneral');
  const [
    isLoadingValidateAfterUploadFiles,
    setIsLoadingValidateAfterUploadFiles,
  ] = useState(false);
  const [loadingButtonId, setLoadingButtonId] = useState<string | null>(null);

  const {
    SIMULATION_LOGEMENT,
    SIMULATION_CHOICEOPERATIONS,
    SIMULATION_PRIX,
    SIMULATION_RECAPITULATIF,
    SIMULATION_INFO_BENEFICIARY,
    WORKSITE_CREATION_INFO_BENEFICIARY,
    WORKSITE_CREATION_INFO_LOGEMENT,
    WORKSITE_CREATION_MATERIAL,
    WORKSITE_CREATION_SUBCONSTRACTOR,
    SIMULATION_OPERATIONDETAILS,
    WORKSITE_CREATION_ND,
    DOCUMENTS,
  } = StepsWorksiteCreationEnum;

  const dateOfExpiration = calculateDateOfExpiration(
    worksiteDatas,
    conventionActive
  );

  const canValidateSimulation = dateOfExpiration >= new Date();

  const titleStep = useMemo(() => {
    if (stepActiveWorksiteCreation === DOCUMENTS) {
      return t('worksites.documents.title');
    }
    const key =
      stepActiveWorksiteCreation > SIMULATION_RECAPITULATIF ||
      worksiteOperationType === OperationTypeEnum.GLOBAL_RENOVATION
        ? 'creation'
        : 'simulation';

    if (workflowSidebar) {
      const step = workflowSidebar[key].find(
        (item) => item.value === stepActiveWorksiteCreation
      );

      if (
        step &&
        step.value === 1 &&
        worksiteOperationType === OperationTypeEnum.B2B
      ) {
        return t('worksites.housing.title_b2b');
      }

      return step ? step.label : '';
    }

    return '';
  }, [workflowSidebar, stepActiveWorksiteCreation]);

  const onValidateWorksiteCreation = async (option: {
    sendToBeneficiary: boolean;
  }) => {
    setLoadingButtonId(
      option.sendToBeneficiary
        ? worksiteCreationButtonIds.withSendToBeneficiary
        : worksiteCreationButtonIds.withoutSendToBeneficiary
    );

    // ajout de la "version" dans les operations en la récupérant depuis le graphOperation
    const operationWithVersion = simulatorData.operations.map(
      (op: ISimulatorData) => {
        const correspondingGraphOp = graphOperation.find(
          (graphOp) => graphOp.value.toLowerCase() === op['operation.id']
        );
        return correspondingGraphOp
          ? { ...op, version: correspondingGraphOp.version?.code || null }
          : { ...op, version: null };
      }
    );

    const simulatorDataWithVersion = {
      ...simulatorData,
      operations: operationWithVersion,
    };
    if (beneficiary?.id) {
      await validateWorksiteCreation({
        t,
        simulatorDatas: simulatorDataWithVersion,
        setIsLoading: updateIsLoading,
        updateWorksiteDatas,
        setMessageError: updateErrorValidateSimulation,
        setWorkflow: updateWorkflowSidebar,
        operationType: worksiteOperationType,
        setCreationStep: updateStepActiveWorksiteCreation,
        sendToBeneficiary: option.sendToBeneficiary,
        worksiteId: worksiteDatas.id > 0 ? worksiteDatas.id : undefined,
      });
    }
    setLoadingButtonId(null);
  };

  const handleClickBackBtn = () => {
    const isSimulation =
      stepActiveWorksiteCreation <= SIMULATION_RECAPITULATIF &&
      worksiteOperationType !== OperationTypeEnum.GLOBAL_RENOVATION;

    const key = isSimulation ? 'simulation' : 'creation';

    if (workflowSidebar) {
      if (stepActiveWorksiteCreation === DOCUMENTS) {
        const lastIndex = workflowSidebar.creation.length - 1;
        const previousStep = workflowSidebar.creation[lastIndex];
        updateStepActiveWorksiteCreation(previousStep.value);
      } else if (workflowSidebar[key]) {
        const stepIndex =
          workflowSidebar[key].findIndex(
            (step) => step.value === stepActiveWorksiteCreation
          ) || 0;

        if (key !== 'simulation' && stepIndex === 0) {
          const lastIndex = workflowSidebar.simulation.length - 1;
          const previousStep = workflowSidebar.simulation[lastIndex];
          updateStepActiveWorksiteCreation(previousStep.value);
        } else if (workflowSidebar[key][stepIndex - 1]) {
          const previousStep = workflowSidebar[key][stepIndex - 1];
          updateStepActiveWorksiteCreation(previousStep.value);
        }
      }
    }
  };

  const disabledButton = () => {
    const arrayDocuments = [];

    // Ajoute les linkedFiles de worksiteDatas si défini
    if (Array.isArray(worksiteDatas.linkedFiles)) {
      arrayDocuments.push(...worksiteDatas.linkedFiles);
    }

    // Pour chaque worksitesOperations, ajoute les linkedFiles s'ils sont définis et s'il s'agit d'un tableau
    if (Array.isArray(worksiteDatas.worksites_operations)) {
      worksiteDatas.worksites_operations.forEach((operation) => {
        if (Array.isArray(operation.linkedFiles)) {
          arrayDocuments.push(...operation.linkedFiles);
        }
      });
    }

    // Filtre les documents pour ceux qui sont obligatoires
    const newArrayDoc = arrayDocuments.filter((doc) => doc.mandatory === true);

    // Vérifie si un des documents obligatoires n'a pas de file_url
    if (newArrayDoc.some((doc) => doc.file_url === null)) return true;

    // Retourne l'état de chargement pour le bouton
    return isLoadingValidateAfterUploadFiles;
  };

  const onValidateWorksiteAfterUploadFiles = async () => {
    setLoadingButtonId('button_finalize_worksite');
    setIsLoadingValidateAfterUploadFiles(true);
    validateWorksiteAfterUploadFiles(
      worksiteDatas.id,
      setIsLoadingValidateAfterUploadFiles,
      signedQuotesToSend,
      navigate
    );
    setLoadingButtonId(null);
  };

  useEffect(() => {
    switch (stepActiveWorksiteCreation) {
      case SIMULATION_CHOICEOPERATIONS:
        setRenderComponent(<ChoiceOperations />);
        setIdFormButton('sendChoiceOperations');
        break;
      case SIMULATION_LOGEMENT:
        setRenderComponent(<StepHabitationSimulation />);
        setIdFormButton('sendGraphGeneral');
        break;
      case SIMULATION_INFO_BENEFICIARY:
        setRenderComponent(<StepBeneficiarySimulation />);
        setIdFormButton('sendBeneficiarySimulationInfos');
        break;
      case SIMULATION_OPERATIONDETAILS:
        setRenderComponent(<StepDetailOperation />);
        setIdFormButton('sendGraphOperation');
        break;
      case SIMULATION_PRIX:
        setRenderComponent(<StepCustomPrices />);
        setIdFormButton('sendCustomPrices');
        break;
      case SIMULATION_RECAPITULATIF:
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollTop = 0;
        }
        setRenderComponent(<Summary />);
        break;
      case WORKSITE_CREATION_INFO_BENEFICIARY:
        setRenderComponent(<InfoBeneficiary />);
        setIdFormButton('sendInfoBeneficiary');
        break;
      case WORKSITE_CREATION_INFO_LOGEMENT:
        setRenderComponent(<InfoLogement />);
        setIdFormButton('sendInfoLogement');
        break;
      case WORKSITE_CREATION_SUBCONSTRACTOR:
        setRenderComponent(<StepSubcontractor />);
        setIdFormButton('sendSubcontractorToWorksite');
        break;
      case WORKSITE_CREATION_ND:
        setRenderComponent(<StepSizingNote />);
        setIdFormButton('sendSizingNote');
        break;
      case WORKSITE_CREATION_MATERIAL:
        setRenderComponent(<StepMaterial />);
        setIdFormButton('sendMaterialToWorksite');
        break;
      case DOCUMENTS:
        setRenderComponent(<StepDocuments />);
        setIdFormButton('');
        break;
      default:
    }
  }, [stepActiveWorksiteCreation]);

  /* useEffect(() => {
    if (disabledNextButton && scrollContainerRef.current) {
      // Défile au bas du conteneur de défilement
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current.scrollHeight;
    }
  }, [simulatorDataOperations]); // Dépendez de la variable qui déclenche la mise à jour du contenu */

  useEffect(() => {
    // Cette fonction vérifie la hauteur actuelle et la met à jour si nécessaire
    const checkAndUpdateHeight = () => {
      if (scrollContainerRef.current) {
        const currentHeight = scrollContainerRef.current.scrollHeight;
        if (currentHeight !== containerHeight) {
          setContainerHeight(currentHeight); // Mise à jour de l'état de la hauteur
          // Ici, tu peux aussi décider de déclencher un scroll si l'ancienne hauteur est différente
          if (currentHeight > containerHeight) {
            scrollContainerRef.current.scrollTop +=
              currentHeight - containerHeight;
          }
        }
      }
    };

    // Appelle la fonction chaque fois que simulatorDataOperations change
    if (disabledNextButton) {
      checkAndUpdateHeight();
    }
  }, [simulatorDataOperations, containerHeight]);

  return (
    <div className="relative w-full border border-borderGrey bg-white rounded-default max-h-[calc(100vh_-_9.625rem)] flex flex-col">
      <div
        className={`p-[1.5rem] flex justify-between w-full items-center ${
          stepActiveWorksiteCreation !== DOCUMENTS &&
          stepActiveWorksiteCreation !== SIMULATION_RECAPITULATIF
            ? 'border-b border-b-borderGrey'
            : ''
        }`}
      >
        <p
          className="text-[1.5rem] font-medium"
          data-test-id={`title_middle_container_step_${stepActiveWorksiteCreation}`}
        >
          {titleStep}
        </p>
        <div className="flex gap-5">
          {stepActiveWorksiteCreation !== SIMULATION_CHOICEOPERATIONS && (
            <ButtonOpx
              label={t('global.back')}
              onClick={handleClickBackBtn}
              type="secondary"
              dataTestId="button_back_step"
              addClass={
                stepActiveWorksiteCreation === DOCUMENTS
                  ? '!w-full mt-[1rem]'
                  : ''
              }
            />
          )}
          {stepActiveWorksiteCreation === SIMULATION_RECAPITULATIF &&
            canValidateSimulation && (
              <>
                {beneficiary !== null && !readOnly && (
                  <ButtonOpx
                    dataTestId="button_validate_worksite_creation_and_send_to_beneficiary"
                    type="tab"
                    onClick={() =>
                      onValidateWorksiteCreation({ sendToBeneficiary: true })
                    }
                    isLoading={
                      loadingButtonId ===
                      worksiteCreationButtonIds.withSendToBeneficiary
                    }
                    label={t(
                      'buttons.valid_worksite_creation_and_send_to_beneficiary'
                    )}
                  />
                )}
                {!readOnly ? (
                  <ButtonOpx
                    dataTestId={
                      beneficiary !== null
                        ? 'button_validate_worksite_creation'
                        : 'button_bind_beneficiary'
                    }
                    type="primary"
                    onClick={
                      beneficiary !== null
                        ? () =>
                            onValidateWorksiteCreation({
                              sendToBeneficiary: false,
                            })
                        : () =>
                            updateIsModals({
                              ...isModals,
                              bindBeneficiary: true,
                            })
                    }
                    isLoading={
                      loadingButtonId ===
                      worksiteCreationButtonIds.withoutSendToBeneficiary
                    }
                    label={
                      beneficiary !== null
                        ? t('buttons.valid_worksite_creation')
                        : t('buttons.bind_beneficiary')
                    }
                  />
                ) : (
                  <ButtonOpx
                    type="primary"
                    onClick={() =>
                      workflowSidebar &&
                      updateStepActiveWorksiteCreation(
                        workflowSidebar.creation[0].value
                      )
                    }
                    isLoading={isLoading}
                    label={t('buttons.next')}
                  />
                )}
              </>
            )}
          {stepActiveWorksiteCreation !== SIMULATION_RECAPITULATIF &&
            stepActiveWorksiteCreation !== DOCUMENTS && (
              <ButtonOpx
                label={
                  stepActiveWorksiteCreation !== WORKSITE_CREATION_ND ||
                  readOnly
                    ? t('buttons.next')
                    : stepActiveWorksiteCreation === WORKSITE_CREATION_ND &&
                      payloadSizingNote.generate &&
                      sizingNote === ''
                    ? t(
                        'worksite_creation.create_worksite.sizing_note.generate_sizing_note'
                      )
                    : t('buttons.validate')
                }
                addClass="!px-16"
                formId={idFormButton}
                isSubmitBtn
                disabled={disabledNextButton}
                isLoading={isLoading}
                dataTestId="button_next"
              />
            )}
          {stepActiveWorksiteCreation === DOCUMENTS && (
            <ButtonOpx
              disabled={disabledButton()}
              label={t('worksite_creation.finalize_worksite_creation')}
              addClass="!w-full mt-[1rem]"
              onClick={() => onValidateWorksiteAfterUploadFiles()}
              isLoading={loadingButtonId === 'button_finalize_worksite'}
              dataTestId="button_finalize_worksite"
            />
          )}
        </div>
      </div>
      <div
        ref={scrollContainerRef}
        className={`p-[1.5rem] pt-0 flex flex-col flex-grow ${
          stepActiveWorksiteCreation !==
          StepsWorksiteCreationEnum.SIMULATION_CHOICEOPERATIONS
            ? 'overflow-y-scroll scroll-smooth'
            : 'overflow-y-hidden'
        } `}
      >
        {worksiteOperationType === OperationTypeEnum.GLOBAL_RENOVATION ? (
          <GlobalRenovation setIdFormButton={setIdFormButton} />
        ) : (
          renderComponent
        )}
      </div>
    </div>
  );
}

export { ContainerMiddleScreen };
