/* eslint-disable react/require-default-props */

import { blueOpx } from '@assets/color';
import { useEffect, useState } from 'react';

interface IProps {
  label: string;
  isToggleOn: boolean;
  onToggle?: (isOn: boolean) => void;
  colorToggle?: string;
  disabled?: boolean;
  addClass?: string;
  tooltip?: string;
  id?: string;
}

// EXEMPLE D'UTILISATION DANS UN COMPOSANT PARENT
// 1. useState de la value séléctionné
// const [isToggleOn, setIsToggleOn] = useState(false);
// 2. fonction à passer en propriété du composant
// const handleToggle = (isOn: boolean) => {
//   setIsToggleOn(isOn);
// }
// return (
//   <div className="w-full items-center justify-center flex ">
//  3. Import des composants avec les props à passer
//     <Toggle label="toggle1" onToggle={(isOn: any) => handleToggle(isOn)} isToggleOn={isToggleOn} colorToggle="red"/>
//   </div>
// );

function Toggle({
  label,
  onToggle,
  isToggleOn,
  colorToggle,
  disabled,
  addClass,
  tooltip,
  id,
}: IProps) {
  const [isChecked, setIsChecked] = useState(isToggleOn || false);

  const handleChange = () => {
    setIsChecked(!isChecked);
    if (onToggle) {
      onToggle(!isChecked);
    }
  };

  useEffect(() => {
    setIsChecked(isToggleOn);
  }, [isToggleOn]);

  return (
    <label
      htmlFor={id || label}
      title={tooltip || label}
      className={['custom-toggle cursor-pointer', addClass].join(' ')}
      style={{
        backgroundColor: isToggleOn ? colorToggle : '#EEEEEE',
      }}
    >
      <input
        id={id || label}
        type="checkbox"
        checked={isToggleOn}
        onChange={handleChange}
        disabled={disabled}
      />
      <span className="slider" />
    </label>
  );
}

export { Toggle };

Toggle.defaultProps = {
  colorToggle: blueOpx,
  disabled: false,
  addClass: '',
  tooltip: undefined,
  onToggle: undefined,
  id: undefined,
};
