import { IWorksiteMar } from '@models/mar/utils/marTypes';
import { MAR_ROUTES_WITH_ID } from '@utils/routesUrls';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { dateToDDMMYYY } from '@utils/format';
import { Tag } from '@components/atomic/Tag';
import { ProgressBarHorizontal } from '@components/atomic/ProgressBarHorizontal';
import { STEPS_WORKSITE_MAR_COMPLETION } from '@models/mar/utils/enums';
import { useContext } from 'react';
import { GlobalContext } from '@context/globalContext';
import { green, red } from '@assets/color';

interface IWorksiteMarCardInListProps {
  worksiteData: IWorksiteMar;
}

function WorksiteMarCardInList({ worksiteData }: IWorksiteMarCardInListProps) {
  const { t } = useTranslation();
  const { globalEnum } = useContext(GlobalContext);

  const stepColor =
    worksiteData.step === STEPS_WORKSITE_MAR_COMPLETION.END ? green : red;

  const address = worksiteData.address
    ? `${worksiteData.address.address}, ${worksiteData.address.postal_code} ${worksiteData.address.city}`
    : ' ';

  const prime = worksiteData.total_cost?.prime;
  const totalCost = worksiteData.total_cost?.cost_ttc;
  const remainingBalance = worksiteData.total_cost?.remaining_amount;

  return (
    <Link to={MAR_ROUTES_WITH_ID(worksiteData.id).WORKSITES_NEW_ID}>
      <div className="w-full bg-white border cursor-pointer border-borderGrey rounded-default grid grid-cols-3 text-[.875rem] font-normal">
        <div className="border-r border-borderGrey">
          <div className="p-4 flex flex-col space-y-2">
            <p className="font-medium text-[1.25rem]">
              {worksiteData.customer?.firstname}{' '}
              {worksiteData.customer?.lastname}
            </p>
            <p className="h-6">{address}</p>
          </div>
        </div>
        <div className="col-span-2">
          <div className="p-4 flex flex-col h-full">
            <div className="flex items-center space-x-4 justify-end">
              <span className="text-[.875rem]">
                {t('mar.progress.kpi.subsidy')} :{' '}
              </span>
              <span className="font-medium text-[1rem]">
                {prime > 0 ? prime?.toLocaleString() : '-'} €
              </span>
            </div>
            <div className="flex items-center space-x-[1rem] w-full">
              <Tag
                color={stepColor}
                label={`${worksiteData.step} - ${
                  globalEnum.mar_worksite_steps[worksiteData.step]
                }`}
                addClass="whitespace-nowrap"
              />
              <div className="w-full" data-test-id="op_progress">
                <ProgressBarHorizontal
                  percent={`${String(
                    (worksiteData.step / STEPS_WORKSITE_MAR_COMPLETION.END) *
                      100
                  )}%`}
                  color={stepColor}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-3 border-t border-borderGrey">
          <div className="p-4 flex justify-between">
            <div className="flex flex-col space-y-2">
              <div data-test-id="created_at">
                {`${t('global.created_at')}: ${dateToDDMMYYY(
                  worksiteData.created_at
                )}`}
              </div>
              <div data-test-id="last_update">
                {`${t('worksites.update')}: ${dateToDDMMYYY(
                  worksiteData.updated_at
                )}`}
              </div>
            </div>
            <div className="flex flex-col space-y-2 text-right">
              <div>
                {t('worksites.cost')}
                {' : '}
                <span className="font-medium">
                  {totalCost ? totalCost.toLocaleString() : '-'} €
                </span>
              </div>
              <div>
                {t('worksites.remainingBalance')}
                {' : '}
                <span className="font-medium">
                  {remainingBalance ? remainingBalance.toLocaleString() : '-'} €
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default WorksiteMarCardInList;
