import {
  IGraph,
  IOptionGraph,
} from '@models/worksiteCreation/utils/types/SimulationTypes';
import { InputText } from '@components/atomic/inputs/InputText';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { useContext, useEffect } from 'react';
import { v4 } from 'uuid';
import { placeholderExample } from '@utils/utils';
import { useTranslation } from 'react-i18next';

interface IGeneralInformationForm {
  infosGraph: IGraph[] | undefined;
}

function BeneficiarySimulationForm({ infosGraph }: IGeneralInformationForm) {
  const { t } = useTranslation();
  const { simulatorData, updateSimulatorData, readOnly } = useContext(
    WorksiteCreationContext
  );

  const placeholders = placeholderExample(t);

  useEffect(() => {
    if (!simulatorData['general.persons'])
      updateSimulatorData((prevState) => ({
        ...prevState,
        'general.persons': '0',
      }));
  }, []);

  const renderInput = (graph: IGraph) => {
    const arrayValues: IOptionGraph[] = [];
    switch (graph.key) {
      case 'general.owner':
        graph.options?.map((option) =>
          arrayValues.push({ label: option.label, value: option.value })
        );
        return (
          <div key={v4()} className="w-full">
            <InputSelect
              dataTestIdSelect={`input_select_${graph.key}`}
              label={`${graph.name}`}
              required
              placeholder={placeholders.SELECT}
              dataLabelValue={arrayValues}
              onSelectLabelValue={(e) => {
                updateSimulatorData({
                  ...simulatorData,
                  [graph.key]: e.value,
                });
              }}
              valueInput={
                simulatorData[graph.key] !== null &&
                simulatorData[graph.key] !== undefined
                  ? arrayValues.filter(
                      (item: { label: string; value: string }) =>
                        item.value === simulatorData[graph.key]
                    )[0].label
                  : placeholders.SELECT
              }
              disabled={readOnly}
            />
          </div>
        );
      case 'general.persons':
        return (
          <InputText
            dataTestId={`input_number_${graph.key}`}
            label={graph.name}
            typeNumber
            key={graph.key}
            title={graph.name}
            placeholder="2"
            required
            valueInitialInput={
              simulatorData[graph.key] !== '0'
                ? simulatorData[graph.key]
                : undefined
            }
            name={graph.key}
            id={graph.key}
            onChange={(e) => {
              if (typeof e === 'string') {
                updateSimulatorData({
                  ...simulatorData,
                  [graph.key]: parseFloat(e.replace(',', '.')) || undefined, // Remplacer la virgule par un point pour une conversion correcte
                });
              }
            }}
            disabled={readOnly}
            addClassToInput={readOnly ? 'bg-backgroundBody' : ''}
          />
        );
      default:
        return <div key={graph.key} />;
    }
  };

  return (
    <div className="flex flex-col space-y-[.5rem]">
      <div className="flex space-x-[.5rem] w-full items-start">
        {infosGraph
          ?.filter(
            (graph) =>
              graph.key === 'general.owner' || graph.key === 'general.persons'
          )
          .sort((a, _b) => {
            if (a.key === 'general.owner') return -1;
            if (a.key === 'general.persons') return 1;
            return 0;
          })
          .map((graph) => renderInput(graph))}
      </div>
    </div>
  );
}

export default BeneficiarySimulationForm;
