import { black, blueOpx, borderGrey } from '@assets/color';
import { Modal } from '@components/Modal';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { RadioGroupCard } from '@components/atomic/inputs/controls/RadioGroupCard';
import { GlobalContext } from '@context/globalContext';
import { PaymentMethod } from '@hyperline/react-components';
import {
  getCreditsPricingForEntity,
  postBuyCredits,
} from '@models/entities/apiRequests/entitiesRequests';
import { getBillingToken } from '@models/users/apiRequests/userRequests';
import { t } from 'i18next';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { toast } from 'react-toastify';

interface IRadioOptions {
  unit_count: string;
  amount: number;
  display: string;
  legend?: string;
}

interface IDocumentModalCreditsPaymentProps {
  setNbCreditsEntity: Dispatch<SetStateAction<number>>;
  setIsModalBuyCredits: Dispatch<SetStateAction<boolean>>;
}

function DocumentModalCreditsPayment({
  setNbCreditsEntity,
  setIsModalBuyCredits,
}: IDocumentModalCreditsPaymentProps) {
  const { userView } = useContext(GlobalContext);

  const [token, setToken] = useState<string>('notset'); // because there is an Hyperline error if length < 5
  const [mode, setMode] = useState<'production' | 'sandbox' | undefined>(
    'production'
  );

  const [isValidPaymentMethod, setIsValidPaymentMethod] = useState(false);
  const [showPaymentMethod, setShowPaymentMethod] = useState(false);

  const [isBuyingCredits, setIsBuyingCredits] = useState(false);
  const [radioOptionsBuyCredits, setRadioOptionsBuyCredits] =
    useState<IRadioOptions[]>();
  const [selectedRadioOption, setSelectedRadioOption] =
    useState<IRadioOptions>();

  const [showInvalidPayment, setShowInvalidPayment] = useState(false);

  const appearancePaymentMethod = {
    variables: {
      colorPrimary: blueOpx,
    },
    rules: {
      input: {
        color: black,
        boxShadow: 'none',
        borderStyle: `1px solid ${borderGrey}`,
      },
    },
  };

  const buyCredits = async () => {
    if (userView && userView.entity_id) {
      getCreditsPricingForEntity(Number(userView.entity_id)).then(
        (res: any) => {
          setRadioOptionsBuyCredits(res.pricing);
          setIsValidPaymentMethod(res.valid_payment_method);
          setShowInvalidPayment(!res.valid_payment_method);
        }
      );
    }
    if (
      selectedRadioOption &&
      selectedRadioOption.unit_count &&
      userView &&
      userView.entity_id
    ) {
      setIsBuyingCredits(true);
      const resultBuyCredits = await postBuyCredits(
        userView.entity_id,
        Number(selectedRadioOption.unit_count)
      );
      if (resultBuyCredits) {
        setNbCreditsEntity(resultBuyCredits);
        if (Number(selectedRadioOption.unit_count) === 1) {
          toast.success(t('toast.success.credit_bought'));
        } else {
          toast.success(
            t('toast.success.credits_bought', {
              count: Number(selectedRadioOption.unit_count),
            })
          );
        }
        setIsModalBuyCredits(false);
        setShowInvalidPayment(false);
      }
      setIsBuyingCredits(false);
    }
  };

  const getToken = async () => {
    await getBillingToken(setToken, setMode);
  };

  useEffect(() => {
    getToken();
    if (userView && userView.entity_id) {
      getCreditsPricingForEntity(userView.entity_id).then((res: any) => {
        if (res) {
          setRadioOptionsBuyCredits(res.pricing);
          setIsValidPaymentMethod(res.valid_payment_method);
        }
      });
    }
  }, []);

  return (
    <Modal
      title={t('global.buy_credits')}
      backgroundTransparent
      btnCancelIsIcon
      onClickCancel={() => setIsModalBuyCredits(false)}
      width="35%"
    >
      <div className="my-4 flex flex-col gap-4">
        <div className="flex justify-center flex-wrap gap-2">
          <RadioGroupCard
            options={radioOptionsBuyCredits}
            setSelectedRadioOption={setSelectedRadioOption}
            selectedRadioOption={selectedRadioOption}
            isValidPaymentMethod={isValidPaymentMethod}
            setShowPaymentMethod={setShowPaymentMethod}
          />
        </div>
        {!isValidPaymentMethod && (
          <div
            style={{
              position: showPaymentMethod ? 'static' : 'absolute',
              opacity: showPaymentMethod ? 1 : 0,
            }}
          >
            <hr />
            <div className="flex justify-center flex-wrap my-4">
              <div className={showPaymentMethod ? 'w-[30rem]' : 'w-[0rem]'}>
                <PaymentMethod
                  options={{ token, mode, appearance: appearancePaymentMethod }}
                  onPaymentMethodCreated={() => {
                    setIsValidPaymentMethod(true);
                    setSelectedRadioOption(selectedRadioOption);
                  }}
                />
              </div>
            </div>
            <hr />
          </div>
        )}
        {showInvalidPayment && (
          <div className="flex justify-center text-red-500">
            {t('global.payment_method_not_valid')}
          </div>
        )}
        <div className="flex justify-center">
          <ButtonOpx
            label="Acheter"
            onClick={() => buyCredits()}
            isLoading={isBuyingCredits}
            disabled={!selectedRadioOption || isBuyingCredits}
          />
        </div>
      </div>
    </Modal>
  );
}

export { DocumentModalCreditsPayment };
