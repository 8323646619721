import LayoutMarDetailsStep from '@models/mar/components/worksiteCompletion/LayoutMarDetailsStep';
import { useContext, useEffect, useState } from 'react';
import { formHousingAnalysis } from '@models/mar/utils/datas';
import { useTranslation } from 'react-i18next';
import OneMarForm from '@models/mar/components/worksiteCompletion/OneMarForm';
import { useFormContext } from 'react-hook-form';
import { validateHousingAnalysis } from '@models/mar/apiRequests/worksiteCreationMarRequests';
import {
  AUDITOR_TYPES,
  STEPS_WORKSITE_MAR_COMPLETION,
} from '@models/mar/utils/enums';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import { IWorksiteMar } from '@models/mar/utils/marTypes';
import {
  determineTypeOfAuditor,
  setFileFromWorksiteData,
} from '@models/mar/utils/functions';
import { fileTypeEnum } from '@utils/enums';
import { AuthContext } from '@context/authContext';
import { GlobalContext } from '@context/globalContext';

function HousingAnalysis() {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { userView } = useContext(GlobalContext);
  const {
    worksiteData,
    updateWorksiteData,
    updateStepCompletion,
    updateButtonNextDisabled,
  } = useContext(WorksiteCreationMarContext);

  const [buttonNextLoading, setButtonNextLoading] = useState(false);

  const { watch, setValue } = useFormContext();

  const checkIfHousingAnalysisInfoReady =
    !!watch('first_visit_date_mar') &&
    watch('first_visit_date_mar') !== '' &&
    !!watch('unfit') &&
    watch('unfit') !== '' &&
    !!watch('enhancement') &&
    watch('enhancement') !== '' &&
    !!watch('adaptation') &&
    watch('adaptation') !== '' &&
    !!watch('summary_grid_file');

  const handleValidateStep = async () => {
    setButtonNextLoading(true);
    const dataToSend = {
      visit_date: watch('first_visit_date_mar'),
      unfit: watch('unfit'),
      enhancement: watch('enhancement'),
      adaptation: watch('adaptation'),
      file: watch('summary_grid_file'),
    };
    const response = await validateHousingAnalysis(
      worksiteData?.id || 0,
      dataToSend
    );
    setButtonNextLoading(false);

    if (response) {
      updateWorksiteData((prev) => ({
        ...prev,
        ...response,
      }));
      updateStepCompletion(
        STEPS_WORKSITE_MAR_COMPLETION.SCENARIOS_AND_CALCULATIONS
      );
    }
  };

  const typeOfAuditor = determineTypeOfAuditor(worksiteData, user, userView);

  const shouldUseAuditorVisitDate =
    typeOfAuditor !== AUDITOR_TYPES.ENGINEERING_OFFICE;

  const defaultValueFirstVisitDate = shouldUseAuditorVisitDate
    ? worksiteData?.audit?.visit_date
    : undefined;

  const preFillHousingAnalysisData = async () => {
    if (worksiteData) {
      if (worksiteData.unfit) {
        setValue('unfit', worksiteData.unfit.toString());
      }
      if (worksiteData.enhancement) {
        setValue('enhancement', worksiteData.enhancement.toString());
      }
      if (worksiteData.adaptation) {
        setValue('adaptation', worksiteData.adaptation.toString());
      }
      if (worksiteData.linked_files && worksiteData.linked_files.length > 0) {
        await setFileFromWorksiteData(
          worksiteData,
          fileTypeEnum.ANALYSE_LOGEMENT,
          'summary_grid_file',
          setValue
        );
      }
      // Le prefill de la date de la première visite est fait rendu du formulaire en tant que defaultValue
    }
  };

  useEffect(() => {
    updateButtonNextDisabled(!checkIfHousingAnalysisInfoReady);
  }, [watch()]);

  useEffect(() => {
    preFillHousingAnalysisData();
  }, []);

  return (
    <LayoutMarDetailsStep
      onValidate={handleValidateStep}
      buttonNextLoading={buttonNextLoading}
    >
      <div className="flex flex-col space-y-6">
        {formHousingAnalysis(
          t,
          worksiteData as IWorksiteMar,
          defaultValueFirstVisitDate
        ).map((form) => (
          <OneMarForm key={form.title} dataForm={form} />
        ))}
      </div>
    </LayoutMarDetailsStep>
  );
}

export default HousingAnalysis;
