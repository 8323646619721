/* eslint-disable no-shadow */
export enum FilterCriteriaEnum {
  VOLUME = 1,
  ETAPE = 2,
  TYPE_OPERATION = 3,
  PRIME = 4,
  NOMBRE_OPERATION = 5,
  CONVENTION = 6,
  RAI = 7,
  RAI_PARTENAIRE = 8,
  TYPE_MENAGE = 9,
  INSTALLATEUR = 10,
  TYPE_DEMANDE = 11,
  PRENOM_NOM = 12,
  VILLE = 13,
  CODE_POSTAL = 14,
  COMPANY_NAME = 15,
  SIRET = 16,
  ADDRESS = 17,
  ENTITY_TYPE = 18,
  ENTITY_STATUS = 19,
  REFERENT = 20,
  DATE = 25,
}
