import { green, orange, red, textGrey } from '@assets/color';
import { Tag } from '@components/atomic/Tag';
import { DOCUMENT_STATE_STRING } from '@utils/utils';
import { useEffect, useState } from 'react';

interface IDocumentStatusTagProps {
  status: string | undefined;
}

interface IDocumentStatusSettings {
  label: string;
  color: string;
}

function DocumentStatusTag({
  status,
}: IDocumentStatusTagProps): React.JSX.Element {
  const [documentStatusSettings, setDocumentStatusSettings] =
    useState<IDocumentStatusSettings>({ label: '', color: '' });

  function statusManager() {
    switch (status) {
      case DOCUMENT_STATE_STRING.TO_IMPORT:
        setDocumentStatusSettings({
          label: DOCUMENT_STATE_STRING.TO_IMPORT,
          color: red,
        });
        break;
      case DOCUMENT_STATE_STRING.PENDING:
        setDocumentStatusSettings({
          label: DOCUMENT_STATE_STRING.PENDING,
          color: orange,
        });
        break;
      case DOCUMENT_STATE_STRING.REFUSE:
        setDocumentStatusSettings({
          label: DOCUMENT_STATE_STRING.REFUSE,
          color: red,
        });
        break;
      case DOCUMENT_STATE_STRING.VALIDATE:
        setDocumentStatusSettings({
          label: DOCUMENT_STATE_STRING.VALIDATE,
          color: green,
        });
        break;
      case DOCUMENT_STATE_STRING.TO_SIGN:
        setDocumentStatusSettings({
          label: DOCUMENT_STATE_STRING.TO_SIGN,
          color: textGrey,
        });
        break;
      case DOCUMENT_STATE_STRING.AWAITING_SIGNATURE:
        setDocumentStatusSettings({
          label: DOCUMENT_STATE_STRING.AWAITING_SIGNATURE,
          color: textGrey,
        });
        break;
      case DOCUMENT_STATE_STRING.SIGNED:
        setDocumentStatusSettings({
          label: DOCUMENT_STATE_STRING.SIGNED,
          color: green,
        });
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    statusManager();
  }, [status]);

  return (
    <Tag
      color={documentStatusSettings.color}
      label={documentStatusSettings.label}
    />
  );
}

export default DocumentStatusTag;
