import {
  useMemo,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FieldValues } from 'react-hook-form';
import _ from 'lodash';

import { CardInformations } from '@components/informations/CardInformations';
import { CardUserInfos } from '@models/users/components/CardUserInfos';
import { CardUserDocs } from '@models/users/components/CardUserDocs';

import { IInfosLine } from 'types/globalTypes';
import {
  IAccountInfos,
  IAccountSignatory,
  IUserDocsType,
} from '@models/users/utils/userTypes';
import {
  addEntitySignatory,
  updateEntitySignatory,
  deleteEntitySignatory,
} from '@models/users/apiRequests/userRequests';
import { DEFAULT_SIGNATORY } from '@models/users/utils/userConstants';
import {
  formatSignatoryParams,
  formatSignatoryRow,
} from '@models/users/utils/userFunctions';

import {
  InfoCircleIcon,
  MailIcon,
  PhoneIcon,
  PositionIcon,
} from '@assets/images/svgComponents';
import { updateEntityInfos } from '@models/entities/apiRequests/entitiesRequests';
import { GlobalContext } from '@context/globalContext';
import { green, orange, red } from '@assets/color';
import { EntityStatusEnum } from '@models/worksiteCreation/utils/enums';
// import { CardLogo } from '@models/users/components/CardLogo';
import { useNavigate } from 'react-router-dom';
import { ROLES } from '@utils/roles';
import { ADMIN_ROUTES } from '@utils/routesUrls';
import { CardLogo } from '@models/users/components/CardLogo';

interface ITabAccountInfosProps {
  data: IAccountInfos;
  setData?: Dispatch<SetStateAction<IAccountInfos>>;
  loading: boolean;
  refetch: CallableFunction;
  uploadDisabled?: boolean;
}

function TabAccountInfos({
  data,
  loading,
  refetch,
  uploadDisabled,
  setData,
}: ITabAccountInfosProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { updateUserView, globalEnum, roleUser } = useContext(GlobalContext);

  const [signatoryRows, setSignatoryRows] = useState<IInfosLine[][]>([
    [DEFAULT_SIGNATORY],
  ]);

  const addSignatory = async (param: FieldValues) => {
    await addEntitySignatory(
      param as IAccountSignatory,
      data.informations_entite.id
    );
  };

  const updateSignatory = async (param: FieldValues) => {
    await updateEntitySignatory(param as IAccountSignatory);
  };

  const deleteSignatory = async (id: number) => {
    if (id !== 0) {
      await deleteEntitySignatory(id);
    }
    refetch();
  };

  const handleSubmitSignatory = async (formData: FieldValues) => {
    const params = formatSignatoryParams(formData);

    await Promise.all(
      params.map((param: FieldValues) => {
        if (param.id === 0) {
          addSignatory(param);
        } else {
          updateSignatory(param);
        }
        return null;
      })
    ).then(() => refetch());
  };

  const infosUser = useMemo(() => data.informations_utilisateur, [data]);
  const infosEntite = useMemo(() => data.informations_entite, [data]);
  const isAdmin = useMemo(() => {
    return infosUser.profile_type === 2;
  }, [infosUser]);
  const isSuperAdmin = useMemo(() => {
    return roleUser === ROLES.ADMIN;
  }, [infosUser]);

  const userDocuments: IUserDocsType[] = useMemo(() => {
    const files: IUserDocsType[] = [];

    const { contracts, conventions } = data.informations_entite;
    const { linkedFiles, entity_type_files, firstname, lastname } =
      data.informations_utilisateur;

    contracts.forEach((contract) => {
      if (contract.linkedFiles && contract.linkedFiles.length > 0) {
        files.push({
          title: contract.reference,
          documents: contract.linkedFiles || [],
        });
      }
    });

    conventions.forEach((convention) => {
      if (convention.linkedFiles && convention.linkedFiles.length > 0) {
        files.push({
          title: convention.reference,
          documents: convention.linkedFiles || [],
        });
      }
    });

    if (linkedFiles && linkedFiles.length > 0) {
      linkedFiles.forEach((linkedFile) => {
        files.push({
          title:
            linkedFile.file_url?.substring(
              (linkedFile.file_url?.lastIndexOf('/') || 0) + 1
            ) || '',
          documents: [linkedFile] || [],
        });
      });
    }

    if (entity_type_files && entity_type_files.length > 0) {
      entity_type_files.forEach((linkedFile) => {
        files.push({
          title: `${firstname} - ${lastname}`,
          documents: [linkedFile] || [],
        });
      });
    }

    return files;
  }, [data]);

  const initSignatories = () => {
    const signatoryArray =
      data.informations_entite.signatories.length === 0
        ? [DEFAULT_SIGNATORY]
        : _.sortBy(data.informations_entite.signatories, [(o) => o.id]);

    const formatSignatories = signatoryArray.map(
      (signatory: IAccountSignatory, iRow: number) =>
        formatSignatoryRow(signatory, isAdmin, t, iRow)
    );
    setSignatoryRows(formatSignatories);
  };

  const onClickAddSignatory = () => {
    const formattedNewSignatory = formatSignatoryRow(
      DEFAULT_SIGNATORY,
      isAdmin,
      t,
      signatoryRows.length
    );
    setSignatoryRows([...signatoryRows, formattedNewSignatory]);
  };

  useEffect(() => {
    initSignatories();
  }, [data, isAdmin]);

  const onEditInfosGenerales = async (dataGenerales: FormData) => {
    const res = await updateEntityInfos(
      infosEntite.id,
      dataGenerales,
      updateUserView
    );
    if (setData && res.data) {
      setData({
        ...data,
        informations_entite: { ...data.informations_entite, ...res.data },
      });
    }
  };

  const colorTag = (status: number) => {
    switch (status) {
      case EntityStatusEnum.GHOST:
        return orange;
      case EntityStatusEnum.ACTIVE:
        return green;
      default:
        return red;
    }
  };

  return (
    <div className="space-y-5">
      <div className="grid grid-cols-3 gap-5">
        {/* infos personnelles */}
        <CardUserInfos
          data={infosUser}
          loading={loading}
          isAdmin={isAdmin}
          refetch={refetch}
          addClass="col-span-2 h-full"
        />
        <CardLogo infoEntite={infosEntite} setData={setData} />{' '}
      </div>
      <div className="grid grid-cols-3 gap-5">
        <div className="col-span-2 space-y-5">
          {/* infos entite */}
          <CardInformations
            isAccountUser={isAdmin}
            title={t('partners.general_infos')}
            data={{
              status: {
                color: colorTag(infosEntite.status),
                label: globalEnum.entity_status[Number(infosEntite.status)],
              },
              rows: [
                [
                  {
                    name: 'company_name',
                    title: t('partners.company_name'),
                    value: infosEntite.company_name,
                    icon: <InfoCircleIcon />,
                  },
                  { empty: true },
                  {
                    name: 'siret',
                    title: t('partners.siret'),
                    value: infosEntite.siret,
                  },
                  {
                    name: 'siren',
                    title: t('partners.siren'),
                    value: infosEntite.siren,
                  },
                  {
                    name: 'revenue',
                    title: t('my_account.turnover'),
                    value: infosEntite.revenue,
                    isEditable: isAdmin,
                  },
                  {
                    name: 'employees_number',
                    title: t('my_account.nb_employees'),
                    value: infosEntite.employees_number,
                    isEditable: isAdmin,
                    type: 'number',
                  },
                  {
                    name: 'iban_payment',
                    title: t('partners.iban'),
                    value: infosEntite.iban_payment,
                    isEditable: isAdmin,
                  },
                ],
                [
                  {
                    name: 'address',
                    title: t('my_account.head_office'),
                    type: 'address',
                    icon: <PositionIcon />,
                    value: `${infosEntite.address}${
                      infosEntite.city ? `, ${infosEntite.city}` : ''
                    }${infosEntite.country ? `, ${infosEntite.country}` : ''}`,
                  },
                ],
                [
                  {
                    name: 'contact_email',
                    title: t('partners.email_address'),
                    icon: <MailIcon />,
                    value: infosEntite.contact_email,
                    isEditable: isAdmin,
                    type: 'email',
                  },
                  {
                    name: 'website',
                    title: t('my_account.website'),
                    value: infosEntite.website,
                    isEditable: isAdmin,
                    type: 'website',
                  },
                ],
                [
                  {
                    name: 'phone_number',
                    title: t('forms.phone.placeholder'),
                    icon: <PhoneIcon />,
                    value: infosEntite.phone_number,
                    isEditable: isAdmin,
                    type: 'phone',
                  },
                ],
              ],
            }}
            loading={loading}
            onEdit={onEditInfosGenerales}
          />
          {/* infos entite contracts */}
          {isSuperAdmin && (
            <CardInformations
              data={{
                status: undefined,
                rows: [
                  [
                    {
                      name: 'associated_contract_count',
                      title: t('my_account.contract_count'),
                      value: infosEntite.associated_contract_count,
                      icon: <InfoCircleIcon />,
                    },
                  ],
                ],
              }}
              title={t('sidebar.contracts')}
              loading={loading}
              customLabelHeaderButton={t('buttons.see') as string}
              onClickHeaderButton={() => {
                navigate(`/admin/contracts?company=${infosEntite.id}`);
              }}
            />
          )}
          {/* infos signataire */}
          <CardInformations
            title={t('my_account.infos_signatories')}
            data={{
              status: undefined,
              rows: signatoryRows,
              additionalFunction: {
                deleteSignatory,
                callbackOnClose: () => initSignatories(),
              },
            }}
            layout="signataire"
            loading={loading}
            onClickAddSignatory={onClickAddSignatory}
            onEdit={handleSubmitSignatory}
          />
        </div>
        <div className="space-y-5">
          {isSuperAdmin && (
            <CardInformations
              data={{
                status: undefined,
                rows: [
                  [
                    {
                      name: 'associated_worksite_count',
                      title: t('my_account.worksite_count'),
                      value: infosEntite.associated_worksite_count,
                      icon: <InfoCircleIcon />,
                    },
                  ],
                ],
              }}
              title={t('sidebar.worksites')}
              loading={loading}
              customLabelHeaderButton={t('buttons.see') as string}
              onClickHeaderButton={() => {
                navigate(
                  `${ADMIN_ROUTES.ADMIN_WORKSITES}?company=${infosEntite.id}&created=${infosUser.current_entity_type}`
                );
              }}
            />
          )}
          <CardUserDocs
            data={userDocuments}
            refetch={refetch}
            isAdmin={isAdmin}
            loading={loading}
            uploadDisabled={uploadDisabled}
          />
        </div>
      </div>
    </div>
  );
}

export { TabAccountInfos };

TabAccountInfos.defaultProps = {
  uploadDisabled: false,
  setData: undefined,
};
