import { useState, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { Modal } from '@components/Modal';
import { storeBrand } from '@models/materials/apiRequests/materialsRequests';
import { IBrandMaterials } from '@models/worksiteCreation/utils/types/SimulationTypes';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';

interface BrandSelectorProps {
  listBrands: { name: string; id: number }[];
  isLoadingBrands: boolean;
  onBrandSelected: (brand: IBrandMaterials) => void;
  resetBrands: () => void;
}

function BrandSelector({
  listBrands,
  isLoadingBrands,
  onBrandSelected,
  resetBrands,
}: BrandSelectorProps) {
  const { t } = useTranslation();
  const { readOnly } = useContext(WorksiteCreationContext);
  const [brandSearchValue, setBrandSearchValue] = useState<string>('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isAddingBrand, setIsAddingBrand] = useState<boolean>(false);
  const [closeDropdown, setCloseDropdown] = useState<boolean>(true);
  const listInnerRefBrand = useRef<HTMLDivElement>(null);

  const createBrand = async () => {
    setShowConfirmation(false);
    setIsAddingBrand(true);
    await storeBrand(brandSearchValue, t).then((response) => {
      if (response) {
        const brandNameUpperCase = brandSearchValue.toUpperCase();
        listBrands.unshift({
          name: brandNameUpperCase,
          id: response.id,
        });
        setBrandSearchValue(brandNameUpperCase);
        onBrandSelected({ name: brandNameUpperCase, id: response.id });
        setCloseDropdown(true);
        setIsAddingBrand(false);
      }
    });
  };

  const handleAddBrand = () => {
    setShowConfirmation(true);
  };

  const resetValues = () => {
    resetBrands();
    setBrandSearchValue('');
    setCloseDropdown(true);
  };

  return (
    <>
      <InputSelect
        label={`${t('worksite_creation.material.mark')}`}
        required
        placeholder={t('worksite_creation.material.search_mark')}
        valueInput={brandSearchValue}
        addClass="w-full"
        isAutoComplete
        disabled={isLoadingBrands || isAddingBrand || readOnly}
        isLoading={isLoadingBrands || isAddingBrand}
        closeDropdown={closeDropdown}
        setCloseDropdown={setCloseDropdown}
        callbackOnSearch={(value) => {
          resetBrands();
          setBrandSearchValue(value);
          setCloseDropdown(false);
        }}
        dataTestIdSearch="search_material_mark"
        showClearButton={brandSearchValue !== ''}
        onClear={() => resetValues()}
      >
        <div ref={listInnerRefBrand} className="flex flex-col">
          {listBrands
            .filter((m) =>
              m.name.toLowerCase().includes(brandSearchValue.toLowerCase())
            )
            .map((m, index) => (
              <button
                type="button"
                className="cursor-pointer flex items-center text-start w-full px-[1rem] py-[.5rem] border-b border-b-borderGrey border-r border-r-borderGrey"
                key={`${m.id}-${m.name}`}
                onClick={() => {
                  onBrandSelected(m);
                  setBrandSearchValue(m.name);
                  setCloseDropdown(true);
                }}
                data-test-id={`option_mark_${index + 1}`}
              >
                {m.name}
              </button>
            ))}
          {brandSearchValue !== '' &&
            !listBrands.some(
              (m) => m.name.toLowerCase() === brandSearchValue.toLowerCase()
            ) && (
              <button
                type="button"
                className="text-blueOpx rounded-b-default cursor-pointer flex items-center text-start w-full px-[1rem] py-[.5rem] border-b border-b-borderGrey border-r border-r-borderGrey"
                onClick={handleAddBrand}
              >
                {`+ ${t('brand.add')}`}
              </button>
            )}
        </div>
      </InputSelect>
      {showConfirmation && (
        <Modal
          title={`${t('brand.add')}`}
          btnCancel
          textBtnConfirm={`${t('buttons.confirm')}`}
          setIsModal={setShowConfirmation}
          onConfirmClick={createBrand}
          backgroundTransparent
        >
          <div className="mt-[1rem]">
            {`${t('brand.add')} : ${brandSearchValue} ?`}
          </div>
        </Modal>
      )}
    </>
  );
}

export default BrandSelector;
