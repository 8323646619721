import { InputText } from '@components/atomic/inputs/InputText';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { GlobalContext } from '../../../../../context/globalContext';
import { WorksiteCreationContext } from '../../../utils/worksiteCreationContext';

function InformationHousing() {
  const { t } = useTranslation();
  const { globalEnum } = useContext(GlobalContext);
  const {
    updatePayloadSizingNote,
    payloadSizingNote,
    simulatorData,
    simulatorDataOperations,
    readOnly,
  } = useContext(WorksiteCreationContext);

  const keyExistInSimulatorDataOperations = (key: string) => {
    return simulatorDataOperations.some((item) => key in item);
  };

  const surfaceExists =
    keyExistInSimulatorDataOperations('operation.surface') ||
    (simulatorData['general.surface'] !== undefined &&
      simulatorData['general.surface'] !== null &&
      simulatorData['general.surface'].trim() !== '' &&
      !Number.isNaN(Number(simulatorData['general.surface'])) &&
      Number(simulatorData['general.surface']) > 0);

  // Vérifier et supprimer "surface" si nécessaire
  if (surfaceExists && payloadSizingNote.surface === null) {
    const updatedPayload = { ...payloadSizingNote };
    delete updatedPayload.surface;
    updatePayloadSizingNote(updatedPayload);
  }

  const temperatureModeDatas = Object.entries(globalEnum.mode_temperature).map(
    ([value, label]) => ({
      label,
      value: parseInt(value, 10).toString(),
    })
  );

  const typeConstructionDatas = Object.entries(
    globalEnum.construction_type
  ).map(([value, label]) => ({
    label,
    value: parseInt(value, 10).toString(),
  }));

  const typeTransmitterDatas = Object.entries(globalEnum.type_emetteur).map(
    ([value, label]) => ({
      label,
      value: parseInt(value, 10).toString(),
    })
  );

  const arraySelectAltitude = Object.entries(globalEnum.altitudes).map(
    ([value, label]) => ({
      label,
      value: parseInt(value, 10).toString(),
    })
  );

  return (
    <div className="mt-[3rem]">
      {/* Titre et sous-titre */}
      <p className="font-medium">
        {t('worksite_creation.create_worksite.sizing_note.information_housing')}
      </p>
      <p className="text-[.875rem]">
        {t(
          'worksite_creation.create_worksite.sizing_note.information_housing_subtitle'
        )}
      </p>
      {/* contenu du formulaire:  */}
      <div className="flex space-x-[.5rem] mt-[2rem]">
        <InputText
          typeNumber
          placeholder={t(
            'worksite_creation.create_worksite.sizing_note.room_height'
          )}
          id="room_height"
          name="room_height"
          onChange={(e) =>
            updatePayloadSizingNote({
              ...payloadSizingNote,
              room_height: e === '' ? null : String(e),
            })
          }
          value={
            payloadSizingNote.room_height ? payloadSizingNote.room_height : ''
          }
          dataTestId="input_type_room_height"
          required
          disabled={readOnly}
          addClassToInput={readOnly ? 'bg-backgroundBody' : ''}
        />
        <div className="w-full">
          <InputSelect
            label={
              t('worksite_creation.create_worksite.sizing_note.altitude') || ''
            }
            placeholder={t(
              'worksite_creation.create_worksite.sizing_note.altitude'
            )}
            dataTestIdSelect="input_select_altitude"
            required
            dataLabelValue={arraySelectAltitude}
            onSelectLabelValue={(e) => {
              updatePayloadSizingNote({
                ...payloadSizingNote,
                altitude: Number(e.value),
              });
            }}
            valueInput={
              payloadSizingNote.altitude
                ? globalEnum.altitudes[payloadSizingNote.altitude]
                : ''
            }
            disabled={readOnly}
          />
        </div>
      </div>
      <div className="flex space-x-[.5rem] mt-[1rem]">
        <div className="w-full">
          <InputSelect
            label={
              t(
                'worksite_creation.create_worksite.sizing_note.temperature_mode'
              ) || ''
            }
            placeholder={t(
              'worksite_creation.create_worksite.sizing_note.temperature_mode'
            )}
            required
            dataTestIdSelect="input_select_temperature_mode"
            dataLabelValue={temperatureModeDatas}
            onSelectLabelValue={(e) =>
              updatePayloadSizingNote({
                ...payloadSizingNote,
                temperature_mode: Number(e.value),
              })
            }
            valueInput={
              payloadSizingNote.temperature_mode
                ? globalEnum.mode_temperature[
                    payloadSizingNote.temperature_mode
                  ]
                : ''
            }
            disabled={readOnly}
          />
        </div>
        <div className="w-full">
          <InputSelect
            label={
              t(
                'worksite_creation.create_worksite.sizing_note.transmitter_type'
              ) || ''
            }
            placeholder={t(
              'worksite_creation.create_worksite.sizing_note.transmitter_type'
            )}
            dataLabelValue={typeTransmitterDatas}
            onSelectLabelValue={(e) =>
              updatePayloadSizingNote({
                ...payloadSizingNote,
                transmitter_type: Number(e.value),
              })
            }
            valueInput={
              payloadSizingNote.transmitter_type !== null &&
              payloadSizingNote.transmitter_type
                ? globalEnum.type_emetteur[payloadSizingNote.transmitter_type]
                : ''
            }
            required
            dataTestIdSelect="input_select_transmitter_type"
            disabled={readOnly}
          />
        </div>
      </div>
      <div className="flex space-x-[.5rem] mt-[1rem]">
        <div className={surfaceExists ? 'w-1/2' : 'w-full'}>
          <InputSelect
            label={
              t(
                'worksite_creation.create_worksite.sizing_note.construction_type'
              ) || ''
            }
            placeholder={t(
              'worksite_creation.create_worksite.sizing_note.construction_type'
            )}
            dataLabelValue={typeConstructionDatas}
            onSelectLabelValue={(e) =>
              updatePayloadSizingNote({
                ...payloadSizingNote,
                construction_type: Number(e.value),
              })
            }
            valueInput={
              payloadSizingNote.construction_type !== null &&
              payloadSizingNote.construction_type
                ? globalEnum.construction_type[
                    payloadSizingNote.construction_type
                  ]
                : ''
            }
            required
            dataTestIdSelect="input_select_construction_type"
            disabled={readOnly}
          />
        </div>
        {!surfaceExists && (
          <InputText
            typeNumber
            placeholder={t(
              'worksite_creation.create_worksite.sizing_note.surface'
            )}
            id="surface"
            name="surface"
            onChange={(e) =>
              updatePayloadSizingNote({
                ...payloadSizingNote,
                surface: e === '' ? null : Number(e),
              })
            }
            value={payloadSizingNote.surface || ''}
            dataTestId="input_type_surface"
            disabled={readOnly}
          />
        )}
      </div>
    </div>
  );
}

export { InformationHousing };
