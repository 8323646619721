import {
  BoltCircleIcon,
  BriefcaseIcon,
  ContractsIcon,
  EuroIcon,
  MoreDotsIcon,
  VolumeGraphIcon,
  ListDotIcon,
  RequestsIcon,
  DepositsIcon,
  PartnersIcon,
  CheckCircleIcon,
  CalendarIcon,
} from '@assets/images/svgComponents';
import React, { useMemo } from 'react';
import { blueOpx } from '@assets/color';
import { ColorCube } from '@components/atomic/ColorCube';
import {
  FilterRequestData,
  SortAndFilterType,
} from '@components/sortAndFilter/utils/sortAndFilterTypes';
import { FilterCriteriaEnum } from '@components/sortAndFilter/utils/enums';

interface FilterIconProps {
  currentFilter: SortAndFilterType;
  selectedFilter: FilterRequestData | undefined;
  isOver: boolean;
}

function SearchAndFilterIcon({
  currentFilter,
  selectedFilter,
  isOver,
}: FilterIconProps): JSX.Element {
  const filterValue = selectedFilter?.value || '0';
  let condition =
    !selectedFilter?.condition || selectedFilter.condition === '='
      ? ''
      : selectedFilter.condition;

  if (filterValue === '0') condition = '';

  const valueDisplay = (val: number, plus = false) => {
    if (plus && val > 99) return '+99';
    return val > 99 || val < 1 ? '...' : val;
  };

  const icon = useMemo(() => {
    switch (currentFilter.value) {
      case FilterCriteriaEnum.VOLUME:
        return <VolumeGraphIcon />;
      case FilterCriteriaEnum.PRIME:
        return <EuroIcon />;
      case FilterCriteriaEnum.NOMBRE_OPERATION:
        return <div>{condition + valueDisplay(Number(filterValue))}</div>;
      case FilterCriteriaEnum.RAI:
      case FilterCriteriaEnum.RAI_PARTENAIRE:
        return <BriefcaseIcon />;
      case FilterCriteriaEnum.TYPE_OPERATION:
        return <BoltCircleIcon />;
      case FilterCriteriaEnum.CONVENTION:
        return <ContractsIcon />;
      case FilterCriteriaEnum.ETAPE:
        return <ListDotIcon />;
      case FilterCriteriaEnum.DATE:
        return <CalendarIcon />;
      default:
        return <MoreDotsIcon />;
    }
  }, [currentFilter, selectedFilter]);

  return (
    <ColorCube
      size="1.5rem"
      numberOrIcon={icon}
      color={blueOpx}
      opacity={!isOver}
    />
  );
}

function SearchIcon({ index }: { index: number }): JSX.Element {
  const icon = useMemo(() => {
    switch (index) {
      case 1:
      case 9:
        return <ContractsIcon />;
      case 2:
      case 3:
        return <RequestsIcon />;
      case 4:
      case 5:
      case 6:
        return <PartnersIcon />;
      case 7:
        return <CheckCircleIcon />;
      case 8:
        return <DepositsIcon />;
      default:
        return <MoreDotsIcon />;
    }
  }, [index]);

  return (
    <ColorCube size="1.5rem" numberOrIcon={icon} color={blueOpx} opacity />
  );
}

export { SearchAndFilterIcon, SearchIcon };
