import { orange, red } from '@assets/color';
import { convertHexToRGBA } from '@utils/functions';

interface ITextErrorProps {
  errorMessage: string;
  isWarning?: boolean;
  addClass?: string;
}
function TextError({ errorMessage, isWarning, addClass }: ITextErrorProps) {
  const colorToUse = isWarning ? orange : red;
  return (
    <div>
      {errorMessage !== '' && (
        <p
          className={`p-[.5rem] rounded-default mt-[1rem] ${addClass}`}
          style={{
            backgroundColor: convertHexToRGBA(colorToUse, 0.1),
            color: colorToUse,
          }}
        >
          {errorMessage}
        </p>
      )}
    </div>
  );
}

export { TextError };

TextError.defaultProps = {
  addClass: '',
  isWarning: false,
};
