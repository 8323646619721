import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { GlobalContext } from '@context/globalContext';
import { AuthContext } from '@context/authContext';

import { BoltCircleIcon, LogoutIcon } from '@assets/images/svgComponents';
import { white } from '@assets/color';
import { leaveImpersonation } from '@models/admin/users/apiRequests/usersRequests';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import ImageContainer from '@components/atomic/ImageContainer';
import { logout } from '@models/auth/apiRequests/authRequests';
import { AUTH_ROUTES, USERS_ROUTES } from '@utils/routesUrls';

function BottomSidebar() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { sidebarMenuIsOpen } = useContext(GlobalContext);
  const { user, updateToken, impersonateToken, updateImpersonateToken } =
    useContext(AuthContext);

  const handleLogout = async () => {
    if (impersonateToken) {
      await leaveImpersonation(updateImpersonateToken);
    }
    await logout(updateToken);
    navigate(AUTH_ROUTES.LOGIN, { replace: true });
    localStorage.removeItem('user_view_switched');
  };

  const handleLeaveImpersonation = async () => {
    await leaveImpersonation(updateImpersonateToken);
    toast.success(
      t('admin.user_impersonate_end', {
        name: `${user?.firstname} ${user?.lastname}`,
      })
    );
    localStorage.removeItem('user_view_switched');
    navigate('/', { replace: true });
  };

  const getAbbreviatedName = (
    firstname: string,
    lastname: string,
    maxLength: number
  ): string => {
    const fullName = `${firstname} ${lastname}`;
    if (impersonateToken && fullName.length > 17) {
      const abbreviatedName = `${firstname.charAt(0)}. ${lastname}`;
      if (abbreviatedName.length > maxLength) {
        return `${abbreviatedName.substring(0, maxLength - 3)}...`;
      }
      return abbreviatedName;
    }
    if (fullName.length > 20) {
      const abbreviatedName = `${firstname.charAt(0)}. ${lastname}`;
      if (abbreviatedName.length > maxLength) {
        return `${abbreviatedName.substring(0, maxLength - 3)}...`;
      }
      return abbreviatedName;
    }
    return fullName;
  };

  return (
    <div
      className={` ${
        !sidebarMenuIsOpen ? 'flex-col' : ''
      } flex justify-between items-center w-full px-[1.5rem]`}
    >
      <Link
        to={USERS_ROUTES.PROFILE}
        replace
        className={`${
          sidebarMenuIsOpen && impersonateToken
            ? 'border-r border-r-[#EEEEEE40] w-8/12 flex space-x-[1rem] items-center'
            : 'border-r border-r-[#EEEEEE40] w-10/12 flex space-x-[1rem] items-center'
        }`}
        data-test-id="navlink_profile"
      >
        <ImageContainer imageUrl={user?.photo_url} width="2rem" height="2rem" />

        {sidebarMenuIsOpen && (
          <div className="flex-grow max-w-full overflow-hidden">
            <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
              <p className="text-[.875rem] truncate overflow-ellipsis whitespace-nowrap">
                {getAbbreviatedName(
                  user?.firstname ?? '',
                  user?.lastname ?? '',
                  sidebarMenuIsOpen && impersonateToken ? 17 : 30
                )}
              </p>
              <p className="text-[.625rem] text-textGrey truncate overflow-ellipsis whitespace-nowrap">
                {user?.function}
              </p>
            </div>
          </div>
        )}
      </Link>
      {impersonateToken && (
        <button
          className={!sidebarMenuIsOpen ? 'mt-[1rem]' : ''}
          type="button"
          onClick={() => handleLeaveImpersonation()}
        >
          <BoltCircleIcon
            fill={white}
            width="1.5rem"
            height="1.5rem"
            className={`${sidebarMenuIsOpen ? 'ml-[1rem]' : ''}`}
          />
        </button>
      )}
      <button
        className={!sidebarMenuIsOpen ? 'mt-[1rem]' : ''}
        type="button"
        onClick={() => handleLogout()}
        data-test-id="logout_button"
      >
        <LogoutIcon
          fill={white}
          width="1.5rem"
          height="1.5rem"
          className={`${sidebarMenuIsOpen ? 'ml-[1rem]' : ''}`}
        />
      </button>
    </div>
  );
}

export { BottomSidebar };
