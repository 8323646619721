/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useMemo, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import _ from 'lodash';

import { AuthContext } from '@context/authContext';
import { HeaderContext } from '@context/headerContext';

import { ListTable, IColumn } from '@components/ListTable';
import { ListPagination } from '@components/atomic/pagination/ListPagination';

import { blueOpx } from '@assets/color';
import { ResultsPerPageButton } from '@components/atomic/pagination/ResultsPerPageButton';
import { getListAllCustomers } from '@models/mar/apiRequests/worksiteCreationMarRequests';
import { ICustomer } from '@models/mar/utils/marTypes';

function CustomersList() {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { updateTitleHeader, refreshHeader } = useContext(HeaderContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [paginationData, setPaginationData] = useState<
    Record<string, string | number | null | any> | undefined
  >();
  const [pagesDatas, setPagesDatas] = useState<{ data: any; meta: any }[]>([]);
  const [resultsPerPage, setResultsPerPage] = useState<number>();

  const [currentPage, setCurrentPage] = useState<number>(1);

  const getData = async (page: number) => {
    setLoading(true);

    const resList = await getListAllCustomers(page, undefined, true);

    if (resList) {
      const meta = _.omit(resList, 'data');

      setData(resList.data);
      setPaginationData(meta.meta);

      if (resList?.current_page > pagesDatas.length) {
        setPagesDatas([...pagesDatas, { data: resList.data, meta }]);
      }
    }
    setLoading(false);
  };

  const pageAlreadyLoad = (page: number) => {
    setData(pagesDatas[page - 1].data);
    setPaginationData(pagesDatas[page - 1].meta);
  };

  const columnHelper = createColumnHelper<ICustomer>();

  const canViewCustomers = useMemo(
    () => user?.permissions_names.includes('lister-beneficiaire-mar'),
    [user]
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('firstname', {
        header: () => t('beneficiaries.fullname'),
        cell: (info) => {
          const firstname = info.row.original.firstname;
          const lastname = info.row.original.lastname;

          return `${firstname} ${lastname}`;
        },
      }),
      columnHelper.accessor('address.address', {
        header: () => t('forms.address.placeholder'),
      }),
      columnHelper.accessor('address.postal_code', {
        header: () => t('forms.address.manual.zip'),
      }),
      columnHelper.accessor('address.city', {
        header: () => t('forms.address.manual.city'),
      }),
    ],
    [data]
  );

  useEffect(() => {
    if (resultsPerPage) {
      getData(1);
      setCurrentPage(1);
    }
  }, [resultsPerPage]);

  useEffect(() => {
    getData(1);
    refreshHeader();
    updateTitleHeader(`${t('customers.list')}`);
  }, []);

  return (
    <div className="w-full items-center justify-center pt-4">
      {canViewCustomers ? (
        <>
          <div className="flex justify-between">
            <div className="mt-4">
              {paginationData?.total !== undefined && (
                <div>
                  {t('pagination.total_results')} : {paginationData.total}
                </div>
              )}
            </div>
            <div className="mb-4">
              <ResultsPerPageButton
                resultsPerPage={resultsPerPage || 5}
                onChange={(value) => {
                  setResultsPerPage(value);
                }}
                options={[5, 10, 20, 50, 100, 250]}
                colorPagination={blueOpx}
              />
            </div>
          </div>
          <ListTable
            loading={loading}
            columns={columns as IColumn[]}
            data={data}
            hideArrow
          />
          {paginationData && (
            <ListPagination
              getData={(page) => {
                setCurrentPage(page);
                if (page > pagesDatas.length) {
                  getData(page);
                } else {
                  pageAlreadyLoad(page);
                }
              }}
              paginationData={paginationData}
              resultsPerPage={resultsPerPage || 5}
              currentPage={currentPage}
            />
          )}
        </>
      ) : (
        <div className="py-10 w-full items-center justify-center text-danger">
          <p>{t('global.no_permission')}</p>
        </div>
      )}
    </div>
  );
}

export { CustomersList };
