import { getLinkedFile } from '@apiRequests/globalRequests';
import { Modal } from '@components/Modal';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { InputText } from '@components/atomic/inputs/InputText';
import { GlobalContext } from '@context/globalContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { updateBeneficiary } from '@models/beneficiaries/apiRequests/beneficiariesRequests';
import {
  getSignatories,
  postAskSignature,
} from '@models/entities/apiRequests/entitiesRequests';
import { WorksitesContext } from '@models/worksites/utils/worksitesContext';
import { DOCUMENT_STATE_STRING } from '@utils/utils';
import { getElectronicSignatureSchema } from '@utils/validationSchemas';
import { t } from 'i18next';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { IActiveDocument } from '../../../types/globalTypes';

interface ISignatureProcedure {
  beneficiary_email: string;
  beneficiary_phone_number: string;
  created_at: string;
  id: number;
  linked_file_id: number;
  signatory: string;
  signed_at: string;
  status: number;
}

interface IFormInputSignatureProps {
  setIsFormulaireEnvoiSignature: Dispatch<SetStateAction<boolean>>;
  setIsFormulaireAnnulerRelancerSignature: Dispatch<SetStateAction<boolean>>;
  nbCreditsEntity: number;
  setNbCreditsEntity: Dispatch<SetStateAction<number>>;
  setSignatureProcedure: Dispatch<
    SetStateAction<ISignatureProcedure | undefined>
  >;
  isAskingSignature: boolean;
  setIsAskingSignature: Dispatch<SetStateAction<boolean>>;
  setStatusDoc: Dispatch<SetStateAction<string | undefined>>;
}

function FormInputSignature({
  setIsFormulaireEnvoiSignature,
  setIsFormulaireAnnulerRelancerSignature,
  nbCreditsEntity,
  setNbCreditsEntity,
  setSignatureProcedure,
  isAskingSignature,
  setIsAskingSignature,
  setStatusDoc,
}: IFormInputSignatureProps) {
  const { userView, documentActive, updateDocumentActive } =
    useContext(GlobalContext);
  const { worksiteDetails } = useContext(WorksitesContext);

  const [signatories, setSignatories] = useState<
    { label: string; value: string }[]
  >([]);
  const [signatorySelected, setSignatorySelected] = useState<string>('');
  const [beneficiaryEmail, setBeneficiaryEmail] = useState<string>('');
  const [beneficiaryPhone, setBeneficiaryPhone] = useState<string>('');
  const [isModalWarningBeneficiary, setIsModalWarningBeneficiary] =
    useState(false);
  const [isLoadingAskSignature, setIsLoadingAskSignature] = useState(false);

  // ---------- USE FORM ----------
  const methods = useForm({
    resolver: yupResolver(getElectronicSignatureSchema(t)),
    defaultValues: {
      beneficiary_email: worksiteDetails?.beneficiary.email,
      beneficiary_phone: worksiteDetails?.beneficiary.mobile_phone,
      signatory_selected: '',
    },
  });
  const { handleSubmit, setValue, getValues } = methods;

  const askSignature = async (data: FieldValues) => {
    if (
      documentActive &&
      documentActive.document &&
      documentActive.document.id &&
      userView &&
      userView.entity_id
    ) {
      setIsAskingSignature(true);
      setIsLoadingAskSignature(true);
      postAskSignature(userView.entity_id, data, documentActive.document.id)
        .then((res) => {
          if (res && res.data) {
            setIsFormulaireEnvoiSignature(false);
            setIsFormulaireAnnulerRelancerSignature(true);
            setNbCreditsEntity(nbCreditsEntity - 1);
            setSignatureProcedure(res.data);
            setStatusDoc(DOCUMENT_STATE_STRING.AWAITING_SIGNATURE);
            toast.success(t('toast.success.request_signature_sent'));
            getLinkedFile(res.data.linked_file_id).then((resLinkedFile) => {
              if (resLinkedFile.data) {
                const newDocumentActive: IActiveDocument = {
                  list: 'AH',
                  document: resLinkedFile.data,
                  listAsTitle: true,
                };
                updateDocumentActive(newDocumentActive);
              }
            });
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setIsLoadingAskSignature(false);
          setIsAskingSignature(false);
        });
    }
  };

  const onSubmit = async (data: FieldValues) => {
    if (
      data.beneficiary_email === worksiteDetails.beneficiary.email &&
      data.beneficiary_phone === worksiteDetails.beneficiary.mobile_phone
    ) {
      askSignature(data);
    } else {
      setIsModalWarningBeneficiary(true);
    }
  };

  const confirmClickUpdateBeneficiary = async () => {
    setIsModalWarningBeneficiary(false);
    if (worksiteDetails && worksiteDetails.beneficiary) {
      const data = {
        mobile_phone: beneficiaryPhone,
        email: beneficiaryEmail,
        address: worksiteDetails.beneficiary.address,
      };
      setIsAskingSignature(true);
      updateBeneficiary({
        id: worksiteDetails.beneficiary.id,
        data,
      })
        .then(() => {
          const dataSignature = {
            beneficiary_email: getValues('beneficiary_email'),
            beneficiary_phone: getValues('beneficiary_phone'),
            signatory_selected: getValues('signatory_selected'),
            generate_file: true,
          };
          worksiteDetails.beneficiary.email = dataSignature.beneficiary_email;
          worksiteDetails.beneficiary.mobile_phone =
            dataSignature.beneficiary_phone;
          askSignature(dataSignature);
        })
        .catch((err) => {
          toast.error(err);
          console.error(err);
        });
    }
  };

  useEffect(() => {
    if (worksiteDetails && worksiteDetails.beneficiary) {
      setBeneficiaryEmail(worksiteDetails.beneficiary.email);
      setBeneficiaryPhone(worksiteDetails.beneficiary.mobile_phone);
    }
    if (userView && userView.entity_id) {
      getSignatories(userView.entity_id).then((res) => {
        if (res) {
          const signatoriesResponse: { label: string; value: string }[] =
            res.map((signatory: any) => {
              return {
                label: `${signatory.firstname} ${signatory.lastname}`,
                value: signatory.id.toString(),
              };
            });
          setSignatories(signatoriesResponse);
        }
      });
    }
  }, []);

  return (
    <FormProvider {...methods}>
      <form
        action="submit"
        id="electronic-signature-form"
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-y-[1.5rem] w-full"
      >
        <div className="flex flex-row justify-between">
          <div className="w-[59%]">
            <InputText
              id="benificiary-email-id"
              name="beneficiary_email"
              placeholder={t('infos.email')}
              label={t('global.beneficiary_email').toString()}
              required
              onChange={(value) => setBeneficiaryEmail(value as string)}
              disabled={isAskingSignature}
            />
          </div>
          <div className="w-[39%]">
            <InputText
              addClass="text-right"
              id="benificiary-phone-id"
              name="beneficiary_phone"
              placeholder={t('infos.phone')}
              label={t('global.beneficiary_phone').toString()}
              required
              typeNumber
              onChange={(value) => setBeneficiaryPhone(value as string)}
              disabled={isAskingSignature}
              addClassToInput="text-right"
            />
          </div>
        </div>
        <InputSelect
          placeholder=""
          label={t('global.signatory_referent').toString()}
          required
          dataLabelValue={signatories}
          valueInput={signatorySelected}
          onSelectLabelValue={(item) => {
            setSignatorySelected(item.label);
            setValue('signatory_selected', item.value);
          }}
          disabled={isAskingSignature}
        />
        <ButtonOpx
          label={t('global.document_ask_signature')}
          isSubmitBtn
          formId="electronic-signature-form"
          disabled={
            signatorySelected === '' ||
            beneficiaryEmail === '' ||
            beneficiaryPhone === '' ||
            isAskingSignature ||
            isLoadingAskSignature ||
            nbCreditsEntity < 1
          }
        />
        {isModalWarningBeneficiary && (
          <Modal
            title="Attention !"
            backgroundTransparent
            btnCancel
            textBtnCancel={t('global.no').toString()}
            textBtnConfirm={t('global.yes').toString()}
            buttonsPosition="bottom"
            onConfirmClick={confirmClickUpdateBeneficiary}
            onClickCancel={() => setIsModalWarningBeneficiary(false)}
          >
            <div className="mt-4">
              Attention, les coordonnées saisies ne correspondent pas à celles
              dans le détail du chantier.
              <br />
              Le détail du chantier sera modifié. Souhaitez-vous continuer ?
            </div>
          </Modal>
        )}
      </form>
    </FormProvider>
  );
}

export { FormInputSignature };
