import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { InputText } from '@components/atomic/inputs/InputText';
import { GlobalContext } from '@context/globalContext';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  getCancelSignatureProcedure,
  postResendSignatureProcedure,
} from '@models/signatureProcedure/signatureProcedureRequests';
import { WorksitesContext } from '@models/worksites/utils/worksitesContext';
import { DOCUMENT_STATE_STRING } from '@utils/utils';
import { getElectronicSignatureSchema } from '@utils/validationSchemas';
import { t } from 'i18next';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

interface ISignatureProcedure {
  beneficiary_email: string;
  beneficiary_phone_number: string;
  created_at: string;
  id: number;
  linked_file_id: number;
  signatory: string;
  signed_at: string;
  status: number;
}

interface IFormDisplaySignature {
  signatureProcedure: ISignatureProcedure | undefined;
  setIsFormulaireAnnulerRelancerSignature: Dispatch<SetStateAction<boolean>>;
  setIsFormulaireEnvoiSignature: Dispatch<SetStateAction<boolean>>;
  nbCreditsEntity: number;
  setNbCreditsEntity: Dispatch<SetStateAction<number>>;
  isFormulaireSigne: boolean;
  setStatusDoc: Dispatch<SetStateAction<string | undefined>>;
}

function FormDisplaySignature({
  signatureProcedure,
  setIsFormulaireAnnulerRelancerSignature,
  setIsFormulaireEnvoiSignature,
  nbCreditsEntity,
  setNbCreditsEntity,
  isFormulaireSigne,
  setStatusDoc,
}: IFormDisplaySignature) {
  const { documentActive, userView } = useContext(GlobalContext);
  const { worksiteDetails } = useContext(WorksitesContext);
  const [isLoadingCancelSignature, setIsLoadingCancelSignature] =
    useState(false);
  const [isLoadingResendSignature, setIsLoadingResendSignature] =
    useState(false);

  const methods = useForm({
    resolver: yupResolver(getElectronicSignatureSchema(t)),
    defaultValues: {
      beneficiary_email: worksiteDetails?.beneficiary.email,
      beneficiary_phone: worksiteDetails?.beneficiary.mobile_phone,
      signatory_selected: '',
    },
  });

  const cancelClickElectronicSignature = async () => {
    if (signatureProcedure && signatureProcedure.id) {
      setIsLoadingCancelSignature(true);
      getCancelSignatureProcedure(signatureProcedure.id)
        .then(() => {
          toast.success(t('toast.success.cancel_signature_procedure'));
          setStatusDoc(DOCUMENT_STATE_STRING.TO_SIGN);
          setNbCreditsEntity(nbCreditsEntity + 1);
          setIsFormulaireAnnulerRelancerSignature(false);
          setIsFormulaireEnvoiSignature(true);
        })
        .catch((err) => {
          toast.error(err);
        })
        .finally(() => {
          setIsLoadingCancelSignature(false);
        });
    }
  };

  const resendClickElectronicSignature = async () => {
    if (
      signatureProcedure &&
      signatureProcedure.id &&
      documentActive &&
      documentActive.document &&
      documentActive.document.id &&
      worksiteDetails &&
      worksiteDetails.beneficiary &&
      worksiteDetails.beneficiary.email &&
      worksiteDetails.beneficiary.mobile_phone &&
      userView &&
      userView.entity_id &&
      worksiteDetails.referent &&
      worksiteDetails.referent.id
    ) {
      setIsLoadingResendSignature(true);
      postResendSignatureProcedure(signatureProcedure.id, {
        beneficiary_email: worksiteDetails.beneficiary.email,
        beneficiary_phone_number: worksiteDetails.beneficiary.mobile_phone,
        entity_id: userView.entity_id,
        signatory_id: worksiteDetails.referent.id,
        linked_file_id: documentActive.document.id,
      })
        .then(() => {
          toast.success(t('toast.success.resend_signature_procedure'));
        })
        .catch((err) => {
          toast.error(err);
          console.error(err);
        })
        .finally(() => {
          setIsLoadingResendSignature(false);
        });
    }
  };

  return (
    <div className="flex flex-col gap-y-[1.5rem] w-full">
      {signatureProcedure && (
        <FormProvider {...methods}>
          <div className="flex flex-row justify-between">
            <div className="w-[59%]">
              <InputText
                id="benificiary-email-id"
                name="beneficiary_email"
                placeholder=""
                label={t('global.beneficiary_email').toString()}
                disabled
                required
                defaultValue={worksiteDetails.beneficiary.email}
                className="border-none bg-transparent text-black text-sm relative w-full"
              />
            </div>
            <div className="w-[39%]">
              <InputText
                addClass="text-right"
                id="benificiary-phone-id"
                name="beneficiary_phone"
                placeholder=""
                label={t('global.beneficiary_phone').toString()}
                disabled
                required
                defaultValue={worksiteDetails.beneficiary.mobile_phone}
                className="border-none bg-transparent text-black text-sm relative w-full text-right"
              />
            </div>
          </div>
          <InputText
            id="signatory_selected"
            name="signatory_selected"
            placeholder=""
            label={t('global.signatory_referent').toString()}
            disabled
            required
            defaultValue={signatureProcedure?.signatory}
            className="border-none bg-transparent text-black text-sm relative w-full"
          />
        </FormProvider>
      )}

      {!isFormulaireSigne && (
        <div className="flex flex-row gap-x-2 justify-end">
          <ButtonOpx
            label="Annuler"
            type="secondary"
            onClick={cancelClickElectronicSignature}
            isLoading={isLoadingCancelSignature}
            disabled={isLoadingCancelSignature}
          />
          <ButtonOpx
            label="Relancer"
            type="primary"
            onClick={resendClickElectronicSignature}
            isLoading={isLoadingResendSignature}
            disabled={isLoadingResendSignature}
          />
        </div>
      )}
    </div>
  );
}

export { FormDisplaySignature };
