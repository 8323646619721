/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

import { GlobalContext } from '@context/globalContext';

import { Card } from '@components/Card';
import { DepositPageHeader } from '@models/deposits/components/DepositPageHeader';
import { DocumentCard } from '@components/documents/DocumentCard';
import { UploadButton } from '@components/documents/UploadButton';
import { DocumentViewer } from '@components/documents/DocumentViewer';

import { initDocumentActive } from '@utils/initialState';

import { getListDocuments } from '@models/contracts/apiRequests/newContractRequests';

import { IDepositType } from '@models/deposits/utils/depositsTypes';
import { sendLinkedFile } from '@apiRequests/globalRequests';
import { ILinkedFile } from '../../../types/globalTypes';

interface ITabDocumentsProps {
  deposit: IDepositType;
}

function TabDocuments(props: ITabDocumentsProps) {
  const { deposit } = props;
  const { t } = useTranslation();

  const { updateDocumentActive } = useContext(GlobalContext);

  const [selectedFile, setSelectedFile] = useState<ILinkedFile>();
  const [documentList, setDocumentList] = useState<ILinkedFile[]>([]);

  const getData = async () => {
    const res = await getListDocuments(deposit.id, 'deposit');
    setDocumentList(res);
  };

  const getTitle = (name: string, url: string) => {
    if (name.length > 0) return name;

    const fileName = url.split('/').pop();
    const extension = `.${url.split('.').pop()}`;
    return fileName?.split(extension)[0];
  };

  const handleUpload = async (file: File, document: ILinkedFile) => {
    const relation_ids = document.relation_ids || [0];
    const relation_type = document.relation_type || '';
    const file_type = document.file_type;
    const status = document.status;
    const commentary = document.commentary || '';
    const linked_entity_id = document.linked_entity_id;
    const mandatory = document.mandatory || false;
    const documentId = document.id || undefined;
    const canDuplicate = document.can_duplicate || false;

    const res = await sendLinkedFile(
      file,
      relation_ids,
      relation_type,
      file_type,
      status,
      commentary,
      linked_entity_id,
      mandatory,
      canDuplicate,
      documentId,
      getData
    );

    return res;
  };

  useEffect(() => {
    getData();
  }, [deposit]);

  return (
    <>
      <DepositPageHeader data={deposit} showRepartition={false} page="Detail" />
      <div className="flex flex-wrap">
        <div className="w-full md:w-[50%] md:pr-6 mb-[1.5rem]">
          <Card
            title={`${t('deposits.deposit_documents')}`}
            actionButtons={
              deposit ? (
                <UploadButton
                  name={t('global.document_add')}
                  onUpload={handleUpload}
                  document={{
                    id: null,
                    relation_ids: [deposit.id],
                    relation_type: 'deposit',
                    file_type: 16,
                    status: 1,
                    isolate_file: false,
                    file_url: null,
                    file_name: null,
                    linked_entity_id: null,
                    commentary: null,
                    file: null,
                    created_at: null,
                    uploaded_at: null,
                    is_deletable: null,
                    file_hash: null,
                    custom_field_id: null,
                  }}
                  fileType={16}
                  addClass="py-[0.5rem] px-[1rem]"
                />
              ) : (
                <div />
              )
            }
          >
            <div>
              {documentList.map((document) => (
                <div key={v4()} className="mb-4">
                  <DocumentCard
                    document={document}
                    onSelect={(file) => {
                      updateDocumentActive(
                        selectedFile?.id === file.id
                          ? {
                              ...initDocumentActive,
                              list: '',
                            }
                          : {
                              list: '',
                              document: file,
                            }
                      );
                      setSelectedFile(
                        selectedFile?.id === file.id ? undefined : file
                      );
                    }}
                    isActive={selectedFile?.id === document.id}
                    isLoading={false}
                    onDelete={(file) => console.log('onDelete', file)}
                    customTitle={getTitle(
                      String(document.file_name),
                      String(document.file_url)
                    )}
                    hideActionButton
                    headerButtonsList={['download']}
                  />
                </div>
              ))}
            </div>
          </Card>
        </div>
        <div className="w-full md:w-[50%] mb-[1.5rem]">
          <DocumentViewer
            isLoading={false}
            setIsLoading={() => console.log('setIsLoading')}
          />
        </div>
      </div>
    </>
  );
}

export { TabDocuments };
