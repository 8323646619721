/* eslint-disable consistent-return */
import { axiosClient } from '@api/apiClient';
import { displayError } from '@utils/format';
import {
  urlGetCreditsPricingForEntity,
  urlGetNbCredits,
  urlGetSignatories,
  urlPostAskSignature,
  urlPostBuyCredits,
  urlUpdateEntity,
} from '@api/apiUrls';
import { FieldValues } from 'react-hook-form';
import { Dispatch, SetStateAction } from 'react';
import { IPartnerView } from '@models/partners/utils/types/partnersType';

export const updateEntityInfos = async (
  id: number,
  data: FieldValues,
  updateUserView?: Dispatch<SetStateAction<IPartnerView | null>>,
  logoOnly?: boolean
) => {
  try {
    const formData = new FormData();

    if (logoOnly) {
      if (data.logo === null) {
        formData.append('logo', '');
      } else {
        formData.append('file', data.logo || '');
      }
      formData.append('update_action', 'logo');
    } else {
      if (data.contact_email) {
        formData.append('contact_email', data.contact_email);
      }
      if (data.employees_number) {
        formData.append('employees_number', data.employees_number);
      }
      if (data.iban_payment) {
        formData.append('iban_payment', data.iban_payment);
      }
      if (data.phone_number) {
        formData.append('phone_number', data.phone_number);
      }
      if (data.revenue) {
        formData.append('revenue', data.revenue);
      }
      if (data.website) {
        formData.append('website', data.website);
      }

      formData.append('update_action', 'info');
    }

    const res = await axiosClient.post(urlUpdateEntity(id), formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (updateUserView) {
      const entityData = res.data.data;

      updateUserView((prevState) =>
        prevState
          ? {
              ...prevState,
              logo: entityData.logo || '',
              company_name: entityData.company_name || '',
            }
          : null
      );
    }

    return res.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const getSignatories = async (id: number, email?: string | null) => {
  try {
    const response = await axiosClient.post(urlGetSignatories(id), { email });
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const postBuyCredits = async (id: number, nbCredits: number) => {
  try {
    const response = await axiosClient.post(urlPostBuyCredits(id), {
      nbCredits,
    });
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const getNbCredits = async (id: number) => {
  try {
    const response = await axiosClient.get(urlGetNbCredits(id));
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const postAskSignature = async (
  entity_id: number,
  data: FieldValues,
  linkedFile_id: number
) => {
  try {
    const response = await axiosClient.post(urlPostAskSignature(entity_id), {
      beneficiary_email: data.beneficiary_email,
      beneficiary_phone_number: data.beneficiary_phone,
      entity_id,
      signatory_id: Number(data.signatory_selected),
      linked_file_id: linkedFile_id,
      generate_file: !!data.generate_file,
    });
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const getCreditsPricingForEntity = async (entity_id: number) => {
  try {
    const response = await axiosClient.get(
      urlGetCreditsPricingForEntity(entity_id)
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};
