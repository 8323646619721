/* eslint-disable no-nested-ternary */
import { useMemo } from 'react';
import { InfosWithIcon } from '@components/atomic/InfosWithIcon';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { InfosWithImage } from '@components/atomic/InfosWithImage';

import { IInfosLine } from 'types/globalTypes';
import { blueOpx } from '@assets/color';
import ImageContainer from '@components/atomic/ImageContainer';

interface IInputInformationReadProps {
  data: IInfosLine;
  loading: boolean;
  isEditMode: boolean;
}

function InputInformationRead({
  data,
  loading,
  isEditMode,
}: IInputInformationReadProps) {
  const {
    type,
    title,
    value,
    icon,
    addClass,
    colorIcon,
    hideOnEdit,
    placeholder,
    width,
    height,
  } = data;

  const addressLabel = useMemo(() => {
    if (type === 'address') {
      if (typeof value === 'string') return value;
      return value
        ? `${value.address || ''} ${value.postal_code || ''} ${
            value.city || ''
          } ${value.country || ''}`.trim()
        : '-';
    }
    return '-';
  }, [type]);

  const inputRead = (
    <div className="w-full">
      {data.isImage ? (
        <InfosWithImage
          loading={loading}
          infos={[
            {
              title,
              subtitle: type === 'address' ? addressLabel : value || '-',
              showImage: data.image,
            },
          ]}
          image={data.image}
          addClass={addClass}
          dataTestId="infos_with_image"
        />
      ) : (
        <InfosWithIcon
          loading={loading}
          infos={[
            {
              title,
              subtitle:
                type === 'address'
                  ? addressLabel
                  : type === 'select' && !value
                  ? placeholder
                  : value || '-',
            },
          ]}
          icon={!isEditMode ? icon : undefined}
          addClass={addClass}
          colorIcon={colorIcon || blueOpx}
          dataTestId="infos_with_icon"
          isLink={Boolean(data.onClick)}
          onClick={() => data.onClick && data.onClick()}
          noWrap={data.noWrap}
          spaceLeft={!data.icon && !isEditMode}
        />
      )}
    </div>
  );

  return type === 'image' ? (
    loading ? (
      <LoaderSkeleton height={height || '2rem'} addClass="w-[8.125rem]" />
    ) : (
      <ImageContainer
        imageUrl={value}
        height={height}
        width={width}
        addClass="mr-4"
        imageText={data.imageText}
      />
    )
  ) : isEditMode ? (
    hideOnEdit ? null : (
      inputRead
    )
  ) : type === 'hidden' ? null : (
    inputRead
  );
}

export { InputInformationRead };
