import { Modal } from '@components/Modal';
import { useTranslation } from 'react-i18next';

interface INoAidProps {
  description: string;
}

function NoAid({ description }: INoAidProps) {
  const { t } = useTranslation();

  const onNewSimulation = () => {
    window.location.reload();
  };

  return (
    <Modal
      title={t('worksite_creation.simulation.noaid')}
      textBtnConfirm={`${t('buttons.new_simulation')}`}
      onConfirmClick={onNewSimulation}
    >
      <div className="mt-[1rem]">{description}</div>
    </Modal>
  );
}

export default NoAid;
