import {
  IContractDraftType,
  IContractOperationPrice,
  IContractPartnerInfo,
  IContractPaymentCondition,
  IDraftPartnerType,
  PriceType,
} from '@models/contractCreation/utils/contractCreationTypes';
import { isValidField } from '@models/contractCreation/utils/newContractHelper';
import { getEtablissementData } from '@models/sirene/apiRequests/sireneRequests';
import { Dispatch, SetStateAction } from 'react';
import {
  initialContractContact,
  initialContractPaymentConditions,
} from '@utils/initialState';
import { IOperationType } from '@models/conventions/utils/conventionTypes';
import { IBeneficiaryAddress } from '@models/beneficiaries/utils/beneficiariesType';
import { initialWorksite } from '@models/worksiteCreation/utils/initialsValues/worksitesInitialValues';
import { ENTITY_TYPES } from '@utils/roles';
import { ContractCreation } from '@models/contractCreation/utils/contractCreationContext';
import { dateToDDMMYYY } from '@utils/format';
import { convertKiloToGiga, convertKiloToMega } from '@utils/functions';
import {
  BeneficiaryTypes,
  ContractTypes,
} from '@models/contractCreation/utils/enums';
import { INCENTIVE_TYPE } from '@models/worksiteCreation/utils/enums';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { TFunction } from 'i18next';

export const isValidSignataire = (
  entity: IContractPartnerInfo,
  isSignatories: boolean,
  haveSignatoryId: boolean
) => {
  const contact = 'signataire';

  const infoGeneralOk =
    isValidField(entity.company_name) &&
    isValidField(entity.siret, 'siret') &&
    isValidField(entity.address.city);

  const contactOk =
    isValidField(String(entity[contact]?.civility || ''), 'num') &&
    isValidField(entity[contact]?.firstname || '', 'name') &&
    isValidField(entity[contact]?.lastname || '', 'name') &&
    isValidField(entity[contact]?.function || '') &&
    isValidField(entity[contact]?.phone_number || '', 'phone') &&
    isValidField(entity[contact]?.email || '', 'email');

  return infoGeneralOk && (contactOk || (isSignatories && haveSignatoryId));
};

export const getEntityTypes = (
  types: { entityType: number }[],
  filtersArray?: number[]
) => {
  const entityTypes: number[] = [];

  const filters = filtersArray || Object.values(ENTITY_TYPES);

  types.forEach((et: { entityType: number }) => {
    const existing = entityTypes.some((t) => t === et.entityType);
    if (!existing && filters.includes(et.entityType)) {
      entityTypes.push(et.entityType);
    }
  });

  return entityTypes;
};

export const checkSiretAides = async (
  siret: string,
  setLoading: Dispatch<SetStateAction<boolean>>,
  updateEntity: Dispatch<SetStateAction<IContractPartnerInfo>>
) => {
  if (siret.length === 14) {
    setLoading(true);
    const res = await getEtablissementData(siret, setLoading);

    if (res) {
      const newAddress: IBeneficiaryAddress = {
        ...initialWorksite.beneficiary.address,
        address: res.address,
        city: res.city,
        postal_code: res.zipcode,
      };

      let entityTypes: number[] = [];

      if (res.entity_types) {
        entityTypes = getEntityTypes(res.entity_types, [
          ENTITY_TYPES.OBLIGE,
          ENTITY_TYPES.DELEGATAIRE,
        ]);
      }

      updateEntity((prevState) => ({
        ...prevState,
        id: res.id,
        address: newAddress,
        siret: res.siret,
        company_name: res.company_name,
        entityTypes,
      }));

      return res;
    }
    updateEntity((prevState) => ({
      ...prevState,
      address: initialContractContact.address,
      company_name: '',
    }));
    return false;
  }
  return false;
};

export const getOperationLabel = (operation: IOperationType | undefined) => {
  if (!operation) return '';

  return `${operation.code} - ${operation.description}`;
};

const convertPuToMega = (operations: IContractOperationPrice[] | undefined) => {
  if (!operations) return [];

  return operations.map((o) => ({
    ...o,
    prix_unitaire_classique: o.prix_unitaire_classique,
    prix_unitaire_precaire: o.prix_unitaire_precaire
      ? o.prix_unitaire_precaire
      : undefined,
  }));
};

const getConditions = (conditions: IContractPaymentCondition[]) => {
  if (conditions.length === 0)
    return [initialContractPaymentConditions, initialContractPaymentConditions];

  if (conditions.length === 1)
    return [conditions[0], initialContractPaymentConditions];

  return conditions;
};

const updateEntity = (
  setEntity: Dispatch<SetStateAction<IContractPartnerInfo>>,
  data: IDraftPartnerType
) => {
  if (data.new_entity) {
    setEntity((prev) => ({
      ...prev,
      id: data.entity_to_id,
      siret: data.new_entity?.siret || '',
      company_name: data.new_entity?.company_name || '',
      signatory_id: data.signatory_id,
      signataire: { ...data.contact_signataire },
      contact: !data.contact_1 ? undefined : { ...data.contact_1 },
      address: {
        ...prev.address,
        city: data.new_entity?.city || '',
        address: data.new_entity?.address || '',
        postal_code: data.new_entity?.zipcode || '',
      },
    }));
  }
};

const formatOperations = (
  ops: IContractOperationPrice[]
): IContractOperationPrice[] => {
  if (!ops) return [];
  return ops.map((op: any) => {
    if (op.operation)
      return {
        ...op,
        label: `${op.operation.code} - ${op.operation.description}`,
      };
    return { ...op };
  });
};

export const updateState = (
  data: IContractDraftType,
  state: ContractCreation,
  isCopy = false
) => {
  const isConvention = [
    ContractTypes.TRIPARTITE,
    ContractTypes.CONVENTION,
  ].includes(state.contractType);

  const isSale = [ContractTypes.VENTE, ContractTypes.DELEGATION].includes(
    state.contractType
  );
  const isTripartite = data.contract_type === ContractTypes.TRIPARTITE;

  state.updateReferenceOpx(data.reference);

  // Generale
  state.updateContractType(data.contract_type);
  state.updateValidationDelay(data.offer_validity_delay);
  state.updateBeneficiaryType(data.beneficiary_type);
  state.updateReferencePerso(data.internal_reference);
  state.updateStartDate(dateToDDMMYYY(data.start_date));
  state.updateEndDate(dateToDDMMYYY(data.end_date));
  state.updateWorksiteAddresses(data.worksite_adresses || []);
  state.updateOrigination(data.origination || 0);
  state.updateIncentiveType(data.incentive_type);
  state.updateCanManageWorksiteDocuments(
    data.can_manage_worksite_documents || false
  );
  state.updateCanUseCustomPrices(data.can_use_custom_prices);
  if (data.rai_id)
    state.updateRai((prev) => ({ ...prev, value: Number(data.rai_id) }));

  if (data.contract_id) {
    state.updateRelatedContract({
      id: data.contract_id,
      contract_type: data.contract_type,
      incentive_type: data.incentive_type,
      label: '',
    });
  }

  if (data.internal?.acteur_type) {
    state.updateSaleProfile(data.internal.acteur_type);
  }

  const removeSpaces = (value: string) => {
    return value.replace(/\s+/g, '');
  };

  const volumeConvert = (k: number | null) =>
    isSale
      ? removeSpaces(convertKiloToMega(k))
      : removeSpaces(convertKiloToGiga(k));

  state.updateRaiMention(data.rai_mention);

  // Volumes
  state.updateVolumeMax({
    classic: volumeConvert(data.volume_classique_kwhc),
    precaire: volumeConvert(data.volume_precaire_kwhc),
  });
  state.updateVolumeMin({
    classic: volumeConvert(data.minimum_volume_classique_kwhc),
    precaire: volumeConvert(data.minimum_volume_precaire_kwhc),
  });
  state.updateDeliveryVolume(convertKiloToMega(data.lot_minimum_volume_kwhc));
  state.updateSalePrices({
    classic: convertKiloToMega(data.pu_classique),
    precaire: convertKiloToMega(data.pu_classique),
  });
  state.updatePenalties({
    classic: volumeConvert(data.amount_penalty_classique),
    precaire: volumeConvert(data.amount_penalty_precaire),
  });

  if (isSale) state.updatePartnerType(data.to.entity_type);

  // Partner
  if (data.to && data.to.new_entity)
    updateEntity(state.updateEntityTo, data.to);

  if (isTripartite) {
    updateEntity(state.updateAmo, data.amo);

    const beneficiaryData = data.beneficiary_with_contacts;

    if (data.beneficiary_type === BeneficiaryTypes.ENTITY) {
      const beneficiaryAddress = beneficiaryData.beneficiary.address;

      state.updateEntityTo((prev) => ({
        ...prev,
        siret: beneficiaryData.beneficiary.siret || '',
        company_name: beneficiaryData.beneficiary.company_name || '',
        signataire: { ...beneficiaryData.contact_signataire },
        address: {
          ...prev.address,
          address: beneficiaryAddress.address,
          postal_code: beneficiaryAddress.postal_code,
          city: beneficiaryAddress.city,
        },
      }));
    }

    if (data.beneficiary_type === BeneficiaryTypes.PERSON) {
      state.updateBeneficiary({
        ...beneficiaryData.beneficiary,
        contact: beneficiaryData.contact_1,
      });
    }
  }

  // Référents
  state.updateReferents(data.internal);

  // Opérations

  let operationPrices = isConvention
    ? data.convention_operations
    : data.contract_operations;

  if (isCopy && data.convention_operations)
    operationPrices = formatOperations(data.convention_operations);

  state.updateOperationPrices(convertPuToMega(operationPrices));

  // Apporteur d'affaire
  if (data.intermediary_business) {
    updateEntity(state.updateIntermediaryBusiness, data.intermediary_business);
  }

  if (data.intermediary_business?.minimum_bonus_allocation) {
    state.updateCommission(
      data.intermediary_business?.minimum_bonus_allocation
    );
  }

  // Payment conditions
  state.updatePaymentConditions(getConditions(data.payment_deadlines || []));

  // signature electronique
  state.updateIsElectronicSignature(data.is_electronic_signature);
};

export const filterPricesByIncentive = (
  incentive: number,
  operationPrices: IContractOperationPrice[],
  updateOperationPrices: Dispatch<SetStateAction<IContractOperationPrice[]>>
) => {
  if (incentive !== INCENTIVE_TYPE.MIXED) {
    const isDirect = incentive === INCENTIVE_TYPE.DIRECT;
    const chosenPrefix = isDirect ? 'direct_' : 'indirect_';
    const oppositePrefix = isDirect ? 'indirect_' : 'direct_';

    const newOperationPrices = operationPrices.map((operationPrice) => {
      let newPrice: IContractOperationPrice = { ...operationPrice };
      Object.entries(newPrice).forEach(([key, value]) => {
        if (key.startsWith(oppositePrefix) && !Number.isNaN(value)) {
          const newKey = key.replace(
            oppositePrefix,
            chosenPrefix
          ) as keyof IContractOperationPrice;

          if (!newPrice[newKey]) {
            // on ajoute le prix correspondant à l'incitation choisie si il n'extiste pas déja
            newPrice = { ...newPrice, [newKey]: Number(value) };
          }

          const oppositeKey = key as keyof IContractOperationPrice;
          delete newPrice[oppositeKey]; // supprime le prix qui ne correspond pas à l'incitation choisie
          if (newPrice[newKey] === 0) delete newPrice[newKey];
        }
      });
      return newPrice;
    });

    updateOperationPrices(newOperationPrices);
  } else {
    const newOperationPrices = operationPrices.map((operationPrice) => {
      let newPrice: IContractOperationPrice = { ...operationPrice };
      Object.entries(newPrice).forEach(([key, value]) => {
        // clés contenant direct_ et indirect_
        if (key.includes('direct_') && !Number.isNaN(value)) {
          const isDirect = key.startsWith('direct_');

          const keyPrefix = isDirect ? 'direct_' : 'indirect_';
          const oppositePrefix = isDirect ? 'indirect_' : 'direct_';
          const currentKey = key as keyof IContractOperationPrice;
          const oppositeKey = key.replace(
            keyPrefix,
            oppositePrefix
          ) as keyof IContractOperationPrice;

          // on remplie les valeur direct et indirect
          if (!newPrice[oppositeKey] || !newPrice[currentKey]) {
            if (newPrice[currentKey]) {
              newPrice = { ...newPrice, [oppositeKey]: Number(value) };
            }

            if (newPrice[oppositeKey]) {
              newPrice = { ...newPrice, [currentKey]: Number(value) };
            }
          }
        }
      });
      return newPrice;
    });
    updateOperationPrices(newOperationPrices);
  }
};
export const convertUnitPricesToFixPrices = (
  priceValue: IContractOperationPrice | undefined,
  setPriceValue: Dispatch<SetStateAction<IContractOperationPrice | undefined>>,
  fixCdp: boolean
) => {
  if (priceValue) {
    let newState = { ...priceValue };

    Object.entries(priceValue).forEach(([key, value]) => {
      if (fixCdp && key.includes('_pu_cdp')) {
        const pfKey = key.replace('_pu_cdp', '_pf_cdp');
        newState = { ...newState, [key]: undefined, [pfKey]: value };
        // efface la valeur du prix unitaire pour la remplacer par le prix fixe
      }

      if (!fixCdp && key.includes('_pf_cdp')) {
        const puKey = key.replace('_pf_cdp', '_pu_cdp');
        newState = { ...newState, [key]: undefined, [puKey]: value };
        // efface la valeur du prix fixe pour la remplacer par le prix unitaire
      }
    });

    setPriceValue(newState);
  }
};

export const handleMinimumAmountPricesErrors = (
  priceValue: IContractOperationPrice,
  mandatoryKeys: PriceType[],
  action: string,
  incentive: 'direct' | 'indirect',
  isCdp: boolean | undefined,
  fixCdp: boolean | undefined,
  methods: UseFormReturn<FieldValues, any, undefined>,
  t: TFunction
) => {
  const { setError, clearErrors } = methods;
  mandatoryKeys.forEach((key) => {
    let puKey: keyof IContractOperationPrice = `${incentive}_pu_${
      isCdp ? 'cdp_' : ''
    }${key}`; // clé prix total

    /* Exemple
        fixCdp : indirect_pu_cdp_precaire => indirect_pf_cdp_precaire
        !fixCdp : indirect_pf_cdp_precaire => indirect_pu_cdp_precaire
      */
    if (puKey.includes(fixCdp ? '_pu_cdp_' : '_pf_cdp_')) {
      puKey = puKey.replace(
        fixCdp ? '_pu_' : '_pf_',
        fixCdp ? '_pf_' : '_pu_'
      ) as keyof IContractOperationPrice;
    }

    const minimumKey: keyof IContractOperationPrice = `${incentive}_beneficiary_minimum_amount_${
      isCdp ? 'cdp_' : ''
    }${key}`; // clé prix minimum

    const puAmount = parseInt(String(priceValue[puKey] || 0), 10); // valeur prix total
    const minimumAmount = parseInt(String(priceValue[minimumKey] || 0), 10); // valeur prix minimum

    // le prix minimum doit être inféreur au prix total
    if (minimumAmount > puAmount) {
      setError(`${action}_${minimumKey}`, {
        message: `${t('convention.minimum_price_error', {
          price: puAmount,
          unit: puKey.includes('_pf_') ? '€' : '€/MWhc',
        })}`,
      });
    } else {
      clearErrors(`${action}_${minimumKey}`);
    }
  });
};
