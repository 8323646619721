/* eslint-disable no-nested-ternary */
import { TextWithRadio } from '@components/atomic/inputs/controls/TextWithRadio';
import { InputText } from '@components/atomic/inputs/InputText';
import { IGraph } from '@models/worksiteCreation/utils/types/SimulationTypes';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  findDuplicates,
  getGraphErrors,
  getNumberConditionValue,
  hasNonEmptyArrayWithNonEmptyValues,
} from '@models/worksiteCreation/utils/functions';
import { OperationTypeEnum } from '@utils/enums';
import ArrayForm from '@models/worksiteCreation/components/simulation/stepDetailOperation.tsx/ArrayForm';
import { useTranslation } from 'react-i18next';
import MultipleArrayForm from '@models/worksiteCreation/components/simulation/stepDetailOperation.tsx/MultipleArrayForm';
import { useFormContext } from 'react-hook-form';
import { WorksiteCreationContext } from '../../../utils/worksiteCreationContext';
import NoAid from './NoAid';
import ScopForm from './ScopForm';
import BoilerForm from './BoilerForm';

interface IRenderFormGraphOperationProps {
  graph: IGraph;
  index: number;
  setErrorCalculPrimeMessage?: Dispatch<SetStateAction<string>>;
  boilerPowerError?: boolean;
  setGraphErrors: Dispatch<SetStateAction<string[]>>;
}

function RenderFormGraphOperation({
  graph,
  index,
  setErrorCalculPrimeMessage,
  boilerPowerError,
  setGraphErrors,
}: IRenderFormGraphOperationProps) {
  const { t } = useTranslation();
  const {
    simulatorDataOperations,
    updateSimulatorDataOperation,
    simulatorData,
    worksiteOperationType,
    updateReplacedEnergy,
    updateNoAidOperationToDisplay,
    noAidOperationToDisplay,
    listOperationSelected,
    readOnly,
  } = useContext(WorksiteCreationContext);

  const formContext = useFormContext();
  const { watch, unregister } = formContext;

  const [errorMessage, setErrorMessage] = useState<string>('');

  const keyActivGraph = simulatorDataOperations[index][graph.key];
  const graphElement = simulatorDataOperations[index] || null;

  const updateOperationData = (value: string | null | number) => {
    updateSimulatorDataOperation((prevState) => {
      // Clone de l'état précédent pour éviter des mutations directes
      const newState = Array.isArray(prevState) ? [...prevState] : [prevState];

      // Trouver l'index de l'opération courante dans newState
      const operationIndex = newState.findIndex(
        (elt) =>
          graphElement && elt['operation.id'] === graphElement['operation.id']
      );

      // Si l'opération courante est trouvée dans newState
      if (operationIndex !== -1) {
        // Supprimer les clés des éléments frères qui ne sont pas le composant actuel
        graph.parentChildrens?.forEach((sibling) => {
          if (
            sibling.key !== graph.key &&
            newState[operationIndex][sibling.key] !== undefined
          ) {
            delete newState[operationIndex][sibling.key];
          }
        });

        // Mettre à jour la valeur pour la clé actuelle
        newState[operationIndex] = {
          ...newState[operationIndex],
          [graph.key]: value,
        };
      } else {
        // Si l'opération courante n'est pas trouvée (ce cas peut survenir selon la structure de vos données),
        // vous pourriez vouloir gérer ce cas spécifiquement, par exemple en ajoutant l'opération à newState.
      }

      return newState;
    });
  };

  const addKeyIfNotExists = () => {
    const keyExists =
      graphElement &&
      Object.keys(graphElement).some((elt) => elt === graph.key);
    if (!keyExists) updateOperationData(null);
  };

  /* const addKeyIfNotExists = () => {
    const keyExists = Object.keys(graphElement).some(
      (elt) => elt === graph.key
    );
    if (!keyExists) {
      updateSimulatorDataOperation((prevState) => {
        const newState = { ...prevState };
        const siblings = graph.parentChildrens; // Supposons que `parentChildrens` est la liste des éléments frères incluant `graph`

        // Supprimer les clés des frères qui ne sont pas le composant actuel
        siblings?.forEach((sibling) => {
          if (
            sibling.key !== graph.key &&
            newState[index][sibling.key] !== undefined
          ) {
            delete newState[index][sibling.key];
          }
        });

        // Ajouter la clé si elle n'existe pas déjà pour le composant actuel
        if (newState[index][graph.key] === undefined) {
          newState[index][graph.key] = null;
        }

        return newState;
      });
    }
  }; */

  const nextChildren = () => {
    // Récupérer les clés des enfants
    const childKeys = graph?.childrens?.map((child) => child.key);
    const duplicateKeys = findDuplicates(childKeys);

    return graph.childrens?.map((grandchild) => {
      const condition = grandchild.condition[0];
      const numberCondition =
        condition && (condition.includes('<') || condition.includes('>'));

      let conditionIsOk =
        grandchild.condition?.includes(keyActivGraph) ||
        grandchild.condition?.length === 0;

      if (numberCondition) {
        const conditionValue = Number(condition.match(/[\d.]+/)[0]);
        const sign = condition.split(conditionValue)[0];
        conditionIsOk = getNumberConditionValue(
          Number(keyActivGraph),
          sign,
          conditionValue
        );
      }

      if (graph.key === 'operation.replacedEnergy') {
        const selectedOption = graph.options?.find(
          (elt) => elt.value === keyActivGraph
        );

        if (selectedOption) {
          const { value, label } = selectedOption;
          if (value === 'none') updateReplacedEnergy(null);
          else updateReplacedEnergy({ value, label });
        }
      }
      if (grandchild.key === 'operation.noaid') {
        if (conditionIsOk && !noAidOperationToDisplay) {
          updateNoAidOperationToDisplay({
            keyParent: graph.key,
            description: grandchild.name,
          });
        } else if (
          !conditionIsOk &&
          noAidOperationToDisplay?.description === grandchild.name
        ) {
          updateNoAidOperationToDisplay(undefined);
        }
        return null;
      }

      if (!conditionIsOk) {
        // Vérifier si la clé du grandchild fait partie des doublons
        const isDuplicate = duplicateKeys.includes(grandchild.key);

        if (watch(`${index}.${grandchild.key}`) !== undefined && !isDuplicate) {
          unregister(`${index}.${grandchild.key}`);
          updateSimulatorDataOperation((prevState) => {
            const newState = Array.isArray(prevState)
              ? [...prevState]
              : [prevState];
            if (
              newState[index] &&
              newState[index][grandchild.key] !== undefined
            ) {
              delete newState[index][grandchild.key];
            }
            return newState;
          });
        }
      }
      return (
        conditionIsOk && (
          <RenderFormGraphOperation
            key={grandchild.key}
            graph={{ ...grandchild, parentChildrens: graph.childrens }}
            index={index}
            boilerPowerError={boilerPowerError}
            setGraphErrors={setGraphErrors}
          />
        )
      );
    });
  };

  const shouldOnlyBeCondensation = (arr: any) => {
    // Check if both 'BAR-SE-109' and 'BAR-TH-107' are present in listOperationSelected
    return ['BAR-SE-109', 'BAR-TH-107'].every((code) =>
      arr?.some((obj: any) => obj.code === code)
    );
  };

  const shouldOnlyBeHeatingNetwork = (arr: any) => {
    // Check if 'BAR-SE-109' and either 'BAR-TH-137' or 'RES-CH-104' is present in listOperationSelected
    return (
      arr?.some((obj: any) => obj.code === 'BAR-SE-109') &&
      ['BAR-TH-137', 'RES-CH-104'].some((code) =>
        listOperationSelected?.some((obj) => obj.code === code)
      )
    );
  };

  useEffect(() => {
    const { operations } = simulatorData;

    if (graph.key === 'operation.heatingType' && graph.options) {
      if (shouldOnlyBeCondensation(listOperationSelected)) {
        // Find the option with the value 'condensation' which corresponds to label 'Chaudière à condensation'
        const chaudiereOption = graph.options.find(
          (option) => option.value === 'condensation'
        );

        // Update the graph options to only include the desired option
        // eslint-disable-next-line no-param-reassign
        graph.options = chaudiereOption
          ? [chaudiereOption]
          : [graph.options[1]];
        updateOperationData('condensation');
      } else if (shouldOnlyBeHeatingNetwork(listOperationSelected)) {
        // Find the option with value 'heating_network' which corresponds to the label 'un réseau de chaleur'
        const ReseauOption = graph.options.find(
          (option) => option.value === 'heating_network'
        );

        // Update the graph options to only include the desired option
        // eslint-disable-next-line no-param-reassign
        graph.options = ReseauOption ? [ReseauOption] : [graph.options[2]];
        updateOperationData('heating_network');
      }
    }

    if (
      worksiteOperationType === OperationTypeEnum.GLOBAL_RENOVATION &&
      graph.key === 'operation.surface' &&
      operations?.length > 0 &&
      !operations[0]['operation.surface']
    ) {
      updateSimulatorDataOperation((prevState) => {
        const operationSurface =
          operations[0]['operation.surface'] ||
          simulatorData['general.surface'];

        return [
          {
            ...prevState[0],
            'operation.surface': operationSurface,
          },
        ];
      });
    }
  }, [graph.key, simulatorData]);

  const hasIncompatibilityWithPac = (arr: any) => {
    const hasPac = arr.some(
      (obj: any) => obj.code === 'BAR-TH-171' || obj.code === 'BAR-TH-172'
    );
    const hasBarTh148 = arr.some((obj: any) => obj.code === 'BAR-TH-148');
    return hasPac && hasBarTh148;
  };

  useEffect(() => {
    if (graph.rules) {
      const graphErrors = getGraphErrors(
        graph,
        simulatorData,
        simulatorDataOperations,
        index
      );

      if (graphErrors.length > 0) {
        setErrorMessage(graphErrors[0].message);
      } else {
        setErrorMessage('');
      }
    }

    if (
      graph.key === 'operation.totalHeatingPower' ||
      graph.key === 'operation.totalConnectedPower'
    ) {
      if (boilerPowerError) {
        const message = t(
          'worksite_creation.simulation.boiler_unit.boiler_power_error'
        );
        setErrorMessage(message);
      } else {
        setErrorMessage('');
      }
    }
  }, [graph, simulatorData]);

  useEffect(() => {
    if (errorMessage !== '') {
      setGraphErrors((prev) => [...prev, graph.key]);
    } else {
      setGraphErrors((prev) => prev.filter((key) => key !== graph.key));
    }
  }, [errorMessage]);

  switch (graph.input) {
    case 'input_type_number':
    case 'inputThermalResistance':
      addKeyIfNotExists();
      return (
        <>
          <InputText
            dataTestId={`input_type_number_${graph.key}`}
            defaultValue={
              graph.key === 'operation.surface' &&
              simulatorData.operations &&
              simulatorData.operations.length > 0
                ? simulatorData.operations[0]['operation.surface'] ||
                  simulatorData['general.surface']
                : undefined
            }
            label={
              worksiteOperationType === OperationTypeEnum.GLOBAL_RENOVATION
                ? graph.name
                : ''
            }
            required
            typeNumber
            disabled={readOnly}
            addClassToInput={readOnly ? 'bg-backgroundBody' : ''}
            placeholder={graph.name}
            id={`${index}.${graph.key}`}
            name={`${index}.${graph.key}`}
            valueInitialInput={simulatorDataOperations[index][
              graph.key
            ]?.toString()}
            addClass="mt-[1rem]"
            onChange={(e) => {
              updateOperationData(e.toString());
            }}
            error={errorMessage !== ''}
            textError={errorMessage}
          />
          {keyActivGraph && nextChildren()}
        </>
      );
    case 'scopForm':
      addKeyIfNotExists();
      return (
        <>
          <ScopForm
            graph={graph}
            setErrorCalculPrimeMessage={setErrorCalculPrimeMessage}
          />
          {keyActivGraph && nextChildren()}
        </>
      );
    case 'boilersNumber':
      addKeyIfNotExists();
      return (
        <>
          <BoilerForm graph={graph} />
          {keyActivGraph && nextChildren()}
        </>
      );
    case 'arrayForm':
      addKeyIfNotExists();
      return (
        <>
          <ArrayForm
            graph={graph}
            operationIndex={index}
            boilerPowerError={boilerPowerError}
          />
          {keyActivGraph && nextChildren()}
        </>
      );
    case 'multipleArrayForm':
      addKeyIfNotExists();
      return (
        <>
          <MultipleArrayForm graph={graph} operationIndex={index} />
          {keyActivGraph &&
            hasNonEmptyArrayWithNonEmptyValues(
              simulatorDataOperations[index][graph.key]
            ) &&
            nextChildren()}
        </>
      );

    case 'noaid':
      if (
        typeof graph.condition === 'object' &&
        graph.condition?.generalSimulator?.step &&
        `general.${graph.condition?.generalSimulator.step}` in simulatorData &&
        graph.condition?.generalSimulator.answer ===
          simulatorData[`general.${graph.condition?.generalSimulator.step}`]
      ) {
        return <NoAid description={graph.name} />;
      }
      if (Object.values(graph.condition || {}).length > 0) {
        return <NoAid description={graph.name} />;
      }
      return null;
    case 'input_number':
      addKeyIfNotExists();
      return (
        <>
          <InputText
            dataTestId={`input_number_${graph.key}`}
            typeNumber
            placeholder={graph.name}
            id={`${index}.${graph.key}`}
            name={`${index}.${graph.key}`}
            addClass="mt-[1rem]"
            onChange={(e) => {
              updateOperationData(Number(e));
            }}
            disabled={readOnly}
            addClassToInput={readOnly ? 'bg-backgroundBody' : ''}
            error={errorMessage !== ''}
            textError={errorMessage}
          />
          {keyActivGraph && nextChildren()}
        </>
      );
    case 'input_string':
    case 'input':
      addKeyIfNotExists();
      return (
        <>
          <InputText
            dataTestId={`input_text_${graph.key}`}
            typeNumber={graph.key === 'operation.totalCost'}
            textError={`${t('worksite_creation.simulation.error_total_cost')}`}
            placeholder={graph.name}
            id={`${index}.${graph.key}`}
            name={`${index}.${graph.key}`}
            required
            addClass="mt-[1rem]"
            valueInitialInput={simulatorDataOperations[index][
              graph.key
            ]?.toString()}
            onChange={(e) => {
              const value =
                graph.key === 'operation.totalCost'
                  ? typeof e === 'string' && e !== ''
                    ? parseFloat(e.replace(',', '.'))
                    : null
                  : e.toString();

              updateOperationData(value);
            }}
            disabled={readOnly}
            addClassToInput={readOnly ? 'bg-backgroundBody' : ''}
          />
          {keyActivGraph && nextChildren()}
        </>
      );

    case 'choice':
      addKeyIfNotExists();
      return (
        <div className="flex w-full flex-col space-y-[.5rem] mt-[1rem]">
          <div className="pt-[.5rem]">{graph.name}</div>
          {graph.options?.map((option) => {
            let toDisabled = false;
            const optionNotAvailable = hasIncompatibilityWithPac(
              listOperationSelected
            );
            // Bar-th-171 et Bar-th-172, ne doivent pas être compatibles avec Bar-th-148, si elles ont un usage = "eau chaude sanitaire"
            if (option.value === 'ecs' && optionNotAvailable) toDisabled = true;

            const isChecked =
              graph.key === 'operation.heatingType' &&
              (shouldOnlyBeCondensation(listOperationSelected) ||
                shouldOnlyBeHeatingNetwork(listOperationSelected));

            return (
              <div key={`${graph.key}_${option.label}`}>
                <TextWithRadio
                  disabled={toDisabled || readOnly}
                  dataTestId={`input_radio_${graph.key}_${option.value}`}
                  label={option.label}
                  value={option.value}
                  selectValue={
                    simulatorDataOperations[index] !== undefined &&
                    keyActivGraph !== undefined
                      ? keyActivGraph?.toString()
                      : ''
                  }
                  setSelectedValue={(e) => {
                    const nullKey = Object.keys(
                      simulatorDataOperations[index]
                    ).find(
                      (key) => simulatorDataOperations[index][key] === null
                    );
                    // eslint-disable-next-line no-unused-expressions
                    nullKey
                      ? delete simulatorDataOperations[index][nullKey]
                      : null;

                    updateOperationData(e);
                  }}
                  isChecked={
                    isChecked ||
                    (simulatorDataOperations[index] !== undefined &&
                    keyActivGraph !== undefined
                      ? keyActivGraph === option.value
                      : false)
                  }
                  withoutName
                />
              </div>
            );
          })}
          {keyActivGraph && <> {nextChildren()} </>}
        </div>
      );
    default:
      return <div />;
  }
}

export { RenderFormGraphOperation };

RenderFormGraphOperation.defaultProps = {
  setErrorCalculPrimeMessage: undefined,
  boilerPowerError: false,
};
