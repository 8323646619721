import { useTranslation } from 'react-i18next';
import { ColorCube } from '@components/atomic/ColorCube';
import {
  AddIcon,
  CheckIcon,
  CrossIcon,
  DownloadIcon,
  LoadingIcon,
  TrashIcon,
} from '@assets/images/svgComponents';
import { blueOpx, green, iconBlue, orange, red, textGrey } from '@assets/color';
import { ROLES } from '@utils/roles';
import { useContext, useMemo } from 'react';
import { GlobalContext } from '@context/globalContext';
import { DOCUMENT_STATE_STRING } from '@utils/utils';
import { Loader } from '@components/atomic/Loader';
import { format, parse } from 'date-fns';
import {
  getFileNameFromUrl,
  shortenStringWithEllipsis,
} from '@utils/functions';
import { ILinkedFile } from '../../types/globalTypes';

interface IDocumentCardHeaderProps {
  title: string;
  document: ILinkedFile;
  isActive: boolean;
  isLoading: boolean;
  onClickCard?: () => void;
  disabled?: boolean;
  status?: string;
  isUploadCard?: boolean;
  onDelete?: (document: ILinkedFile) => void;
  headerButtonsList?: string[];
  isAddButton?: boolean;
  subtitleOptional?: string;
  showSubtitle?: boolean;
  readOnly?: boolean;
}

function DocumentCardHeader({
  title,
  document,
  status,
  isActive,
  isUploadCard,
  isLoading,
  onClickCard,
  disabled,
  onDelete,
  headerButtonsList,
  isAddButton,
  subtitleOptional,
  showSubtitle,
  readOnly,
}: IDocumentCardHeaderProps) {
  const { t } = useTranslation();
  const { roleUser } = useContext(GlobalContext);

  const enabledColor = readOnly ? blueOpx : iconBlue;

  const rightPartIcon = useMemo(() => {
    const btnAdd = (
      <ColorCube
        size="1.5rem"
        numberOrIcon={<AddIcon />}
        color={disabled ? textGrey : blueOpx}
      />
    );

    const btnDownload = (
      <a
        href={document.file_url as string}
        download
        target="_blank"
        rel="noreferrer"
        data-test-id="download_button"
      >
        <ColorCube
          size="1.5rem"
          numberOrIcon={<DownloadIcon />}
          color={disabled ? textGrey : enabledColor}
          opacity={!readOnly}
        />
      </a>
    );

    const btnDelete =
      !readOnly && onDelete ? (
        <ColorCube
          size="1.5rem"
          numberOrIcon={<TrashIcon />}
          color={disabled ? textGrey : red}
          opacity
          onClick={() => onDelete(document)}
        />
      ) : (
        <div />
      );

    if (headerButtonsList && !readOnly) {
      return (
        <>
          {headerButtonsList.includes('download') && btnDownload}
          {headerButtonsList.includes('delete') && btnDelete}
        </>
      );
    }

    if (
      roleUser === ROLES.GESTION &&
      status === DOCUMENT_STATE_STRING.PENDING &&
      !isAddButton
    ) {
      return undefined;
    }
    if (status === DOCUMENT_STATE_STRING.TO_IMPORT) {
      return btnAdd;
    }

    if (isAddButton) {
      return (
        <ColorCube
          size="1.5rem"
          numberOrIcon={<CheckIcon />}
          color={disabled ? textGrey : green}
          opacity
        />
      );
    }

    return (
      <>
        {(document.status === 4 || document.status === 3) && (
          <ColorCube
            size="1.5rem"
            numberOrIcon={document.status === 4 ? <CheckIcon /> : <CrossIcon />}
            color={document.status === 4 ? green : red}
            opacity
          />
        )}
        {/* { btnDownload} */}
        {!document.mandatory &&
          (roleUser !== ROLES.PRODUCTION
            ? btnDelete
            : status !== DOCUMENT_STATE_STRING.VALIDATE && btnDelete)}
      </>
    );
  }, [document, onDelete, roleUser]);

  const subtitle = useMemo(
    () =>
      showSubtitle && document.created_at !== null
        ? format(
            parse(document.created_at, 'yyyy-MM-dd HH:mm:ss', new Date()),
            'dd/MM/yyyy HH:mm'
          )
        : undefined,
    [document, showSubtitle]
  );

  const iconToDisplay = useMemo(() => {
    switch (status) {
      case DOCUMENT_STATE_STRING.VALIDATE:
        return { color: green, icon: <CheckIcon />, dataTestId: 'validated' };
      case DOCUMENT_STATE_STRING.PENDING:
        return { color: orange, icon: <LoadingIcon />, dataTestId: 'pending' };
      case DOCUMENT_STATE_STRING.REFUSE:
        return { color: red, icon: <CrossIcon />, dataTestId: 'refused' };
      default:
        return { color: '', icon: null, dataTestId: '' };
    }
  }, [status]);

  const stateIcon = useMemo(() => {
    if (
      !isUploadCard &&
      status !== DOCUMENT_STATE_STRING.TO_IMPORT &&
      iconToDisplay.icon &&
      !isAddButton
    ) {
      return (
        <ColorCube
          size="1.5rem"
          numberOrIcon={iconToDisplay.icon}
          color={iconToDisplay.color}
          opacity
          dataTestId={iconToDisplay.dataTestId}
        />
      );
    }
    return undefined;
  }, [isUploadCard, iconToDisplay, roleUser]);

  return (
    <div
      className={`flex items-center justify-between w-full ${
        isAddButton ? 'p-[.7rem]' : 'p-[1rem]'
      }`}
      onClick={onClickCard}
      aria-hidden
    >
      <div className="flex items-center space-x-[.5rem]">
        <div
          className={`${
            isUploadCard && !isLoading
              ? ''
              : 'flex items-center space-x-[.5rem]'
          }`}
        >
          {isLoading && <Loader />}
          {!isLoading && !readOnly && stateIcon}
          <div
            className={`text-left ${
              subtitle ? 'flex flex-col space-y-[.25rem]' : ''
            }`}
          >
            {readOnly ? (
              <p className="text-[.875rem]">
                {shortenStringWithEllipsis(
                  document.file_name || getFileNameFromUrl(document.file_url),
                  30
                )}{' '}
              </p>
            ) : (
              <p
                className={`${
                  isAddButton ? 'text-[.875rem]' : 'text-[1rem]'
                }  leading-[1.25rem] ${
                  isActive && !isAddButton ? 'font-[500]' : ''
                } ${isUploadCard ? 'text-textGrey' : 'black'}`}
              >
                {shortenStringWithEllipsis(title, 30)}{' '}
                {subtitleOptional ? `${subtitleOptional}` : ''}
              </p>
            )}
            {readOnly && document.operations && document.operations[0] && (
              <p className="text-[.75rem] leading-[.75rem] text-textGrey mt-3">
                {document.operations[0].name}
              </p>
            )}
            <p className="text-[.75rem] leading-[.75rem] text-textGrey">
              {subtitle}
            </p>
          </div>
        </div>
      </div>
      <div
        className={`${
          document.mandatory ? 'flex items-center space-x-[.5rem]' : ''
        }`}
      >
        {!readOnly && document.mandatory && !isAddButton && (
          <p className="text-[.75rem] leading-[.75rem]">
            {t('global.mandatory')}
          </p>
        )}
        <div className="flex items-center space-x-[.5rem]">{rightPartIcon}</div>
      </div>
    </div>
  );
}

export { DocumentCardHeader };

DocumentCardHeader.defaultProps = {
  status: undefined,
  onDelete: undefined,
  isUploadCard: false,
  headerButtonsList: undefined,
  isAddButton: false,
  disabled: false,
  showSubtitle: false,
  subtitleOptional: undefined,
  readOnly: false,
  onClickCard: undefined,
};
