import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { RequestAndActivityIntoModalOrCardContext } from '@context/requestAndChangeContext';
import { OneRequest } from '@models/requests/components/OneRequest';
import { useContext, useRef } from 'react';
import { IListGroupRequests } from '@models/requests/types/requestTypes';
import { filterRequestsByRelationType } from '@models/requests/utils/functions';

function ListRequestsInModal() {
  const listInnerRef = useRef<HTMLDivElement>(null);
  const { listRequestsGroupAll, isLoadingListRequestOrChange } = useContext(
    RequestAndActivityIntoModalOrCardContext
  );

  const listRequestsGroupAllWorkiste: IListGroupRequests = {
    todo: listRequestsGroupAll.todo
      ? filterRequestsByRelationType(listRequestsGroupAll.todo, 'worksite')
      : undefined,
    waiting: listRequestsGroupAll.waiting
      ? filterRequestsByRelationType(listRequestsGroupAll.waiting, 'worksite')
      : undefined,
  };

  const listRequestsGroupAllNote: IListGroupRequests = {
    todo: listRequestsGroupAll.todo
      ? filterRequestsByRelationType(listRequestsGroupAll.todo, 'note')
      : undefined,
  };

  return (
    <div
      ref={listInnerRef}
      className="flex flex-col space-y-[1rem] overflow-y-scroll max-h-[60vh]"
    >
      {!isLoadingListRequestOrChange ? (
        <div>
          {/* TODO: lorsque l'on aura la route pour obtenir TOUTES les demandes groupées, modifier par listRequestsGroupAll */}
          {listRequestsGroupAllWorkiste.todo !== undefined ? (
            listRequestsGroupAllWorkiste.todo
              .slice(0, 6)
              .map((request) => (
                <OneRequest
                  key={`${request.id}-${request.title}`}
                  request={request}
                  borderBottom
                  isKanban={false}
                />
              ))
          ) : (
            <div />
          )}
          {listRequestsGroupAllNote.todo !== undefined ? (
            listRequestsGroupAllNote.todo.map((request) => (
              <OneRequest
                key={`${request.id}-${request.title}`}
                request={request}
                borderBottom
                isKanban={false}
              />
            ))
          ) : (
            <div />
          )}
        </div>
      ) : (
        <div className="flex flex-col space-y-[.5rem]">
          <LoaderSkeleton height="6rem" />
          <LoaderSkeleton height="6rem" />
          <LoaderSkeleton height="6rem" />
          <LoaderSkeleton height="6rem" />
        </div>
      )}
    </div>
  );
}

export { ListRequestsInModal };

ListRequestsInModal.defaultProps = {
  setDetailRequest: undefined,
};
