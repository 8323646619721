import { Tag } from '@components/atomic/Tag';
import { Dispatch, SetStateAction } from 'react';
import { Loader } from '@components/atomic/Loader';
import { Radio } from './Radio';

interface IRadioOptions {
  unit_count: string;
  amount: number;
  display: string;
  legend?: string;
}

interface IRadioGroupCard {
  options: IRadioOptions[] | undefined;
  setSelectedRadioOption: Dispatch<SetStateAction<IRadioOptions | undefined>>;
  selectedRadioOption: IRadioOptions | undefined;
  isValidPaymentMethod: boolean;
  setShowPaymentMethod: Dispatch<SetStateAction<boolean>>;
}

function RadioGroupCard({
  options,
  setSelectedRadioOption,
  selectedRadioOption,
  isValidPaymentMethod,
  setShowPaymentMethod,
}: IRadioGroupCard) {
  if (options) {
    return (
      <div className="flex flex-col gap-2">
        {options.map((option) => {
          return (
            <div
              className={
                selectedRadioOption === option
                  ? 'flex flex-row items-center justify-between w-[25rem] border rounded-default p-4 cursor-pointer border-green bg-successOpacity'
                  : 'flex flex-row items-center justify-between w-[25rem] border rounded-default p-4 cursor-pointer hover:border-blueOpx'
              }
              onClick={() => {
                setSelectedRadioOption(option);
                setShowPaymentMethod(!isValidPaymentMethod);
              }}
              aria-hidden="true"
            >
              <div className="flex flex-row gap-4">
                <Radio
                  name="options"
                  value={option.unit_count}
                  onSelect={() => {
                    return null;
                  }}
                  isChecked={selectedRadioOption === option}
                />
                <div>
                  {Number(option.unit_count) === 1 && (
                    <div>{option.unit_count} crédit</div>
                  )}
                  {Number(option.unit_count) > 1 && (
                    <div>{option.unit_count} crédits</div>
                  )}
                </div>
              </div>
              {option.legend && (
                <div>
                  <Tag color="red" addClass="bg-red/10" label={option.legend} />
                </div>
              )}
              <div className="font-semibold	text-[1.2rem]">{option.display}</div>
            </div>
          );
        })}
      </div>
    );
  }
  return <Loader />;
}

export { RadioGroupCard };
