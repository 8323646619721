import { toast } from 'react-toastify';
import { InputText } from '@components/atomic/inputs/InputText';
import {
  IEntityInfoType,
  IPersonalInfoType,
  IAccountType,
  ISubscriptionsStored,
  IRepresentativesType,
} from '@models/auth/utils/types';
import {
  checkEmail,
  createAccount,
  invitationInfo,
} from '@models/auth/apiRequests/registrationRequests';
import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ENTITY_TYPES } from '@utils/roles';
import { actionsBottom } from '@models/auth/utils/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { getRegistrationPersonalInfoSchema } from '@utils/validationSchemas';
import { emailValidationRegex } from '@utils/regex';
import { TextWithCheckboxOrToggle } from '@components/atomic/inputs/controls/TextWithCheckboxOrToggle';
import { CompanyManagerInfo } from '@models/auth/components/registrationSteps/CompanyManagerInfo';
import {
  INVITATION_STATE,
  placeholderExample,
  USER_STATUS,
} from '@utils/utils';
import { EntityProfil } from './EntityProfil';
import { SubscriptionOverview } from './SubscriptionOverview';
import { RequiredDocuments } from './RequiredDocuments';

interface IPersonalInfoProps {
  globalInfos?: IEntityInfoType;
  setAsNew: Dispatch<SetStateAction<boolean>>;
  setShowPersonalInfo: Dispatch<SetStateAction<boolean>>;
  setRepresentativesTimeOut: Dispatch<SetStateAction<boolean>>;
  representatives: IRepresentativesType;
  setRepresentatives: Dispatch<SetStateAction<IRepresentativesType>>;
  formLayout?: boolean;
}

function PersonalInfo({
  globalInfos,
  setAsNew,
  setShowPersonalInfo,
  setRepresentativesTimeOut,
  setRepresentatives,
  representatives,
  formLayout,
}: IPersonalInfoProps) {
  const { t } = useTranslation();
  const query = new URLSearchParams(useLocation().search);
  const inviteId = query.get('invite-id');
  const invitationStatus = INVITATION_STATE(t);

  const [userInfo, setUserInfo] = useState<IAccountType>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isCompanyManager, setIsCompanyManager] = useState<boolean>(false);
  const [showCompanyManagerInfo, setShowCompanyManagerInfo] =
    useState<boolean>(false);
  const [showEntityProfil, setShowEntityProfil] = useState<boolean>(false);
  const [showSubscriptionOverview, setShowSubscriptionOverview] =
    useState<boolean>(false);
  const [subscriptionsStored, setSubscriptionsStored] = useState<
    ISubscriptionsStored | undefined
  >(undefined);
  const [personalInfos, setPersonalInfos] = useState<IPersonalInfoType>();
  const [showRequiredDocuments, setShowRequiredDocuments] =
    useState<boolean>(false);
  const [isModel, setIsModel] = useState<boolean>(false);

  const methods = useForm({
    resolver: yupResolver(getRegistrationPersonalInfoSchema(t)),
  });

  const { handleSubmit, watch, setValue } = methods;
  const formValues = watch(); // Obtenir toutes les valeurs du formulaire

  const allFieldsFilled = Object.entries(formValues).every(([key, value]) => {
    // Vérifie si le champ est l'email et, si oui, s'il correspond à la regex
    if (key === 'email') {
      return emailValidationRegex.test(value);
    }
    // Pour les autres champs, vérifie simplement s'ils ne sont pas vides
    return value !== undefined && value !== '';
  });

  const placeholders = placeholderExample(t);

  const backToGlobalInfo = () => {
    setAsNew(true);
    setShowPersonalInfo(false);
  };

  const onSubmitSubscriptions = async (isSkipped = false) => {
    setLoading(true);
    // Inscription suite à une invitation en masse
    if (globalInfos && inviteId && personalInfos) {
      const guestAccountData = {
        entity_data: {
          id: globalInfos.entity_data.id,
          siren: globalInfos.entity_data.siren,
          siret: globalInfos.entity_data.siret,
          legal_category: globalInfos.entity_data.legal_category,
          company_name: globalInfos.entity_data.company_name,
          zipcode: globalInfos.entity_data.zipcode,
          city: globalInfos.entity_data.city,
          address: globalInfos.entity_data.address,
        },
        ...personalInfos,
        profils: [ENTITY_TYPES.INSTALLATEUR], // le user doit être créé avec le profil installateur
        user_status: isModel ? USER_STATUS.PENDING : USER_STATUS.INACTIVE, // isModel : pending else inactive
        subscription_payment_frequency: isSkipped
          ? 'free'
          : subscriptionsStored?.paymentFrequency,
        representatives,
      };

      try {
        const createdAccount = await createAccount(guestAccountData);
        if (createdAccount) {
          setUserInfo({
            id: createdAccount.info.user_id,
            entity_id: createdAccount.info.entity_id,
            email: createdAccount.info.email,
            entity_types: createdAccount.info.entity_types,
          });
          setShowSubscriptionOverview(false);
          setShowRequiredDocuments(true);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    setLoading(false);
    return false;
  };

  const onSubmitPersonalInfos = async (formDatas: FieldValues) => {
    setLoading(true);

    const isAlreadyActiveEmail = await checkEmail({
      email: formDatas.email,
    });

    if (isAlreadyActiveEmail) {
      toast.error(t('auth.registration.already_active.email'));
      setLoading(false);
    } else {
      setPersonalInfos({
        lastname: formDatas.lastname,
        firstname: formDatas.firstname,
        email: formDatas.email,
        phone_number: formDatas.phone_number,
        function: formDatas.function,
        password: formDatas.password,
      });

      if (inviteId) {
        // Inscription suite à une invitation en masse
        setShowSubscriptionOverview(true);
      } else {
        // Inscription sans invitation
        setShowEntityProfil(true);
      }
      if (!isCompanyManager) {
        setShowCompanyManagerInfo(true);
      } else {
        setRepresentatives((prevState) => ({
          ...prevState,
          company_manager: {
            // données du gérant = données admin
            ...prevState.company_manager,
            lastname: formDatas.lastname,
            firstname: formDatas.firstname,
            email: formDatas.email,
            function: formDatas.function,
            phone_number: formDatas.phone_number,
          },
        }));
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    if (inviteId) {
      const getInfo = async () => {
        const res = await invitationInfo(inviteId || '');
        if (
          res.model_info?.is_model &&
          res.status === invitationStatus.PENDING
        ) {
          setIsModel(true);
        }
        setValue('email', res.recipient);
      };
      getInfo();
    }
  }, []);

  if (showCompanyManagerInfo) {
    return (
      <CompanyManagerInfo
        representatives={representatives}
        setRepresentatives={setRepresentatives}
        setShowCompanyManagerInfo={setShowCompanyManagerInfo}
        setShowSubscriptionOverview={setShowSubscriptionOverview}
        setShowEntityProfil={setShowEntityProfil}
        setRepresentativesTimeOut={setRepresentativesTimeOut}
      />
    );
  }

  if (showEntityProfil && !loading) {
    return (
      <EntityProfil
        setShowEntityProfil={setShowEntityProfil}
        isCompanyManager={isCompanyManager}
        setShowCompanyManagerInfo={setShowCompanyManagerInfo}
        globalInfos={globalInfos}
        personalInfos={personalInfos}
        representatives={representatives}
        formLayout={formLayout}
      />
    );
  }

  if (showSubscriptionOverview) {
    return (
      <SubscriptionOverview
        profilTypes={[ENTITY_TYPES.INSTALLATEUR]}
        setShowSubscriptionOverview={setShowSubscriptionOverview}
        setSubscriptionsStored={setSubscriptionsStored}
        onSubmitSubscriptions={onSubmitSubscriptions}
        loading={loading}
      />
    );
  }

  if (showRequiredDocuments && userInfo) {
    return (
      <RequiredDocuments
        userInfo={userInfo}
        isCompanyManager={isCompanyManager}
        formLayout={formLayout}
      />
    );
  }

  return (
    <FormProvider {...methods}>
      <form
        id="form-personal-infos"
        className="flex flex-col gap-3 lg:w-[35rem]"
        onSubmit={handleSubmit(onSubmitPersonalInfos)}
      >
        <p className="text-[1.6875rem] font-medium leading-[2.5rem]">
          {t('auth.registration.personal_info.header')}
        </p>

        <p className="font-normal text-black text-[.875rem] mb-4">
          {t('auth.registration.personal_info.subtitle')}
        </p>

        <div className="flex flex-col items-start space-y-4 w-full mb-6">
          <div className="flex gap-4 self-stretch">
            <InputText
              label={t('auth.registration.forms.firstname') || ''}
              id="firstname"
              name="firstname"
              placeholder={placeholders.FIRSTNAME}
              required
              data-test-id="firstname"
            />
            <InputText
              label={t('auth.registration.forms.lastname') || ''}
              id="lastname"
              name="lastname"
              placeholder={placeholders.LASTNAME}
              required
              data-test-id="lastname"
            />
          </div>
          <div className="w-full">
            <InputText
              id="function"
              name="function"
              required
              label={t('auth.registration.forms.function') || ''}
              placeholder={placeholders.FUNCTION}
              dataTestId="function"
            />
          </div>
          <div className="flex gap-4 self-stretch">
            <InputText
              id="email"
              name="email"
              required={!inviteId}
              disabled={!!inviteId}
              value={formValues.email}
              loading={!!inviteId && !formValues.email}
              label={t('auth.registration.forms.email') || ''}
              placeholder={placeholders.EMAIL}
              dataTestId="email"
            />
            <InputText
              id="phone_number"
              name="phone_number"
              required
              label={t('auth.registration.forms.phone_number') || ''}
              placeholder={placeholders.TELEPHONE}
              dataTestId="input_phone"
            />
          </div>
          <div className="flex">
            <TextWithCheckboxOrToggle
              label={t('auth.registration.personal_info.admin_is_manager')}
              onCheck={(isChecked) => setIsCompanyManager(isChecked)}
              checked={isCompanyManager}
              checkOrTogglePosition="left"
              type="checkbox"
              dataTestId="company_manager_checkbox"
            />
          </div>
        </div>

        <div className="flex flex-col items-start space-y-4 w-full">
          <p className="text-[1rem] font-medium">
            {t('auth.registration.forms.header_password')}
          </p>
          <p className="text-[.75rem] font-bold">
            {t('forms.password.format_indication')}
          </p>
          <div className="flex gap-4 self-stretch">
            <div className="flex flex-col flex-1">
              <InputText
                id="password"
                name="password"
                required
                type="password"
                label={t('auth.registration.forms.password') || ''}
                placeholder={placeholders.PASSWORD}
                dataTestId="new_password"
              />
            </div>
            <div className="flex flex-col flex-1">
              <InputText
                id="confirmPassword"
                name="confirmPassword"
                required
                type="password"
                label={t('auth.registration.forms.confirm_password') || ''}
                placeholder={placeholders.PASSWORD}
                dataTestId="old_password"
              />
            </div>
          </div>
        </div>
        {actionsBottom(
          t,
          () => backToGlobalInfo(),
          undefined,
          'form-personal-infos',
          loading,
          !allFieldsFilled || Object.keys(formValues).length === 0
        )}
      </form>
    </FormProvider>
  );
}

export { PersonalInfo };

PersonalInfo.defaultProps = {
  globalInfos: undefined,
  formLayout: false,
};
