import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { useContext, useEffect, useState } from 'react';
import { numFormatSpace, transformIsoDate } from '@utils/functions';
import { useTranslation } from 'react-i18next';
import { InfosWithIcon } from '@components/atomic/InfosWithIcon';
import { EditIcon, EuroIcon } from '@assets/images/svgComponents';
import { blueOpx, green, red } from '@assets/color';
import ModalPrimesDistribution from '@models/worksiteCreation/components/simulation/summary/ModalPrimesDistribution';
import { calculateDateOfExpiration } from '@models/worksiteCreation/utils/functions';

function PrimesSummary() {
  const { t } = useTranslation();
  const {
    simulatorData,
    conventionActive,
    updateSimulatorData,
    worksiteDatas,
  } = useContext(WorksiteCreationContext);

  const totalCee = Number(simulatorData.totalCeeEuros);
  const totalMpr = Number(simulatorData.totalMprEuros);
  const totalGisement = Number(simulatorData.totalGisement) / 1000;
  const beneficiaryMinimumAmount = Number(
    simulatorData.beneficiaryMinimumAmount
  );

  const [beneficiaryAmount, setBeneficiaryAmount] = useState<number>(
    beneficiaryMinimumAmount
  );
  const [modalPrimesDistributionOpen, setModalPrimesDistributionOpen] =
    useState<boolean>(false);

  const installerAmount = totalCee - beneficiaryAmount;
  const beneficiaryAmountPercent = (beneficiaryAmount / totalCee) * 100;
  const installerAmountPercent = 100 - beneficiaryAmountPercent;

  const dateOfExpiration = calculateDateOfExpiration(
    worksiteDatas,
    conventionActive
  );

  const canValidateSimulation = dateOfExpiration >= new Date();

  /* const [operationsDetails, setOperationsDetails] = useState<any[]>([]);
    const simulatorDataWithVersion = {
      ...simulatorData,
      'worksite.incentive_type': worksiteDatas.incentive_type,
      operations: operationWithVersion,
    };
    if (beneficiary?.id) {
      await validateWorksiteCreation(
        simulatorDataWithVersion,
        setIsLoading,
        updateWorksiteDatas,
        setErrorValidateSimulation,
        updateWorkflowSidebar,
        worksiteOperationType,
        updateStepActiveWorksiteCreation,
        worksiteDatas.id > 0 ? worksiteDatas.id : undefined
      );
    }
  };
>>>>>>> feature/opx-2200

  const renderOneLine = (datas: {
    label: string;
    value: string;
    key?: string;
    isBold?: boolean;
    error?: boolean;
    addClass?: string;
  }) => (
    <div
      key={datas.key}
      className={`flex justify-between w-full items-center ${
        datas.isBold ? 'font-medium text-[1rem]' : 'text-[.875rem] font-light'
      } ${datas.error && 'text-red'} ${datas.addClass && datas.addClass}`}
    >
      <p>{datas.label}</p>
      <p>{datas.value}</p>
    </div>
  );

  useEffect(() => {
    const opsDetails: any[] = [];

    const calculPrimes = (i: number) => {
      const operation = simulatorData.operations[i];
      const primes = operation.primes;

      let primeForThisOpCee = 0;
      let primeForThisOpMpr = 0;
      let gisementForThisOp = 0;

      if (primes.cdp !== 0 && primes.cdp !== undefined) {
        primeForThisOpCee = primes?.cdp || 0;
      } else {
        primeForThisOpCee = primes.cee;
      }

      if (primes.gisementCdp !== 0 && primes.gisementCdp !== undefined) {
        gisementForThisOp += primes.gisementCdp;
      } else {
        gisementForThisOp += primes.gisementCee;
      }

      if (primes.mpr !== undefined) {
        primeForThisOpMpr = primes.mpr;
      }

      opsDetails.push({
        name: operation['operation.name'],
        primeCee: primeForThisOpCee,
        primeMpr: primeForThisOpMpr,
        gisement: gisementForThisOp,
      });
    };

    if (simulatorData.operations) {
      if (worksiteOperationType === OperationTypeEnum.GLOBAL_RENOVATION) {
        calculPrimes(0);
      } else {
        for (let i = 0; i < simulatorData.operations.length; i += 1) {
          calculPrimes(i);
        }
      }
    }

    setTotalCee(Number(simulatorData.totalCeeEuros));
    setTotalMpr(Number(simulatorData.totalMprEuros));
    setTotalGisement(Number(simulatorData.totalGisement) / 1000);

    setOperationsDetails(opsDetails);
  }, []); */

  useEffect(() => {
    if (beneficiaryAmount !== 0) {
      updateSimulatorData({
        ...simulatorData,
        beneficiaryAmount,
      });
    }
  }, [beneficiaryAmount]);

  return (
    <div className="mb-[1rem] border border-borderGrey rounded-default">
      <div className="border-b border-b-borderGrey w-full flex justify-between p-[1.5rem]">
        <p className="text-[1.25rem] font-medium">
          {t('worksite_creation.simulation.summary.offer_recap')}
        </p>
        {beneficiaryMinimumAmount !== 0 && (
          <EditIcon
            fill={blueOpx}
            className="cursor-pointer"
            onClick={() => setModalPrimesDistributionOpen(true)}
            width="1.5rem"
            height="1.5rem"
          />
        )}
      </div>
      {modalPrimesDistributionOpen && (
        <ModalPrimesDistribution
          setOpen={setModalPrimesDistributionOpen}
          setBeneficiaryAmount={setBeneficiaryAmount}
          totalCeeBonus={totalCee}
          beneficiaryAmount={beneficiaryAmount}
          installerAmount={installerAmount}
          beneficiaryAmountMinimum={beneficiaryMinimumAmount}
        />
      )}
      <div className="p-[1.5rem] grid grid-cols-2 gap-4">
        <InfosWithIcon
          icon={<EuroIcon />}
          spaceLeft
          infos={[
            {
              title: t('worksite_creation.simulation.summary.total_cee_bonus'),
              subtitle: totalCee > 0 ? `${numFormatSpace(totalCee)} €` : '-',
            },
          ]}
        />
        {beneficiaryMinimumAmount !== 0 && (
          <InfosWithIcon
            spaceLeft
            infos={[
              {
                title: t(
                  'worksite_creation.simulation.summary.beneficiary_amount'
                ),
                subtitle: `${numFormatSpace(beneficiaryAmount)} €`,
                subtitleTag: {
                  color: green,
                  label: `${numFormatSpace(beneficiaryAmountPercent)} %`,
                },
              },
            ]}
          />
        )}
        <InfosWithIcon
          spaceLeft
          infos={[
            {
              title: t('worksite_creation.simulation.summary.total_cee_volume'),
              subtitle:
                totalGisement > 0
                  ? `${numFormatSpace(totalGisement)} MWhc`
                  : '-',
            },
          ]}
        />
        {beneficiaryMinimumAmount !== 0 && (
          <InfosWithIcon
            spaceLeft
            infos={[
              {
                title: t(
                  'worksite_creation.simulation.summary.installer_amount'
                ),
                subtitle: `${numFormatSpace(installerAmount)} €`,
                subtitleTag: {
                  color: green,
                  label: `${numFormatSpace(installerAmountPercent)} %`,
                },
              },
            ]}
          />
        )}
        {totalMpr > 0 ? (
          <InfosWithIcon
            spaceLeft
            infos={[
              {
                title: t(
                  'worksite_creation.simulation.summary.total_mpr_bonus'
                ),
                subtitle: totalMpr > 0 ? `${numFormatSpace(totalMpr)} €` : '-',
              },
            ]}
          />
        ) : (
          <div />
        )}
        <InfosWithIcon
          spaceLeft
          infos={[
            {
              title: t(
                'worksite_creation.simulation.summary.offer_validity_date'
              ),
              subtitle: dateOfExpiration
                ? transformIsoDate(String(dateOfExpiration))
                : '-',
            },
          ]}
          subtitleColor={!canValidateSimulation ? red : undefined}
        />
      </div>
      {/* <div className="flex flex-col space-y-[.5rem] pb-4 border-b border-borderGrey">
        {totalGisement > 0 && (
          <>
            {operationsDetails.length > 1 &&
              operationsDetails.map((op) =>
                renderOneLine({
                  key: `gisement_${op.name}`,
                  label: op.name,
                  value: `${numFormatSpace(op.gisement / 1000)} MWhc`,
                })
              )}

            {renderOneLine({
              label: t('worksite_creation.global_renovation.cee_calculation'),
              value: `${numFormatSpace(totalGisement)} MWhc`,
              isBold: true,
              addClass: '!mb-2',
            })}
          </>
        )}
        {totalCee > 0 && (
          <>
            {operationsDetails.length > 1 &&
              operationsDetails.map((op) =>
                renderOneLine({
                  key: `cee_${op.name}`,
                  label: op.name,
                  value: `${numFormatSpace(op.primeCee)} €`,
                })
              )}
            {renderOneLine({
              label: t('worksite_creation.simulation.summary.total_cee'),
              value: `${numFormatSpace(totalCee)} €`,
              isBold: true,
              addClass: '!mb-2',
            })}
          </>
        )}
        {totalMpr > 0 && (
          <>
            {operationsDetails.length > 1 &&
              operationsDetails.map((op) =>
                renderOneLine({
                  key: `mpr_${op.name}`,
                  label: op.name,
                  value: `${numFormatSpace(op.primeMpr)} €`,
                })
              )}
            {renderOneLine({
              label: t('worksite_creation.simulation.summary.total_mpr'),
              value: `${numFormatSpace(totalMpr)} €`,
              isBold: true,
            })}
          </>
        )}
      </div>
      <div className="flex flex-col space-y-[.5rem] pt-4">
        {totalMpr > 0 &&
          totalCee > 0 &&
          renderOneLine({
            label: t('worksite_creation.simulation.summary.total_primes'),
            value: `${numFormatSpace(totalCee + totalMpr)} €`,
            isBold: true,
          })}
        {totalCee === 0 && totalMpr === 0 && (
          <NoAid description={t('worksite_creation.simulation.no_prime')} />
        )}
        {renderOneLine({
          label: t('worksite_creation.simulation.summary.validity_offer_until'),
          value: conventionActive.offer_validity_delay
            ? transformDate(simulatorData['worksite.offer_expired_at'])
            : '-',
          isBold: true,
          error: conventionActive.offer_period_invalid,
        })}
      </div> */}
    </div>
  );
}

export { PrimesSummary };
