import { useTranslation } from 'react-i18next';
import { Modal } from '@components/Modal';
import React, { useContext, useState } from 'react';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import { STEPS_WORKSITE_MAR_INIT } from '@models/mar/utils/enums';
import { UploadButton } from '@components/documents/UploadButton';
import { FileTypeEnum } from '@models/worksiteCreation/utils/enums';
import { createWorksiteMar } from '@models/mar/apiRequests/worksiteCreationMarRequests';
import { useLocation, useNavigate } from 'react-router-dom';

function AssociateContract() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { customer, updateStepInit, updateIsInit } = useContext(
    WorksiteCreationMarContext
  );
  const [uploadedContract, setUploadedContract] = useState<File>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingAddLater, setIsLoadingAddLater] = useState<boolean>(false);

  const validateWorksiteCreation = async (addContractLater = false) => {
    const worksiteCreated = await createWorksiteMar(
      addContractLater ? setIsLoadingAddLater : setIsLoading,
      {
        customer_id: customer?.id || 0,
        contract: addContractLater ? undefined : uploadedContract,
      }
    );
    if (worksiteCreated) {
      // Mettre à jour l'URL
      const currentPath = location.pathname;
      const cleanedPath = currentPath.endsWith('/')
        ? currentPath.slice(0, -1)
        : currentPath;
      const newPath = `${cleanedPath}/${worksiteCreated.id}`;
      navigate(newPath, { replace: true });
      updateIsInit(false);
      updateStepInit(STEPS_WORKSITE_MAR_INIT.BIND_CUSTOMER);
    }
  };

  return (
    <Modal
      withArrowBack
      onBackClick={() => updateStepInit(STEPS_WORKSITE_MAR_INIT.BIND_CUSTOMER)}
      title={t(
        'mar.worksite_creation.init.contract_creation.associate_contract_to_folder'
      )}
      onConfirmClick={() => validateWorksiteCreation()}
      textBtnConfirm={`${t('buttons.validate')}`}
      isLoading={isLoading}
      onClickCancel={() => validateWorksiteCreation(true)}
      textBtnCancel={`${t(
        'mar.worksite_creation.init.contract_creation.add_later'
      )}`}
      isLoadingBtnCancel={isLoadingAddLater}
      btnCancel
      btnConfirmDisabled={!uploadedContract}
    >
      <div className="mt-4 flex flex-col space-y-4">
        <p className="font-medium">
          {t('mar.worksite_creation.init.contract_creation.description')}
        </p>
        <UploadButton
          name={
            uploadedContract ? t('global.document_change') : t('buttons.add')
          }
          onUpload={(file: File) => {
            setUploadedContract(file);
          }}
          onDelete={() => setUploadedContract(undefined)}
          fileType={FileTypeEnum.CONTRAT}
          withDisplayFileUpload
          color={uploadedContract ? 'grey' : 'blue'}
        />
      </div>
    </Modal>
  );
}

export default AssociateContract;
