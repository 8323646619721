/* eslint-disable @typescript-eslint/no-unused-vars */
import { DatePicker } from '@components/atomic/inputs/datepicker/Datepicker';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
  FilterRequestData,
  SortAndFilterType,
} from '@components/sortAndFilter/utils/sortAndFilterTypes';
import { dateToDDMMYYY, toAPIDateStr } from '@utils/format';
import { isDate, parseISO } from 'date-fns';

interface DateFilterProps {
  filter: SortAndFilterType;
  selectedFilters: FilterRequestData[];
  setSelectedFilters: Dispatch<SetStateAction<FilterRequestData[]>>;
  id: number;
}

function DateFilter({
  filter,
  selectedFilters,
  setSelectedFilters,
  id,
}: DateFilterProps): JSX.Element {
  const [displayedCalendar, setDisplayedCalendar] = useState('startDate');

  const onChangeDate = (date: 'startDate' | 'endDate', value: string) => {
    if (value !== '' && isDate(parseISO(value))) {
      const apiDate = toAPIDateStr(value);

      const newState = selectedFilters.map((f) => {
        const currentFilter = f.criteria === filter.value;
        if (!currentFilter) return f;

        const otherDate = date === 'startDate' ? 'endDate' : 'startDate';

        const filterDates = f.dates
          ? { ...f.dates, [id]: { ...f.dates[id], [date]: apiDate } }
          : { [id]: { [date]: apiDate, [otherDate]: '' } };

        const d = filterDates as {
          [id: number]: { startDate: string; endDate: string };
        };

        return { ...f, dates: d };
      });

      setSelectedFilters(newState);

      setDisplayedCalendar('endDate');
    }
  };

  const dates = selectedFilters.find((f) => f.criteria === filter.value)
    ?.dates?.[id] || { startDate: '', endDate: '' };

  useEffect(() => {
    if (!dates || !dates.startDate || dates.startDate === '')
      setDisplayedCalendar('startDate');
    else if (!dates.endDate || dates.endDate === '')
      setDisplayedCalendar('endDate');
    else setDisplayedCalendar('');
  }, [dates]);

  return (
    <div className="absolute top-0 -left-4 -translate-x-[100%] w-[20rem] bg-white border-2 border-borderGrey rounded-default">
      <DatePicker
        required
        onChangeDate={(e) =>
          dateToDDMMYYY(dates.startDate) !== e && onChangeDate('startDate', e)
        }
        addClassToCalendar="translate-y-[4rem] border-2 border-borderGrey rounded-default"
        addClass="p-2"
        onSelect={() =>
          setDisplayedCalendar(
            displayedCalendar === 'startDate' ? '' : 'startDate'
          )
        }
        hideCalendar={displayedCalendar !== 'startDate'}
        defaultDate={dateToDDMMYYY(dates.startDate)}
        maxDate={
          dates.endDate === '' ? undefined : dateToDDMMYYY(dates.endDate)
        }
      />
      <DatePicker
        required
        onChangeDate={(e) =>
          dateToDDMMYYY(dates.endDate) !== e && onChangeDate('endDate', e)
        }
        addClass="px-2 pb-2 pt-1 "
        addClassToCalendar="translate-y-[.5rem] border-2 border-borderGrey rounded-default"
        onSelect={() =>
          setDisplayedCalendar(displayedCalendar === 'endDate' ? '' : 'endDate')
        }
        hideCalendar={displayedCalendar !== 'endDate'}
        defaultDate={dateToDDMMYYY(dates.endDate)}
        minDate={
          dates.startDate === '' ? undefined : dateToDDMMYYY(dates.startDate)
        }
      />
    </div>
  );
}

export { DateFilter };
