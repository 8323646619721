import { LoaderDivSkeleton } from '@components/loaders/LoaderDivSkeleton';

function LoaderIncomesMar() {
  return (
    <div className="flex flex-col space-y-4">
      <LoaderDivSkeleton height="1.5rem" width="15rem" />
      <div className="flex flex-col space-y-2">
        <LoaderDivSkeleton height="1rem" />
        <LoaderDivSkeleton height="1rem" />
      </div>
      <LoaderDivSkeleton height="2.875rem" />
      <LoaderDivSkeleton height="2.875rem" />
      <LoaderDivSkeleton height="2.875rem" />
      <LoaderDivSkeleton height="2.875rem" />
    </div>
  );
}

export default LoaderIncomesMar;
