import { borderGrey } from '@assets/color';
import { CheckIcon } from '@assets/images/svgComponents';
import { v4 } from 'uuid';
import { ColorCube } from './ColorCube';

type IProgressBarVerticalProps = {
  headerHeight: number;
  stepHeight: number;
  color: string;
  showList: boolean;
  stepActive: number;
  stepsList: string[];
  backgroundColor?: string;
  border?: string;
  width?: string;
  addClass?: string;
  dataTestId?: string;
};

function ProgressBarVertical({
  headerHeight,
  stepHeight,
  color,
  showList,
  stepActive,
  stepsList,
  backgroundColor,
  border,
  width,
  addClass,
  dataTestId,
}: IProgressBarVerticalProps): JSX.Element {
  const numberOrIconToShow = (stepNumber: number) => {
    if (stepNumber < stepActive) {
      return <CheckIcon />;
    }
    return stepNumber;
  };

  return (
    <div
      style={{
        backgroundColor: !showList ? borderGrey || backgroundColor : '',
        borderRadius: border,
        width: width || '.25rem',
      }}
      className={['h-full flex-col items-center flex', addClass].join('')}
    >
      <div
        style={{
          backgroundColor: color,
          borderRadius: border,
          height: `calc(${headerHeight}px + .875rem)`,
        }}
        className="w-full"
      />
      {!showList ? (
        <ColorCube
          numberOrIcon={
            stepActive <= stepsList.length ? stepActive : <CheckIcon />
          }
          color={color}
          size="1.5rem"
          borderRadius=".125rem"
        />
      ) : (
        <div
          className="flex flex-col items-center"
          style={{ height: stepHeight }}
        >
          {stepsList.map((step: string, index: number) => (
            <div className="flex flex-col items-center h-full" key={v4()}>
              <div
                style={{
                  backgroundImage: `linear-gradient(to bottom, ${
                    index + 1 >= stepActive ? `${color}19` : color
                  }, ${index + 1 >= stepActive ? `${color}19` : color})`,
                  borderRadius: border,
                  width: width || '.25rem',
                  height: index + 1 === stepsList.length ? '30%' : '100%',
                }}
              />
              <div className="absolute">
                <ColorCube
                  numberOrIcon={numberOrIconToShow(index + 1)}
                  color={color}
                  size="1.5rem"
                  opacity={index + 1 > stepActive}
                  borderRadius=".125rem"
                  dataTestId={index + 1 === stepActive ? dataTestId : ''}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export { ProgressBarVertical };

ProgressBarVertical.defaultProps = {
  border: '',
  backgroundColor: '',
  width: '',
  addClass: '',
  dataTestId: '',
};
