/* eslint-disable no-shadow */

// ENUM DES DIFFERENTES ETAPES DE LA MODAL PERMETTANT D'EFFECTUER LES CHOIX AVANT LA SIMULATION
enum StepsEnumBeforeSimulation {
  CHOICE_BENEFICIARY_TYPE,
  CHOICE_OPERATION_TYPE,
  CHOICE_CONTRACT,
  CHOICE_INCENTIVE_TYPE,
  BIND_BENEFICIARY,
  CREATE_BENEFICIARY,
  CHOICE_INSTALLER,
}

// ENUM CIVILITES BENEFICIAIRE (form creation d'un bénéficiaire)
enum CivilityEnum {
  MRS,
  MR,
}

// ENUM STEPS SIMULATIONS
enum StepsWorksiteCreationEnum {
  SIMULATION_CHOICEOPERATIONS,
  SIMULATION_LOGEMENT,
  SIMULATION_INFO_BENEFICIARY,
  SIMULATION_OPERATIONDETAILS,
  SIMULATION_PRIX,
  SIMULATION_RECAPITULATIF,
  WORKSITE_CREATION_INFO_BENEFICIARY,
  WORKSITE_CREATION_INFO_LOGEMENT,
  WORKSITE_CREATION_SUBCONSTRACTOR,
  WORKSITE_CREATION_MATERIAL,
  WORKSITE_CREATION_ND,
  DOCUMENTS,
  GLOBAL_CHOICEOPERATIONS,
  GLOBAL_PRIMES,
}

// CORRESPOND A globalEnum.worksite_creation_status
enum WORKSITE_CREATION_STATUS {
  DELETED = -1,
  SIMULATION = 1,
  CREATION_INFO_BENEFICIARY,
  CREATION_INFO_LOGEMENT,
  CREATION_SUBCONSTRACTOR,
  CREATION_MATERIAL,
  CREATION_ND,
  STATUS_DOCUMENTS,
}

// ENUM STATUS DES STEPS
enum StatusStepEnum {
  IN_PROGRESS,
  COMPLETED,
  NOT_STARTED,
}

enum EntityStatusEnum {
  INACTIVE = 1,
  ACTIVE,
  GHOST,
}

enum FileTypeEnum {
  CONTRAT = 17,
}
const WORKSITE_AGE = {
  YOUNG: '0',
  BETWEEN: '3',
  OLD: '16',
};

const HABITATION = {
  HOUSE: 'house',
  APARTMENT: 'apartment',
  OTHER: 'other',
};

const HABITATION_ZONE = {
  QPV: 'zone_qpv',
  CONTRACTED: 'contracted',
  OTHER: 'other',
};

const ACTIVITY = {
  SYNDIC: 'syndic',
  SOCIAL_LANDLORD: 'bailleur-social',
  OTHER: 'other',
};

const INCOMES = {
  LOW: 1,
  MODEST: 2,
  INTERMEDIATE: 3,
  CLASSIC: 4,
};

const OWNER = {
  LANDLORD: 'landlord-owner',
  TENANT: 'tenant',
  OWNER: 'owner',
  OTHER: 'other',
};

const INCENTIVE_TYPE = {
  DIRECT: 1,
  INDIRECT: 2,
  MIXED: 3,
};

export {
  StepsEnumBeforeSimulation,
  WORKSITE_CREATION_STATUS,
  CivilityEnum,
  StepsWorksiteCreationEnum,
  StatusStepEnum,
  EntityStatusEnum,
  FileTypeEnum,
  WORKSITE_AGE,
  HABITATION_ZONE,
  ACTIVITY,
  INCOMES,
  OWNER,
  INCENTIVE_TYPE,
  HABITATION,
};
