import React from 'react';
import { createRoot } from 'react-dom/client';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-pdf/dist/cjs/Page/TextLayer.css';
import './style/tailwind.css';
import './style/global.css';
import './i18n';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/react';
import App from './App';

if (
  process.env.REACT_APP_ENV === 'production' ||
  process.env.REACT_APP_ENV === 'staging'
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      /^https:\/\/crm\.api\.staging\.opx\.co\/api\/v1/,
      /^https:\/\/crm\.api\.testing\.opx\.co\/api\/v1/,
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const element = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(element!);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
