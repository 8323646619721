import { formHousingInformation } from '@models/mar/utils/datas';
import OneMarForm from '@models/mar/components/worksiteCompletion/OneMarForm';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { CadastreLocation } from '@models/worksiteCreation/components/createWorksite/infoLogement/CadastreLocation';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import { useContext, useEffect, useState } from 'react';
import AgeMar from '@models/mar/components/worksiteCompletion/stepsCompletion/housing/AgeMar';
import LayoutMarDetailsStep from '@models/mar/components/worksiteCompletion/LayoutMarDetailsStep';
import { STEPS_WORKSITE_MAR_COMPLETION } from '@models/mar/utils/enums';
import { validateHousingTypology } from '@models/mar/apiRequests/worksiteCreationMarRequests';
import { IWorksiteMar } from '@models/mar/utils/marTypes';

function HousingMar() {
  const { t } = useTranslation();
  const {
    worksiteAddress,
    updateWorksiteAddressFromKeyValue,
    updateButtonNextDisabled,
    updateStepCompletion,
    worksiteData,
    updateWorksiteData,
  } = useContext(WorksiteCreationMarContext);

  const formContext = useFormContext();
  const { watch } = formContext;

  const [buttonNextLoading, setButtonNextLoading] = useState(false);

  const handleValidateStep = async () => {
    setButtonNextLoading(true);
    const addressToSend = {
      address_type: 4,
      address: `${worksiteAddress.numberStreet} ${worksiteAddress.streetName}`,
      postal_code: worksiteAddress.zipCode,
      city: worksiteAddress.city,
      country: 'France',
      longitude: worksiteAddress.longitude,
      latitude: worksiteAddress.latitude,
    };

    const dataToSend = {
      address: addressToSend,
      housing_type: watch('housing_type'),
      age: Number(watch('age')),
      cadastral_parcel: `${worksiteAddress.parcelPrefix} ${worksiteAddress.parcelSection} ${worksiteAddress.parcelNumber}`,
      surface: watch('surface') ? watch('surface').replace(',', '.') : null,
      tax_reference: watch('tax_reference'),
    };
    const response = await validateHousingTypology(
      worksiteData?.id || 0,
      dataToSend
    );
    setButtonNextLoading(false);

    if (response) {
      updateWorksiteData((prev) => ({ ...prev, ...response }));
      updateStepCompletion(STEPS_WORKSITE_MAR_COMPLETION.USER_INFORMATION);
    }
  };

  const checkIfHousingInformationIsComplete = () => {
    return !!watch('housing_type') && !!watch('tax_reference');
  };

  const checkIfAddressIsComplete = () =>
    !!(
      worksiteAddress.numberStreet &&
      worksiteAddress.streetName &&
      worksiteAddress.zipCode &&
      worksiteAddress.city
    );

  const checkIfAgeIsComplete = () => {
    return !!watch('age');
  };

  const checkIfCadastreIsComplete = () => {
    return !!(
      worksiteAddress.latitude !== 0 &&
      worksiteAddress.longitude !== 0 &&
      worksiteAddress.parcelNumber &&
      worksiteAddress.parcelSection &&
      worksiteAddress.parcelPrefix
    );
  };

  useEffect(() => {
    updateButtonNextDisabled(
      !(
        checkIfHousingInformationIsComplete() &&
        checkIfAddressIsComplete() &&
        checkIfAgeIsComplete() &&
        checkIfCadastreIsComplete()
      )
    );
  }, [worksiteAddress, watch()]);

  return (
    <LayoutMarDetailsStep
      onValidate={handleValidateStep}
      buttonNextLoading={buttonNextLoading}
    >
      <>
        <OneMarForm
          dataForm={formHousingInformation(t, worksiteData as IWorksiteMar)}
        />
        <CadastreLocation
          worksiteAddress={worksiteAddress}
          updateWorksiteAddressFromKeyValue={updateWorksiteAddressFromKeyValue}
        />
        <AgeMar />
      </>
    </LayoutMarDetailsStep>
  );
}

export default HousingMar;
