import LayoutMarDetailsStep from '@models/mar/components/worksiteCompletion/LayoutMarDetailsStep';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OneMarForm from '@models/mar/components/worksiteCompletion/OneMarForm';
import { useFormContext } from 'react-hook-form';
import { validateNotificationOfGrant } from '@models/mar/apiRequests/worksiteCreationMarRequests';
import { STEPS_WORKSITE_MAR_COMPLETION } from '@models/mar/utils/enums';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import { IWorksiteMar } from '@models/mar/utils/marTypes';
import { formGrantNotification } from '@models/mar/utils/datas';
import { setFileFromWorksiteData } from '@models/mar/utils/functions';
import { fileTypeEnum } from '@utils/enums';

function GrantNotification() {
  const { t } = useTranslation();
  const {
    worksiteData,
    updateWorksiteData,
    updateStepCompletion,
    updateButtonNextDisabled,
  } = useContext(WorksiteCreationMarContext);

  const [buttonNextLoading, setButtonNextLoading] = useState(false);

  const { watch, setValue } = useFormContext();

  const primeAmount = watch('prime_amount');
  const agreementDate = watch('agreement_date');
  const file = watch('file_grant_notification');

  const checkIfInfosAreFilled = primeAmount && agreementDate && file;

  const handleValidateStep = async () => {
    setButtonNextLoading(true);
    const dataToSend = {
      primeAmount,
      agreementDate,
      file,
    };
    const response = await validateNotificationOfGrant(
      worksiteData?.id || 0,
      dataToSend
    );

    if (response) {
      updateWorksiteData((prev) => ({
        ...prev,
        ...response,
      }));
      updateStepCompletion(STEPS_WORKSITE_MAR_COMPLETION.WORK_IN_PROGRESS);
    }
    setButtonNextLoading(false);
  };

  const preFillNotificationOfGrant = async () => {
    if (worksiteData) {
      if (worksiteData.linked_files && worksiteData.linked_files.length > 0) {
        await setFileFromWorksiteData(
          worksiteData,
          fileTypeEnum.ACCORD_SUBVENTION,
          'file_grant_notification',
          setValue
        );
      }
      setValue('prime_amount', worksiteData?.prime_amount);
    }
  };

  useEffect(() => {
    updateButtonNextDisabled(!checkIfInfosAreFilled);
  }, [watch()]);

  useEffect(() => {
    preFillNotificationOfGrant();
  }, []);

  return (
    <LayoutMarDetailsStep
      onValidate={handleValidateStep}
      buttonNextLoading={buttonNextLoading}
    >
      <div className="flex flex-col space-y-6">
        {formGrantNotification(t, worksiteData as IWorksiteMar).map((form) => (
          <OneMarForm key={form.title} dataForm={form} />
        ))}
      </div>
    </LayoutMarDetailsStep>
  );
}

export default GrantNotification;
