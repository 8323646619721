import { v4 } from 'uuid';
import {
  FILTER_TYPES,
  getPlaceholder,
  onChangeInputValue,
  onCheckOption,
  onCheckRadio,
} from '@components/sortAndFilter/utils/functions';
import { Checkbox } from '@components/atomic/inputs/controls/Checkbox';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  FilterRequestData,
  SortAndFilterType,
} from '@components/sortAndFilter/utils/sortAndFilterTypes';
import { InputText } from '@components/atomic/inputs/InputText';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { t } from 'i18next';
import { SearchBar } from '@components/SearchBar';
import { formatWord } from '@utils/format';
import { numberRegex } from '@utils/regex';
import { Radio } from '@components/atomic/inputs/controls/Radio';
import { FormProvider, useForm } from 'react-hook-form';
import { DateFilter } from '@components/sortAndFilter/DateFilter';
import { FilterCriteriaEnum } from '@components/sortAndFilter/utils/enums';

interface FilterOptionsProps {
  filter: SortAndFilterType;
  setSelectedFilters: Dispatch<SetStateAction<FilterRequestData[]>>;
  selectedFilters: FilterRequestData[];
}

function FilterOptions({
  filter,
  setSelectedFilters,
  selectedFilters,
}: FilterOptionsProps): JSX.Element {
  const methods = useForm();
  const [filterOptions, setFilterOptions] =
    useState<{ description: string; id: number }[]>();
  const [isOverOption, setIsOverOption] = useState<number>(-10);
  const [selectedOption, setSelectedOption] = useState<number>(-10);
  const [condition, setCondition] = useState<string>('=');
  const [inputValue, setInputValue] = useState<string>(String(''));

  const currentFilter = selectedFilters.find(
    (f) => f.criteria === filter.value
  );

  const isRadio = filter.type === FILTER_TYPES.RADIO_SELECT;
  const isNumCondition = filter.type === FILTER_TYPES.NUMERIC_CONDITION;
  // const isMultipleSelect = filter.type === FILTER_TYPES.MULTI_SELECT;
  // const isText = filter.type === FILTER_TYPES.TEXT_SEARCH;

  const isChecked = (value: number) => {
    if (!currentFilter) return false;
    if (!currentFilter.ids) return false;
    return currentFilter.ids.includes(value);
  };

  const onClickOption = (option: { description: string; id: number }) => {
    if (isRadio) {
      const newValues = onCheckRadio(option.id, selectedFilters, filter);
      setSelectedFilters(newValues);
    } else {
      const newValues = onCheckOption(option.id, selectedFilters, filter);
      setSelectedFilters(newValues);
    }

    const checkedOption = selectedFilters
      .find((f) => f.criteria === filter.value)
      ?.ids?.includes(option.id);

    if (!checkedOption) setSelectedOption(option.id);
  };

  const isDate = filter.value === FilterCriteriaEnum.DATE;

  useEffect(() => {
    setFilterOptions(filter.options);
    if (currentFilter) {
      setInputValue(String(currentFilter.value));
      setCondition(String(currentFilter.condition));
    } else {
      setInputValue('');
      setCondition('=');
    }
  }, [filter]);

  useEffect(() => {
    const newConditions = selectedFilters.map((f) => {
      if (f.criteria === filter.value && f.value) return { ...f, condition };
      return f;
    });

    setSelectedFilters(newConditions);
  }, [condition]);

  const searchBar = useMemo(() => {
    return (
      <SearchBar
        width="100%"
        placeholder={`${t('search_bar.placeholder')}`}
        onSearch={(e) =>
          filter.options &&
          setFilterOptions(
            filter.options.filter((f) =>
              formatWord(f.description).includes(formatWord(e))
            )
          )
        }
      />
    );
  }, [filter, filterOptions]);

  const searchButton = useMemo(() => {
    return (
      <div className="flex gap-1 mb-3">
        <ButtonOpx
          label={t('buttons.less')}
          type={condition === '<' ? 'primary' : 'tab'}
          onClick={() => setCondition('<')}
        />
        <ButtonOpx
          label={t('buttons.more')}
          type={condition === '>' ? 'primary' : 'tab'}
          onClick={() => setCondition('>')}
        />
        <ButtonOpx
          label={t('buttons.equal')}
          type={condition === '=' ? 'primary' : 'tab'}
          onClick={() => setCondition('=')}
        />
      </div>
    );
  }, [condition]);

  const showDateFilter = (id: number) => {
    return isDate && selectedOption === id;
  };

  if (filterOptions && filterOptions.length < 1)
    return (
      <div className="absolute -left-4 -translate-x-[100%] w-[20rem] bg-white border-2 border-borderGrey rounded-default">
        {searchBar}
        <div className="p-2">{t('list.no_result')}</div>
      </div>
    );

  return (
    <div
      className="absolute -left-4 min-w-[15rem] max-w-[40rem]
     -translate-x-[100%] bg-white border-2 border-borderGrey rounded-default"
    >
      {filterOptions && searchBar}
      <div className="overflow-y-auto max-h-[25rem] min-w-[15rem] max-w-[40rem]">
        {filterOptions ? (
          filterOptions.map((option, i) => {
            return (
              <div
                key={v4()}
                onMouseOver={() => !isDate && setIsOverOption(option.id)}
                onFocus={() => !isDate && setIsOverOption(option.id)}
                className="cursor-pointer"
              >
                <div
                  className={`p-3 border border-b-1 border-transparent ${
                    i !== 0 && 'border-t-borderGrey'
                  }   flex gap-2 items-center ${
                    isOverOption === option.id && `bg-[#F7F7FB]`
                  } `}
                  aria-hidden
                  onClick={() => onClickOption(option)}
                >
                  {isRadio ? (
                    <Radio
                      name={filter.label}
                      isChecked={isChecked(option.id)}
                      value=""
                      onSelect={() => isChecked(option.id)}
                    />
                  ) : (
                    <Checkbox label="" checked={isChecked(option.id)} />
                  )}
                  <div className="overflow-hidden text-ellipsis ">
                    {option.description}
                  </div>
                </div>
                {showDateFilter(option.id) && (
                  <DateFilter
                    filter={filter}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                    id={option.id}
                  />
                )}
              </div>
            );
          })
        ) : (
          <FormProvider {...methods}>
            <div className="p-3">
              {isNumCondition && searchButton}

              <InputText
                id={String(filter.value)}
                name={String(filter.value)}
                placeholder={`${getPlaceholder(filter.label, filter.type)}`}
                required
                value={inputValue}
                onChange={(e) => {
                  const formattedValue = isNumCondition
                    ? String(e).replace(',', '.')
                    : String(e);
                  const isToBeSearched = isNumCondition
                    ? numberRegex.test(formattedValue) // maintain check on numeric for other filters ( Volume, prime etc... )
                    : true;

                  if (isToBeSearched) {
                    setInputValue(String(e));
                    const newValues = onChangeInputValue(
                      String(formattedValue),
                      condition,
                      selectedFilters,
                      filter
                    );
                    setSelectedFilters(newValues);
                  }
                }}
              />
            </div>
          </FormProvider>
        )}
      </div>
    </div>
  );
}

export { FilterOptions };
