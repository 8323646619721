import { createContext, Dispatch, SetStateAction } from 'react';
import {
  ICustomer,
  IProEntity,
  IWorksiteMar,
} from '@models/mar/utils/marTypes';
import { IWorksiteAddress } from '@models/worksiteCreation/utils/types/worksitesType';
import { ILabelValue } from '../../../types/globalTypes';

interface WorksiteCreationMar {
  isInit: boolean;
  updateIsInit: Dispatch<SetStateAction<boolean>>;
  stepInit: number;
  updateStepInit: Dispatch<SetStateAction<number>>;
  customer: ICustomer | undefined;
  updateCustomer: Dispatch<SetStateAction<ICustomer | undefined>>;
  worksiteAddress: IWorksiteAddress;
  updateWorksiteAddressFromKeyValue: (
    updates: Partial<IWorksiteAddress>
  ) => void;
  worksiteData: IWorksiteMar | undefined;
  updateWorksiteData: Dispatch<SetStateAction<IWorksiteMar | undefined>>;
  stepCompletion: number;
  updateStepCompletion: Dispatch<SetStateAction<number>>;
  buttonNextDisabled: boolean;
  updateButtonNextDisabled: Dispatch<SetStateAction<boolean>>;
  operationsList: ILabelValue[] | undefined;
  prosList: IProEntity[];
  getPros: () => Promise<void>;
}

export const WorksiteCreationMarContext = createContext(
  {} as WorksiteCreationMar
);
