import { ITaxHouselod } from '@models/worksiteCreation/utils/types/worksitesType';
import { Dispatch, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { InputText } from '@components/atomic/inputs/InputText';
import {
  incorrectNoticeReferenceYear,
  fiscalReferenceIsNotValid,
} from '@utils/functions';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { placeholderExample } from '@utils/utils';

interface IOneTaxHouseholdProps {
  household: ITaxHouselod;
  index: number;
  setTaxHouseholdDatas: Dispatch<SetStateAction<ITaxHouselod[]>>;
  taxHouseholdDatas: ITaxHouselod[];
}

function OneTaxHousehold({
  household,
  setTaxHouseholdDatas,
  taxHouseholdDatas,
  index,
}: IOneTaxHouseholdProps) {
  const { t } = useTranslation();

  const placeholders = placeholderExample(t);

  const { readOnly } = useContext(WorksiteCreationContext);

  return (
    <div>
      <p className="py-[1.5rem]">
        {' '}
        {t('worksite_creation.create_worksite.tax_household.title')} N°
        {index + 1}
      </p>
      <div className="flex space-x-[.5rem] items-center">
        <InputText
          label={t(
            'worksite_creation.create_worksite.tax_household.firstname_person'
          )}
          placeholder={placeholders.FIRSTNAME}
          id={`firstname_${index}`}
          name={`firstname_${index}`}
          value={household.firstname}
          onChange={(e) => {
            const updatedHouseholds = [...taxHouseholdDatas];
            updatedHouseholds[index] = {
              ...household,
              firstname: e.toString(),
            };
            setTaxHouseholdDatas(updatedHouseholds);
          }}
          dataTestId={`input_text_household_firstname__${index + 1}`}
          required
          readOnly={readOnly}
          addClassToInput={readOnly ? 'bg-backgroundBody' : ''}
        />
        <InputText
          label={t(
            'worksite_creation.create_worksite.tax_household.lastname_person'
          )}
          placeholder={placeholders.LASTNAME}
          id={`lastname_${index}`}
          name={`lastname_${index}`}
          value={household.lastname}
          onChange={(e) => {
            const updatedHouseholds = [...taxHouseholdDatas];
            updatedHouseholds[index] = {
              ...household,
              lastname: e.toString(),
            };
            setTaxHouseholdDatas(updatedHouseholds);
          }}
          dataTestId={`input_text_household_lastname__${index + 1}`}
          required
          readOnly={readOnly}
          addClassToInput={readOnly ? 'bg-backgroundBody' : ''}
        />
      </div>
      <div className="flex space-x-[.5rem] mt-[.5rem]">
        <InputText
          id={`tax_number_${index}`}
          typeNumber
          maxLength={13}
          label={t(
            'worksite_creation.create_worksite.tax_household.tax_number'
          )}
          placeholder={placeholders.IMPOSITION}
          name={`tax_number_${index}`}
          value={household.fiscal_number}
          onChange={(e) => {
            const updatedHouseholds = [...taxHouseholdDatas];
            updatedHouseholds[index] = {
              ...household,
              fiscal_number: e.toString(),
            };
            setTaxHouseholdDatas(updatedHouseholds);
          }}
          dataTestId={`input_text_household_tax_number_${index + 1}`}
          readOnly={readOnly}
          addClassToInput={readOnly ? 'bg-backgroundBody' : ''}
        />
        <InputText
          id={`notice_reference_${index}`}
          label={t(
            'worksite_creation.create_worksite.tax_household.notice_reference'
          )}
          placeholder={placeholders.REFERENCE_IMPOSITION}
          maxLength={14}
          name={`notice_reference_${index}`}
          value={household.fiscal_reference}
          onChange={(e) => {
            const updatedHouseholds = [...taxHouseholdDatas];
            updatedHouseholds[index] = {
              ...household,
              fiscal_reference: e.toString(),
            };
            setTaxHouseholdDatas(updatedHouseholds);
          }}
          error={
            fiscalReferenceIsNotValid(household.fiscal_reference) ||
            incorrectNoticeReferenceYear(household.fiscal_reference)
          }
          textError={
            household.fiscal_reference.length > 12 &&
            !incorrectNoticeReferenceYear(household.fiscal_reference)
              ? `${t(
                  'worksite_creation.create_worksite.tax_household.error_format_notice_reference'
                )}`
              : `${t(
                  'worksite_creation.create_worksite.tax_household.error_year_notice_reference'
                )}`
          }
          dataTestId={`input_text_household_notice_reference_${index + 1}`}
          readOnly={readOnly}
          addClassToInput={readOnly ? 'bg-backgroundBody' : ''}
        />
      </div>
    </div>
  );
}

export { OneTaxHousehold };
