import { ColorCube } from '@components/atomic/ColorCube';
import { blueOpx, red } from '@assets/color';
import { InputText } from '@components/atomic/inputs/InputText';
import { useTranslation } from 'react-i18next';
import { TrashIcon } from '@assets/images/svgComponents';

interface IOneOperationItemProps {
  indexOperation: number;
  operationName: string;
  onDeleteOperation: () => void;
  gestureName: string;
  isInvoice?: boolean;
}

function OneOperationItem({
  indexOperation,
  operationName,
  onDeleteOperation,
  gestureName,
  isInvoice,
}: IOneOperationItemProps) {
  const { t } = useTranslation();
  const isBatEn110 =
    operationName.substring(0, 10).toLowerCase() === 'bat-en-110';

  const isOtherGesture = gestureName.includes('gestes_autre');

  return (
    <div className="flex flex-col w-full space-y-4">
      <div className="flex space-x-4 w-full items-end">
        <ColorCube
          size="2.5rem"
          numberOrIcon={indexOperation + 1}
          color={blueOpx}
          opacity
          addClass="mb-1"
        />
        <div className="w-2/3 bg-borderGrey rounded-default text-textGrey p-3 text-[.875rem] truncate">
          {operationName}
        </div>
        <div className="w-1/6">
          <InputText
            id={`${gestureName}.cost_ht`}
            name={`${gestureName}.cost_ht`}
            typeNumber
            placeholder=""
            label={`${t(
              'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.quote_section.cost_ht'
            )}`}
            required
          />
        </div>
        <div className="w-1/6">
          <InputText
            id={`${gestureName}.cost_ttc`}
            name={`${gestureName}.cost_ttc`}
            typeNumber
            placeholder=""
            label={`${t(
              'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.quote_section.cost_ttc'
            )}`}
            required
          />
        </div>
        <ColorCube
          size="2rem"
          numberOrIcon={<TrashIcon />}
          color={red}
          backgroundColor="transparent"
          onClick={onDeleteOperation}
          addClass="mb-2"
        />
      </div>
      {(!isInvoice || isOtherGesture) && (
        <>
          <InputText
            id={`${gestureName}.description`}
            name={`${gestureName}.description`}
            placeholder={`${t(
              'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.quote_section.description'
            )}`}
            label={`${t(
              'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.quote_section.operation_description'
            )}`}
            required
          />
          {isBatEn110 && (
            <InputText
              id={`${gestureName}.exemption_message`}
              name={`${gestureName}.exemption_message`}
              placeholder={`${t(
                'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.quote_section.reason_for_exemption'
              )}`}
              label={`${t(
                'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.quote_section.reason_for_exemption'
              )}`}
              required
            />
          )}
        </>
      )}
    </div>
  );
}

export default OneOperationItem;

OneOperationItem.defaultProps = {
  isInvoice: false,
};
