import { createContext, Dispatch, SetStateAction } from 'react';
import {
  IGraph,
  IIncomes,
  ISimulatorData,
  ISimulatorDataOperation,
} from '@models/worksiteCreation/utils/types/SimulationTypes';
import {
  INoAidOperation,
  IPayloadInfosBeneficiary,
  IPayloadInfosLogement,
  IPayloadMaterials,
  IPayloadSizingNote,
  IPayloadSubcontractor,
  IWorksite,
  IWorksiteAddress,
} from '@models/worksiteCreation/utils/types/worksitesType';
import { IBeneficiary } from '@models/beneficiaries/utils/beneficiariesType';
import { IPartnerType } from '@models/partners/utils/types/partnersType';
import { IConvention } from './types/conventionsTypes';
import { IOperation } from './types/operationTypes';
import { IWorkflowSidebar } from './types/sidebarTypes';
import { ILinkedFile } from '../../../types/globalTypes';

interface WorksiteCreation {
  isModals: {
    formBeforeSimulation: boolean;
    bindBeneficiary: boolean;
    createBeneficiary: boolean;
    saveAndQuit: boolean;
    deleteWorksite: boolean;
  };
  updateIsModals: Dispatch<
    SetStateAction<{
      formBeforeSimulation: boolean;
      bindBeneficiary: boolean;
      createBeneficiary: boolean;
      saveAndQuit: boolean;
      deleteWorksite: boolean;
    }>
  >;
  isLoading: boolean;
  updateIsLoading: Dispatch<SetStateAction<boolean>>;
  beneficiary: IBeneficiary | null;
  updateBeneficiary: Dispatch<SetStateAction<IBeneficiary | null>>;
  conventionActive: IConvention;
  updateConventionActive: Dispatch<SetStateAction<IConvention>>;
  stepActiveWorksiteCreation: number;
  updateStepActiveWorksiteCreation: Dispatch<SetStateAction<number>>;
  listOperationSelected: IOperation[] | null;
  updateListOperationSelected: Dispatch<SetStateAction<IOperation[] | null>>;
  graphGeneral: IGraph[];
  updateGraphGeneral: Dispatch<SetStateAction<IGraph[]>>;
  simulatorData: ISimulatorData;
  updateSimulatorData: Dispatch<SetStateAction<ISimulatorData>>;
  simulatorDataOperations: ISimulatorDataOperation[];
  updateSimulatorDataOperation: Dispatch<
    SetStateAction<ISimulatorDataOperation[]>
  >;
  graphOperation: IGraph[];
  updateGraphOperation: Dispatch<SetStateAction<IGraph[]>>;
  workflowSidebar: IWorkflowSidebar | undefined;
  updateWorkflowSidebar: Dispatch<SetStateAction<IWorkflowSidebar | undefined>>;
  incomesOptionsArray: IIncomes[] | null;
  updateIncomesOptionsArray: Dispatch<SetStateAction<IIncomes[] | null>>;
  informationBeneficiaryDataPost: IPayloadInfosBeneficiary;
  updateInformationBeneficiaryDataPost: Dispatch<
    SetStateAction<IPayloadInfosBeneficiary>
  >;
  informationLogementDataPost: IPayloadInfosLogement;
  updateInformationLogementDataPost: Dispatch<
    SetStateAction<IPayloadInfosLogement>
  >;
  worksiteDatas: IWorksite;
  updateWorksiteDatas: Dispatch<SetStateAction<IWorksite>>;
  arraySubcontractorDataPost: IPayloadSubcontractor[] | null;
  updateArraySubcontractorDataPost: Dispatch<
    SetStateAction<IPayloadSubcontractor[] | null>
  >;
  arrayMaterialsDataPost: IPayloadMaterials[] | null;
  updateArrayMaterialsDataPost: Dispatch<
    SetStateAction<IPayloadMaterials[] | null>
  >;
  payloadSizingNote: IPayloadSizingNote;
  updatePayloadSizingNote: Dispatch<SetStateAction<IPayloadSizingNote>>;
  sizingNote: string;
  updateSizingNote: Dispatch<SetStateAction<string>>;
  listOperations: IOperation[];
  updateListOperations: Dispatch<SetStateAction<IOperation[]>>;
  stepWithTotalCost: boolean;
  arrayOperations: IOperation[] | ISimulatorData | null;
  worksiteOperationType: number;
  updateWorksiteOperationType: Dispatch<SetStateAction<number>>;
  scenario: { value: string; label: string } | null;
  updateScenario: Dispatch<
    SetStateAction<{ value: string; label: string } | null>
  >;
  replacedEnergy: { value: string; label: string } | null;
  updateReplacedEnergy: Dispatch<
    SetStateAction<{ value: string; label: string } | null>
  >;
  lastStep: number;
  updateLastStep: Dispatch<SetStateAction<number>>;
  disabledNextButton: boolean;
  updateDisabledNextButton: Dispatch<SetStateAction<boolean>>;
  nextStepWorksiteCreation: () => void;
  worksiteAddress: IWorksiteAddress;
  updateWorksiteAddressFromKeyValue: (
    updates: Partial<IWorksiteAddress>
  ) => void;
  noAidOperationToDisplay: INoAidOperation;
  updateNoAidOperationToDisplay: Dispatch<SetStateAction<INoAidOperation>>;
  errorValidateSimulation: string;
  updateErrorValidateSimulation: Dispatch<SetStateAction<string>>;
  partner: IPartnerType | null;
  updatePartner: Dispatch<SetStateAction<IPartnerType | null>>;
  signedQuotesToSend: ILinkedFile[];
  updateSignedQuotesToSend: Dispatch<SetStateAction<ILinkedFile[]>>;
  readOnly: boolean;
}

export const WorksiteCreationContext = createContext({} as WorksiteCreation);
