import {
  FilterRequestData,
  ISortAndFilterType,
  SortAndFilterType,
} from '@components/sortAndFilter/utils/sortAndFilterTypes';
import { Dispatch, SetStateAction } from 'react';
import { t } from 'i18next';

export const onCheckOption = (
  value: number,
  selectedFilters: FilterRequestData[],
  filter: SortAndFilterType
): FilterRequestData[] => {
  const currentFilter = selectedFilters.find(
    (f) => f.criteria === filter.value
  );

  const newCriteria = { criteria: Number(filter.value), ids: [value] };
  if (currentFilter) {
    const newValues = selectedFilters.map((val) => {
      const ids = val.ids || [];
      const newIds = !ids.includes(value)
        ? [...ids, value]
        : ids.filter((i) => i !== value);

      const dates = val.dates;
      if (dates && ids.includes(value)) delete dates[value];

      if (val.criteria === filter.value) return { ...val, ids: newIds, dates };
      return val;
    });

    return newValues.filter((v) => v.ids && v.ids[0]);
  }
  return [...selectedFilters, newCriteria];
};

export const onCheckRadio = (
  value: number,
  selectedFilters: FilterRequestData[],
  filter: SortAndFilterType
): FilterRequestData[] => {
  if (!selectedFilters || !selectedFilters[0])
    return [{ criteria: Number(filter.value), ids: [value] }];

  return selectedFilters.map((val) => {
    if (val.criteria === filter.value) return { ...val, ids: [value] };
    return val;
  });
};

export const onChangeInputValue = (
  value: string,
  condition: string,
  selectedFilters: FilterRequestData[],
  filter: SortAndFilterType
): FilterRequestData[] => {
  const newValue = {
    criteria: Number(filter.value),
    condition,
    value,
  };

  const currentFilter = selectedFilters.find(
    (f) => f.criteria === filter.value
  );

  if (value !== '') {
    if (!currentFilter) {
      return [...selectedFilters, newValue];
    }
    return selectedFilters.map((f) => {
      if (f.criteria === filter.value) return newValue;
      return f;
    });
  }
  return selectedFilters.filter((f) => f.criteria !== filter.value);
};

export const sortList = (
  column: string,
  direction: string,
  setData: Dispatch<SetStateAction<ISortAndFilterType>>
) => {
  setData((prevState) => ({
    ...prevState,
    order_by: { column, direction },
  }));
};

export const filterList = (
  filters: FilterRequestData[],
  setData: Dispatch<SetStateAction<ISortAndFilterType>>
) => {
  setData((prevState) => ({ ...prevState, filters }));
};

export const resetPagination = (
  setPaginationData: Dispatch<SetStateAction<any>>,
  setPagesData?: Dispatch<SetStateAction<{ data: any; meta: any }[]>>
) => {
  setPaginationData(undefined);
  if (setPagesData) setPagesData([]);
};

export const FILTER_TYPES = {
  NUMERIC_CONDITION: 1,
  TEXT_SEARCH: 2,
  MULTI_SELECT: 3,
  RADIO_SELECT: 4,
};

export const getPlaceholder = (name: string, type: number) => {
  if (name === 'Prime') return `${t('global.in')} €`;
  if (name === 'Volume') return `${t('global.in')} Gwhc`;
  if (type === FILTER_TYPES.TEXT_SEARCH) return `${t('global.contains')}`;
  return name.toLowerCase();
};
