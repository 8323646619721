import { axiosClient } from '@api/apiClient';
import {
  urlGetOneRequest,
  urlGetRequestsList,
  urlGetRequestsMessages,
  urlPostMarkNoteAsRead,
  urlPostRequestsNewMessage,
} from '@api/apiUrls';
import { Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';
import { ISortAndFilterType } from '@components/sortAndFilter/utils/sortAndFilterTypes';
import { displayError } from '@utils/format';
import { urlRequest, urlGetListRequestGroup } from '../../../api/apiUrls';
import {
  IListGroupRequests,
  IMessageRequest,
  IPayloadDataPostListRequests,
  IPayloadNewRequest,
  IRequestTypes,
} from '../types/requestTypes';

// RECUPERE LA LISTE DES DEMANDES
export const getListRequests = async (
  setListRequest: Dispatch<SetStateAction<IRequestTypes[]>>,
  setTotalListRequests: Dispatch<SetStateAction<number>>,
  listRequests: IRequestTypes[],
  dataPost: IPayloadDataPostListRequests,
  setIsLoading?: Dispatch<SetStateAction<boolean>>,
  type?: number
) => {
  const typeRequests = type || 1;
  if (setIsLoading) {
    setIsLoading(true);
  }
  try {
    const response = await axiosClient.post(urlGetRequestsList, {
      ...dataPost,
      type: typeRequests,
    });
    if (dataPost.pagination.page === 1) {
      setListRequest(response.data.data);
    } else {
      setListRequest([...listRequests, ...response.data.data]);
    }
    setTotalListRequests(response.data.meta.total);
    if (setIsLoading) {
      setIsLoading(false);
    }
  } catch (error) {
    if (setIsLoading) {
      setIsLoading(false);
    }
  }
};

// RECUPERE LES MESSAGES D'UNE DEMANDE
export const getMessagesRequest = async (
  updateMessagesRequest: Dispatch<SetStateAction<IMessageRequest[] | null>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  requestId: number
) => {
  try {
    const response = await axiosClient.get(
      urlGetRequestsMessages(requestId.toString())
    );
    updateMessagesRequest(response.data.data);
    setIsLoading(false);
  } catch (error) {
    toast.error(error.message);
  }
};

// RECUPERE LE DETAIL D'UNE DEMANDE
export const getOneRequest = async (
  updateDetailRequest: Dispatch<SetStateAction<IRequestTypes | null>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  requestId: number
) => {
  try {
    const response = await axiosClient.get(
      urlGetOneRequest(requestId.toString())
    );

    updateDetailRequest(response.data.data);
    setIsLoading(false);
  } catch (error) {
    toast.error(error.message);
  }
};

// ENVOYER UN NOUVEAU MESSAGE DANS UNE DEMANDE
export const postNewMessage = async (
  updateMessagesRequest: Dispatch<SetStateAction<IMessageRequest[] | null>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  requestId: number,
  message: string,
  reset: any
) => {
  const formData = new FormData();
  formData.append('message', message);
  try {
    await axiosClient.post(
      urlPostRequestsNewMessage(requestId.toString()),
      formData
    );
    await getMessagesRequest(updateMessagesRequest, setIsLoading, requestId);
    reset({ new_message: '' });
    return true;
  } catch (error) {
    toast.error(error.message);
    setIsLoading(false);
    return false;
  }
};

// ENVOYER UNE NOUVELLE DEMANDE
export const postNewRequest = async (payload: IPayloadNewRequest) => {
  try {
    await axiosClient.post(urlRequest, { ...payload, nb: 0 });
  } catch (error) {
    displayError(error);
  }
};

// RECUPERE LA LISTE DES DEMANDES DE TOUT LES CHANTIERS
export const getRequestsListGroup = async (
  updateList: Dispatch<SetStateAction<IListGroupRequests>>,
  setIsLoading?: Dispatch<SetStateAction<boolean>>,
  relationType?: string,
  dataPost?: { sort_and_filter: ISortAndFilterType }
) => {
  try {
    const group = relationType ? `/${relationType}` : '';
    const response = await axiosClient.post(
      `${urlGetListRequestGroup}${group}`,
      dataPost
    );
    updateList(response.data);
    if (setIsLoading) setIsLoading(false);
  } catch (error) {
    displayError(error.response);
  }
};

// MARK the note as read
export const markNoteAsRead = async (demandId: number) => {
  try {
    await axiosClient.post(urlPostMarkNoteAsRead(demandId));
  } catch (error) {
    toast.error(error.message);
  }
};
