/* eslint-disable no-lonely-if */
import { useTranslation } from 'react-i18next';
import { transformIsoDate, numFormatSpace } from '@utils/functions';
import { useMemo } from 'react';
import { totalBonus } from '@models/worksites/utils/utils';
import { Tag } from '@components/atomic/Tag';
import { red } from '@assets/color';
import { IWorksiteDetails } from '../../utils/worksitesTypes';

type IWorksitesCardSummaryProps = {
  details: IWorksiteDetails;
};

function WorksitesCardSummary({
  details,
}: IWorksitesCardSummaryProps): JSX.Element {
  const { t } = useTranslation();

  const operations =
    details.worksites_operations?.length > 0
      ? details.worksites_operations
      : details.simulationOperations || [];

  const remainingBalance = useMemo(() => {
    return details.total_cost - totalBonus(operations);
  }, [details]);

  const renderFinancial = (
    title: string,
    value: number,
    dataTestId: string
  ) => {
    if (value <= 0) return null;
    return (
      <div data-test-id={dataTestId} className="text-left">
        {`${title}: `}
        <span className="font-medium">{numFormatSpace(value)} €</span>
      </div>
    );
  };

  return (
    <div className="w-full text-[.875rem] border-t p-[1rem] flex items-center justify-between border-borderGrey">
      <div className="flex items-center mr-6">
        {details?.demandNumber && details.demandNumber > 0 ? (
          <Tag
            color={red}
            label={`${String(details.demandNumber)} ${
              details.demandNumber > 1 ? t('worksites.das') : t('worksites.da')
            } `}
            addClass="mr-4"
          />
        ) : (
          <div />
        )}
        <div data-test-id="last_update" className="text-left m-0">
          {`${t('worksites.update')}: ${transformIsoDate(details.updated_at)}`}
        </div>
      </div>
      <div>
        <div className="flex items-center space-x-[1rem]">
          {renderFinancial(
            t('worksites.remainingBalance'),
            remainingBalance,
            'worksite_remaining_balance'
          )}
          {renderFinancial(
            t('worksites.cost'),
            details.total_cost ? details.total_cost : 0,
            'worksite_total_cost'
          )}
          {renderFinancial(
            t('worksites.total_bonus'),

            totalBonus(operations),
            'worksite_total_bonus'
          )}
        </div>
        {operations.length > 0 && details.offer_expired_at && (
          <div className="w-full flex justify-end mt-2">
            {t('worksite_creation.simulation.summary.validity_offer_until')}
            {transformIsoDate(details.offer_expired_at)}
          </div>
        )}
      </div>
    </div>
  );
}

export { WorksitesCardSummary };
