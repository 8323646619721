import { InputSelect } from '@components/atomic/inputs/InputSelect';
import OneOperationItem from '@models/mar/components/worksiteCompletion/stepsCompletion/quotes/OneOperationItem';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ILabelValue } from '@globalTypes/globalTypes';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { IInvoice, IProDevis, IWorksiteMar } from '@models/mar/utils/marTypes';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';

interface IGesturesProps {
  operations: ILabelValue[];
  otherOperations: ILabelValue[];
  setProOperationsSelected: Dispatch<SetStateAction<ILabelValue[]>>;
  setProOtherOperationsSelected: Dispatch<SetStateAction<ILabelValue[]>>;
  prefixForm: string;
  indexPro?: number;
  indexQuote?: number;
  indexInvoice?: number;
}

function Gestures({
  operations,
  otherOperations,
  setProOperationsSelected,
  setProOtherOperationsSelected,
  prefixForm,
  indexPro,
  indexQuote,
  indexInvoice,
}: IGesturesProps) {
  const { t } = useTranslation();
  const { worksiteData } = useContext(WorksiteCreationMarContext);

  const isInvoice = indexInvoice !== undefined;

  const [resetSignal, setResetSignal] = useState<number>(1);

  const { control } = useFormContext();
  const {
    fields: gesturesFields,
    append: appendGesture,
    remove: removeGesture,
  } = useFieldArray({
    control,
    name: `${prefixForm}gestes`,
  });

  const {
    fields: otherGesturesFields,
    append: appendOtherGesture,
    remove: removeOtherGesture,
  } = useFieldArray({
    control,
    name: `${prefixForm}gestes_autre`,
  });

  const handleGesturesPrefill = (
    gesturesSource: IInvoice | IProDevis | undefined
  ) => {
    if (
      gesturesSource &&
      gesturesSource.mar_gestes &&
      otherGesturesFields &&
      otherGesturesFields.length === 0 &&
      gesturesFields &&
      gesturesFields.length === 0
    ) {
      const allGesturesData = gesturesSource.mar_gestes.map((geste) => ({
        cost_ht: geste.cost_ht,
        cost_ttc: geste.cost_ttc,
        description: geste.description,
        operation: {
          value: geste.geste.id.toString(),
          label: geste.geste.description,
        },
      }));

      const gesturesData = allGesturesData.filter((geste) =>
        operations.find(
          (operation) => operation.value === geste.operation.value
        )
      );

      const otherGesturesData = allGesturesData.filter((geste) =>
        otherOperations.find(
          (operation) => operation.value === geste.operation.value
        )
      );

      gesturesData.forEach((geste) => {
        appendGesture(geste);
        setProOperationsSelected((prev) => [...prev, geste.operation]);
      });

      otherGesturesData.forEach((geste) => {
        appendOtherGesture(geste);
        setProOtherOperationsSelected((prev) => [...prev, geste.operation]);
      });
    }
  };

  const prefillGestures = () => {
    const prosList = (worksiteData as IWorksiteMar).pros;

    if (
      indexPro !== undefined &&
      indexQuote !== undefined &&
      prosList &&
      prosList[indexPro] &&
      prosList[indexPro].devis &&
      prosList[indexPro].devis[indexQuote]
    ) {
      handleGesturesPrefill(prosList[indexPro].devis[indexQuote]);
    }

    if (isInvoice && worksiteData?.factures) {
      handleGesturesPrefill(worksiteData?.factures?.[indexInvoice]);
    }
  };

  const onDeleteOperation = (
    index: number,
    operationValue: string,
    isOther: boolean
  ) => {
    const filterOperations = (operationsList: ILabelValue[]) => {
      return operationsList.filter(
        (operation) => operationValue !== operation.value
      );
    };
    if (isOther) {
      removeOtherGesture(index);
      setProOtherOperationsSelected((prev) => filterOperations(prev));
    } else {
      removeGesture(index);
      setProOperationsSelected((prev) => filterOperations(prev));
    }
  };

  const handleSelectAllOperations = (isOther: boolean) => {
    const operationsToUse = isOther ? otherOperations : operations;
    operationsToUse.forEach((operation) => {
      if (isOther) {
        appendOtherGesture({ operation });
        setProOtherOperationsSelected((prev) => [...prev, operation]);
      } else {
        appendGesture({ operation });
        setProOperationsSelected((prev) => [...prev, operation]);
      }
    });
    setResetSignal((prev) => prev + 1);
  };

  useEffect(() => {
    prefillGestures();
  }, []);

  return (
    <>
      <p className="font-medium text-[1rem]">
        {t(
          'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.quote_section.work_list'
        )}
      </p>
      <InputSelect
        placeholder={`${t(
          'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.quote_section.select_operation'
        )}`}
        label={`${
          isInvoice
            ? t(
                'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.quote_section.associate_operations_quote'
              )
            : t(
                'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.quote_section.associate_operations'
              )
        }`}
        dataLabelValue={[
          // Ajoutez un élément "Sélectionner tout" au début de la liste des opérations
          ...(operations.length > 1
            ? [
                {
                  value: 'select_all',
                  label: `${t('global.select_all')}`,
                },
              ]
            : []),
          ...operations,
        ]}
        onSelectLabelValue={(value) => {
          if (value.value === 'select_all') {
            handleSelectAllOperations(false); // Sélectionnez toutes les opérations
          } else {
            appendGesture({ operation: value });
            setProOperationsSelected((prev) => [...prev, value]);
          }
          setResetSignal((prev) => prev + 1);
        }}
        resetSignal={resetSignal}
        disabled={operations.length === 0}
        required
      />
      {gesturesFields.map((gestureField: any, index) => {
        const operation = gestureField.operation as ILabelValue;
        return (
          <OneOperationItem
            key={gestureField.id}
            indexOperation={index}
            operationName={operation.label}
            onDeleteOperation={() =>
              onDeleteOperation(index, operation.value, false)
            }
            gestureName={`${prefixForm}gestes[${index}]`}
            isInvoice={isInvoice}
          />
        );
      })}
      <InputSelect
        placeholder={`${t(
          'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.quote_section.select_operation'
        )}`}
        label={`${t(
          'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.quote_section.associate_other_operations'
        )}`}
        dataLabelValue={[
          // Ajoutez un élément "Sélectionner tout" au début de la liste des opérations
          ...(otherOperations.length > 1
            ? [
                {
                  value: 'select_all',
                  label: `${t('global.select_all')}`,
                },
              ]
            : []),
          ...otherOperations,
        ]}
        onSelectLabelValue={(value) => {
          if (value.value === 'select_all') {
            handleSelectAllOperations(true); // Sélectionnez toutes les opérations
          } else {
            appendOtherGesture({ operation: value });
            setProOtherOperationsSelected((prev) => [...prev, value]);
          }
          setResetSignal((prev) => prev + 1);
        }}
        resetSignal={resetSignal}
        disabled={otherOperations.length === 0}
        addClass="!mt-8"
        required
      />
      {otherGesturesFields.map((otherGestureField: any, index) => {
        const operation = otherGestureField.operation as ILabelValue;
        if (!operation) {
          return null;
        }
        return (
          <OneOperationItem
            key={otherGestureField.id}
            indexOperation={index}
            operationName={operation.label}
            onDeleteOperation={() =>
              onDeleteOperation(index, operation.value, true)
            }
            gestureName={`${prefixForm}gestes_autre[${index}]`}
            isInvoice={isInvoice}
          />
        );
      })}
    </>
  );
}

export default Gestures;

Gestures.defaultProps = {
  indexPro: undefined,
  indexQuote: undefined,
  indexInvoice: undefined,
};
