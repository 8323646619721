/* eslint-disable react/no-array-index-key */
import { useContext, useEffect, useState } from 'react';
import LayoutMarDetailsStep from '@models/mar/components/worksiteCompletion/LayoutMarDetailsStep';
import {
  formCustomerInformation,
  formIncomes,
  formTaxHouseholdDeclarant,
  formTaxHouseholdNumber,
} from '@models/mar/utils/datas';
import { IWorksiteMar } from '@models/mar/utils/marTypes';
import OneMarForm from '@models/mar/components/worksiteCompletion/OneMarForm';
import { useTranslation } from 'react-i18next';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import { useFormContext } from 'react-hook-form';
import { IIncomes } from '@models/worksiteCreation/utils/types/SimulationTypes';
import {
  fetchIncomesMar,
  validateCustomerInformation,
} from '@models/mar/apiRequests/worksiteCreationMarRequests';
import { constructIncomesContentArray } from '@models/worksiteCreation/utils/functions';
import BoolMar from '@models/mar/components/BoolMar';
import { INCOMES } from '@models/worksiteCreation/utils/enums';
import { TextError } from '@components/TextError';
import { stringToBoolean } from '@utils/functions';
import { STEPS_WORKSITE_MAR_COMPLETION } from '@models/mar/utils/enums';
import LoaderIncomesMar from '@models/mar/components/worksiteCompletion/stepsCompletion/customerInformation/LoaderIncomesMar';

function CustomerInformationMar() {
  const { t } = useTranslation();
  const {
    worksiteData,
    worksiteAddress,
    updateWorksiteData,
    updateStepCompletion,
    updateButtonNextDisabled,
  } = useContext(WorksiteCreationMarContext);
  const [buttonNextLoading, setButtonNextLoading] = useState(false);
  const [incomesOptionsArray, setIncomesOptionsArray] = useState<IIncomes[]>(
    []
  );
  const [loadingIncomes, setLoadingIncomes] = useState(false);

  const formContext = useFormContext();
  const { watch } = formContext;

  const persons = watch('persons')
    ? Number(watch('persons'))
    : worksiteData?.persons;
  const precarityType = watch('precarity_type')
    ? Number(watch('precarity_type'))
    : worksiteData?.precarity_type;
  const occupationType = watch('occupation_type')
    ? Number(watch('occupation_type'))
    : worksiteData?.occupation_type;
  const taxHouseholdNumber =
    watch('tax_household_number') || worksiteData?.tax_households?.length;
  const ptz = watch('ptz') ? stringToBoolean(watch('ptz')) : worksiteData?.ptz;
  const opah = watch('opah')
    ? stringToBoolean(watch('opah'))
    : worksiteData?.opah;
  const taxHouseholds =
    Array.from({ length: taxHouseholdNumber }).map((_, index) => ({
      firstname: watch(`tax_household_declarant_${index + 1}_firstname`) || '',
      lastname: watch(`tax_household_declarant_${index + 1}_lastname`) || '',
    })) || worksiteData?.tax_households;

  const folderNotEligible =
    (ptz &&
      !(precarityType === INCOMES.LOW || precarityType === INCOMES.MODEST)) ||
    (ptz && opah === false);

  const checkIfInfosAreReady =
    persons &&
    precarityType &&
    occupationType &&
    taxHouseholdNumber &&
    taxHouseholdNumber > 0 &&
    ptz !== null &&
    (ptz === false || (ptz === true && opah !== null)) &&
    !folderNotEligible &&
    taxHouseholds &&
    taxHouseholds.every(
      (taxHousehold) =>
        taxHousehold &&
        taxHousehold.firstname &&
        taxHousehold.lastname &&
        taxHousehold.firstname !== '' &&
        taxHousehold.lastname !== ''
    );

  const handleValidateStep = async () => {
    setButtonNextLoading(true);
    const dataToSend = {
      occupation_type: occupationType as number,
      persons: Number(persons),
      precarity_type: precarityType as number,
      ptz: ptz || false,
      opah: opah || false,
      tax_households: taxHouseholds,
    };
    const response = await validateCustomerInformation(
      worksiteData?.id || 0,
      dataToSend
    );

    setButtonNextLoading(false);

    if (response) {
      updateWorksiteData((prev) => ({ ...prev, ...response }));
      updateStepCompletion(STEPS_WORKSITE_MAR_COMPLETION.ENERGY_AUDIT);
    }
  };

  const fetchIncomes = async () => {
    if (persons) {
      setLoadingIncomes(true);
      const incomesFetched = await fetchIncomesMar(
        persons.toString(),
        worksiteAddress
      );
      if (incomesFetched) {
        setIncomesOptionsArray(constructIncomesContentArray(t, incomesFetched));
        setLoadingIncomes(false);
      }
    }
  };

  useEffect(() => {
    fetchIncomes();
  }, [persons]);

  useEffect(() => {
    updateButtonNextDisabled(!checkIfInfosAreReady);
  }, [watch()]);

  return (
    <LayoutMarDetailsStep
      onValidate={handleValidateStep}
      buttonNextLoading={buttonNextLoading}
    >
      <>
        <OneMarForm
          dataForm={formCustomerInformation(t, worksiteData as IWorksiteMar)}
        />
        {persons && (
          <div>
            {loadingIncomes ? (
              <LoaderIncomesMar />
            ) : (
              <OneMarForm
                dataForm={formIncomes(
                  t,
                  incomesOptionsArray,
                  worksiteData as IWorksiteMar
                )}
              />
            )}
          </div>
        )}
        {precarityType && (
          <div className="flex flex-col space-y-6">
            <BoolMar
              fieldName="ptz"
              question={`${t(
                'mar.worksite_creation.worksite_completion.folder_details.customer_information.ptz_question'
              )}`}
            />
            {/* Si pas de PTZ ou si OUI mais pas modest ou très modeste */}
            {ptz === false ||
              (ptz &&
                (precarityType === INCOMES.LOW ||
                  precarityType === INCOMES.MODEST) && (
                  <BoolMar
                    fieldName="opah"
                    question={`${t(
                      'mar.worksite_creation.worksite_completion.folder_details.customer_information.opah_question'
                    )}`}
                  />
                ))}
            {folderNotEligible && (
              <TextError
                errorMessage={`${t(
                  'mar.worksite_creation.worksite_completion.folder_details.customer_information.folder_no_eligible'
                )}`}
                addClass="!mt-0"
              />
            )}
          </div>
        )}
        <OneMarForm
          dataForm={formTaxHouseholdNumber(t, worksiteData as IWorksiteMar)}
        />
        {!!taxHouseholdNumber &&
          Array.from({ length: taxHouseholdNumber }).map((_, index) => (
            <OneMarForm
              key={`declarant-${index}`}
              dataForm={formTaxHouseholdDeclarant(
                t,
                worksiteData as IWorksiteMar,
                index + 1
              )}
            />
          ))}
      </>
    </LayoutMarDetailsStep>
  );
}

export default CustomerInformationMar;
