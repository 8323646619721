/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react/no-danger */
import React, {
  useState,
  useRef,
  useEffect,
  ReactElement,
  cloneElement,
} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import DOMPurify from 'dompurify';

interface ITooltipProps {
  content: string; // Le contenu est une chaîne contenant du HTML
  children: ReactElement;
  position?: 'top' | 'bottom' | 'left' | 'right';
  delay?: number; // Délai avant l'affichage du tooltip (en ms)
  className?: string;
}

function Tooltip({
  content,
  children,
  position,
  delay,
  className,
}: ITooltipProps) {
  const [visible, setVisible] = useState(false);
  const showTimeout = useRef<ReturnType<typeof setTimeout>>();
  const hideTimeout = useRef<ReturnType<typeof setTimeout>>();

  const showTooltip = () => {
    clearTimeout(hideTimeout.current);

    showTimeout.current = setTimeout(() => {
      setVisible(true);
    }, delay);
  };

  const hideTooltip = () => {
    clearTimeout(showTimeout.current);

    hideTimeout.current = setTimeout(() => {
      setVisible(false);
    }, 300); // Ajustez ce délai selon vos besoins
  };

  useEffect(() => {
    return () => {
      clearTimeout(showTimeout.current);
      clearTimeout(hideTimeout.current);
    };
  }, []);

  const childElement = React.Children.only(children) as ReactElement;

  // Nettoyage du contenu HTML
  const sanitizedContent = DOMPurify.sanitize(content, {
    ALLOWED_ATTR: ['href', 'title', 'target', 'rel'],
  });

  // Gestionnaire de clic pour empêcher la propagation des clics sur les liens
  const handleTooltipClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const target = event.target as HTMLElement;
    if (target.tagName.toLowerCase() === 'a') {
      event.stopPropagation();
      // Optionnel : Vous pouvez également gérer le clic sur le lien ici si nécessaire
    }
  };

  // Positionnement dynamique en fonction de la prop `position`
  const positionClasses = {
    top: 'bottom-full left-1/2 transform -translate-x-1/2 mb-2',
    bottom: 'top-full left-1/2 transform -translate-x-1/2 mt-2',
    left: 'right-full top-1/2 transform -translate-y-1/2 mr-2',
    right: 'left-full top-1/2 transform -translate-y-1/2 ml-2',
  };

  return (
    <div className={`relative inline-block ${className}`}>
      {cloneElement(childElement, {
        onMouseEnter: showTooltip,
        onMouseLeave: hideTooltip,
        onFocus: showTooltip,
        onBlur: hideTooltip,
        'aria-describedby': visible ? 'tooltip' : undefined,
      })}
      {visible && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
        <div
          id="tooltip"
          role="tooltip"
          tabIndex={0} // Rendre le tooltip focalisable
          className={`tooltip absolute z-10 p-2 bg-backgroundBody rounded border border-borderGrey ${
            positionClasses[position || 'top']
          } text-sm w-[15rem]`}
          onMouseEnter={showTooltip}
          onMouseLeave={hideTooltip}
          onFocus={showTooltip}
          onBlur={hideTooltip}
          onClick={handleTooltipClick} // Ajout du gestionnaire de clic
          dangerouslySetInnerHTML={{ __html: sanitizedContent }}
        />
      )}
    </div>
  );
}

export default Tooltip;

Tooltip.defaultProps = {
  position: 'top',
  delay: 0,
  className: '',
};
