import { InfosWithIcon } from '@components/atomic/InfosWithIcon';
import { blueOpx } from '@assets/color';
import {
  CalendarIcon,
  EuroIcon,
  HomeIcon,
  PersonIcon,
  PositionIcon,
} from '@assets/images/svgComponents';
import { useTranslation } from 'react-i18next';
import {
  labelAge,
  labelIncomes,
} from '@models/worksiteCreation/utils/functions';
import { Fragment, useContext } from 'react';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import { useFormContext } from 'react-hook-form';
import {
  HOUSING_TYPES,
  MANDATORY_TYPES,
  OCCUPATION_TYPES,
  sidebarStepsWithTranslation,
} from '@models/mar/utils/enums';
import LayoutListInfosFolderMar from '@models/mar/components/worksiteCompletion/rightPart/LayoutListInfosFolderMar';
import { IMandataire, IPro, IWorksiteMar } from '@models/mar/utils/marTypes';
import { dateToDDMMYYY } from '@utils/format';
import { calculationInvoicesCostsHT } from '@models/mar/utils/functions';
import { GlobalContext } from '@context/globalContext';

function ListInfosFolderMar() {
  const { t } = useTranslation();
  const { userView } = useContext(GlobalContext);
  const { worksiteData, worksiteAddress } = useContext(
    WorksiteCreationMarContext
  );

  const formContext = useFormContext();
  const { watch } = formContext;

  const housingTypeLabel = (housingType: number | string | undefined) => {
    if (!housingType) {
      return undefined;
    }
    let housingTypeNumber = housingType;
    if (typeof housingType === 'string') {
      housingTypeNumber = Number(housingType);
    }
    return housingTypeNumber === HOUSING_TYPES.HOUSE
      ? t('worksite_creation.simulation.habitation.house')
      : t('worksite_creation.simulation.habitation.apartment');
  };

  const housingType = housingTypeLabel(
    watch('housing_type') || worksiteData?.housing_type
  );

  const surface = watch('surface') ? watch('surface') : worksiteData?.surface;

  const age = watch('age') ? watch('age') : worksiteData?.age_type;

  const address =
    worksiteAddress.numberStreet && worksiteAddress.streetName
      ? `${worksiteAddress.numberStreet} ${worksiteAddress.streetName}, ${worksiteAddress.zipCode} ${worksiteAddress.city}`
      : undefined;

  const occupationTypeLabel = (occupationType: number | string | undefined) => {
    if (!occupationType) {
      return undefined;
    }
    let occupationTypeNumber = occupationType;
    if (typeof occupationType === 'string') {
      occupationTypeNumber = Number(occupationType);
    }
    return occupationTypeNumber === OCCUPATION_TYPES.OWNER_OCCUPANT
      ? t('worksite_creation.simulation.owner')
      : t('worksite_creation.simulation.landlord_owner');
  };

  const occupationType = occupationTypeLabel(
    watch('occupation_type') || worksiteData?.occupation_type
  );

  const precarityType = watch('precarity_type') || worksiteData?.precarity_type;

  const startDate = watch('start_date')
    ? watch('start_date')
    : worksiteData?.start_date;

  const endDate = watch('end_date')
    ? watch('end_date')
    : worksiteData?.end_date;

  const invoicesCostsHT = calculationInvoicesCostsHT(
    worksiteData as IWorksiteMar
  );

  const firstVisitDateMar =
    watch('first_visit_date_mar') || worksiteData?.visit_date;

  const auditor = () => {
    const auditData = watch('auditor') || worksiteData?.audit;
    if (!auditData) {
      return undefined;
    }

    const getFullName = () => {
      const firstname = auditData.firstname || '';
      const lastname = auditData.lastname || '';
      const fullName = `${firstname} ${lastname}`.trim();
      return fullName || '-';
    };

    if (auditData.entity_id === userView?.entity_id) {
      return getFullName();
    }

    if (auditData.company_name) {
      return auditData.company_name;
    }

    if (auditData.entity?.company_name) {
      return auditData.entity.company_name;
    }

    return getFullName();
  };

  const scenarioAmount = () => {
    const scenarioSelected = worksiteData?.scenarios?.find(
      (scenario) => scenario.selected
    );
    if (!scenarioSelected) {
      return undefined;
    }
    return scenarioSelected.mpr_amount;
  };

  const quoteInstallersSelected = worksiteData?.pros?.filter(
    (pro) => pro.selected
  );

  const oneValuePreWorksiteAnalysisIsSet =
    housingType || surface || age || address;

  const renderOneProSelected = (pro: IPro) => {
    const quotesSelected = pro.devis?.filter((quote) => quote.selected);

    const costsQuoteSelected = quotesSelected?.reduce(
      (acc, quote) => {
        const quoteCosts = quote.mar_gestes?.reduce(
          (quoteAcc, geste) => {
            // eslint-disable-next-line no-param-reassign
            quoteAcc.cost_ht += geste.cost_ht;
            // eslint-disable-next-line no-param-reassign
            quoteAcc.cost_ttc += geste.cost_ttc;
            return quoteAcc;
          },
          { cost_ht: 0, cost_ttc: 0 }
        );

        acc.cost_ht += quoteCosts?.cost_ht || 0;
        acc.cost_ttc += quoteCosts?.cost_ttc || 0;
        acc.mpr_amount += quote.mpr_amount || 0;
        return acc;
      },
      { cost_ht: 0, cost_ttc: 0, mpr_amount: 0 }
    );

    return (
      <Fragment key={`pro-${pro.id}`}>
        <InfosWithIcon
          colorIcon={blueOpx}
          icon={<PersonIcon />}
          infos={[
            {
              title: t(
                'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.list_infos_folder.installer_selected'
              ),
              subtitle: pro.entity.company_name,
            },
          ]}
          addClass="col-span-2"
        />
        {costsQuoteSelected.cost_ht !== 0 && (
          <>
            <InfosWithIcon
              icon={<EuroIcon />}
              colorIcon={blueOpx}
              infos={[
                {
                  title: t(
                    'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.list_infos_folder.cost_ht_quote_selected'
                  ),
                  subtitle: `${costsQuoteSelected.cost_ht.toLocaleString()} €`,
                },
              ]}
            />
            <InfosWithIcon
              spaceLeft
              infos={[
                {
                  title: t(
                    'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.list_infos_folder.cost_ttc_quote_selected'
                  ),
                  subtitle: `${costsQuoteSelected.cost_ttc.toLocaleString()} €`,
                },
              ]}
            />
          </>
        )}
        {quotesSelected && quotesSelected.length > 0 && (
          <InfosWithIcon
            spaceLeft
            infos={[
              {
                title: t(
                  'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.list_infos_folder.estimated_bonus'
                ),
                subtitle: `${
                  costsQuoteSelected?.mpr_amount.toLocaleString() || 0
                } €`,
              },
            ]}
          />
        )}
      </Fragment>
    );
  };

  const renderAnahInfos = () => {
    const mandatories = worksiteData?.mandataires;
    const agreementDate =
      watch('agreement_date') || worksiteData?.agreement_date;
    const primeAmount = watch('prime_amount') || worksiteData?.prime_amount;
    if (
      (!mandatories || mandatories.length === 0) &&
      !agreementDate &&
      !primeAmount
    ) {
      return null;
    }
    return (
      <LayoutListInfosFolderMar
        title={sidebarStepsWithTranslation(t).FOLDER_ANAH.label}
      >
        <>
          {(mandatories as IMandataire[])
            .filter(
              (mandatory) =>
                mandatory.type === MANDATORY_TYPES.ADMINISTRATIF_SIGNATURE ||
                mandatory.type === MANDATORY_TYPES.FINANCIER
            )
            .map((mandatory, index) => {
              let mandatoryTitle = '';
              switch (mandatory.type) {
                case MANDATORY_TYPES.ADMINISTRATIF_SIGNATURE:
                  mandatoryTitle = t(
                    'mar.progress.kpi.administrative_mandatory'
                  );
                  break;
                case MANDATORY_TYPES.FINANCIER:
                  mandatoryTitle = t('mar.progress.kpi.financial_mandatory');
                  break;
                default:
                  mandatoryTitle = '';
              }
              return (
                <InfosWithIcon
                  key={`mandate-${mandatory.type}`}
                  colorIcon={blueOpx}
                  icon={index === 0 ? <PersonIcon /> : undefined}
                  spaceLeft={index !== 0}
                  infos={[
                    {
                      title: mandatoryTitle,
                      subtitle: mandatory.firstname
                        ? `${mandatory.firstname} ${mandatory.lastname}`
                        : '-',
                    },
                  ]}
                />
              );
            })}
          {agreementDate && (
            <InfosWithIcon
              colorIcon={blueOpx}
              icon={<CalendarIcon />}
              infos={[
                {
                  title: t(
                    'mar.worksite_creation.worksite_completion.folder_details.notification_of_grant.grant_date'
                  ),
                  subtitle: dateToDDMMYYY(agreementDate),
                },
              ]}
            />
          )}
          {primeAmount && (
            <InfosWithIcon
              colorIcon={blueOpx}
              icon={<EuroIcon />}
              infos={[
                {
                  title: t(
                    'mar.worksite_creation.worksite_completion.folder_details.notification_of_grant.prime_confirmed'
                  ),
                  subtitle: `${primeAmount.toLocaleString()} €`,
                },
              ]}
            />
          )}
        </>
      </LayoutListInfosFolderMar>
    );
  };

  const renderBalanceInfos = () => {
    const paymentRequestDate =
      watch('payment_request_date') || worksiteData?.payment_request_date;
    const paymentReceiptDate =
      watch('payment_receipt_date') || worksiteData?.payment_receipt_date;
    if (!paymentRequestDate && !paymentReceiptDate) {
      return null;
    }
    return (
      <LayoutListInfosFolderMar
        title={sidebarStepsWithTranslation(t).BALANCE.label}
      >
        <>
          {paymentRequestDate && (
            <InfosWithIcon
              colorIcon={blueOpx}
              icon={<CalendarIcon />}
              infos={[
                {
                  title: t(
                    'mar.worksite_creation.worksite_completion.folder_details.balance.balance_request.deposit_date_short'
                  ),
                  subtitle: dateToDDMMYYY(paymentRequestDate),
                },
              ]}
            />
          )}
          {paymentReceiptDate && (
            <InfosWithIcon
              colorIcon={blueOpx}
              spaceLeft={paymentRequestDate}
              infos={[
                {
                  title: t(
                    'mar.worksite_creation.worksite_completion.folder_details.balance.payment_letter.payment_date'
                  ),
                  subtitle: dateToDDMMYYY(paymentReceiptDate),
                },
              ]}
            />
          )}
        </>
      </LayoutListInfosFolderMar>
    );
  };

  if (!oneValuePreWorksiteAnalysisIsSet) {
    return null;
  }

  return (
    <div className="flex flex-col space-y-4">
      {oneValuePreWorksiteAnalysisIsSet && (
        <LayoutListInfosFolderMar
          title={sidebarStepsWithTranslation(t).PRE_WORKSITE_ANALYSIS.label}
        >
          <>
            {address && (
              <InfosWithIcon
                colorIcon={blueOpx}
                icon={<PositionIcon />}
                infos={[
                  {
                    title: t('worksites.address'),
                    subtitle: address,
                  },
                ]}
                addClass="col-span-2"
              />
            )}
            {housingType && (
              <InfosWithIcon
                colorIcon={blueOpx}
                icon={<HomeIcon />}
                infos={[
                  {
                    title: t('worksite_creation.simulation.habitation.title'),
                    subtitle: housingType,
                  },
                ]}
              />
            )}
            {surface && (
              <InfosWithIcon
                spaceLeft
                infos={[
                  {
                    title: t('worksite_creation.simulation.surface'),
                    subtitle: `${surface} m2`,
                  },
                ]}
              />
            )}
            {age && (
              <InfosWithIcon
                spaceLeft
                infos={[
                  {
                    title: t('worksite_creation.simulation.age.title'),
                    subtitle: labelAge(t, age),
                  },
                ]}
              />
            )}
            {occupationType && (
              <InfosWithIcon
                spaceLeft
                infos={[
                  {
                    title: t(
                      'mar.worksite_creation.worksite_completion.folder_details.customer_information.household_information.customer_quality'
                    ),
                    subtitle: occupationType,
                  },
                ]}
              />
            )}
            {precarityType && (
              <InfosWithIcon
                spaceLeft
                infos={[
                  {
                    title: t('worksite_creation.simulation.income'),
                    subtitle: labelIncomes(t, Number(precarityType)),
                  },
                ]}
              />
            )}
            {auditor() && (
              <InfosWithIcon
                spaceLeft
                infos={[
                  {
                    title: t(
                      'mar.worksite_creation.worksite_completion.folder_details.energy_audit.auditor.title'
                    ),
                    subtitle: auditor() || '',
                  },
                ]}
              />
            )}
            {firstVisitDateMar && (
              <InfosWithIcon
                spaceLeft
                infos={[
                  {
                    title: t(
                      'mar.worksite_creation.worksite_completion.folder_details.housing_analysis.short_visit_date'
                    ),
                    subtitle: dateToDDMMYYY(firstVisitDateMar),
                  },
                ]}
              />
            )}
            {scenarioAmount() && (
              <InfosWithIcon
                spaceLeft
                infos={[
                  {
                    title: t(
                      'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.one_scenario.scenario_amount_selected'
                    ),
                    subtitle: `${scenarioAmount()?.toLocaleString()} €`,
                  },
                ]}
              />
            )}
          </>
        </LayoutListInfosFolderMar>
      )}
      {quoteInstallersSelected && quoteInstallersSelected.length > 0 && (
        <LayoutListInfosFolderMar
          title={sidebarStepsWithTranslation(t).CHOICE_OF_PROVIDERS.label}
        >
          <>{quoteInstallersSelected.map((pro) => renderOneProSelected(pro))}</>
        </LayoutListInfosFolderMar>
      )}
      {startDate && (
        <LayoutListInfosFolderMar
          title={sidebarStepsWithTranslation(t).WORKSITE.label}
        >
          <>
            {startDate && (
              <InfosWithIcon
                colorIcon={blueOpx}
                icon={<CalendarIcon />}
                infos={[
                  {
                    title: t('forms.start_date.placeholder'),
                    subtitle: dateToDDMMYYY(startDate),
                  },
                ]}
              />
            )}
            {endDate && (
              <InfosWithIcon
                spaceLeft
                colorIcon={blueOpx}
                infos={[
                  {
                    title: t('forms.end_date.placeholder'),
                    subtitle: dateToDDMMYYY(endDate),
                  },
                ]}
              />
            )}
            {invoicesCostsHT && (
              <InfosWithIcon
                icon={<EuroIcon />}
                colorIcon={blueOpx}
                infos={[
                  {
                    title: t(
                      'mar.worksite_creation.worksite_completion.folder_details.end_of_work.cost_ht'
                    ),
                    subtitle: `${invoicesCostsHT?.toLocaleString()} €`,
                  },
                ]}
              />
            )}
          </>
        </LayoutListInfosFolderMar>
      )}
      {renderAnahInfos()}
      {renderBalanceInfos()}
    </div>
  );
}

export default ListInfosFolderMar;
