import { IWorksiteMar } from '@models/mar/utils/marTypes';
import { TFunction } from 'i18next';
import { IKPITab } from '@globalTypes/globalTypes';
import { MANDATORY_TYPES } from '@models/mar/utils/enums';
import { calculateTotalCostTTC } from '@models/mar/utils/functions';
import {
  BriefcaseIcon,
  EuroIcon,
  PersonIcon,
} from '@assets/images/svgComponents';
import { purple } from '@assets/color';

export const buildKpiTab = (
  worksiteData: IWorksiteMar,
  t: TFunction
): IKPITab[] => {
  const administrativeMandatory = worksiteData?.mandataires?.find(
    (mandataire) => mandataire.type === MANDATORY_TYPES.ADMINISTRATIF_SIGNATURE
  );

  const financialMandatory = worksiteData?.mandataires?.find(
    (mandataire) => mandataire.type === MANDATORY_TYPES.FINANCIER
  );

  const installersSelected = worksiteData?.pros?.filter((pro) => pro.selected);

  const totalCostTTC = calculateTotalCostTTC(worksiteData);

  const administrativeMandatoryKpi = {
    icon: <BriefcaseIcon />,
    infos: [
      {
        title: `${t('mar.progress.kpi.administrative_mandatory')}`,
        subtitle: administrativeMandatory
          ? `${administrativeMandatory.firstname} ${administrativeMandatory.lastname}`
          : '-',
      },
    ],
  };

  const financialMandatoryKpi = {
    icon: <BriefcaseIcon />,
    infos: [
      {
        title: `${t('mar.progress.kpi.financial_mandatory')}`,
        subtitle: financialMandatory
          ? `${financialMandatory.firstname} ${financialMandatory.lastname}`
          : '-',
      },
    ],
  };

  const installersKpi =
    installersSelected?.map((installer, index) => ({
      icon: <PersonIcon />,
      infos: [
        {
          title: `${t('mar.progress.kpi.installer')}${
            installersSelected.length > 1 ? ` ${index + 1}` : ''
          }`,
          subtitle: installer.entity.company_name || '-',
        },
      ],
    })) || []; // Retourner un tableau vide si undefined

  const subsidyKpi: IKPITab = {
    icon: <EuroIcon />,
    colorIcon: purple,
    infos: [
      {
        title: `${t('mar.progress.kpi.subsidy')}`,
        subtitle: worksiteData?.prime_amount
          ? `${worksiteData.prime_amount.toLocaleString()} €`
          : '-',
      },
    ],
  };

  const eligibleWorkCostKpi = {
    icon: <EuroIcon />,
    infos: [
      {
        title: `${t('worksites.remainingBalance')}`,
        subtitle:
          totalCostTTC && worksiteData?.prime_amount
            ? `${(
                (totalCostTTC as number) - worksiteData.prime_amount
              )?.toLocaleString()} €`
            : '-',
      },
    ],
  };

  // Tableau des KPI avant filtration
  const kpiTabWorksiteProgress = [
    administrativeMandatoryKpi,
    financialMandatoryKpi,
    ...installersKpi, // Assuré que c'est un tableau
    subsidyKpi,
    eligibleWorkCostKpi,
  ];

  // Filtrer les éléments où le subtitle est vide
  return kpiTabWorksiteProgress.filter((kpi) =>
    kpi.infos.some((info) => info.subtitle)
  );
};
