import { InputText } from '@components/atomic/inputs/InputText';
import { IRepresentativesType } from '@models/auth/utils/types';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { actionsBottom } from '@models/auth/utils/utils';
import { emailValidationRegex } from '@utils/regex';
import { placeholderExample } from '@utils/utils';

interface ICompanyManagerInfoProps {
  representatives: IRepresentativesType;
  setRepresentatives: Dispatch<SetStateAction<IRepresentativesType>>;
  setShowCompanyManagerInfo: Dispatch<SetStateAction<boolean>>;
  setShowSubscriptionOverview: Dispatch<SetStateAction<boolean>>;
  setShowEntityProfil: Dispatch<SetStateAction<boolean>>;
  setRepresentativesTimeOut: Dispatch<SetStateAction<boolean>>;
}

function CompanyManagerInfo({
  representatives,
  setRepresentatives,
  setShowCompanyManagerInfo,
  setShowSubscriptionOverview,
  setShowEntityProfil,
  setRepresentativesTimeOut,
}: ICompanyManagerInfoProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const methods = useForm({
    defaultValues: {
      company_manager_lastname: representatives.company_manager.lastname,
      company_manager_firstname: representatives.company_manager.firstname,
      company_manager_email: '',
      company_manager_function: '',
      company_manager_phone_number: '',
    },
  });

  const { handleSubmit, watch } = methods;
  const formValues = watch(); // Obtenir toutes les valeurs du formulaire

  const placeholders = placeholderExample(t);

  useEffect(() => {
    setRepresentativesTimeOut(true);
  }, []);

  const allFieldsFilled = Object.entries(formValues).every(([key, value]) => {
    if (key === 'company_manager_email') {
      return emailValidationRegex.test(value);
    }
    return value !== undefined && value !== '';
  });

  const backToPersonalInfo = () => {
    setShowSubscriptionOverview(false);
    setShowEntityProfil(false);
    setShowCompanyManagerInfo(false);
  };

  const onSubmitCompanyManagerInfo = (formDatas: FieldValues) => {
    setLoading(true);
    setRepresentatives((prevState) => {
      return {
        ...prevState,
        company_manager: {
          ...prevState.company_manager,
          lastname: formDatas.company_manager_lastname,
          firstname: formDatas.company_manager_firstname,
          email: formDatas.company_manager_email,
          function: formDatas.company_manager_function,
          phone_number: formDatas.company_manager_phone_number,
        },
      };
    });

    setShowCompanyManagerInfo(false);

    setLoading(false);
  };

  return (
    <FormProvider {...methods}>
      <form
        id="form-company-manager-infos"
        className="flex flex-col gap-3 lg:w-[35rem]"
        onSubmit={handleSubmit(onSubmitCompanyManagerInfo)}
      >
        <p className="text-[1.6875rem] font-medium leading-[2.5rem]">
          {t('auth.registration.company_manager_info.header')}
        </p>

        <p className="font-normal text-black text-[.875rem] mb-4">
          {t('auth.registration.company_manager_info.subtitle')}
        </p>

        <div className="flex flex-col items-start space-y-4 w-full mb-6">
          <div className="flex gap-4 self-stretch">
            <InputText
              label={t('auth.registration.forms.firstname') || ''}
              id="firstname"
              name="company_manager_firstname"
              defaultValue={representatives.company_manager.firstname}
              placeholder={placeholders.FIRSTNAME}
              required
              data-test-id="company_manager_firstname"
            />
            <InputText
              label={t('auth.registration.forms.lastname') || ''}
              id="lastname"
              name="company_manager_lastname"
              defaultValue={representatives.company_manager.lastname}
              placeholder={placeholders.LASTNAME}
              required
              data-test-id="company_manager_lastname"
            />
          </div>
          <div className="w-full">
            <InputText
              id="function"
              name="company_manager_function"
              defaultValue={representatives.company_manager.function || ''}
              required
              label={t('auth.registration.forms.function') || ''}
              placeholder={placeholders.FUNCTION}
              dataTestId="company_manager_function"
            />
          </div>
          <div className="flex gap-4 self-stretch">
            <InputText
              id="email"
              name="company_manager_email"
              defaultValue={representatives.company_manager.email || ''}
              required
              label={t('auth.registration.forms.email') || ''}
              placeholder={placeholders.EMAIL}
              dataTestId="company_manager_email"
            />
            <InputText
              id="phone_number"
              name="company_manager_phone_number"
              required
              label={t('auth.registration.forms.phone_number') || ''}
              placeholder={placeholders.TELEPHONE}
              defaultValue={representatives.company_manager.phone_number || ''}
              dataTestId="company_manager_phone_number"
            />
          </div>
        </div>

        {actionsBottom(
          t,
          () => backToPersonalInfo(),
          undefined,
          'form-company-manager-infos',
          loading,
          !allFieldsFilled || Object.keys(formValues).length === 0
        )}
      </form>
    </FormProvider>
  );
}

export { CompanyManagerInfo };
