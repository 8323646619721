import LayoutMarDetailsStep from '@models/mar/components/worksiteCompletion/LayoutMarDetailsStep';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OneMarForm from '@models/mar/components/worksiteCompletion/OneMarForm';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import { IWorksiteMar } from '@models/mar/utils/marTypes';
import {
  defaultDocumentWithFileType,
  formWorkInProgress,
} from '@models/mar/utils/datas';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { InputTypes } from '@utils/utils';
import {
  deleteOneLinkedFile,
  postLinkedFile,
  validateWorkInProgress,
} from '@models/mar/apiRequests/worksiteCreationMarRequests';
import { STEPS_WORKSITE_MAR_COMPLETION } from '@models/mar/utils/enums';
import { createFileWithLinkedFile } from '@utils/functions';
import { ILinkedFile } from '@globalTypes/globalTypes';
import LoaderFile from '@models/mar/components/LoaderFile';
import { fileTypeEnum } from '@utils/enums';

function WorkInProgress() {
  const { t } = useTranslation();
  const {
    worksiteData,
    updateButtonNextDisabled,
    updateWorksiteData,
    updateStepCompletion,
  } = useContext(WorksiteCreationMarContext);

  const [buttonNextLoading, setButtonNextLoading] = useState(false);
  const [isPrefilled, setIsPrefilled] = useState(false);
  const [indexOfDocumentLoading, setIndexOfDocumentLoading] =
    useState<number>();

  const { control, watch, setValue } = useFormContext();
  const { fields, append } = useFieldArray({
    control,
    name: 'files',
  });

  const startDate = watch('start_date');
  const endDate = watch('end_date');
  const files = watch('files');

  const checkIfInfosAreFilled =
    startDate &&
    endDate &&
    files &&
    files.length > 0 &&
    files.some((file: { file: File | undefined }) => file.file);

  const handleValidateStep = async () => {
    setButtonNextLoading(true);
    const dataToSend = {
      start_date: startDate,
      end_date: endDate,
    };
    const response = await validateWorkInProgress(
      worksiteData?.id || 0,
      dataToSend
    );

    if (response) {
      updateWorksiteData((prev) => ({
        ...prev,
        ...response,
      }));
      updateStepCompletion(STEPS_WORKSITE_MAR_COMPLETION.END_OF_WORK);
    }
    setButtonNextLoading(false);
  };

  const preFillWorkInProgress = async () => {
    if (worksiteData) {
      if (
        worksiteData.linked_files &&
        worksiteData.linked_files.length > 0 &&
        fields.length === 0
      ) {
        const signedQuotesFiles = worksiteData.linked_files.filter(
          (linkedFile) => linkedFile.file_type === fileTypeEnum.DEVIS_SIGNE
        );

        if (signedQuotesFiles && signedQuotesFiles.length > 0) {
          const fileCreationPromises = signedQuotesFiles.map(
            (signedQuoteFile) =>
              createFileWithLinkedFile(
                signedQuoteFile.file_url as string,
                signedQuoteFile.file_name as string
              ).then((fileToSet) => {
                append({ file: fileToSet, linkedFileId: signedQuoteFile.id });
              })
          );

          // Attendre que toutes les promesses soient résolues
          await Promise.all(fileCreationPromises);
        }
      }
    }

    setIsPrefilled(true);
  };

  const onUploadDocument = async (
    file: File,
    originalLinkedFile: ILinkedFile,
    index: number
  ) => {
    setIndexOfDocumentLoading(index);
    const response = await postLinkedFile(file, originalLinkedFile);
    if (response) {
      setValue(`files[${index}].linkedFileId`, response.id);
    }
    setIndexOfDocumentLoading(undefined);
  };

  const onDeleteDocument = async (linkedFileId: number, index: number) => {
    setIndexOfDocumentLoading(index);
    const response = await deleteOneLinkedFile(linkedFileId);
    if (response) {
      setValue(`files[${index}]`, {
        file: undefined,
        linkedFileId: undefined,
      });
    }
    setIndexOfDocumentLoading(undefined);
  };

  const defaultDocument: ILinkedFile = defaultDocumentWithFileType(
    fileTypeEnum.DEVIS_SIGNE,
    worksiteData?.id || 0
  );

  useEffect(() => {
    updateButtonNextDisabled(!checkIfInfosAreFilled);
  }, [watch()]);

  useEffect(() => {
    preFillWorkInProgress();
  }, []);

  useEffect(() => {
    if (isPrefilled && fields && fields.length === 0) {
      append({ file: undefined, linkedFileId: undefined });
    }
  }, [isPrefilled]);

  return (
    <LayoutMarDetailsStep
      onValidate={handleValidateStep}
      buttonNextLoading={buttonNextLoading}
    >
      <div className="flex flex-col space-y-6">
        {formWorkInProgress(t, worksiteData as IWorksiteMar).map((form) => (
          <OneMarForm key={form.title} dataForm={form} />
        ))}
        <div className="flex flex-col space-y-4">
          <p className="font-medium text-[1.125rem]">
            {t(
              'mar.worksite_creation.worksite_completion.folder_details.work_in_progress.add_signed_quote'
            )}
          </p>
          {fields.map((field, index) => {
            if (indexOfDocumentLoading === index) {
              return (
                <LoaderFile key={`loader-file-${field.id}`} withoutLabel />
              );
            }
            return (
              <OneMarForm
                key={field.id}
                dataForm={{
                  fields: [
                    {
                      name: `files[${index}].file`,
                      linkedFileId: watch(`files[${index}].linkedFileId`),
                      type: InputTypes.DOCUMENT,
                      fullWidth: true,
                      defaultDocument,
                      onUploadDocument: (file, originalLinkedFile) =>
                        onUploadDocument(file, originalLinkedFile, index),
                      onDeleteDocument: (linkedFileId) => {
                        onDeleteDocument(linkedFileId, index);
                      },
                    },
                  ],
                }}
              />
            );
          })}

          <ButtonOpx
            label={`${t(
              'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.quote_section.add_another_quote'
            )}`}
            small
            onClick={() => append({ file: undefined, linkedFileId: undefined })}
            type="tierciary"
            addClass="text-[.875rem] w-full"
            disabled={indexOfDocumentLoading !== undefined}
          />
        </div>
      </div>
    </LayoutMarDetailsStep>
  );
}

export default WorkInProgress;
