/* eslint-disable no-nested-ternary */
import { ChevronLeftIcon, CrossIcon } from '@assets/images/svgComponents';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { backgroundBody, black, blueOpx } from '@assets/color';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { convertHexToRGBA } from '@utils/functions';
import { GlobalContext } from '@context/globalContext';
import { ColorCube } from './atomic/ColorCube';
import { ButtonOpx } from './atomic/ButtonOpx';

/**
 * @param {boolean} isOverflowAuto false = visible, true = auto
 */
interface IModalProps {
  title: string;
  children: JSX.Element;
  withArrowBack?: boolean;
  btnCancel?: boolean;
  textBtnCancel?: string;
  textBtnConfirm?: string;
  btnConfirmDisabled?: boolean;
  textBtnExport?: string;
  btnExportOnClick?: () => void;
  formId?: string;
  isLoading?: boolean;
  isLoadingBtnCancel?: boolean;
  addClassBtn?: string;
  closeModal?: boolean;
  backgroundTransparent?: boolean;
  sidebarVisible?: boolean;
  setIsModal?: Dispatch<SetStateAction<boolean>>;
  btnCancelIsIcon?: boolean;
  lightHeader?: boolean;
  onBackClick?: () => void;
  onConfirmClick?: () => void;
  onClickCancel?: () => void;
  width?: string;
  maxHeight?: string;
  buttonsPosition?: 'bottom' | 'top';
  isOverflowAuto?: boolean;
  adminModalNewUser?: boolean;
}

// MODAL COMPONENT SE METTANT AUTOMATIQUEMENT PAR DESSUS L'ECRAN AFFICHE AVEC UN BACKGROUND FAIBLE OPACITE
// LA MODAL S'UTILISE AINSI:
// const [isModal, setIsModal] = useState(false);
//   {isModal && (
//     <Modal
//       title="Je suis la modal"
//       btnCancel
//       textBtnConfirm="Confirmer"
//       setIsModal={setIsModal}
//       onConfirmClick={() => setIsModal(false)}
//     >
//       <div className="mt-5">
//         ICI AJOUTER LE CONTENU DE LA MODAL
//       </div>
//     </Modal>
//   )}

function Modal({
  title,
  children,
  withArrowBack,
  onBackClick,
  btnCancel,
  textBtnConfirm,
  btnConfirmDisabled,
  setIsModal,
  onConfirmClick,
  textBtnExport,
  btnExportOnClick,
  formId,
  isLoading,
  isLoadingBtnCancel,
  addClassBtn,
  textBtnCancel,
  closeModal,
  backgroundTransparent,
  sidebarVisible,
  onClickCancel,
  btnCancelIsIcon,
  lightHeader,
  width,
  buttonsPosition,
  maxHeight,
  isOverflowAuto,
  adminModalNewUser,
}: IModalProps): JSX.Element {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const { sidebarMenuIsOpen, route } = useContext(GlobalContext);

  const router = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const isLargeScreen = useMemo(() => windowWidth > 1024, [windowWidth]);
  const finalWidth = useMemo(() => {
    if (adminModalNewUser) {
      return 'auto';
    }
    return isLargeScreen ? width : '75%';
  }, [adminModalNewUser]);

  const modalPosition: string | number = useMemo(() => {
    if (sidebarVisible) {
      return sidebarMenuIsOpen ? '7.5%' : '2.5%';
    }
    return 0;
  }, [sidebarMenuIsOpen, sidebarVisible]);

  const buttons = (
    <div className="flex space-x-[1rem] w-full justify-end mt-0 pr-[1.5rem]">
      {textBtnExport && (
        <ButtonOpx
          onClick={btnExportOnClick}
          label={textBtnExport}
          type="primary"
          addClass="text-[1rem] !px-[3.5rem]"
          dataTestId="button_export"
        />
      )}
      {btnCancel && (
        <ButtonOpx
          onClick={() =>
            setIsModal
              ? setIsModal(false)
              : onClickCancel
              ? onClickCancel()
              : undefined
          }
          isLoading={isLoadingBtnCancel}
          label={textBtnCancel || t('buttons.cancel')}
          type="secondary"
          addClass="text-[1rem] !px-[3.5rem]"
          dataTestId="button_cancel"
        />
      )}
      {btnCancelIsIcon && (
        <ColorCube
          onClick={() =>
            setIsModal
              ? setIsModal(false)
              : onClickCancel
              ? onClickCancel()
              : undefined
          }
          color={blueOpx}
          numberOrIcon={<CrossIcon />}
          size="1.5rem"
          opacity
        />
      )}
      {closeModal && (
        <ButtonOpx
          onClick={() =>
            router(route.from !== location.pathname ? route.from : '/')
          }
          label={textBtnCancel || t('buttons.cancel')}
          type="secondary"
          addClass="text-[1rem] !px-[3.5rem] !mt-0"
        />
      )}
      {textBtnConfirm && (
        <ButtonOpx
          onClick={onConfirmClick}
          label={textBtnConfirm}
          disabled={btnConfirmDisabled}
          type="primary"
          addClass={['text-[1rem] !px-[3.5rem] !mt-0', addClassBtn].join(' ')}
          formId={formId}
          isLoading={isLoading}
          isSubmitBtn
          dataTestId="button_confirm"
        />
      )}
    </div>
  );

  useEffect(() => {
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth));
  }, []);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    // Nettoyage : réactiver le défilement lorsque le composant est démonté
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <div
      style={{
        zIndex: 10000,
        backgroundColor: backgroundTransparent
          ? convertHexToRGBA(black, 0.2)
          : backgroundBody,
      }}
      className="w-screen h-full fixed inset-0 flex items-center justify-center !mt-0"
    >
      <div
        style={{
          left: modalPosition,
          width: finalWidth,
          maxHeight,
          overflow: isOverflowAuto ? 'auto' : 'visible',
        }}
        className="relative bg-white h-auto rounded-md rounded-default border border-borderGrey"
      >
        <div
          className={`flex justify-between items-center w-full ${
            lightHeader ? '' : 'border-b border-b-borderGrey'
          } py-[1.5rem]`}
          style={
            buttonsPosition === 'bottom'
              ? { border: 0, paddingTop: '1.5rem', paddingBottom: '0.5rem' }
              : { paddingTop: '1.5rem', paddingBottom: '1.5rem' }
          }
        >
          <div className="flex space-x-[1rem] items-center w-full pl-[1.5rem]">
            {withArrowBack && (
              <ColorCube
                onClick={onBackClick ? () => onBackClick() : undefined}
                color={blueOpx}
                numberOrIcon={<ChevronLeftIcon />}
                size="1.5rem"
                opacity
              />
            )}
            <p className="text-[1.5rem] font-medium w-full text-black">
              {title}
            </p>
          </div>
          {buttonsPosition === 'top' && buttons}
        </div>
        <div className="px-[1.5rem] pb-[1.5rem]">{children}</div>
        {buttonsPosition === 'bottom' && (
          <div className="pb-[1.5rem]">{buttons}</div>
        )}
      </div>
    </div>
  );
}

export { Modal };

Modal.defaultProps = {
  withArrowBack: false,
  onBackClick: undefined,
  btnCancel: false,
  textBtnConfirm: undefined,
  btnConfirmDisabled: undefined,
  textBtnExport: undefined,
  btnExportOnClick: undefined,
  setIsModal: undefined,
  onConfirmClick: undefined,
  formId: undefined,
  isLoading: false,
  isLoadingBtnCancel: false,
  closeModal: false,
  textBtnCancel: undefined,
  backgroundTransparent: undefined,
  addClassBtn: '',
  sidebarVisible: false,
  onClickCancel: undefined,
  btnCancelIsIcon: false,
  lightHeader: undefined,
  width: '50%',
  maxHeight: undefined,
  buttonsPosition: 'top',
  isOverflowAuto: false,
  adminModalNewUser: false,
};
