/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
  useReactTable,
  Column,
  getCoreRowModel,
  flexRender,
  Row,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { ChevronLeftIcon } from '@assets/images/svgComponents';
import { backgroundGrey, grey, textGrey } from '@assets/color';
import { useEffect } from 'react';
import { ColorCube } from '@components/atomic/ColorCube';
import { v4 } from 'uuid';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';

export type IRow = Record<string, unknown>;
export type IColumn = Column<any>;

//
// Exemple d'utilisation :
//  import { List, IColumn, IRow } from '@components/List';
//  <List
//    columns={columns as IColumn[]}
//    data={sampleData}
//    callBackOnRowClick={(e: IRow) => console.debug('row clicked ===> ', e)}
//  />
//

interface IListProps {
  data: IRow[];
  columns: IColumn[];
  callBackOnRowClick?: CallableFunction;
  addClass?: string;
  loading?: boolean;
  hiddenColumns?: string[];
  hideArrow?: boolean;
  rowsPerPage?: number;
  type?: 'primary' | 'secondary';
  addStyleRow?: { [key: string]: string | number | null } | undefined;
  addStyleHeader?: { [key: string]: string | number | null } | undefined;
}

function ListTable({
  data,
  columns,
  callBackOnRowClick,
  addClass,
  loading,
  hiddenColumns,
  hideArrow,
  rowsPerPage,
  type,
  addStyleRow,
  addStyleHeader,
}: IListProps): JSX.Element {
  const { t } = useTranslation();

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  });

  const rowClass = `block sm:grid gap-4 p-[28px] bg-white ${
    !loading ? 'hover:font-semibold cursor-pointer' : ''
  }`;

  useEffect(() => {
    if (hiddenColumns && hiddenColumns?.length > 0) {
      table.getAllLeafColumns().map((column) => {
        column.toggleVisibility(!hiddenColumns.includes(column.id));
      });
    }
  }, [hiddenColumns]);

  return (
    <div
      className={[
        `border border-grey rounded-default list__container overflow-x-auto`,
        addClass,
      ].join(' ')}
      style={{ backgroundColor: type === 'secondary' ? backgroundGrey : '' }}
    >
      <div className="hidden sm:block list__header">
        {table.getHeaderGroups().map((headerGroup) => (
          <div
            key={headerGroup.id}
            className="block sm:grid gap-4 p-[28px]"
            style={{
              gridTemplateColumns: `repeat(${headerGroup.headers.length}, 1fr) 1.5rem`,
              ...addStyleHeader,
            }}
          >
            {headerGroup.headers.map((header) => (
              <div
                key={header.id}
                className="text-sm font-medium text-textGrey"
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </div>
            ))}
            <div />
          </div>
        ))}
      </div>
      <div className="list__body" data-test-id="list_body">
        {/* eslint-disable-next-line no-nested-ternary */}
        {!loading ? (
          table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
            table.getRowModel().rows.map((row: Row<IRow>, index) => {
              return (
                <div
                  key={row.id}
                  className={rowClass}
                  style={{
                    gridTemplateColumns: `repeat(${
                      table.getHeaderGroups()[0].headers.length
                    }, 1fr) 1.5rem`,
                    borderBottom:
                      index !== table.getRowModel().rows.length - 1
                        ? '1px solid #EEEEEE'
                        : undefined,
                    ...addStyleRow,
                  }}
                  onClick={() =>
                    callBackOnRowClick ? callBackOnRowClick(row) : null
                  }
                  data-test-id="list_row"
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <div
                        key={cell.id}
                        className="list__cell break-all text-sm text-black my-2 sm:my-0 flex flex-col justify-center"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </div>
                    );
                  })}
                  {!hideArrow && (
                    <div
                      className="list__cell text-sm text-black flex flex-col justify-center"
                      role="cell"
                    >
                      <ColorCube
                        size="1.5rem"
                        numberOrIcon={
                          <ChevronLeftIcon className="rotate-[180deg]" />
                        }
                        color={textGrey}
                        backgroundColor={grey}
                      />
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <div
              className="list_noElements flex items-center justify-center h-36 text-danger"
              data-test-id="no_result"
            >
              {t('list.no_result')}
            </div>
          )
        ) : (
          // Affiche le nombre de lignes avec skeleton loader en fonction de rowsPerPage
          [...Array(rowsPerPage)].map((index) => (
            <div
              key={v4()}
              className={rowClass}
              style={{
                gridTemplateColumns: `repeat(${
                  table.getHeaderGroups()[0].headers.length
                }, 1fr) 1.5rem`,
                borderBottom:
                  index !== (rowsPerPage as number) - 1
                    ? '1px solid #EEEEEE'
                    : undefined,
                ...addStyleRow,
              }}
            >
              {/* Le nombre de colonnes est défini en fonction du nombre de headers + 1 */}
              {[...Array(table.getHeaderGroups()[0].headers.length + 1)].map(
                () => (
                  <div
                    key={v4()}
                    className="list__cell text-sm font-normal text-black flex flex-col justify-center"
                    role="cell"
                  >
                    <LoaderSkeleton height="1.5rem" />
                  </div>
                )
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
}

ListTable.defaultProps = {
  callBackOnRowClick: null,
  addClass: '',
  loading: false,
  hiddenColumns: [],
  hideArrow: false,
  rowsPerPage: 5,
  type: 'primary',
  addStyleRow: '',
  addStyleHeader: '',
};

export { ListTable };
