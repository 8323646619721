import React, { useEffect, useState, useMemo } from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { InputDatePicker } from './InputDatepicker';
import { Calendar } from './Calendar';

interface IDatePickerProps {
  required: boolean;
  onChangeDate: (date: string) => void;
  label?: string;
  addClass?: string;
  error?: boolean;
  textError?: string;
  noDefaultDate?: boolean;
  defaultDate?: string;
  valid?: boolean;
  minDate?: string | Date;
  maxDate?: string | Date;
  dataTestId?: string;
  addClassToCalendar?: string;
  hideCalendar?: boolean | undefined;
  onSelect?: () => void;
  placeholder?: string;
}

function DatePicker({
  label,
  required,
  onChangeDate,
  addClass,
  error,
  textError,
  noDefaultDate,
  defaultDate,
  valid,
  minDate,
  maxDate,
  dataTestId,
  addClassToCalendar,
  hideCalendar,
  onSelect,
  placeholder,
}: IDatePickerProps) {
  const { i18n, t } = useTranslation();
  const [showCalendar, setShowCalendar] = useState(false);
  const [date, setDate] = useState(
    noDefaultDate || defaultDate === ''
      ? ''
      : new Date().toLocaleDateString(i18n.language, {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })
  );

  useEffect(() => {
    if (date !== '') {
      onChangeDate(date);
    }
  }, [date]);

  useMemo(() => {
    if (defaultDate) setDate(defaultDate);
  }, [defaultDate]);

  const handleSelectDate = (d: string) => {
    setDate(d);
    setShowCalendar(false);
  };
  const closeCalendar = () => {
    setShowCalendar(false);
  };

  const changeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.target.value;
    const onlyNumber = /^[0-9]*$/;

    const valueWithoutSlash = currentValue.replace(/\//g, '');
    if (currentValue.length < 11 && valueWithoutSlash.match(onlyNumber)) {
      if (currentValue.length === 2 || currentValue.length === 5) {
        setDate(`${currentValue}/`);
      } else {
        setDate(currentValue);
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const currentValue = e.currentTarget.value;

    if (e.key === 'Backspace') {
      // Vérifie si l'avant-dernier caractère est un '/'
      if (currentValue.slice(-2, -1) === '/') {
        setDate(currentValue.slice(0, -2)); // supprime deux caractères
        e.preventDefault();
      } else {
        setDate(currentValue.slice(0, -1)); // supprime un caractère
        e.preventDefault();
      }
    }
  };

  const convertToDate = (data: string | null | undefined): Date => {
    try {
      const d = String(data).split('/');
      return new Date(`${d[2]}/${d[1]}/${d[0]}`);
    } catch (e) {
      return new Date();
    }
  };

  const looksLikeADate = (data: string): boolean => {
    return data.includes('/') && data.length === 10;
  };

  useEffect(() => {
    if (hideCalendar !== undefined) {
      setShowCalendar(!hideCalendar);
    }
  }, [hideCalendar]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={['relative', addClass].join(' ')}
      onClick={(e) => e.stopPropagation()}
    >
      {label && (
        <p className="text-[.75rem] leading-3 text-textGrey mb-2">
          {label}{' '}
          <span>{`${required ? '' : `(${t('global.optional')})`}`}</span>
        </p>
      )}
      <InputDatePicker
        required={required}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeDate(e)}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
          handleKeyDown(e)
        }
        value={date.replace(/-/g, '/')}
        placeholder={placeholder}
        onClickCalendar={() => {
          setShowCalendar(!showCalendar);
          if (onSelect) onSelect();
        }}
        showCalendar={showCalendar}
        error={error}
        textError={textError}
        valid={valid}
        dataTestId={dataTestId}
        isSelected={!!(onSelect && !hideCalendar)}
      />

      {showCalendar && (
        <Calendar
          date={format(
            looksLikeADate(date) ? convertToDate(date) : new Date(),
            'yyyy-MM-dd'
          )}
          handleSelectDate={handleSelectDate}
          closeCalendar={closeCalendar}
          minDate={minDate}
          maxDate={maxDate}
          addClass={addClassToCalendar}
        />
      )}
    </div>
  );
}

export { DatePicker };

DatePicker.defaultProps = {
  addClass: '',
  label: '',
  error: false,
  textError: '',
  noDefaultDate: false,
  defaultDate: '',
  valid: false,
  minDate: undefined,
  maxDate: undefined,
  dataTestId: '',
  addClassToCalendar: '',
  hideCalendar: undefined,
  onSelect: undefined,
  placeholder: '',
};
