import LayoutMarDetailsStep from '@models/mar/components/worksiteCompletion/LayoutMarDetailsStep';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OneMarForm from '@models/mar/components/worksiteCompletion/OneMarForm';
import { useFormContext } from 'react-hook-form';
import {
  generateAdditionalCommitmentsCertificate,
  validateCeeAnah,
} from '@models/mar/apiRequests/worksiteCreationMarRequests';
import { STEPS_WORKSITE_MAR_COMPLETION } from '@models/mar/utils/enums';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import { InputTypes } from '@utils/utils';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { downloadWhenGenerated } from '@models/mar/utils/functions';
import { IWorksiteMar } from '@models/mar/utils/marTypes';

function CeeAnah() {
  const { t } = useTranslation();
  const {
    worksiteData,
    updateWorksiteData,
    updateStepCompletion,
    updateButtonNextDisabled,
  } = useContext(WorksiteCreationMarContext);

  const [buttonNextLoading, setButtonNextLoading] = useState(false);
  const [loadingGeneration, setLoadingGeneration] = useState(false);

  const { watch, setValue } = useFormContext();

  const reference = watch('reference');

  const handleValidateStep = async () => {
    setButtonNextLoading(true);
    const dataToSend = {
      reference,
    };
    const response = await validateCeeAnah(worksiteData?.id || 0, dataToSend);

    if (response) {
      updateWorksiteData((prev) => ({
        ...prev,
        ...response,
      }));
      updateStepCompletion(STEPS_WORKSITE_MAR_COMPLETION.APPLICATION_DEPOSIT);
    }
    setButtonNextLoading(false);
  };

  const preFillCeeAnahData = () => {
    if (worksiteData && worksiteData.reference) {
      setValue('reference', worksiteData?.reference);
    }
  };

  const generateAttestation = async () => {
    setLoadingGeneration(true);
    const dataToSend = {
      reference,
    };
    const response = await generateAdditionalCommitmentsCertificate(
      worksiteData?.id || 0,
      dataToSend
    );
    if (response && response.file_url) {
      downloadWhenGenerated(
        response.file_url,
        'attestation_engagements_complementaires.pdf'
      );
      updateWorksiteData((prev) => ({
        ...(prev as IWorksiteMar),
        linked_files: [...(prev as IWorksiteMar).linked_files, response],
      }));
    }
    setLoadingGeneration(false);
  };

  useEffect(() => {
    updateButtonNextDisabled(!reference);
  }, [watch()]);

  useEffect(() => {
    preFillCeeAnahData();
  }, []);

  return (
    <LayoutMarDetailsStep
      onValidate={handleValidateStep}
      buttonNextLoading={buttonNextLoading}
      customTitle={t(
        'mar.worksite_creation.worksite_completion.folder_details.cee_anah.title'
      )}
    >
      <div className="flex flex-col space-y-6">
        <OneMarForm
          dataForm={{
            fields: [
              {
                title: t(
                  'mar.worksite_creation.worksite_completion.folder_details.cee_anah.reference'
                ),
                name: 'reference',
                type: InputTypes.TEXT,
              },
            ],
          }}
        />
        <ButtonOpx
          label={`${t(
            'mar.worksite_creation.worksite_completion.folder_details.cee_anah.generate_additional_commitments_certificate'
          )}`}
          onClick={generateAttestation}
          isLoading={loadingGeneration}
          disabled={!reference}
        />
      </div>
    </LayoutMarDetailsStep>
  );
}

export default CeeAnah;
