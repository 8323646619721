import { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '@context/authContext';
import { login } from '@models/auth/apiRequests/authRequests';
import { InputText } from '@components/atomic/inputs/InputText';
import { GlobalContext } from '@context/globalContext';
import { AuthLayout } from '@pages/auth/AuthLayout';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { yupResolver } from '@hookform/resolvers/yup';
import { getLoginSchema } from '@utils/validationSchemas';
import { AUTH_ROUTES } from '@utils/routesUrls';
import { placeholderExample } from '@utils/utils';

function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from || '/';
  const { updateToken } = useContext(AuthContext);
  const { updateLayoutIsLoading } = useContext(GlobalContext);
  const [loading, setLoading] = useState<boolean>(false);

  interface FormInputs {
    email: string;
    password: string;
  }

  const methods = useForm<FormInputs>({
    resolver: yupResolver(getLoginSchema(t)),
  });

  const placeholders = placeholderExample(t);

  const { handleSubmit } = methods;

  const authenticate = async (formDatas: FieldValues) => {
    setLoading(true);
    const isLogged = await login(
      {
        email: formDatas.email,
        password: formDatas.password,
      },
      updateToken
    );
    setLoading(false);
    if (isLogged) {
      updateLayoutIsLoading(true);
      navigate(from, { replace: true });
    }
  };

  useEffect(() => {
    updateLayoutIsLoading(false);
    if (localStorage.getItem('user_view_switched')) {
      localStorage.removeItem('user_view_switched');
    }
  }, []);

  return (
    <AuthLayout>
      <div className="w-full lg:max-w-[35rem]">
        <p className="mb-6 text-[1.6875rem] font-medium leading-[2.5rem]">
          {t('auth.form_title')}
        </p>
        <FormProvider {...methods}>
          <form id="form-login" onSubmit={handleSubmit(authenticate)}>
            <div className="space-y-6">
              <div className="space-y-4">
                <p className="text-base font-medium leading-5">
                  {t('auth.registration.forms.email')}
                </p>
                <InputText
                  id="email"
                  name="email"
                  placeholder={placeholders.EMAIL}
                  required
                  hideLabel
                  data-test-id="email"
                />
              </div>
              <div className="space-y-4">
                <p className="text-[1rem] font-medium leading-5">
                  {t('forms.password.placeholder')}
                </p>
                <InputText
                  id="password"
                  name="password"
                  placeholder={placeholders.PASSWORD}
                  required
                  type="password"
                  hideLabel
                  data-test-id="password"
                />
              </div>
              <Link
                to={AUTH_ROUTES.FORGOT_PASSWORD}
                className="text-blueOpx block w-full text-sm text-right"
              >
                {t('auth.forget_pwd.tooltip')}
              </Link>
              <ButtonOpx
                label={t('auth.login')}
                formId="form-login"
                isSubmitBtn
                disabled={loading}
                dataTestId="login_button"
                addClass="w-full"
                isLoading={loading}
              />
              <p className="text-center">
                {t('auth.registration.already_registered.no_account')}{' '}
                <Link
                  to={AUTH_ROUTES.REGISTER}
                  className="text-blueOpx"
                  data-test-id="already_registered_link"
                >
                  {t('auth.registration.already_registered.link')}
                </Link>
              </p>
            </div>
          </form>
        </FormProvider>
      </div>
    </AuthLayout>
  );
}

export { Login };
