import { InputText } from '@components/atomic/inputs/InputText';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { ChangeEvent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { decimalRegex, decimalRegexWithoutNegative } from '@utils/regex';

const formatDecimalInput = (input: string) => {
  // use commas
  const isValidDecimal = decimalRegexWithoutNegative.test(input);
  return isValidDecimal ? input : null;
};

function InformationMaterial() {
  const { t } = useTranslation();
  const {
    updatePayloadSizingNote,
    payloadSizingNote,
    worksiteDatas,
    readOnly,
  } = useContext(WorksiteCreationContext);

  const [inputValueShutdown, setInputValueShutdown] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');

  const operationWithMaterial = worksiteDatas.worksites_operations.find(
    (operation) =>
      operation.operation.code === 'BAR-TH-159' ||
      operation.operation.code === 'BAR-TH-171' ||
      operation.operation.code === 'BAR-TH-172' ||
      operation.operation.code === 'BAR-TH-113'
  );

  let materialName = '';

  if (
    operationWithMaterial &&
    operationWithMaterial.material &&
    operationWithMaterial.material.caracteristics
  ) {
    const caracteristics = JSON.parse(
      operationWithMaterial.material.caracteristics
    );
    materialName = caracteristics.mark ? caracteristics.mark.name : '';
  }

  return (
    <div className="mt-[3rem]">
      {/* Titre et sous-titre */}
      <p className="font-medium">
        {t(
          'worksite_creation.create_worksite.sizing_note.information_material'
        )}
      </p>
      <p className="text-[.875rem]">
        {`${t(
          'worksite_creation.create_worksite.sizing_note.information_material_subtitle'
        )} ${materialName}.`}
      </p>
      {/* contenu du formulaire:  */}
      <div className="flex items-center space-x-[.5rem] mt-[2rem]">
        <InputText
          placeholder={t(
            'worksite_creation.create_worksite.sizing_note.shutdown_temperature'
          )}
          id="shutdown_temperature"
          name="shutdown_temperature"
          value={inputValueShutdown}
          onChange={(value: string | ChangeEvent<HTMLInputElement>) => {
            const newValue =
              typeof value === 'string' ? value : value.target.value;
            const validNumber = decimalRegex.test(newValue);
            if (validNumber || newValue === '') {
              setInputValueShutdown(newValue); // Update the local input state
              updatePayloadSizingNote({
                ...payloadSizingNote,
                heat_pump_shutdown_temperature:
                  newValue === '' ? null : parseFloat(newValue), // Convert to number or null
              });
            }
          }}
          required
          dataTestId="input_text_shutdown_temperature"
          disabled={readOnly}
          addClassToInput={readOnly ? 'bg-backgroundBody' : ''}
        />
        <InputText
          placeholder={t(
            'worksite_creation.create_worksite.sizing_note.power_or_stop'
          )}
          id="power_or_stop"
          name="power_or_stop"
          value={inputValue}
          onChange={(value: string | ChangeEvent<HTMLInputElement>) => {
            const newValue =
              typeof value === 'string' ? value : value.target.value;

            // Format and validate the input
            const formattedValue = formatDecimalInput(newValue);

            if (formattedValue !== null) {
              setInputValue(formattedValue);
              // Replace commas for saving
              const convertedValue = formattedValue.replace(',', '.');
              updatePayloadSizingNote({
                ...payloadSizingNote,
                power_temperature:
                  convertedValue === '' ? null : parseFloat(convertedValue),
              });
            }
          }}
          required
          dataTestId="input_text_power_or_stop"
          disabled={readOnly}
          addClassToInput={readOnly ? 'bg-backgroundBody' : ''}
        />
      </div>
      <div className="flex items-center space-x-[.5rem] mt-[.5rem]">
        <InputText
          placeholder={t(
            'worksite_creation.create_worksite.sizing_note.heat_pump_electrical_resistance'
          )}
          dataTestId="input_text_heat_pump_electrical_resistance"
          id="heat_pump_electrical_resistance"
          name="heat_pump_electrical_resistance"
          onChange={(value: string | React.ChangeEvent<HTMLInputElement>) => {
            let inputValueElectricalResistance: string;

            if (typeof value === 'string') {
              inputValueElectricalResistance = value;
            } else {
              inputValueElectricalResistance = value.target.value;
            }

            // Prevent negative and decimal numbers
            if (
              inputValueElectricalResistance.includes('-') ||
              inputValueElectricalResistance.includes('.')
            ) {
              return;
            }

            updatePayloadSizingNote({
              ...payloadSizingNote,
              electrical_resistance_power:
                inputValueElectricalResistance === ''
                  ? null
                  : Number(inputValueElectricalResistance),
            });
          }}
          value={
            payloadSizingNote.electrical_resistance_power !== undefined &&
            payloadSizingNote.electrical_resistance_power !== null
              ? payloadSizingNote.electrical_resistance_power
              : ''
          }
          disabled={readOnly}
          required
          addClassToInput={readOnly ? 'bg-backgroundBody' : ''}
        />
        <InputText
          placeholder={t(
            'worksite_creation.create_worksite.sizing_note.other_heating_supplement'
          )}
          id="other_heating_supplement"
          dataTestId="input_text_other_heating_supplement"
          name="other_heating_supplement"
          onChange={(value: string | React.ChangeEvent<HTMLInputElement>) => {
            let inputValueOtherHeating: string;

            if (typeof value === 'string') {
              inputValueOtherHeating = value;
            } else {
              inputValueOtherHeating = value.target.value;
            }

            // Prevent negative and decimal numbers
            if (
              inputValueOtherHeating.includes('-') ||
              inputValueOtherHeating.includes('.')
            ) {
              return;
            }

            updatePayloadSizingNote({
              ...payloadSizingNote,
              other_heating:
                inputValueOtherHeating === ''
                  ? null
                  : Number(inputValueOtherHeating),
            });
          }}
          value={
            payloadSizingNote.other_heating !== undefined &&
            payloadSizingNote.other_heating !== null
              ? payloadSizingNote.other_heating
              : ''
          }
          required
          disabled={readOnly}
          addClassToInput={readOnly ? 'bg-backgroundBody' : ''}
        />
      </div>
    </div>
  );
}

export { InformationMaterial };
