/* eslint-disable no-case-declarations */
/* eslint-disable no-nested-ternary */
import { useContext, useEffect, useState } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';

import { InputImage } from '@components/atomic/inputs/InputImage';
import { InputText } from '@components/atomic/inputs/InputText';

import { IInfosLine } from 'types/globalTypes';
import {
  emailValidationRegex,
  phoneNumberRegex,
  websiteRegex,
} from '@utils/regex';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { AddressEdit } from '@components/informations/AddressEdit';
import { ColorCube } from '@components/atomic/ColorCube';
import { blueOpx } from '@assets/color';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@components/atomic/inputs/datepicker/Datepicker';
import { storeProjectedEnd } from '@models/worksites/apiRequests/worksitesRequests';
import { dateToDDMMYYY, toAPIDateStr } from '@utils/format';
import { WorksitesContext } from '@models/worksites/utils/worksitesContext';
import { WORKSITE_STATUS } from '@models/worksites/utils/enums';

// import { useOptionalFormContext } from '@utils/utils';

interface IInputInformationEditProps {
  data: IInfosLine;
  value: any;
  setValue: UseFormSetValue<FieldValues>;
  isSubmitted?: boolean;
  refresh?: () => Promise<void>;
}

function InputInformationEdit({
  data,
  value,
  setValue,
  isSubmitted,
  refresh,
}: IInputInformationEditProps) {
  const {
    name,
    placeholder,
    type,
    required,
    title,
    hideOnEdit,
    showIconOnEdit,
    addClass,
    hideLabel,
  } = data;
  /* const formContext = useOptionalFormContext();
        let watchValue: any;
        if (formContext) {
          const {
            watch,
          } = formContext;
          watchValue = watch(name);
        } */
  const { worksiteDetails } = useContext(WorksitesContext);

  const { t } = useTranslation();
  const [isValid, setIsValid] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (name && type && type === 'surface' && value)
      setValue(name, parseInt(value, 10));
  }, [type]);

  const renderInput = () => {
    switch (type) {
      case 'image':
        return (
          <InputImage
            data={data}
            setValue={setValue}
            imageText={data.imageText}
          />
        );
      case 'address':
        return (
          <AddressEdit
            name={name}
            label={placeholder || title}
            setValue={setValue}
            value={value}
            addClass={addClass}
          />
        );
      case 'phone':
        return (
          <InputText
            typeNumber
            id={name}
            name={name}
            placeholder={placeholder || title}
            required={required}
            rules={{
              pattern: {
                value: phoneNumberRegex,
                message: t('forms.phone.error_pattern') || '',
              },
            }}
            label={hideLabel ? undefined : placeholder || title}
            addClass={addClass}
            dataTestId="input_phone"
          />
        );
      case 'number':
        return (
          <InputText
            typeNumber
            id={name}
            name={name}
            placeholder={placeholder || title}
            required={required}
            label={hideLabel ? undefined : placeholder || title}
            addClass={addClass}
          />
        );
      case 'website':
        return (
          <InputText
            id={name}
            name={name}
            placeholder={placeholder || title}
            rules={{
              pattern: {
                value: websiteRegex,
                message: t('forms.website.error') || '',
              },
            }}
            label={hideLabel ? undefined : placeholder || title}
            addClass={addClass}
            dataTestId="input_website"
          />
        );
      case 'email':
        return (
          <InputText
            id={name}
            name={name}
            placeholder={placeholder || title}
            required={required}
            rules={{
              pattern: {
                value: emailValidationRegex,
                message: t('forms.email.error_pattern') || '',
              },
            }}
            label={hideLabel ? undefined : placeholder || title}
            addClass={addClass}
            dataTestId="input_mail"
          />
        );
      case 'select':
        return (
          <InputSelect
            dataArrayString={data.list || []}
            onSelect={(selectedItem: string) => {
              setValue(name, {
                selectedItem,
                fullItem: data?.fullItem,
              });
            }}
            placeholder={placeholder}
            valueInput={value}
            label={hideLabel ? undefined : title}
            addClass={addClass}
            error={data.error}
            defaultSelected={data.defaultSelected}
          />
        );
      case 'projectedEnd':
        const handleChangeProjectedEnd = async (date: string) => {
          const newDate = toAPIDateStr(date);

          if (newDate !== data.defaultDate) {
            await storeProjectedEnd(Number(data.worksiteId), [
              { id: data.id, projected_end: newDate },
            ]);

            if (refresh) refresh();
          }
        };

        if (worksiteDetails.status === WORKSITE_STATUS.WORKSITE_IN_PROGRESS)
          return (
            <div>
              <div className="text-[.75rem] leading-[.75rem] text-textGrey mb-2">
                {data.title}
              </div>
              {!worksiteDetails.worksites_operations.some(
                (operation) => !operation.projected_end
              ) ? (
                <div className="font-[500] text-[.875rem] leading-[1rem]">
                  {dateToDDMMYYY(data.defaultDate)}
                </div>
              ) : (
                <DatePicker
                  required={false}
                  onChangeDate={(e) => handleChangeProjectedEnd(e)}
                  noDefaultDate
                />
              )}
            </div>
          );
        return <div />;
      default:
        return (
          <InputText
            id={name}
            name={name}
            placeholder={placeholder || title}
            required={required}
            label={hideLabel ? undefined : placeholder || title}
            addClass={addClass}
            type={type}
            dataTestId="input_text"
            valid={isSubmitted ? Boolean(value || isValid) : isValid}
            error={
              isSubmitted
                ? value
                  ? undefined
                  : !isValid
                : isValid !== undefined
                ? !isValid
                : undefined
            }
            onChange={(e) => {
              setIsValid(required ? Boolean(e) : true);
            }}
          />
        );
    }
  };

  return (
    <div className="flex-col items-center space-y-[.25rem]">
      {!hideOnEdit &&
        (showIconOnEdit ? (
          <div className="flex align-start">
            <ColorCube
              size="2rem"
              numberOrIcon={data.icon}
              color={blueOpx}
              opacity
              addClass="mr-4"
            />
            {renderInput()}
          </div>
        ) : (
          renderInput()
        ))}
    </div>
  );
}

InputInformationEdit.defaultProps = {
  isSubmitted: undefined,
  refresh: undefined,
};
export { InputInformationEdit };
