import { Card } from '@components/Card';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { FormProvider, useForm } from 'react-hook-form';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ContractCreationContext } from '@models/contractCreation/utils/contractCreationContext';
import { InputText } from '@components/atomic/inputs/InputText';
import { alphanumericRegex, siretRegex } from '@utils/regex';
import { setContactInfo } from '@models/contractCreation/utils/newContractHelper';
import {
  checkSiretAides,
  isValidSignataire,
} from '@models/contractCreation/utils/functions';
import { ContactFields } from '@models/contractCreation/components/steps/ContactFields';
import {
  ContractTypes,
  CreationSteps,
} from '@models/contractCreation/utils/enums';
import { toast } from 'react-toastify';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { GlobalContext } from '@context/globalContext';
import { ENTITY_TYPES } from '@utils/roles';
import { Checkbox } from '@components/atomic/inputs/controls/Checkbox';
import { getSignatories } from '@models/entities/apiRequests/entitiesRequests';
import { IAccountSignatory } from '@models/users/utils/userTypes';
import { useTranslation } from 'react-i18next';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { placeholderExample } from '@utils/utils';

function StepPartner() {
  const { t } = useTranslation();
  const {
    changeStep,
    activeStep,
    amo,
    entityTo,
    updateAmo,
    updateEntityTo,
    intermediaryBusiness,
    updatePartnerType,
    contractType,
    partnerType,
  } = useContext(ContractCreationContext);

  const { globalEnum } = useContext(GlobalContext);
  const [loadingSiret, setLoadingSiret] = useState<boolean>(false);
  // const [isSameContact, setIsSameContact] = useState<boolean>(true);
  const [isContact, setIsContact] = useState<boolean>(false);
  const [isSignatories, setIsSignatories] = useState<boolean>(false);
  const [signatories, setSignatories] = useState<IAccountSignatory[]>([]);
  const [isLoadingSignatories, setIsLoadingSignatories] =
    useState<boolean>(true);
  const [errorSiret, setErrorSiret] = useState<boolean>(false);
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [selectedSignatory, setSelectedSignatory] = useState<{
    id: number;
    name: string;
  }>({ id: 0, name: '' });

  const placeholders = placeholderExample(t);

  const entity = useMemo(
    () => (activeStep === CreationSteps.AMO ? amo : entityTo),
    [activeStep, entityTo, amo]
  );

  const setEntity = useMemo(
    () => (activeStep === CreationSteps.AMO ? updateAmo : updateEntityTo),
    [activeStep]
  );

  const methods = useForm();
  const { watch, setValue } = methods;
  const formValues = watch();

  const onClickNext = useCallback(async () => {
    const contactInfos = entity.signataire
      ? { ...entity.signataire }
      : entity.contact;

    // check email add
    setEntity({ ...entity, contact: contactInfos });

    if (activeStep === CreationSteps.AMO) {
      // check email
    }

    const signataireEmail = formValues.signataireEmail;
    if (signatories.length > 0 && signataireEmail && signataireEmail !== '') {
      const res: IAccountSignatory[] = await getSignatories(
        Number(entity.id),
        signataireEmail
      );

      if (res && res.length > 0) {
        // Vérifiez si l'e-mail existe déjà parmi les signataires actuels
        const emailExists = res.some(
          (signatory: IAccountSignatory) => signatory.email === signataireEmail
        );

        if (emailExists) {
          toast.error(
            t('contract.signatory_email_exist', {
              signatory: `${res[0].firstname} ${res[0].lastname}`,
            })
          );
        } else {
          changeStep('next');
        }
      } else {
        changeStep('next');
      }
    } else {
      changeStep('next');
    }
  }, [entity, formValues]);

  const fetchSignatories = useCallback(async () => {
    setIsSignatories(false);
    setIsLoadingSignatories(true);
    if (entity.id !== null) {
      const resSignatories = await getSignatories(Number(entity.id));

      if (resSignatories.length > 0) {
        setSignatories(resSignatories);
        setIsSignatories(true);
        // Set selected signatory based on entity id
        const signatory = resSignatories.find(
          (s: IAccountSignatory) => s.entity_id === entity.id
        );
        if (signatory) {
          setSelectedSignatory({
            id: signatory.id,
            name: `${signatory.firstname} ${signatory.lastname}`,
          });
          setValue('signatory_id', signatory.id);
          setContactInfo('signatory_id', signatory.id, setEntity);
        }
      }
    }
    setIsLoadingSignatories(false);
  }, [entity, setEntity, setValue]);

  const onChangeSiret = (value: string) => {
    setContactInfo('siret', value, setEntity);

    if (value !== '') {
      const siretList =
        activeStep === CreationSteps.AMO
          ? [entityTo.siret, intermediaryBusiness.siret]
          : [amo.siret, intermediaryBusiness.siret];

      if (siretList.includes(value)) {
        toast.error(t('contract.same_siret'));
        setErrorSiret(true);
      } else {
        setErrorSiret(false);
        checkSiretAides(value, setLoadingSiret, setEntity);
      }
    }
  };

  const onChangeEmail = (
    value: string,
    contactType: 'signataire' | 'contact'
  ) => {
    if (value !== '') {
      const otherContact =
        contactType === 'signataire' ? 'contact' : 'signataire';

      const emailList =
        activeStep === CreationSteps.AMO
          ? [
              entityTo[contactType]?.email,
              entityTo[otherContact]?.email,
              intermediaryBusiness[contactType]?.email,
              intermediaryBusiness[otherContact]?.email,
            ]
          : [
              amo[contactType]?.email,
              amo[otherContact]?.email,
              intermediaryBusiness[contactType]?.email,
              intermediaryBusiness[otherContact]?.email,
            ];

      if (emailList.includes(value)) {
        toast.error(t('contract.same_email'));
        setErrorEmail(true);
      } else {
        setErrorEmail(false);
      }
    }
  };

  const partnerTypes = useMemo(() => {
    if (entity.entityTypes?.length === 1) {
      updatePartnerType(entity.entityTypes[0]);
      return [globalEnum.entity_type[entity.entityTypes[0]]];
    }

    return Object.entries(globalEnum.entity_type)
      .filter((partner) =>
        [ENTITY_TYPES.OBLIGE, ENTITY_TYPES.DELEGATAIRE].includes(
          Number(partner[0])
        )
      )
      .map((p) => p[1]);
  }, [globalEnum, entity]);

  const displayContactFields = () => {
    setValue('signatory_id', undefined);
    setValue('signataireFirstname', '');
    setValue('signataireLastname', '');
    setValue('signataireFunction', '');
    setValue('signatairePhone', '');
    setValue('signataireEmail', '');
    if (isContact && selectedSignatory.id !== 0) {
      setContactInfo('signatory_id', selectedSignatory.id, setEntity);
    } else {
      setContactInfo('signatory_id', '', setEntity);
    }
    setIsContact((prevState) => !prevState);
    setContactInfo('signataire', '', setEntity);
  };

  const getName = (sign: IAccountSignatory) =>
    `${sign.firstname} ${sign.lastname}`;

  /* const getSelectedSignatory = () => {
    const idSignatoryToUse = entity.id;
    const signatory = signatories.find(
      (elt) => elt.entity_id === idSignatoryToUse
    );

    if (!signatory) return '';

    return getName(signatory);
  }; */

  const signatoriesArrayList = useMemo(
    () => signatories.map((elt) => getName(elt)),
    [signatories]
  );

  useEffect(() => {
    switch (contractType) {
      case ContractTypes.MANDAT:
      case ContractTypes.MANDAT_NON_DEPOSANT:
        updatePartnerType(ENTITY_TYPES.MANDATAIRE);
        setTitle(t('contract.information_representative') || '');
        break;
      case ContractTypes.CONVENTION:
        updatePartnerType(ENTITY_TYPES.INSTALLATEUR);
        setTitle(t('contract.installer_infos') || '');
        break;
      case ContractTypes.TRIPARTITE:
        updatePartnerType(ENTITY_TYPES.AMO);
        if (activeStep === CreationSteps.AMO)
          setTitle(t('contract.amo_infos') || '');
        else setTitle(t('contract.beneficiary_infos') || '');

        break;
      default:
        break;
    }

    fetchSignatories();
  }, [entity.id, contractType, activeStep]);

  useEffect(() => {
    if (formValues.signataireEmail && formValues.signataireEmail !== '') {
      setIsContact(true);
    }
  }, [formValues]);

  return (
    <Card
      title={title}
      actionButtons={
        <div className="flex gap-3">
          <ButtonOpx
            type="secondary"
            label={`${t('global.back')}`}
            onClick={() => changeStep('back')}
          />
          <ButtonOpx
            type="primary"
            label={`${t('buttons.next')}`}
            onClick={onClickNext}
            disabled={
              partnerType < 1 ||
              errorEmail ||
              errorSiret ||
              (activeStep !== CreationSteps.BENEFICIARY &&
                !isValidSignataire(
                  entity,
                  isSignatories,
                  !!formValues.signatory_id || !!entity?.signatory_id
                ))
            }
            dataTestId="button_next"
          />
        </div>
      }
    >
      <FormProvider {...methods}>
        <form>
          <p className="w-full font-medium text-[1rem] mb-[1.5rem]">{`${t(
            'partners.general_infos'
          )}`}</p>

          <div className="flex flex-col gap-3">
            <div className="flex gap-3 w-full">
              <InputText
                id="company_name"
                name="company_name"
                label={`${t('partners.company_name')}`}
                placeholder={t('global.text_placeholder')}
                onChange={(e) =>
                  setContactInfo('company_name', String(e), setEntity)
                }
                required
                valid={entity.company_name !== ''}
                value={entity.company_name}
                error={!alphanumericRegex.test(entity.company_name)}
                disabled={entity.siret?.length === 14 || loadingSiret}
                loading={loadingSiret}
                spinnerLoader
              />
              <InputText
                typeNumber
                id="siret"
                name="siret"
                label={`${t('partners.siret')}`}
                placeholder={placeholders.SIRET}
                onChange={(e) => onChangeSiret(String(e))}
                required
                value={entity.siret}
                valid={entity.siret?.length === 14}
                error={entity.siret !== '' && !siretRegex.test(entity.siret)}
                disabled={loadingSiret}
                loading={loadingSiret}
                spinnerLoader
              />
            </div>

            <div className="flex gap-3">
              <InputText
                id="address"
                name="address"
                label={`${t('partners.head_office_address')}`}
                placeholder={placeholders.ADDRESS}
                required
                disabled={entity.siret?.length === 14 || loadingSiret}
                value={
                  entity.address.address !== ''
                    ? ` ${entity.address.address}, ${entity.address.postal_code} ${entity.address.city}`
                    : ''
                }
                loading={loadingSiret}
                valid={entity.address.city !== ''}
                addClass="w-full"
                spinnerLoader
              />
              {[ContractTypes.VENTE, ContractTypes.DELEGATION].includes(
                contractType
              ) && (
                <InputSelect
                  placeholder={t('global.choose')}
                  label="Type de partenaire"
                  required
                  dataArrayString={partnerTypes}
                  onSelect={(e) => {
                    const selectedType = Object.entries(
                      globalEnum.entity_type
                    ).find((et) => et[1] === e);

                    if (selectedType)
                      updatePartnerType(Number(selectedType[0]));
                  }}
                  defaultSelected={globalEnum.entity_type[partnerType]}
                  addClass="w-full"
                  disabled={entity.entityTypes?.length === 1}
                  dataTestIdSelect="select_partner_type"
                  dataTestIdOptions="partner_type"
                />
              )}
            </div>
          </div>

          {/* display contact fields only if signatories empty */}
          <div className="flex flex-col gap-3 mt-[0.5rem]">
            <div className="w-full">
              <p className="w-full font-medium text-[1rem] mt-[1.0rem]">{`${t(
                `contract.contact_signataire`
              )}`}</p>
              <p className="w-full text-[0.875rem] mb-[1.0rem]">{`${t(
                `contract.contact_signataire_description`
              )}`}</p>
              {isLoadingSignatories && (
                <div className="w-1/2">
                  <LoaderSkeleton height="2.5rem" />
                </div>
              )}
              {isSignatories && entity && (
                <>
                  <div className="w-[50%]">
                    <InputSelect
                      resetValue={!formValues.signatory_id}
                      dataArrayString={signatoriesArrayList}
                      placeholder={t('contract.signatory')}
                      onSelect={(e) => {
                        const selectedSignatoryItem = signatories.find(
                          (elt) => `${elt.firstname} ${elt.lastname}` === e
                        );
                        if (selectedSignatoryItem) {
                          setValue('signatory_id', selectedSignatoryItem.id);
                          setContactInfo(
                            'signatory_id',
                            selectedSignatoryItem.id,
                            setEntity
                          );
                          setSelectedSignatory({
                            id: selectedSignatoryItem.id,
                            name: e,
                          });
                        }
                      }}
                      dataTestIdSelect="select_signatories"
                      dataTestIdOptions="signatories"
                      required
                      disabled={isContact}
                      valueInput={selectedSignatory.name}
                      defaultSelected={selectedSignatory.name}
                    />
                  </div>
                  <div className="w-full flex gap-3 mt-[1.0rem] mb-[1.0rem] text-[0.875rem]">
                    <div> {t('contract.signatories_not_in_list')}</div>{' '}
                    <Checkbox
                      label=""
                      checked={Boolean(isContact)}
                      onCheck={displayContactFields}
                      width="1rem"
                    />
                  </div>
                </>
              )}

              {(!isSignatories || isContact) && !isLoadingSignatories && (
                <ContactFields
                  updateInfosPartner={setEntity}
                  contactType="signataire"
                  required
                  infosPartner={entity}
                  onCheckEmail={(email) => onChangeEmail(email, 'signataire')}
                  errorEmail={errorEmail}
                />
              )}
            </div>
          </div>
        </form>
      </FormProvider>
    </Card>
  );
}

export { StepPartner };
