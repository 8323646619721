import { IDataMarField, IDataMarForm } from '@models/mar/utils/marTypes';
import DynamicInputEdit from '@components/atomic/inputs/DynamicInputEdit';
import React, { useContext } from 'react';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import { STEPS_WORKSITE_MAR_COMPLETION } from '@models/mar/utils/enums';
import AddressMar from '@models/mar/components/worksiteCompletion/stepsCompletion/housing/AddressMar';
import { useFormContext } from 'react-hook-form';
import { InputTypes } from '@utils/utils';
import ChoiceMar from '@models/mar/components/ChoiceMar';
import { FileTypeEnum } from '@models/worksiteCreation/utils/enums';
import { UploadButton } from '@components/documents/UploadButton';
import { useTranslation } from 'react-i18next';
import BoolMar from '@models/mar/components/BoolMar';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { TextWithCheckboxOrToggle } from '@components/atomic/inputs/controls/TextWithCheckboxOrToggle';
import RadioCivility from '@models/mar/components/worksiteCompletion/stepsCompletion/mandates/RadioCivility';
import { ILinkedFile } from '@globalTypes/globalTypes';

interface IOneMarFormProps {
  dataForm: IDataMarForm;
}

function OneMarForm({ dataForm }: IOneMarFormProps) {
  const { t } = useTranslation();
  const { title, subtitle, fields } = dataForm;
  const { stepCompletion } = useContext(WorksiteCreationMarContext);

  const { watch, setValue } = useFormContext();

  const renderField = (field: IDataMarField) => {
    const defaultValue = field.defaultValue || field.optionSelected?.label;
    if (!watch(field.name) && field.optionSelected) {
      setValue(field.name, field.optionSelected.value);
    }
    if (
      field.type === InputTypes.CHECK_BOOL ||
      field.type === InputTypes.CHECK_TOGGLE
    ) {
      return (
        <TextWithCheckboxOrToggle
          label={field.title || ''}
          id={field.name}
          onCheck={(isChecked) => setValue(field.name, isChecked)}
          checked={!!watch(field.name)}
          checkOrTogglePosition={field.checkOrTogglePosition || 'right'}
          type={field.type === InputTypes.CHECK_TOGGLE ? 'toggle' : 'checkbox'}
        />
      );
    }

    if (field.type === InputTypes.BOOL) {
      return <BoolMar fieldName={field.name} question={field.title || ''} />;
    }

    if (field.type === InputTypes.CHOICE) {
      return <ChoiceMar key={field.name} field={field} />;
    }

    if (field.type === InputTypes.DOCUMENT) {
      return (
        <UploadButton
          label={field.title || undefined}
          name={
            watch(field.name) ? t('global.document_change') : t('buttons.add')
          }
          onUpload={(file: File, originalLinkedFile: ILinkedFile) => {
            setValue(field.name, file);
            if (field.onUploadDocument) {
              field.onUploadDocument(file, originalLinkedFile);
            }
          }}
          onDelete={() => {
            if (field.onDeleteDocument) {
              field.onDeleteDocument(field.linkedFileId || 0);
            } else {
              setValue(field.name, undefined);
            }
          }}
          fileType={field.fileType || FileTypeEnum.CONTRAT}
          withDisplayFileUpload
          color={watch(field.name) ? 'grey' : 'blue'}
          defaultFileUploaded={watch(field.name)}
          placeholder={field.placeholder}
          document={field.defaultDocument}
        />
      );
    }

    if (field.type === InputTypes.SELECT && field.disabled) {
      return (
        <InputSelect
          key={field.name}
          placeholder=""
          label={`${t(
            'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.initial_situation.performance_energetic_class'
          )}`}
          disabled
          required
        />
      );
    }

    if (field.type === InputTypes.RADIO_CIVILITY) {
      return <RadioCivility key={field.name} fieldName={field.name} />;
    }

    return (
      <DynamicInputEdit
        isEditMode
        label={field.title || ''}
        name={field.name}
        inputType={field.type}
        options={field.options}
        placeholder={field.placeholder}
        initialValue={defaultValue}
        minDate={field.minDate}
        maxDate={field.maxDate}
        required
        noUnregister
      />
    );
  };

  return (
    <div className="flex flex-col space-y-4">
      {title && (
        <div>
          <p className="font-medium text-[1.125rem]">{title}</p>
          {subtitle && <p className="text-[.875rem] pt-2">{subtitle}</p>}
        </div>
      )}
      {stepCompletion === STEPS_WORKSITE_MAR_COMPLETION.TYPOLOGY_OF_HOUSING && (
        <AddressMar />
      )}
      <div className="grid grid-cols-2 gap-4 items-center">
        {fields.map((field) => (
          <div key={field.name} className={field.fullWidth ? 'col-span-2' : ''}>
            {renderField(field)}
          </div>
        ))}
      </div>
    </div>
  );
}

export default OneMarForm;
