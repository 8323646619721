import { IWorksiteDetails } from '@models/worksites/utils/worksitesTypes';
import { InstallerInfos } from '@models/beneficiaries/components/view/InstallerInfos';
import { WorksiteInfos } from '@models/beneficiaries/components/view/WorksiteInfos';
import { BeneficiaryInfos } from '@models/beneficiaries/components/view/BeneficiaryInfos';
import { useContext } from 'react';
import { GlobalContext } from '@context/globalContext';

interface TabDetailsProps {
  worksiteDatas: IWorksiteDetails;
}

function TabDetails({ worksiteDatas }: TabDetailsProps) {
  const { isMobile } = useContext(GlobalContext);

  return (
    <div
      className={`mt-5 h-[87vh] flex gap-5 ${
        isMobile ? 'flex-col' : 'flex-row'
      } `}
    >
      {isMobile && (
        <div className="w-full">
          <BeneficiaryInfos worksiteDatas={worksiteDatas} />
        </div>
      )}
      <div className={`${isMobile ? 'w-full' : 'w-4/12'}`}>
        <InstallerInfos worksiteDatas={worksiteDatas} />
      </div>
      <div className={`${isMobile ? 'w-full' : 'w-8/12'}`}>
        <WorksiteInfos worksiteDatas={worksiteDatas} />
      </div>
    </div>
  );
}

export { TabDetails };
