interface ILayoutListInfosFolderMarProps {
  children: JSX.Element;
  title: string;
}

function LayoutListInfosFolderMar({
  children,
  title,
}: ILayoutListInfosFolderMarProps) {
  return (
    <div>
      <p className="text-4 mb-4 font-medium">{title}</p>
      <div className="border border-borderGrey p-[1.5rem] rounded-default">
        <div className="grid w-full grid-cols-2 gap-y-4">{children}</div>
      </div>
    </div>
  );
}

export default LayoutListInfosFolderMar;
