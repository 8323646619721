/* eslint-disable react/no-unstable-nested-components */
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { Card } from '@components/Card';
import { Radio } from '@components/atomic/inputs/controls/Radio';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { InputText } from '@components/atomic/inputs/InputText';
import { setContactInfo } from '@models/contractCreation/utils/newContractHelper';
import { ContractCreationContext } from '@models/contractCreation/utils/contractCreationContext';
import { IColumn, ListTable } from '@components/ListTable';
import { createColumnHelper } from '@tanstack/react-table';
import { IContractOperationPrice } from '@models/contractCreation/utils/contractCreationTypes';
import { blueSecondary, orange } from '@assets/color';
import { Tag } from '@components/atomic/Tag';
import { numberWithSeparator } from '@utils/format';
import {
  checkSiretAides,
  isValidSignataire,
} from '@models/contractCreation/utils/functions';
import { ContactFields } from '@models/contractCreation/components/steps/ContactFields';
import { toast } from 'react-toastify';
import { initialContractContact } from '@utils/initialState';
import { getSignatories } from '@models/entities/apiRequests/entitiesRequests';
import { IAccountSignatory } from '@models/users/utils/userTypes';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { Checkbox } from '@components/atomic/inputs/controls/Checkbox';
import { useTranslation } from 'react-i18next';
import { placeholderExample } from '@utils/utils';

function StepIntermediaryBusiness() {
  const { t } = useTranslation();
  const [withProvider, setWithProvider] = useState<boolean>(false);
  const [commissionPercent, setCommissionPercent] = useState<string>('');
  const [commissionPrice, setCommissionPrice] = useState<string>('');
  const [loadingSiret, setLoadingSiret] = useState<boolean>(false);
  const [errorSiret, setErrorSiret] = useState<boolean>(false);
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const [isSignatories, setIsSignatories] = useState<boolean>(false);
  const [signatories, setSignatories] = useState<IAccountSignatory[]>([]);
  const [isContact, setIsContact] = useState<boolean>(false);

  const {
    updateIntermediaryBusiness,
    intermediaryBusiness,
    operationPrices,
    amo,
    entityTo,
    changeStep,
    commission,
    updateCommission,
    payloadData,
  } = useContext(ContractCreationContext);
  const columnHelper = createColumnHelper<IContractOperationPrice>();

  const methods = useForm();
  const { watch, setValue } = methods;
  const formValues = watch();
  const placeholders = placeholderExample(t);

  const fetchSignatories = useCallback(async () => {
    // check signatories for entity
    // if new entity setIsSignatories = false
    setIsSignatories(false);
    if (intermediaryBusiness.id !== null) {
      const resSignatories = await getSignatories(
        Number(intermediaryBusiness.id)
      );
      if (resSignatories.length > 0) {
        setSignatories(resSignatories);
        setIsSignatories(true);
      }
    }
  }, [intermediaryBusiness, isSignatories]);

  const getCommission = (price: number | undefined, total = false) => {
    if (!price || price === 0) return '';
    const percent = Number(commissionPercent.replace(',', '.'));
    const comPrice = Number(commissionPrice.replace(',', '.'));

    const totalOpPrice = operationPrices.reduce(
      (acc, item) =>
        acc +
        Number(item.prix_unitaire_classique || 0) +
        Number(item.prix_unitaire_precaire || 0), // TODO
      0
    );
    if (percent > 0) {
      const newPrice =
        (totalOpPrice * (percent / 100)) / operationPrices.length;
      if (total)
        return `${numberWithSeparator(String(newPrice + price))}€/MWhc`;

      const pricePercent = (newPrice * 100) / price;

      return `${numberWithSeparator(
        String(newPrice)
      )} €/MWhc (${numberWithSeparator(String(pricePercent))}%)`;
    }

    if (comPrice > 0) {
      const totalPrice = comPrice + price;
      if (total) return `${numberWithSeparator(String(totalPrice))}€/MWhc`;

      const comPercent = (comPrice * 100) / price;

      return `${commissionPrice} €/MWhc (${numberWithSeparator(
        String(comPercent)
      )}%)`;
    }

    return '';
  };

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('label', {
        header: () => t('contract.business.table.operation'),
      }),

      columnHelper.accessor('operation_id', {
        header: () => t('contract.business.table.gisement'),
        cell: (info) => {
          return (
            <div className="flex flex-col gap-2">
              <Tag color={blueSecondary} label={t('global.classic')} />
              {Number(info.row.original.prix_unitaire_precaire) > 0 && (
                <Tag color={orange} label={t('global.precaire')} />
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor('prix_unitaire_precaire', {
        header: () => t('contract.business.table.price'),
        cell: (info) => {
          return (
            <div className="flex flex-col gap-2">
              <div>
                {`${numberWithSeparator(
                  String(info.row.original.prix_unitaire_classique)
                )} €/MWhc`}
              </div>
              {Number(info.row.original.prix_unitaire_precaire) > 0 && (
                <div>
                  {`${numberWithSeparator(
                    String(info.row.original.prix_unitaire_precaire)
                  )} €/MWhc`}
                </div>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor('commission', {
        header: () => t('contract.business.commission'),
        cell: (info) => {
          return (
            <div className="flex flex-col gap-2">
              {getCommission(Number(info.row.original.prix_unitaire_classique))}
              {Number(info.row.original.prix_unitaire_precaire) > 0 && (
                <div>
                  {getCommission(
                    Number(info.row.original.prix_unitaire_precaire)
                  )}
                </div>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor('total', {
        header: () => t('contract.business.table.total'),
        cell: (info) => {
          return (
            <div className="flex flex-col gap-2">
              <div>
                {getCommission(
                  Number(info.row.original.prix_unitaire_classique),
                  true
                )}
              </div>
              {Number(info.row.original.prix_unitaire_precaire) > 0 && (
                <div>
                  {getCommission(
                    Number(info.row.original.prix_unitaire_precaire),
                    true
                  )}
                </div>
              )}
            </div>
          );
        },
      }),
    ];
  }, [operationPrices, commissionPercent, commissionPrice]);

  const onChangeSiret = async (value: string) => {
    setContactInfo('siret', value, updateIntermediaryBusiness);

    if (value !== '') {
      if (![entityTo.siret, amo.siret].includes(value)) {
        checkSiretAides(value, setLoadingSiret, updateIntermediaryBusiness);
        setErrorSiret(false);
      } else {
        setErrorSiret(true);
        toast.error('Siret déja utilisé');
      }
    }

    setValue('signatory_id', undefined);
    setIsContact(false);
  };

  const displayContactFields = () => {
    setIsContact((prevState) => !prevState);
    setValue('signatory_id', undefined);
    setValue('signataireFirstname', '');
    setValue('signataireLastname', '');
    setValue('signataireFunction', '');
    setValue('signatairePhone', '');
    setValue('signataireEmail', '');

    setContactInfo('signatory_id', '', updateIntermediaryBusiness);
    setContactInfo('signataire', '', updateIntermediaryBusiness);
  };

  const getName = (sign: IAccountSignatory) =>
    `${sign.firstname} ${sign.lastname}`;

  const getSelectedSignatory = () => {
    const signatory = signatories.find(
      (elt) => elt.id === intermediaryBusiness?.signatory_id
    );

    if (!signatory) return '';

    return getName(signatory);
  };

  const signatoriesArrayList = useMemo(() => {
    return signatories.map((elt) => getName(elt));
  }, [signatories]);

  const onChangeEmail = (value: string) => {
    if (value !== '') {
      const emailList = [
        entityTo.signataire?.email,
        entityTo.contact?.email,
        amo.signataire?.email,
        amo.contact?.email,
        intermediaryBusiness.signataire?.email,
        intermediaryBusiness.contact?.email,
      ];

      if (emailList.includes(value)) {
        toast.error(t('contract.same_email'));
        setErrorEmail(true);
      } else {
        setErrorEmail(false);
      }
    }
  };

  const isDisabled = useMemo(() => {
    if (!withProvider) return false;

    return (
      !isValidSignataire(
        intermediaryBusiness,
        isSignatories,
        !!formValues.signatory_id || !!intermediaryBusiness?.signatory_id
      ) ||
      errorEmail ||
      errorSiret ||
      commission === 0
    );
  }, [errorSiret, errorEmail, intermediaryBusiness, commission, withProvider]);

  useEffect(() => {
    const total = operationPrices.reduce(
      (acc, item) =>
        acc +
        Number(item.prix_unitaire_classique || 0) +
        Number(item.prix_unitaire_precaire || 0), // TODO
      0
    );

    const percent = Number(commissionPercent.replace(',', '.')) / 100;
    const price = Number(commissionPrice.replace(',', '.'));

    if (percent > 0) {
      updateCommission((total * percent) / operationPrices.length);
    }
    if (price > 0) {
      updateCommission(price);
    }
  }, [operationPrices, commissionPercent, commissionPrice]);

  useEffect(() => {
    const { siret, company_name, signataire, address } = intermediaryBusiness;

    const emptyFields =
      siret === '' &&
      company_name === '' &&
      address.address === '' &&
      address.city === '' &&
      address.postal_code === '' &&
      !signataire;

    if (!emptyFields) setWithProvider(true);
  }, [intermediaryBusiness]);

  useEffect(() => {
    if (payloadData?.intermediary_business?.minimum_bonus_allocation) {
      setCommissionPrice(
        payloadData?.intermediary_business?.minimum_bonus_allocation.toString()
      );
    }
  }, [payloadData, intermediaryBusiness]);

  useEffect(() => {
    if (
      intermediaryBusiness.signataire &&
      intermediaryBusiness.signataire.email !== '' &&
      !intermediaryBusiness?.signatory_id
    )
      setIsContact(true);
    if (intermediaryBusiness.id !== null) {
      fetchSignatories();
    }
  }, [intermediaryBusiness.id]);

  return (
    <Card
      title={t('contract.business.title')}
      actionButtons={
        <div className="flex gap-3">
          <ButtonOpx
            type="secondary"
            label={`${t('global.back')}`}
            onClick={() => changeStep('back')}
          />

          <ButtonOpx
            label={`${
              withProvider === false
                ? t('contract.business.continue_without')
                : t('buttons.next')
            }`}
            onClick={() => changeStep('next')}
            disabled={isDisabled}
            dataTestId="button_next"
          />
        </div>
      }
    >
      <div>
        <FormProvider {...methods}>
          <Radio
            name="withProvider"
            value="withProvider"
            onSelect={() => setWithProvider(true)}
            label={`${t('contract.business.with')}`}
            isChecked={!!withProvider}
            colorText="black"
            addClass="border rounded-default focus:outline-none w-full p-2 flex items-center gap-2 blueOpx mb-2"
            dataTestId="with_business"
          />
          <Radio
            name="withoutProvider"
            value="withoutProvider"
            onSelect={() => {
              updateIntermediaryBusiness(initialContractContact);
              setWithProvider(false);
            }}
            label={`${t('contract.business.without')}`}
            isChecked={withProvider === false}
            colorText="black"
            addClass="border rounded-default focus:outline-none w-full p-2 flex items-center gap-2 blueOpx"
            dataTestId="without_business"
          />
          {!!withProvider && (
            <form>
              <div className="mt-[1rem] mb-[1.5rem]">
                <p className="w-full font-medium text-[1rem]">{`${t(
                  'partners.general_infos'
                )}`}</p>
                <p className="text-[0.875rem]">
                  {t('contract.general_infos_info')}
                </p>
              </div>

              <div className="flex flex-col gap-3 mb-2">
                <div className="flex gap-3 w-full">
                  <InputText
                    id="company_name"
                    name="company_name"
                    placeholder={`${t('global.text_placeholder')}`}
                    label={`${t('partners.company_name')}`}
                    required
                    onChange={(e) =>
                      setContactInfo(
                        'company_name',
                        String(e),
                        updateIntermediaryBusiness
                      )
                    }
                    disabled={intermediaryBusiness.siret.length === 14}
                    value={intermediaryBusiness.company_name}
                    loading={loadingSiret}
                    spinnerLoader
                  />
                  <InputText
                    id="siret"
                    name="siret"
                    placeholder={placeholders.SIRET}
                    label={`${t('partners.siret')}`}
                    value={intermediaryBusiness.siret}
                    required
                    onChange={(e) => onChangeSiret(String(e))}
                    disabled={loadingSiret}
                    loading={loadingSiret}
                    spinnerLoader
                  />
                </div>

                <InputText
                  id="address"
                  name="address"
                  placeholder={placeholders.ADDRESS}
                  label={`${t('partners.head_office_address')}`}
                  required
                  disabled={intermediaryBusiness.siret.length === 14}
                  value={
                    intermediaryBusiness.address.address !== ''
                      ? ` ${intermediaryBusiness.address.address}, ${intermediaryBusiness.address.postal_code} ${intermediaryBusiness.address.city}`
                      : ''
                  }
                  loading={loadingSiret}
                  spinnerLoader
                />
              </div>

              {/* display contact fields only if signatories empty */}
              <div className="flex flex-col gap-3 mt-[0.5rem]">
                <div className="w-full">
                  <p className="w-full font-medium text-[1rem] mt-[1.0rem]">{`${t(
                    `contract.contact_business`
                  )}`}</p>
                  <p className="w-full text-[0.875rem] mb-[1.0rem]">{`${t(
                    `contract.contact_signataire_description`
                  )}`}</p>
                  {isSignatories && intermediaryBusiness && (
                    <>
                      <div className="w-[50%]">
                        <InputSelect
                          resetValue={!formValues.signatory_id}
                          dataArrayString={signatoriesArrayList}
                          placeholder={t('contract.signatory')}
                          onSelect={(e) => {
                            const selectedSignatory = signatories.find(
                              (elt) => `${elt.firstname} ${elt.lastname}` === e
                            );
                            if (selectedSignatory) {
                              setValue('signatory_id', selectedSignatory.id);

                              setContactInfo(
                                'signatory_id',
                                selectedSignatory.id,
                                updateIntermediaryBusiness
                              );
                            }
                          }}
                          dataTestIdSelect="select_signatories"
                          dataTestIdOptions="signatories"
                          required
                          disabled={isContact}
                          valueInput={getSelectedSignatory()}
                          defaultSelected={getSelectedSignatory()}
                        />
                      </div>
                      <div className="w-full flex gap-3 mt-[1.0rem] mb-[1.0rem] text-[0.875rem]">
                        <div> {t('contract.signatories_not_in_list')}</div>{' '}
                        <Checkbox
                          label=""
                          checked={Boolean(isContact)}
                          onCheck={displayContactFields}
                          width="1rem"
                        />
                      </div>
                    </>
                  )}
                  {(!isSignatories || isContact) && (
                    <ContactFields
                      updateInfosPartner={updateIntermediaryBusiness}
                      contactType="signataire"
                      required={false}
                      infosPartner={intermediaryBusiness}
                      onCheckEmail={(e) => onChangeEmail(e)}
                      errorEmail={errorEmail}
                    />
                  )}
                </div>
              </div>

              <div className="mt-[1.5rem]">
                <p className="w-full font-medium text-[1rem] mt-[1rem] mb-[1.5rem]">{`${t(
                  'contract.business.commission'
                )}`}</p>

                <div className="flex gap-3">
                  <InputText
                    id="commissionPrice"
                    name="commissionPrice"
                    placeholder="€/MWhc"
                    label="€/MWhc"
                    onChange={(e) => setCommissionPrice(String(e))}
                    value={commissionPrice}
                    disabled={Number(commissionPercent.replace(',', '.')) > 0}
                  />
                  <InputText
                    id="commissionPercent"
                    name="commissionPercent"
                    placeholder={`${t('contract.business.percent')}`}
                    label={`${t('contract.business.percent')}`}
                    onChange={(e) => setCommissionPercent(String(e))}
                    value={commissionPercent}
                    error={Number(commissionPercent.replace(',', '.')) > 100}
                    disabled={Number(commissionPrice.replace(',', '.')) > 0}
                  />
                </div>

                <ListTable
                  data={operationPrices}
                  columns={columns as IColumn[]}
                  type="secondary"
                  addClass="border border-borderGrey mt-[1.5rem]"
                  hideArrow
                />
              </div>
            </form>
          )}
        </FormProvider>
      </div>
    </Card>
  );
}

export { StepIntermediaryBusiness };
