import { useContext } from 'react';

import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import HousingMar from '@models/mar/components/worksiteCompletion/stepsCompletion/housing/HousingMar';
import { STEPS_WORKSITE_MAR_COMPLETION } from '@models/mar/utils/enums';
import CustomerInformationMar from '@models/mar/components/worksiteCompletion/stepsCompletion/customerInformation/CustomerInformationMar';
import EnergyAudit from '@models/mar/components/worksiteCompletion/stepsCompletion/energyAudit/EnergyAudit';
import HousingAnalysis from '@models/mar/components/worksiteCompletion/stepsCompletion/housingAnalysis/HousingAnalysis';
import ScenariosAndCalculations from '@models/mar/components/worksiteCompletion/stepsCompletion/scenariosAndCalculations/ScenariosAndCalculations';
import Professionals from '@models/mar/components/worksiteCompletion/stepsCompletion/professionals/Professionals';
import Quotes from '@models/mar/components/worksiteCompletion/stepsCompletion/quotes/Quotes';
import Mandate from '@models/mar/components/worksiteCompletion/stepsCompletion/mandates/Mandate';
import CeeAnah from '@models/mar/components/worksiteCompletion/stepsCompletion/ceeAnah/CeeAnah';
import DepositOfApplication from '@models/mar/components/worksiteCompletion/stepsCompletion/depositOfApplication/DepositOfApplication';
import GrantNotification from '@models/mar/components/worksiteCompletion/stepsCompletion/grantNotification/GrantNotification';
import WorkInProgress from '@models/mar/components/worksiteCompletion/stepsCompletion/workInProgress/WorkInProgress';
import EndOfWork from '@models/mar/components/worksiteCompletion/stepsCompletion/endOfWork/EndOfWork';
import AfterWork from '@models/mar/components/worksiteCompletion/stepsCompletion/afterWork/AfterWork';
import PaymentLetter from '@models/mar/components/worksiteCompletion/stepsCompletion/paymentLetter/PaymentLetter';
import { useTranslation } from 'react-i18next';

function FolderMarDetailsContent() {
  const { t } = useTranslation();
  const { stepCompletion } = useContext(WorksiteCreationMarContext);

  switch (stepCompletion) {
    case STEPS_WORKSITE_MAR_COMPLETION.TYPOLOGY_OF_HOUSING:
      return <HousingMar />;
    case STEPS_WORKSITE_MAR_COMPLETION.USER_INFORMATION:
      return <CustomerInformationMar />;
    case STEPS_WORKSITE_MAR_COMPLETION.ENERGY_AUDIT:
      return <EnergyAudit />;
    case STEPS_WORKSITE_MAR_COMPLETION.HOUSING_ANALYSIS:
      return <HousingAnalysis />;
    case STEPS_WORKSITE_MAR_COMPLETION.SCENARIOS_AND_CALCULATIONS:
      return <ScenariosAndCalculations />;
    case STEPS_WORKSITE_MAR_COMPLETION.PROFESSIONALS:
      return <Professionals />;
    case STEPS_WORKSITE_MAR_COMPLETION.QUOTES:
      return <Quotes />;
    case STEPS_WORKSITE_MAR_COMPLETION.ADMINISTRATIVE_MANDATE:
    case STEPS_WORKSITE_MAR_COMPLETION.FINANCIAL_MANDATE:
      return <Mandate />;
    case STEPS_WORKSITE_MAR_COMPLETION.CEE_ANAH:
      return <CeeAnah />;
    case STEPS_WORKSITE_MAR_COMPLETION.APPLICATION_DEPOSIT:
      return <DepositOfApplication />;
    case STEPS_WORKSITE_MAR_COMPLETION.GRANT_NOTIFICATION:
      return <GrantNotification />;
    case STEPS_WORKSITE_MAR_COMPLETION.WORK_IN_PROGRESS:
      return <WorkInProgress />;
    case STEPS_WORKSITE_MAR_COMPLETION.END_OF_WORK:
      return <EndOfWork />;
    case STEPS_WORKSITE_MAR_COMPLETION.AFTER_WORK:
      return <AfterWork />;
    case STEPS_WORKSITE_MAR_COMPLETION.PAYMENT_LETTER:
      return <PaymentLetter />;
    case STEPS_WORKSITE_MAR_COMPLETION.END:
      return (
        <div className="w-full text-center">
          {t(
            'mar.worksite_creation.worksite_completion.folder_details.folder_complete'
          )}
        </div>
      );
    default:
      return <div>Step défaut</div>;
  }
}

export default FolderMarDetailsContent;
