import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { IOptionGraph } from '@models/worksiteCreation/utils/types/SimulationTypes';
import { TextWithRadio } from '@components/atomic/inputs/controls/TextWithRadio';
import { arrayAgeOptions } from '@models/worksiteCreation/utils/functions';
import { useFormContext } from 'react-hook-form';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';

function AgeMar() {
  const { t } = useTranslation();
  const { worksiteData } = useContext(WorksiteCreationMarContext);
  const [ageSelected, setAgeSelected] = useState<string>(
    worksiteData?.age !== null && worksiteData?.age !== undefined
      ? worksiteData.age.toString()
      : ''
  );

  const formContext = useFormContext();

  const { setValue } = formContext;

  useEffect(() => {
    setValue('age', ageSelected);
  }, []);

  return (
    <div>
      <p className="font-medium text-[1.125rem] mb-4">
        {t('worksites.housing.housing_age')}
      </p>
      <div className="flex w-full space-x-[.5rem]">
        {arrayAgeOptions(t).map((option: IOptionGraph) => {
          const isChecked = () => {
            return ageSelected === option.value;
          };
          const onSelect = (e: string) => {
            setAgeSelected(e);
            setValue('age', e);
          };

          return (
            <TextWithRadio
              dataTestId={`input_radio_age_${option.value}`}
              key={option.label}
              label={option.label}
              value={option.value.toString()}
              addClass="flex-grow"
              selectValue={ageSelected !== '' ? ageSelected : undefined}
              isChecked={isChecked()}
              setSelectedValue={(e) => onSelect(e)}
            />
          );
        })}
      </div>
    </div>
  );
}

export default AgeMar;
