import { useTranslation } from 'react-i18next';
import { ColorCube } from '@components/atomic/ColorCube';
import { blueOpx, red } from '@assets/color';
import { ChevronLeftIcon, TrashIcon } from '@assets/images/svgComponents';
import { useFieldArray, useFormContext } from 'react-hook-form';
import React, { useContext, useEffect, useState } from 'react';
import OneQuote from '@models/mar/components/worksiteCompletion/stepsCompletion/quotes/OneQuote';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { InputTypes } from '@utils/utils';
import OneMarForm from '@models/mar/components/worksiteCompletion/OneMarForm';
import { ILabelValue } from '@globalTypes/globalTypes';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import ModalAddPro from '@models/mar/components/worksiteCompletion/stepsCompletion/quotes/ModalAddPro';
import { TextError } from '@components/TextError';
import { deleteQuotes } from '@models/mar/apiRequests/worksiteCreationMarRequests';
import { IProEntity, IWorksiteMar } from '@models/mar/utils/marTypes';
import { filterOperations } from '@models/mar/utils/functions';

interface IOneProProps {
  indexPro: number;
  nbPros: number;
  onDelete: (index: number) => void;
  prosList: IProEntity[];
  openByDefault: boolean;
}

function OnePro({
  indexPro,
  nbPros,
  onDelete,
  prosList,
  openByDefault,
}: IOneProProps) {
  const { t } = useTranslation();
  const { operationsList, worksiteData, updateWorksiteData } = useContext(
    WorksiteCreationMarContext
  );

  const [isCollapsed, setIsCollapsed] = useState<boolean>(!openByDefault); // État pour gérer l'ouverture/fermeture

  const scenarioSelected = worksiteData?.scenarios?.find(
    (scenario) => scenario.selected
  );

  const scenarioSelectedOperationsIds = new Set(
    scenarioSelected?.mar_gestes?.map((geste) => geste.geste_id) ?? []
  );

  const [scenarioSelectedOperations, otherOperations] = operationsList
    ? operationsList.reduce(
        ([selected, others], operation) => {
          if (scenarioSelectedOperationsIds.has(Number(operation.value))) {
            selected.push(operation);
          } else {
            others.push(operation);
          }
          return [selected, others];
        },
        [[], []] as [typeof operationsList, typeof operationsList]
      )
    : [[], []];

  const [operationsSelected, setOperationsSelected] = useState<ILabelValue[]>(
    []
  );
  const [otherOperationsSelected, setOtherOperationsSelected] = useState<
    ILabelValue[]
  >([]);
  const [displayModalAddPro, setDisplayModalAddPro] = useState(false);
  const [resetSignalPro, setResetSignalPro] = useState(1);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const [
    scenarioSelectedOperationsFiltered,
    setScenarioSelectedOperationsFiltered,
  ] = useState(scenarioSelectedOperations);
  const [otherOperationsFiltered, setOtherOperationsFiltered] =
    useState(otherOperations);

  const { control, setValue, watch } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `pros.${indexPro}.quotes`,
  });

  // const proValues = watch(`pros.${indexPro}`);

  const prefix = `pros.${indexPro}.`;
  const selectedProName = `${prefix}selected_pro`;

  const proSelected = prosList.find(
    (pro) => pro.id === watch(`${prefix}entity_id`)
  );

  const quotes = watch(`${prefix}quotes`);
  const selectedQuotes = quotes?.filter((quote: any) => quote.selected_devis);
  const gesturesSelectedQuotes = selectedQuotes
    ?.map((quote: any) => quote.gestes)
    .filter((gestes: any[]) => gestes !== undefined && gestes !== null) // Filtrer les devis sans gestes
    .flat();

  // Fonction qui vérifie si le devis retenu couvre tous les gestes du scénario retenu
  const isQuoteCoveringScenarioGestures =
    gesturesSelectedQuotes && scenarioSelectedOperations
      ? scenarioSelectedOperations.every((operation) =>
          gesturesSelectedQuotes.some(
            (geste: any) => geste.operation.value === operation.value
          )
        )
      : false;

  const handleDeleteOneQuote = async (indexQuote: number) => {
    if (!loadingDelete) {
      remove(indexQuote);
      const quoteToDelete = worksiteData?.pros?.[indexPro]?.devis?.[indexQuote];
      if (quoteToDelete) {
        setLoadingDelete(true);
        const response = await deleteQuotes(worksiteData?.id || 0, [
          quoteToDelete.id,
        ]);
        updateWorksiteData((prev) => ({
          ...(prev as IWorksiteMar),
          pros: response.data.pros,
        }));
        setLoadingDelete(false);
      }
    }
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed); // Bascule l'état ouvert/fermé
  };

  useEffect(() => {
    filterOperations(
      scenarioSelectedOperations,
      setScenarioSelectedOperationsFiltered,
      operationsSelected,
      otherOperations,
      setOtherOperationsFiltered,
      otherOperationsSelected
    );
  }, [operationsSelected, otherOperationsSelected]);

  useEffect(() => {
    if (fields && fields.length === 0) {
      append({});
    }
  }, []);

  return (
    <div className="w-full flex flex-col space-y-6 p-4 border border-borderGrey rounded-default">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={toggleCollapse}
      >
        <div className="flex space-x-2 items-center">
          <ColorCube
            size="1.5rem"
            numberOrIcon={indexPro + 1}
            color={blueOpx}
            opacity
          />
          <p className="text-[1.125rem] font-medium leading-0">
            {t(
              'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.installer'
            )}
            {isCollapsed && proSelected && ` - ${proSelected.company_name}`}
          </p>
          {nbPros > 1 && (
            <ColorCube
              size="2rem"
              numberOrIcon={<TrashIcon />}
              color={red}
              opacity
              onClick={() => onDelete(indexPro)}
              addClass="!ml-4"
            />
          )}
        </div>

        <ColorCube
          numberOrIcon={
            <ChevronLeftIcon
              className={`${
                !isCollapsed ? 'rotate-[90deg]' : 'rotate-[-90deg]'
              } transform duration-200 m-auto`}
            />
          }
          size="1.5rem"
          color={blueOpx}
          opacity
        />
      </div>
      {!isCollapsed && (
        <>
          {!isQuoteCoveringScenarioGestures &&
            selectedQuotes &&
            selectedQuotes.length > 0 &&
            selectedQuotes.every((quote: any) => quote.mpr_amount) && (
              <TextError
                errorMessage={t(
                  'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.quote_section.warning_quotes_no_cover_scenario'
                )}
                isWarning
                addClass="!mt-0 text-[.875rem]"
              />
            )}
          <div className="flex space-x-4 items-end w-full">
            <InputSelect
              placeholder=""
              label={`${t(
                'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.select_installer'
              )}`}
              dataLabelValue={
                prosList.length > 0
                  ? prosList.map((pro) => ({
                      label: pro.company_name,
                      value: pro.id.toString() || '0',
                    }))
                  : undefined
              }
              onSelectLabelValue={(value) => {
                setValue(`${prefix}entity_id`, value.value);
              }}
              defaultSelected={
                proSelected ? proSelected.company_name : undefined
              }
              addClass="flex-1"
              resetSignal={resetSignalPro}
              disabled={prosList.length === 0}
              required
            />
            <ButtonOpx
              label={`${t(
                'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.add_new_installer'
              )}`}
              type="tierciary"
              onClick={() => setDisplayModalAddPro(true)}
            />
            {displayModalAddPro && (
              <ModalAddPro
                setDisplayModalAddPro={setDisplayModalAddPro}
                indexPro={indexPro}
                setResetSignalPro={setResetSignalPro}
              />
            )}
          </div>
          <OneMarForm
            dataForm={{
              fields: [
                {
                  name: selectedProName,
                  title: t(
                    'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.installer_selected'
                  ),
                  type: InputTypes.CHECK_TOGGLE,
                  fullWidth: true,
                },
              ],
            }}
          />
          {fields.map((fieldRecord, index) => (
            <React.Fragment key={fieldRecord.id}>
              <OneQuote
                indexPro={indexPro}
                indexQuote={index}
                nbQuotes={fields.length}
                onDelete={handleDeleteOneQuote}
                operations={scenarioSelectedOperationsFiltered}
                otherOperations={otherOperationsFiltered}
                setProOperationsSelected={setOperationsSelected}
                setProOtherOperationsSelected={setOtherOperationsSelected}
              />
              {index < fields.length - 1 && (
                <div className="w-full h-[1px] bg-borderGrey mt-4" />
              )}
            </React.Fragment>
          ))}
          <ButtonOpx
            label={`${t(
              'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.quote_section.add_another_quote'
            )}`}
            small
            onClick={() => append({})}
            type="tierciary"
            addClass="text-[.875rem] w-full"
          />
        </>
      )}
    </div>
  );
}

export default OnePro;
