/* eslint-disable consistent-return */
import { useState, useMemo, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import { Modal } from '@components/Modal';
import { DatePicker } from '@components/atomic/inputs/datepicker/Datepicker';

import { renderTextField } from '@models/contractCreation/utils/newContractHelper';
import {
  DepositDateModalType,
  IDepositType,
} from '@models/deposits/utils/depositsTypes';
import { saveDateDeposit } from '../apiRequests/depositRequests';
import { getReqBody, validateDate, validateForm } from '../utils/depositHelper';

interface IModalDateDepositProps {
  type: DepositDateModalType;
  deposit: IDepositType;
  setDeposit: Dispatch<SetStateAction<IDepositType | undefined>>;
  callbackConfirm: (res: any) => void;
  callbackClose: () => void;
}

function ModalDateDeposit({
  type,
  deposit,
  setDeposit,
  callbackConfirm,
  callbackClose,
}: IModalDateDepositProps) {
  const { t } = useTranslation();

  const methods = useForm();
  const { watch, setValue } = methods;
  const valuesForm = watch();

  const [hasChange, setHasChange] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleConfirm = async () => {
    setIsLoading(true);
    const valid = validateForm(type, valuesForm, deposit, t);

    if (!valid) {
      setIsLoading(false);
      return false;
    }

    const requestBody = getReqBody(type, deposit, valuesForm);
    if (!requestBody) {
      setIsLoading(false);
      return false;
    }

    const res: any = await saveDateDeposit(requestBody);
    setDeposit(res);
    setIsLoading(false);

    if (res && res.id) callbackConfirm(res);
  };

  const title = useMemo(() => {
    if (type.includes('complements')) {
      return `${t('deposits.date_complements')}`;
    }

    const splitStr = type.split('_');
    return `${t(`deposits.${splitStr[1]}_${splitStr[0]}`)}`;
  }, [type]);

  return (
    <Modal
      title={title}
      textBtnConfirm={`${t('buttons.validate')}`}
      onConfirmClick={handleConfirm}
      btnCancel
      setIsModal={callbackClose}
      backgroundTransparent
      buttonsPosition="bottom"
      sidebarVisible
      btnConfirmDisabled={!validateForm(type, valuesForm, deposit, t)}
      isLoading={isLoading}
    >
      <FormProvider {...methods}>
        <form>
          <div className="mt-4">
            {type === 'deposit_date' && (
              <p className="mb-6">{t('deposits.warning_deposit_date')}</p>
            )}
            <div className="flex grid grid-cols-2 gap-4">
              <DatePicker
                label={type !== 'deposit_date' ? '' : title}
                required
                onChangeDate={(value: string) => {
                  if (value !== '') setHasChange(true);
                  setValue(type, value);
                }}
                addClass="w-full"
                textError={validateDate(type, valuesForm, deposit, t).error}
                error={
                  hasChange
                    ? !validateDate(type, valuesForm, deposit, t).valid
                    : false
                }
              />
              {type === 'deposit_date' &&
                renderTextField({
                  valuesForm,
                  label: `${t('deposits.emmy_ref')}`,
                  placeholder: '',
                  name: 'emmy_reference',
                  className: 'w-full',
                  required: true,
                  isValid: hasChange
                    ? valuesForm.emmy_reference &&
                      valuesForm.emmy_reference !== ''
                    : true,
                  onChange: () => setHasChange(true),
                })}

              {type === 'validation_date' && (
                <>
                  {renderTextField({
                    valuesForm,
                    label: `${t('partners.deposits.dd_classic')}`,
                    placeholder: '',
                    name: 'num_dd_c',
                    className: 'w-full',
                    required: true,
                    isValid: hasChange
                      ? valuesForm.num_dd_c && valuesForm.num_dd_c !== ''
                      : true,
                    onChange: () => setHasChange(true),
                  })}
                  {renderTextField({
                    valuesForm,
                    label: `${t('partners.deposits.dd_precarity')}`,
                    placeholder: '',
                    name: 'num_dd_p',
                    className: 'w-full',
                    required: true,
                    isValid: hasChange
                      ? valuesForm.num_dd_p && valuesForm.num_dd_p !== ''
                      : true,
                    onChange: () => setHasChange(true),
                  })}
                </>
              )}
            </div>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
}

export { ModalDateDeposit };
