import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { TFunction } from 'i18next';
import { initialGhostDocument } from '@utils/initialState';
import { IUserEntityTypeType } from '@models/auth/utils/types';
import { DOCUMENT_TYPES } from '@utils/utils';
import { ENTITY_TYPES } from '@utils/roles';
import { ILinkedFile } from '../../../types/globalTypes';

export const actionsBottom = (
  t: TFunction,
  backFunction: () => void,
  continueFonction?: () => void,
  formSubmitId?: string,
  loading?: boolean,
  continueDisabled?: boolean
): JSX.Element => (
  <div className="mt-4 w-full flex items-center justify-end space-x-[1rem]">
    <ButtonOpx
      addClass="min-w-[7.5rem]"
      label={t('global.back')}
      type="secondary"
      onClick={backFunction}
      dataTestId="subscription_back_button"
    />
    <ButtonOpx
      addClass="min-w-[12.5rem]"
      label={t('global.continue')}
      type="primary"
      onClick={continueFonction}
      isSubmitBtn={!!formSubmitId}
      formId={formSubmitId}
      isLoading={loading}
      disabled={continueDisabled || loading}
      dataTestId="subscription_continue_button"
    />
  </div>
);

export const ghostRegistrationDocuments = (
  userId: number | null,
  entityId: number | null,
  entityTypes: IUserEntityTypeType[] | null,
  isCompagnyManager: boolean
): ILinkedFile[] => {
  const documents: ILinkedFile[] = [];
  if (entityTypes) {
    entityTypes.forEach((entityType) => {
      const { entity_type } = entityType;
      documents.push(
        initialGhostDocument(
          DOCUMENT_TYPES.KBIS,
          userId,
          entityId,
          entityType.id,
          undefined,
          1
        )
      );
      documents.push(
        initialGhostDocument(
          DOCUMENT_TYPES.ManagerID,
          userId,
          entityId,
          entityType.id,
          undefined,
          2
        )
      );
      documents.push(
        initialGhostDocument(
          DOCUMENT_TYPES.RCPro,
          userId,
          entityId,
          entityType.id,
          undefined,
          3
        )
      );
      documents.push(
        initialGhostDocument(
          DOCUMENT_TYPES.SocialRegularityCertificate,
          userId,
          entityId,
          entityType.id,
          false,
          4
        )
      );
      // profil installateur
      if ([ENTITY_TYPES.INSTALLATEUR, ENTITY_TYPES.MAR].includes(entity_type)) {
        documents.push(
          initialGhostDocument(
            DOCUMENT_TYPES.DecennialInsurance,
            userId,
            entityId,
            entityType.id,
            false,
            5
          )
        );
      }
      // display Document Pouvoir(not compulsory) if company manager not checked
      if (!isCompagnyManager) {
        documents.push(
          initialGhostDocument(
            DOCUMENT_TYPES.Pouvoir,
            userId,
            entityId,
            entityType.id,
            false,
            6
          )
        );
      }
      documents.push(
        initialGhostDocument(
          DOCUMENT_TYPES.Other,
          userId,
          entityId,
          entityType.id,
          false,
          7
        )
      );
    });
  }
  return documents;
};
