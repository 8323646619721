import { useContext, useRef, useMemo } from 'react';
import { GlobalContext } from '@context/globalContext';
import { ROLES } from '@utils/roles';
import { DOCUMENT_STATE_STRING } from '@utils/utils';
import { handleUploadClick } from '@utils/functions';
import { DocumentCardList } from '@components/documents/DocumentCardList';
import { ILinkedFile } from '../../types/globalTypes';
import { DocumentCardHeader } from './DocumentCardHeader';

interface IDocumentCardProps {
  document: ILinkedFile;
  onSelect: (document: ILinkedFile) => void;
  isActive: boolean;
  isLoading: boolean;
  onUpload?: (file: File, originalLinkedFile: ILinkedFile) => void;
  onDelete?: (document: ILinkedFile) => void;
  onValidate?: (() => void) | undefined;
  onReject?: ((rejectionComment: string) => void) | undefined;
  customTitle?: string | null;
  hideActionButton?: boolean;
  headerButtonsList?: string[];
  isAddButton?: boolean;
  disabled?: boolean;
  subtitleOptional?: string;
  readOnly?: boolean;
}

function DocumentCard({
  document,
  onSelect,
  isActive,
  onUpload,
  isLoading,
  onDelete,
  onValidate,
  onReject,
  customTitle,
  hideActionButton,
  headerButtonsList,
  isAddButton,
  disabled,
  subtitleOptional,
  readOnly,
}: IDocumentCardProps) {
  const { roleUser, globalEnum } = useContext(GlobalContext);
  const hiddenFileInputCard = useRef<HTMLInputElement>(null);

  const title = useMemo(
    () => customTitle || globalEnum.linked_file_type[document.file_type],
    [document, globalEnum, customTitle]
  );
  const status = globalEnum.linked_file_status[document.status];

  const showActions = useMemo(() => {
    switch (roleUser) {
      case ROLES.GESTION:
        return (
          !hideActionButton &&
          (status === DOCUMENT_STATE_STRING.PENDING ||
            status === DOCUMENT_STATE_STRING.REFUSE)
        );
      case ROLES.PRODUCTION:
        return !hideActionButton && status === DOCUMENT_STATE_STRING.REFUSE;
      default:
        return !hideActionButton;
    }
  }, [roleUser, status]);

  const onClickCard = () => {
    if (!disabled && !isLoading) {
      onSelect(document);
      if (status === DOCUMENT_STATE_STRING.TO_IMPORT) {
        handleUploadClick(hiddenFileInputCard);
      }
    }
  };

  const acceptFileType = () => {
    if (document.file_type === 6 || document.file_type === 12) {
      return '.jpg,.jpeg,.png';
    }
    if (document.file_type === 16) {
      return '.jpg,.jpeg,.png, .pdf';
    }
    return '.pdf';
  };

  return (
    <div>
      <input
        type="file"
        ref={hiddenFileInputCard}
        onChange={(e) => {
          const files = e.target.files;
          if (onUpload && files && files.length > 0) {
            onUpload(files[0], document);
          }
        }}
        style={{ display: 'none' }}
        accept={acceptFileType()}
      />
      <div
        // onClick={onClickCard}
        className={`${disabled && 'cursor-not-allowed'} ${
          isLoading && 'cursor-wait'
        } 
        ${
          !disabled && !isLoading && 'cursor-pointer'
        }  w-full rounded-default border-[1px] ${
          status === DOCUMENT_STATE_STRING.REFUSE
            ? 'flex flex-col space-y-[1rem]'
            : ''
        } ${
          isActive && status !== DOCUMENT_STATE_STRING.TO_IMPORT
            ? 'bg-backgroundBody'
            : 'bg-white'
        } ${
          !isActive || status === DOCUMENT_STATE_STRING.TO_IMPORT
            ? 'border-borderGrey'
            : 'border-transparent'
        }`}
        data-test-id="button_document_card"
      >
        <DocumentCardHeader
          readOnly={readOnly}
          title={title}
          document={document}
          status={status}
          isActive={isActive}
          isUploadCard={status === DOCUMENT_STATE_STRING.TO_IMPORT}
          onDelete={onDelete}
          isLoading={isLoading}
          disabled={disabled}
          headerButtonsList={headerButtonsList}
          isAddButton={isAddButton}
          subtitleOptional={subtitleOptional || undefined}
          onClickCard={onClickCard}
        />

        {!readOnly && showActions && !isAddButton && !isLoading && (
          <DocumentCardList
            document={document}
            status={status as string}
            isActive={isActive}
            setDocumentActive={onSelect}
            onUpload={onUpload}
            onValidate={onValidate}
            onReject={
              onReject
                ? (rejectionComment: string) => onReject(rejectionComment)
                : undefined
            }
          />
        )}
      </div>
    </div>
  );
}

export { DocumentCard };

DocumentCard.defaultProps = {
  onDelete: undefined,
  onUpload: undefined,
  onValidate: undefined,
  onReject: undefined,
  customTitle: null,
  hideActionButton: false,
  headerButtonsList: undefined,
  isAddButton: false,
  disabled: false,
  subtitleOptional: undefined,
  readOnly: false,
};
