import { useTranslation } from 'react-i18next';
import { ButtonOpx } from '@components/atomic/ButtonOpx';

interface IActionButtonsProps {
  isEditMode: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  onSubmit: () => void;
  isLoading: boolean;
  disabled?: boolean;
  onCancel?: () => void;
}

function ActionButtons({
  isEditMode,
  setIsEditMode,
  onSubmit,
  isLoading,
  disabled,
  onCancel,
}: IActionButtonsProps) {
  const { t } = useTranslation();
  return isEditMode ? (
    <div className="flex">
      <ButtonOpx
        onClick={() => {
          setIsEditMode(false);
          if (onCancel) onCancel();
        }}
        label={t('buttons.cancel')}
        type="secondary"
        small
        addClass="!border-transparent"
      />
      <ButtonOpx
        onClick={onSubmit}
        label={t('buttons.save')}
        isLoading={isLoading}
        type="primary"
        small
        addClass="!border-transparent"
        disabled={disabled}
      />
    </div>
  ) : (
    <ButtonOpx
      onClick={() => setIsEditMode(true)}
      label={t('buttons.update')}
      type="tierciary"
      addClass="!border-transparent"
      small
      disabled={disabled}
    />
  );
}

export default ActionButtons;

ActionButtons.defaultProps = {
  disabled: false,
  onCancel: undefined,
};
