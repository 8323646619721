import { useContext, useMemo } from 'react';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import { STEPS_WORKSITE_MAR_INIT } from '@models/mar/utils/enums';
import { BindCustomer } from '@models/mar/components/initWorksite/BindCustomer';
import { NewCustomer } from '@models/mar/components/initWorksite/NewCustomer';
import AssociateContract from '@models/mar/components/initWorksite/AssociateContract';

function InitWorksiteMar() {
  const { stepInit } = useContext(WorksiteCreationMarContext);

  const stepToRender = useMemo(() => {
    switch (stepInit) {
      case STEPS_WORKSITE_MAR_INIT.BIND_CUSTOMER:
        return <BindCustomer />;
      case STEPS_WORKSITE_MAR_INIT.CUSTOMER_CREATION:
        return <NewCustomer />;
      case STEPS_WORKSITE_MAR_INIT.CONTRACT_CREATION:
        return <AssociateContract />;
      default:
        return <div>ETAPE PAS RENDUE</div>;
    }
  }, [stepInit]);

  return stepToRender;
}

export default InitWorksiteMar;
