import { KpiTab } from '@components/atomic/KpiTab';
import { useContext } from 'react';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import { IWorksiteMar } from '@models/mar/utils/marTypes';
import { useTranslation } from 'react-i18next';
import { buildKpiTab } from '@models/mar/utils/marHelper';
import { ProgressCardWorksiteMar } from '@models/mar/components/progress/ProgressCardWorksiteMar';

function WorksiteProgress() {
  const { t } = useTranslation();
  const { worksiteData } = useContext(WorksiteCreationMarContext);

  const kpiTabWorksiteProgress = buildKpiTab(worksiteData as IWorksiteMar, t);

  return (
    <div className="space-y-4">
      <KpiTab infos={kpiTabWorksiteProgress} />
      <ProgressCardWorksiteMar />
    </div>
  );
}

export default WorksiteProgress;
