import { Modal } from '@components/Modal';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { InputText } from '@components/atomic/inputs/InputText';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { storeSubcontractor } from '@models/partners/apiRequests/subcontractorsRequest';
import { getEtablissementData } from '@models/sirene/apiRequests/sireneRequests';
import { useTranslation } from 'react-i18next';
import { IPayloadCreateSubcontractor } from '@models/worksiteCreation/utils/types/worksitesType';
import { emailRegex, phoneNumberRegex } from '@utils/regex';
import { TextError } from '@components/TextError';
import { CompanyActivityStatusEnum } from '@utils/enums';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';

interface IAddSubcontractorFormProps {
  setModal: Dispatch<SetStateAction<boolean>>;
  refreshList: CallableFunction;
  backgroundTransparent?: boolean;
  sidebarVisible?: boolean;
}

function AddSubcontractorForm({
  setModal,
  refreshList,
  backgroundTransparent,
  sidebarVisible,
}: IAddSubcontractorFormProps) {
  const { t } = useTranslation();
  const methods = useForm();
  const { handleSubmit, watch, setValue, formState } = methods;
  const { isValid } = formState;

  const { worksiteDatas } = useContext(WorksiteCreationContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const onSubmit = async (formData: FieldValues) => {
    setErrorMessage('');

    setLoading(true);
    const payload = formData as IPayloadCreateSubcontractor;
    const res = await storeSubcontractor(
      payload,
      setLoading,
      setModal,
      refreshList,
      worksiteDatas?.id
    );
    setErrorMessage(res);
  };

  const getEtablissementInfos = async (siret: string) => {
    setLoading(true);
    await getEtablissementData(siret, setLoading, setValue);
  };

  const onChangeSiret = (siretValue: string) => {
    if (!loading && siretValue.length === 14) {
      getEtablissementInfos(siretValue);
    }
  };

  return (
    <Modal
      title={t('partners.subcontractors.modal_add.title')}
      backgroundTransparent={backgroundTransparent}
      textBtnConfirm={String(t('buttons.validate'))}
      btnCancel
      setIsModal={setModal}
      onConfirmClick={handleSubmit(onSubmit)}
      isLoading={loading}
      btnConfirmDisabled={
        loading ||
        !isValid ||
        watch('status') !== CompanyActivityStatusEnum.ACTIVE
      }
      sidebarVisible={sidebarVisible}
    >
      <div className="mt-5">
        <h3 className="text-xl font-medium">
          {t('partners.subcontractors.modal_add.header_title')}
        </h3>
        <h4>{t('partners.subcontractors.modal_add.header_text')}</h4>
        <FormProvider {...methods}>
          <form
            id="form-add-subcontractor"
            className="mt-5 flex flex-col gap-3"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="w-full">
              <InputText
                id="siret"
                name="siret"
                placeholder={`${t('partners.siret')}`}
                onChange={(e) => onChangeSiret(String(e))}
                required
                disabled={loading}
                typeNumber
                maxLength={14}
                rules={{
                  pattern: {
                    value: /^\d{14}$/,
                    message: t('forms.siret.error'),
                  },
                }}
                error={
                  watch('status') &&
                  watch('status') !== CompanyActivityStatusEnum.ACTIVE
                }
                textError={
                  watch('status') &&
                  watch('status') !== CompanyActivityStatusEnum.ACTIVE &&
                  t('forms.siret.error_invalid_status', {
                    company_name: watch('company_name'),
                  })
                }
                valid={watch('siret') && watch('siret').length === 14}
                dataTestId="input_text_siret"
              />
            </div>
            <div>
              <InputText
                id="company_name"
                name="company_name"
                placeholder={`${t('partners.company_name')}`}
                required
                valid={false}
                value={watch('company_name')}
              />
            </div>
            <div className="grid grid-cols-2 gap-3">
              <InputText
                id="lastname"
                name="lastname"
                placeholder={t('partners.lastname')}
                disabled={loading}
                dataTestId="input_text_subcontractor_lastname"
                required
              />
              <InputText
                id="firstname"
                name="firstname"
                placeholder={t('partners.firstname')}
                disabled={loading}
                dataTestId="input_text_subcontractor_firstname"
                required
              />
              <InputText
                id="phone_number"
                name="phone_number"
                placeholder={t('forms.phone.placeholder')}
                typeNumber
                rules={{
                  pattern: {
                    value: phoneNumberRegex,
                    message: t('forms.phone.error_pattern'),
                  },
                }}
                error={
                  watch('phone_number')?.length > 10 &&
                  !phoneNumberRegex.test(watch('phone_number'))
                }
                textError={`${t('forms.phone.error_pattern')}`}
                disabled={loading}
              />
              <InputText
                id="contact_email"
                name="contact_email"
                placeholder={`${t('forms.email.placeholder')}`}
                disabled={loading}
                required
                rules={{
                  pattern: {
                    value: emailRegex,
                    message: t('forms.email.error_pattern'),
                  },
                }}
                valid={emailRegex.test(watch('contact_email'))}
                dataTestId="input_text_contact_email"
              />
            </div>
            <div>
              <InputText
                id="address"
                name="address"
                placeholder={t('forms.address.placeholder')}
                disabled
                valid={false}
                value={
                  !watch('address')
                    ? ''
                    : `${watch('address')} ${watch('zipcode')} ${watch('city')}`
                }
              />
            </div>
          </form>
          <TextError errorMessage={errorMessage} />
        </FormProvider>
      </div>
    </Modal>
  );
}

AddSubcontractorForm.defaultProps = {
  sidebarVisible: true,
  backgroundTransparent: true,
};

export { AddSubcontractorForm };
