import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  getRepresentativesInfo,
  getSiretInfo,
  invitationInfo,
  setPartnership,
} from '@models/auth/apiRequests/registrationRequests';
import { InputText } from '@components/atomic/inputs/InputText';
import { GlobalContext } from '@context/globalContext';
import {
  IEntityInfoType,
  IRepresentativesType,
} from '@models/auth/utils/types';
import { CompanyActivityStatusEnum } from '@utils/enums';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { yupResolver } from '@hookform/resolvers/yup';
import { getSiretSchema } from '@utils/validationSchemas';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { INVITATION_STATE, placeholderExample } from '@utils/utils';
import { AUTH_ROUTES } from '@utils/routesUrls';
import { RecognisedSiret } from './RecognisedSiret';
import { RegisteredSiret } from './RegisteredSiret';
import { PartnershipConfirmation } from './PartnershipConfirmation';
import { AccountToActivate } from './AccountToActivate';

const initialRepresentative = {
  lastname: '',
  firstname: '',
  birthday: '',
  siren: '',
  citizenship: '',
  country: '',
  entity_siret: '',
};

interface SiretSectionProps {
  formLayout?: boolean;
}

function SiretSection({ formLayout }: SiretSectionProps) {
  const { t } = useTranslation();
  const { updateLayoutIsLoading } = useContext(GlobalContext);
  const query = new URLSearchParams(useLocation().search);
  const inviteId = query.get('invite-id');
  const invitationStatus = INVITATION_STATE(t);

  const [registered, setRegistered] = useState<boolean>(false);
  const [recognised, setRecognised] = useState<boolean>(false);
  const [asNew, setAsNew] = useState<boolean>(false);
  const [isModel, setIsModel] = useState<boolean>(false);
  const [infos, setInfos] = useState<IEntityInfoType>();
  const [companyName, setCompanyName] = useState<string>('');
  const [typeRegistered, setTypeRegistered] = useState<boolean>(false);
  const [showAccountToActivate, setShowAccountToActivate] =
    useState<boolean>(false);
  const [representatives, setRepresentatives] = useState<IRepresentativesType>({
    company_manager: { ...initialRepresentative },
    effective_beneficiaries: [],
  });
  const [representativesTimeOut, setRepresentativesTimeOut] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [descLoading, setDescLoading] = useState<boolean>(!!inviteId);
  const [siretLoaded, setSiretLoaded] = useState<boolean>(false);
  const [loadingRepresentative, setLoadingRepresentative] = useState(false);

  const methods = useForm({
    resolver: yupResolver(getSiretSchema(t)),
  });

  const { handleSubmit, watch, setValue, setError } = methods;

  const checkSiretInfo = async (formDatas: FieldValues) => {
    setLoading(true);
    setLoadingRepresentative(true);

    try {
      const siretInfo = await getSiretInfo(formDatas);

      if (
        siretInfo.status === 'registered' &&
        siretInfo.entity_data.status !== CompanyActivityStatusEnum.ACTIVE
      ) {
        setLoading(false);
        setError('siret', {
          type: 'custom',
          message:
            t('forms.siret.error_innactive_account', {
              company_name: siretInfo.entity_data.company_name,
            }) || '',
        });
        return;
      }
      if (siretInfo.entity_data.status !== CompanyActivityStatusEnum.ACTIVE) {
        setLoading(false);

        setError('siret', {
          type: 'custom',
          message:
            t('forms.siret.error_invalid_status', {
              company_name: siretInfo.entity_data.company_name,
            }) || '',
        });
        return;
      }

      if (isModel) {
        setRegistered(false);
        setRecognised(true);
        setInfos(siretInfo);
        setAsNew(true);
      } else {
        switch (siretInfo.status) {
          case 'registered':
            setRegistered(true);
            break;
          case 'recognised':
            setRecognised(true);
            setInfos(siretInfo);
            setAsNew(false);
            break;
          case 'new':
            setRecognised(true);
            setAsNew(true);
            setInfos(siretInfo);
            break;
          default:
            break;
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        setError('siret', {
          type: 'custom',
          message: t('forms.siret.error_invalid_siret') || '',
        });
      } else {
        setError('siret', {
          type: 'custom',
          message: t('forms.siret.error_invalid_siret') || '',
        });
      }
    } finally {
      setLoading(false);
      setLoadingRepresentative(false);
    }
  };

  const checkRepresentativesInfo = async () => {
    const representativesInfo = await getRepresentativesInfo(watch());

    // une fois arrivé à létape CompanyManagerInfo la reponse l'api Inpi ne sera plus pris en compte
    if (
      !representativesTimeOut &&
      representativesInfo &&
      representativesInfo.data
    ) {
      setRepresentatives({
        company_manager: representativesInfo.data.company_manager || null,
        effective_beneficiaries:
          representativesInfo.data.effective_beneficiaries || [],
      });
    }

    setLoadingRepresentative(false);
  };

  const placeholders = placeholderExample(t);

  useEffect(() => {
    if (loadingRepresentative) checkRepresentativesInfo();
    if (isModel) {
      setSiretLoaded(true);
    }
  }, [loadingRepresentative, isModel]);

  useEffect(() => {
    updateLayoutIsLoading(false);
  }, []);

  useEffect(() => {
    if (inviteId) {
      const setPartnerShip = async () => {
        // update invitation and display screen registered
        await setPartnership(inviteId);
      };

      const getInfo = async () => {
        const res = await invitationInfo(inviteId || '');

        setCompanyName(res.company_name);
        setDescLoading(false);

        if (
          res.status === invitationStatus.PENDING &&
          res.model_info?.is_model
        ) {
          setIsModel(true);
          setShowAccountToActivate(false);
          setValue('siret', res.model_info?.link_entity.siret);
        } else if (!res.is_new) {
          // if existing but not yet activated
          if (!res.is_active) {
            setShowAccountToActivate(true);
          } else {
            // if already activated
            if (res.status === invitationStatus.PENDING) setPartnerShip(); // check response of invitation status (if pending, then set partnership)
            setTypeRegistered(true);
          }
        }
      };

      getInfo();
    }
  }, [inviteId]);

  const typeNew = () => {
    if (!descLoading) {
      return (
        <p className="font-normal text-black text-[.875rem]">
          {t('auth.registration.create_account_message_via_link_one')}{' '}
          <span style={{ fontWeight: 'bold' }}>{companyName}</span>
          {t('auth.registration.create_account_message_via_link_two')}
        </p>
      );
    }

    return <LoaderSkeleton height="2.5rem" />;
  };

  const validateSiret = () => (
    <div className="lg:w-[35rem]">
      {!formLayout && (
        <>
          <p className="mb-2 text-[1.6875rem] font-medium leading-[2.5rem]">
            {t('auth.registration.welcome_message')}
          </p>
          {!inviteId ? (
            <p className="font-normal text-black text-[.875rem]">
              {t('auth.registration.create_account_message')}
            </p>
          ) : (
            typeNew()
          )}
        </>
      )}
      <br />
      <p className="font-medium text-black text-[1rem] mb-4">
        {t('auth.registration.forms.siret')}
      </p>
      <FormProvider {...methods}>
        <form id="form-siret" onSubmit={handleSubmit(checkSiretInfo)}>
          <div className="mb-3">
            {inviteId && !siretLoaded && isModel ? (
              <LoaderSkeleton height="2.5rem" />
            ) : (
              <InputText
                id="siret"
                name="siret"
                label={t('auth.registration.forms.siret_placeholder') || ''}
                placeholder={placeholders.SIRET}
                required
                typeNumber
                maxLength={14}
                data-test-id="siret_number"
                valueInitialInput={watch('siret') || ''}
                readOnly={isModel}
              />
            )}
          </div>
          <div
            className={formLayout ? '' : 'mb-[1.5rem] mt-3'}
            style={{ direction: formLayout ? 'rtl' : 'unset' }}
          >
            <ButtonOpx
              label={t('auth.registration.start')}
              isLoading={loading}
              disabled={
                loading ||
                (!siretLoaded &&
                  (!watch('siret') || watch('siret').length !== 14))
              }
              formId="form-siret"
              isSubmitBtn
              dataTestId="registration_button"
              addClass={formLayout ? 'w-[65%]' : 'w-full'}
            />
          </div>
          {!formLayout && (
            <p className="text-center">
              {t('auth.registration.already_registered.got_account')}{' '}
              <Link to={AUTH_ROUTES.LOGIN} className="text-blueOpx">
                {t('auth.registration.tooltip')}
              </Link>
            </p>
          )}
        </form>
      </FormProvider>
    </div>
  );

  if (showAccountToActivate) {
    return <AccountToActivate />;
  }

  if (typeRegistered) {
    return <PartnershipConfirmation />;
  }

  return (
    <div className="w-full">
      {!registered && !recognised && validateSiret()}
      {registered && <RegisteredSiret setRegistered={setRegistered} />}
      {recognised && (
        <RecognisedSiret
          infos={infos}
          setAsNew={setAsNew}
          asNew={asNew}
          setRecognised={setRecognised}
          setRepresentativesTimeOut={setRepresentativesTimeOut}
          representatives={representatives}
          setRepresentatives={setRepresentatives}
          formLayout={formLayout}
        />
      )}
    </div>
  );
}

SiretSection.defaultProps = {
  formLayout: false,
};

export { SiretSection };
