/* eslint-disable @typescript-eslint/no-unused-vars */
import { IKeyValue } from 'types/globalTypes';
import { format, parse, parseISO } from 'date-fns';
import { toast } from 'react-toastify';

export const displayError = (err: any) => {
  if (err) {
    const res = err.data;
    if (res.errors) {
      if (Array.isArray(res.errors)) {
        // Parcourir chaque objet d'erreur
        res.errors.forEach((error: any) => {
          // Pour chaque clé dans l'objet d'erreur
          Object.keys(error).forEach((key) => {
            // Assurez-vous que la clé a une valeur de type tableau et qu'elle contient au moins un élément
            if (Array.isArray(error[key]) && error[key].length > 0) {
              // Concaténer et afficher chaque message d'erreur
              const errorMessage = error[key].join(', ');
              toast.error(errorMessage);
            }
          });
        });
      } else {
        const textError = `${
          typeof res.errors === 'string' ? res.errors : res.errors.join(',')
        }`;

        toast.error(textError);
      }
    }
    if (res.message !== undefined) toast.error(`${res.message}`);
  }
};

export const dateToDDMMYYY = (
  date: string | null | undefined,
  withHour = false
): string => {
  if (!date || date === '') return '';

  const validDate = date ? date.slice(0, 10) : date;

  try {
    const parsedDate = parse(validDate || '', 'yyyy-MM-dd', new Date());
    if (Number.isNaN(parsedDate.getTime())) {
      throw new Error('Invalid date');
    }
    if (withHour) return format(parsedDate, 'dd/MM/yyyy HH:mm:ss');
    return format(parsedDate, 'dd/MM/yyyy');
  } catch (error) {
    console.error('Erreur de conversion de date:', error, date);
    return '';
  }
};

export const getTodayDate = (): string => {
  return format(new Date(), 'dd/MM/yyyy');
};

export const toAPIDateStr = (date: string | null | undefined): string => {
  if (date && date !== '') {
    if (date.length < 10) return date;
    const validDate = date.slice(0, 10);
    try {
      const parsedDate = parse(validDate, 'dd/MM/yyyy', new Date());
      if (Number.isNaN(parsedDate.getTime())) {
        throw new Error('Invalid date');
      }
      return format(parsedDate, 'yyyy-MM-dd');
    } catch (error) {
      console.error('Erreur de conversion de date:', error, date);
      return '';
    }
  }
  return '';
};

export const toAPIDate = (date: string | null | undefined): Date => {
  return date && date !== ''
    ? parse(date || '', 'yyyy-MM-dd', new Date())
    : new Date();
};
export const formatPriceWithoutRounding = (str: string): string => {
  const [integerPart, decimalPart] = str.includes('.')
    ? str.split('.')
    : [str, ''];
  const formattedIntegerPart = integerPart
    .replace(/\s/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  return decimalPart
    ? `${formattedIntegerPart},${decimalPart}`
    : formattedIntegerPart;
};

export const numberWithSeparator = (str: string): string => {
  if (Number(str) === 0 || Number.isNaN(Number(str))) return '0,00';

  let separator = null;
  if (str.includes(',')) separator = ',';
  if (str.includes('.')) separator = '.';

  let formatNum = str.replace(' ', '');
  if (separator) formatNum = formatNum.replace(separator, '.');

  const number = Number(formatNum).toFixed(2).replace('.', ',');
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const formatEnumIntoArray = (
  objEnum: Record<number, string>
): IKeyValue[] => {
  return Object.entries(objEnum).map((entry: string[]) => {
    return {
      key: entry[0],
      value: entry[1],
    };
  });
};

export const formatWord = (word: string | undefined) => {
  if (!word) return '';
  return word
    .replaceAll(/[àáâãäå]/g, 'a')
    .replaceAll(/[àáâãäå]/g, 'a')
    .replaceAll(/æ/g, 'ae')
    .replaceAll(/ç/g, 'c')
    .replaceAll(/[èéêë]/g, 'e')
    .replaceAll(/[ìíîï]/g, 'i')
    .replaceAll(/ñ/g, 'n')
    .replaceAll(/[òóôõö]/g, 'o')
    .replaceAll(/œ/g, 'oe')
    .replaceAll(/[ùúûü]/g, 'u')
    .replaceAll(/[ýÿ]/g, 'y')
    .toLowerCase();
};

export const formatToSingular = (words: string) => {
  const plurialChars = ['s', 'x'];
  const exceptions = ['cours'];

  const singularWords = words.split(' ').map((word) => {
    const lastChar = word.toLowerCase().at(-1) || '';
    const isFormat =
      plurialChars.includes(lastChar) &&
      !exceptions.includes(word.toLowerCase());

    return isFormat ? word.slice(0, -1) : word;
  });

  return singularWords.join(' ').trimEnd();
};

export const capitalizeWord = (word: string) => {
  const firstLetter = word.charAt(0).toUpperCase();
  const otherLetters = word.slice(1);
  return firstLetter + otherLetters;
};
