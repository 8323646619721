/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import {
  Dispatch,
  SetStateAction,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';

import { GlobalContext } from '@context/globalContext';

import { Modal } from '@components/Modal';
import {
  getAccessList,
  storeUserWithAccess,
} from '@models/users/apiRequests/userRequests';
import { IUserAccessItem, IUserRight } from '@models/users/utils/userTypes';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { InputText } from '@components/atomic/inputs/InputText';
import AccessSection from '@models/users/components/AccessSection';
import { yupResolver } from '@hookform/resolvers/yup';
import { getAddUserSchema } from '@utils/validationSchemas';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { IStoreUserType } from '@models/auth/utils/types';

interface IModalAddUserProps {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  refetch: CallableFunction;
}

interface IFormInputs {
  email: string;
  role_name?: string;
  firstname: string;
  lastname: string;
  function: string;
  phone_number?: string;
}

function ModalAddUser({ setShowModal, refetch }: IModalAddUserProps) {
  const { t } = useTranslation();
  const { globalEnum } = useContext(GlobalContext);
  const methods = useForm<IFormInputs>({
    shouldUnregister: false,
    resolver: yupResolver(getAddUserSchema(t)),
  });

  const {
    formState: { isValid },
  } = methods;

  const { handleSubmit, setValue } = methods;

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingAccess, setLoadingAccess] = useState<boolean>(false);
  const [access, setAccess] = useState<IUserRight>();
  const [selectedAccessList, setSelectedAccessList] = useState<
    IUserAccessItem[]
  >([]);
  const [accessLists, setAccessLists] = useState<
    { role_name: string; access_list: IUserAccessItem[] }[]
  >([]);

  const loadAccessList = useCallback(
    async (role_name: string) => {
      setLoadingAccess(true);
      const existantAccess = accessLists.find(
        (item) => item.role_name === role_name
      );
      if (existantAccess && access) {
        setAccess({
          ...access,
          access_list: existantAccess.access_list,
        });
        setSelectedAccessList(existantAccess.access_list);
      } else {
        const resAccess = await getAccessList(role_name);
        if (resAccess) {
          const tmpAccess = resAccess.access_list.map((accessId: number) => {
            return {
              access: accessId,
              toggle: false,
            };
          });

          setAccess({
            ...resAccess,
            access_list: tmpAccess,
          });
          setSelectedAccessList(tmpAccess);
          setAccessLists((oldList) => [
            ...oldList,
            { role_name, access_list: tmpAccess },
          ]);
        }
      }
      setLoadingAccess(false);
    },
    [accessLists, access]
  );

  const roleNames = Object.values(globalEnum.profile_type).map(
    (role: string) => role.charAt(0).toUpperCase() + role.slice(1).toLowerCase()
  );

  useEffect(() => {
    setValue('role_name', roleNames[0]);
    setValue('email', '');
    loadAccessList(roleNames[0]);
  }, []);

  const onSubmit = useCallback(
    async (formData: FieldValues) => {
      setLoading(true);

      const role = formData.role_name
        ? { selectedItem: formData.role_name }
        : { selectedItem: roleNames[0] };

      const userData: IStoreUserType = {
        firstname: formData.firstname,
        lastname: formData.lastname,
        function: formData.function,
        email: formData.email,
        phone_number: formData.phone_number,
        role_name: role,
      };

      const resAddUser = await storeUserWithAccess(
        userData,
        selectedAccessList
          .filter((accessItem: IUserAccessItem) => accessItem.toggle)
          .map((itemAccess: IUserAccessItem) => itemAccess.access)
      );

      if (resAddUser) {
        setShowModal(false);
        refetch();
      }

      setLoading(false);
    },
    [selectedAccessList, setShowModal, refetch]
  );

  const noAccess = useMemo(() => {
    return !selectedAccessList.some((item) => item.toggle);
  }, [selectedAccessList]);

  return (
    <Modal
      title={t('my_account.new_user')}
      setIsModal={setShowModal}
      backgroundTransparent
      btnCancelIsIcon
      lightHeader
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-8 border border-borderGrey rounded-default p-6">
            <p className="font-[500] text-[1.250rem] leading-[2rem] pb-[.25rem]">
              {t('partners.general_infos')}
            </p>
            <p className="text-[.875rem] leading-[1.250rem]">
              {t('my_account.add_user_description')}
            </p>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-8">
              <InputText
                label={t('partners.firstname') || ''}
                id="firstname"
                name="firstname"
                placeholder={t('partners.firstname')}
                required
                dataTestId="input_firstname"
              />
              <InputText
                label={t('partners.lastname') || ''}
                id="lastname"
                name="lastname"
                placeholder={t('partners.lastname')}
                required
                dataTestId="input_lastname"
              />
              <InputText
                label={t('auth.registration.forms.function') || ''}
                id="function"
                name="function"
                placeholder={t('auth.registration.forms.function')}
                required
                dataTestId="input_function"
              />
              <InputText
                label={t('partners.email_address') || ''}
                id="email"
                name="email"
                placeholder={t('partners.email_address')}
                required
                dataTestId="input_email"
              />
              <InputText
                label={t('partners.phone') || ''}
                id="phone_number"
                name="phone_number"
                placeholder={t('partners.phone')}
                dataTestId="input_phone_number"
              />
              <InputSelect
                label={t('my_account.role') || ''}
                dataArrayString={roleNames}
                placeholder={t('my_account.role') || ''}
                onSelect={(value) => {
                  loadAccessList(value);
                  setValue('role_name', value);
                }}
                dataTestIdSelect="role_name"
                dataTestIdOptions="role_name_option"
                required
                defaultSelected={roleNames[0]}
              />
            </div>
          </div>
          <AccessSection
            setSelectedAccessList={setSelectedAccessList}
            loadingAccess={loadingAccess}
            selectedAccessList={selectedAccessList}
            access={access}
            dataTestId="acces_button"
          />
          <ButtonOpx
            type="primary"
            label={`${t('buttons.validate')}`}
            isSubmitBtn
            onClick={handleSubmit(onSubmit)}
            addClass="infosGeneral rounded-default px-[1rem] py-[0.5rem] bg-blueOpx text-white flex items-center ml-auto"
            disabled={!isValid || noAccess}
            isLoading={loading}
            dataTestId="button_next"
          />
        </form>
      </FormProvider>
    </Modal>
  );
}

export { ModalAddUser };
