import { blueOpx, red } from '@assets/color';
import { useContext, useMemo, useState } from 'react';
import { ColorCube } from '@components/atomic/ColorCube';
import { ChevronDownIcon } from '@assets/images/svgComponents';
import { ProgressBarVertical } from '@components/atomic/ProgressBarVertical';
import { IWorksiteOperation } from '@models/worksites/utils/worksitesTypes';
import { numFormatSpace } from '@utils/functions';
import { dateToDDMMYYY } from '@utils/format';
import { GlobalContext } from '@context/globalContext';
import { useTranslation } from 'react-i18next';
import { RequestAndActivityIntoModalOrCardContext } from '@context/requestAndChangeContext';
import { COFRAC_OPERATION_STATUS } from '@models/cofrac/utils/cofracConstants';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import {
  getWorksiteDetails,
  setOperationAccessible,
} from '@models/worksites/apiRequests/worksitesRequests';
import { WorksitesContext } from '@models/worksites/utils/worksitesContext';
import { Modal } from '@components/Modal';
import { getWorksiteOperationStatusColor } from '@models/worksites/utils/utils';
import { WorksiteDetailsOperationCardHeader } from './WorksiteDetailsOperationCardHeader';
import { WorksiteDetailsOperationCardSteps } from './WorksiteDetailsOperationCardSteps';

type IWorksiteDetailsOperationCardProps = {
  operation: IWorksiteOperation;
  handleChangeProjectedEnd: (id: number, date: string) => void;
};

function WorksiteDetailsOperationCard({
  operation,
  handleChangeProjectedEnd,
}: IWorksiteDetailsOperationCardProps): JSX.Element {
  const { t } = useTranslation();
  const { globalEnum } = useContext(GlobalContext);
  const { listRequests } = useContext(RequestAndActivityIntoModalOrCardContext);

  const [detailsIsActive, setDetailsIsActive] = useState<boolean>(false);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [stepsHeight, setStepsHeight] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);

  const { updateWorksiteDetails, worksiteDetails } =
    useContext(WorksitesContext);

  const totalAmount = useMemo(
    () =>
      operation.mpr_amount +
      (operation.cdp_amount > 0 ? operation.cdp_amount : operation.cee_amount),
    [operation]
  );

  const generalInfos = useMemo(() => {
    return {
      id: operation.id,
      title: operation.operation.description,
      slug: operation.operation.code,
      kwhc:
        Number(operation.kwhc_classique || 0) +
        Number(operation.kwhc_precaire || 0),
      totalAmount: numFormatSpace(totalAmount),
      haveCdp: operation.cdp_amount > 0,
      lastUpdate:
        operation.revisionStatus.length > 0
          ? dateToDDMMYYY(operation.revisionStatus[0].created_at)
          : 'XX/XX/XXXX',
      da: listRequests.length === 0 ? undefined : listRequests.length,
      projectedEnd: operation.projected_end,
    };
  }, [operation, totalAmount, listRequests]);

  const lastUpdateDate = useMemo(
    () => `${t('worksites.update')} : ${generalInfos.lastUpdate}`,
    [generalInfos, t]
  );

  const changeOperationStatus = () => {
    if (operation.operation_invalid_fixed) {
      setOperationAccessible(operation.operation_invalid_fixed).then((res) => {
        if (res) {
          getWorksiteDetails(
            updateWorksiteDetails,
            String(worksiteDetails?.id)
          );
          setShowModal(false);
        }
      });
    }
  };

  return (
    <div>
      <div
        className="w-full h-full pr-[1.5rem] rounded-[.25em] border-[1px] flex border-grey bg-white"
        onClick={() => {
          if (!detailsIsActive) {
            setDetailsIsActive(true);
          }
        }}
        onKeyDown={(e) => {
          // Gestionnaire d'événements clavier : Permet d'activer les détails avec la touche 'Enter'
          // Ceci est ajouté pour respecter les normes d'accessibilité, permettant aux utilisateurs de clavier
          // d'interagir avec l'élément comme ils le feraient avec un bouton.
          if (e.key === 'Enter' && !detailsIsActive) {
            setDetailsIsActive(true);
          }
        }}
        role="button"
        // L'attribut 'role' avec la valeur 'button' indique aux technologies d'assistance
        // que cet élément doit être traité comme un bouton.
        tabIndex={0}
        // 'tabIndex={0}' rend la div focusable et navigable au clavier.
        // important pour l'accessibilité et permet à l'élément de se comporter davantage comme un bouton.
        data-test-id="worksite_op_card"
      >
        <div className="px-[2rem]">
          <ProgressBarVertical
            color={
              operation.operation_invalid_fixed?.status ===
              COFRAC_OPERATION_STATUS.INSATISFAISANTE
                ? red
                : getWorksiteOperationStatusColor(operation.status)
            }
            headerHeight={headerHeight}
            stepHeight={stepsHeight}
            showList={detailsIsActive}
            stepActive={operation.status}
            stepsList={Object.values(globalEnum.worksite_operation_status)}
            dataTestId="worksite_step_active"
          />
        </div>
        <div className="w-full flex space-x-[1.5rem] items-baseline">
          <div className="w-full">
            <WorksiteDetailsOperationCardHeader
              infos={generalInfos}
              stepActive={operation.status}
              detailsIsActive={detailsIsActive}
              lastUpdateDate={lastUpdateDate}
              setHeaderHeight={setHeaderHeight}
              setDetailsIsActive={setDetailsIsActive}
              operationInvalidFixed={operation.operation_invalid_fixed}
              handleChangeProjectedEnd={handleChangeProjectedEnd}
            />
            <WorksiteDetailsOperationCardSteps
              detailsIsActive={detailsIsActive}
              stepsList={Object.values(globalEnum.worksite_operation_status)}
              stepActive={operation.status}
              setStepHeight={setStepsHeight}
              revisionStatus={operation.revisionStatus}
              lastUpdateDate={lastUpdateDate}
              operation={operation}
            />
            {operation.operation_invalid_fixed &&
              operation.operation_invalid_fixed.is_accessible_button && (
                <div className="my-5 text-end flex gap-3 justify-end">
                  <ButtonOpx
                    label={t('worksites.operation.buttons.delete')}
                    small
                    type="secondary"
                  />
                  <ButtonOpx
                    label={t('worksites.operation.buttons.available')}
                    small
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowModal(true);
                    }}
                  />
                </div>
              )}
          </div>
          <ColorCube
            numberOrIcon={
              <ChevronDownIcon
                style={{ rotate: detailsIsActive ? '180deg' : '' }}
              />
            }
            color={blueOpx}
            size="1.5rem"
            onClick={() => setDetailsIsActive(!detailsIsActive)}
            opacity
            dataTestId="show_steps_list"
          />
        </div>
      </div>
      {showModal && (
        <Modal
          title={`${t('worksites.operation.modal_title')}`}
          setIsModal={setShowModal}
          onConfirmClick={changeOperationStatus}
          backgroundTransparent
          sidebarVisible
          btnCancel
          buttonsPosition="bottom"
          textBtnConfirm={`${t('buttons.confirm')}`}
        >
          <div>{t('worksites.operation.modal_text')}</div>
        </Modal>
      )}
    </div>
  );
}

export { WorksiteDetailsOperationCard };
