import { Dispatch, SetStateAction, useRef } from 'react';
import { IWorksiteDetails } from '@models/worksites/utils/worksitesTypes';
import { getBeneficiaryView } from '@models/beneficiaries/apiRequests/beneficiariesRequests';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthLayout } from '@pages/auth/AuthLayout';
import { useTranslation } from 'react-i18next';

interface ISecurityPageProps {
  setWorksiteDatas: Dispatch<SetStateAction<IWorksiteDetails | undefined>>;
  setCanAccess: Dispatch<SetStateAction<boolean>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}
function SecurityPage({
  setWorksiteDatas,
  setCanAccess,
  setIsLoading,
}: ISecurityPageProps) {
  const { t } = useTranslation();
  const { uuid, operationType } = useParams();
  const navigate = useNavigate();

  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const handleFormSubmit = async () => {
    const typedLetters = inputRefs.current
      .map((ref) => ref?.value)
      .join('')
      .toUpperCase();

    if (typedLetters.length === 3) {
      setIsLoading(true);
      const dataRecovered = await getBeneficiaryView(
        String(uuid),
        setWorksiteDatas,
        navigate,
        String(typedLetters)
      );
      if (dataRecovered) {
        setCanAccess(true);
      }
      setIsLoading(false);
    }
  };
  const handleInputChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = e.target.value;
    // Si la longueur de la saisie atteint la longueur maximale (1 lettre)
    if (inputValue.length === 1 && index < inputRefs.current.length - 1) {
      // Passer automatiquement au champ d'entrée suivant
      inputRefs.current[index + 1]?.focus();
    }
    if (index === 2) {
      handleFormSubmit();
    }
  };
  const handleInputKeyDown = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === 'Backspace' && e.currentTarget.value === '' && index > 0) {
      // Si la touche est "DEL" et le champ est vide
      // et ce n'est pas le premier champ
      // Déplacer automatiquement au champ d'entrée précédent
      inputRefs.current[index - 1]?.focus();
    }
  };

  return (
    <AuthLayout>
      <div className="w-full lg:max-w-[35rem]">
        <p className="mb-6 text-[1.6875rem] font-medium leading-[2.5rem]">
          {t('beneficiaries.protected_page')}
        </p>
        <div className="flex flex-col justify-center items-center w-full gap-5">
          <p className="text-black">
            {t(`beneficiaries.password_inputs_${operationType}`)}
          </p>
          <div className="flex justify-center items-center w-full gap-5">
            <input
              type="text"
              className="border p-2 rounded-default h-24 w-24 text-8xl text-center"
              ref={(ref) => {
                inputRefs.current[0] = ref;
              }}
              onChange={(e) => {
                handleInputChange(0, e);
              }}
              onKeyDown={(e) => {
                handleInputKeyDown(0, e);
              }}
            />
            <input
              type="text"
              className="border p-2 rounded-default h-24 w-24 text-8xl text-center"
              ref={(ref) => {
                inputRefs.current[1] = ref;
              }}
              onChange={(e) => {
                handleInputChange(1, e);
              }}
              onKeyDown={(e) => {
                handleInputKeyDown(1, e);
              }}
            />
            <input
              type="text"
              className="border p-2 rounded-default h-24 w-24 text-8xl text-center"
              ref={(ref) => {
                inputRefs.current[2] = ref;
              }}
              onChange={(e) => {
                handleInputChange(2, e);
              }}
              onKeyDown={(e) => {
                handleInputKeyDown(2, e);
              }}
            />
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}

export { SecurityPage };
