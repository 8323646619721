import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { useTranslation } from 'react-i18next';
import {
  ChevronDownIcon,
  FilterListIcon,
  SwapIcon,
} from '@assets/images/svgComponents';
import React, { useContext, useMemo, useState, useEffect } from 'react';
import { GlobalContext } from '@context/globalContext';
import { backgroundGrey, blueOpx, textGrey } from '@assets/color';
import { ColorCube } from '@components/atomic/ColorCube';
import { v4 } from 'uuid';
import { FilterOptions } from '@components/sortAndFilter/FilterOptions';
import { SearchAndFilterIcon } from '@components/sortAndFilter/SearchAndFilterIcon';
import { getFilters } from '@apiRequests/globalRequests';
import { AuthContext } from '@context/authContext';
// import { FilterCriteriaEnum } from '@components/sortAndFilter/utils/enums';
// import { isValid, parseISO } from 'date-fns';
import { FilterCriteriaEnum } from '@components/sortAndFilter/utils/enums';
import { isValid, parseISO } from 'date-fns';
import { IPageInList } from '../../types/globalTypes';
import {
  FilterRequestData,
  ISortAndFilterDataType,
  SortAndFilterType,
} from './utils/sortAndFilterTypes';

interface SortAndFilterProps {
  page: IPageInList;
  onSort?: (column: string, direction: string) => void;
  hideFilter?: boolean;
  hideSort?: boolean;
  onFilter?: (filters: FilterRequestData[]) => void;
  preFilter?: FilterRequestData[];
}

function SortAndFilter(props: SortAndFilterProps): JSX.Element {
  const {
    globalEnum: { sorts, list_pages },
    updateIsOpaque,
    updateDisplayModalUpgradeSubscription,
    showDropDownSortAndFilter,
    updateShowDropDownSortAndFilter,
  } = useContext(GlobalContext);

  const { user } = useContext(AuthContext);

  const { page, onSort, hideFilter, onFilter, hideSort, preFilter } = props;

  const [filters, setFilters] = useState<SortAndFilterType[]>([]);
  const [sort, setSort] = useState({ column: '', direction: '' });
  const [isDisplay, setIsDisplay] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState<FilterRequestData[]>(
    []
  );
  const [isOverFilter, setIsOverFilter] = useState<number>(-10);

  const { t } = useTranslation();

  const sortData = sorts as unknown as ISortAndFilterDataType;

  const columns = sortData[page] || [];

  const activeFilterCount = selectedFilters.reduce((total, filter) => {
    // Ajoutez la longueur du tableau 'ids' du filtre actuel au total
    return total + (filter.ids ? filter.ids.length : 1);
  }, 0);

  const checkForTrueValue = (object: { [key: string]: boolean }) => {
    return Object.values(object).some((value) => value);
  };

  const buttonIsActive = (column: string, direction: string) => {
    return sort.column === column && sort.direction === direction;
  };

  const handleSort = (column: string, direction: string) => {
    if (column !== sort.column || direction !== sort.direction) {
      if (onSort) onSort(column, direction);
    }
  };

  const selectedFilter = useMemo(() => {
    return filters.find((f) => f.value === isOverFilter);
  }, [isOverFilter, filters]);

  const dateError = useMemo(() => {
    return selectedFilters.some((f) => {
      const isDate = f.criteria === FilterCriteriaEnum.DATE;
      if (f.ids) {
        return f.ids.some((id) => {
          const startDate = f.dates?.[id]?.startDate || 'error';
          const endDate = f.dates?.[id]?.endDate || 'error';

          const errorDate =
            !isValid(parseISO(startDate)) || !isValid(parseISO(endDate));
          return isDate && errorDate;
        });
      }
      return false;
    });
  }, [selectedFilters]);

  useEffect(() => {
    const filterIndex = Object.values(list_pages).indexOf(page) + 1;
    if (filterIndex > 0) getFilters(filterIndex, setFilters);
    if (preFilter) setSelectedFilters(preFilter);
  }, [page]);

  useEffect(() => {
    updateIsOpaque(checkForTrueValue(showDropDownSortAndFilter));
    if (!checkForTrueValue(showDropDownSortAndFilter)) setIsDisplay(false);
  }, [showDropDownSortAndFilter]);

  return (
    <div className="flex gap-3">
      {!hideSort && (
        <div
          className={`relative ${
            showDropDownSortAndFilter.sort && isDisplay ? 'z-[1001]' : ''
          }`}
        >
          <ButtonOpx
            label={t('buttons.sort')}
            type="secondary"
            icon={<SwapIcon />}
            onClick={() => {
              if (user?.is_freemium) {
                updateDisplayModalUpgradeSubscription(true);
              } else {
                updateShowDropDownSortAndFilter({
                  sort: !showDropDownSortAndFilter.sort,
                  filter: false,
                });
                setIsDisplay(!isDisplay);
              }
            }}
            addClass={
              showDropDownSortAndFilter.sort && isDisplay ? 'z-[1001]' : ''
            }
            dataTestId="sort_button"
          />
          {showDropDownSortAndFilter.sort && isDisplay && (
            <div className="absolute bg-white right-0 mt-3 whitespace-nowrap rounded-default border border-2 border-borderGrey z-[1001]">
              {columns.map((column, index) => (
                <div
                  key={v4()}
                  className={`p-3 border border-b-1 border-transparent ${
                    index !== 0 && 'border-t-borderGrey'
                  }  flex justify-between gap-10`}
                >
                  <div>{column.label}</div>
                  <div className="flex gap-3">
                    <ColorCube
                      numberOrIcon={
                        <ChevronDownIcon className="rotate-180 relative right-[.2px] bottom-[.3px]" />
                      }
                      size="1.5rem"
                      color={blueOpx}
                      opacity={!buttonIsActive(String(column.value), 'asc')}
                      onClick={() => {
                        setSort({
                          column: String(column.value),
                          direction: 'asc',
                        });
                        handleSort(String(column.value), 'asc');
                        updateShowDropDownSortAndFilter({
                          sort: false,
                          filter: false,
                        });
                      }}
                      dataTestId={`sort_button_${column.value}_asc`}
                    />
                    <ColorCube
                      numberOrIcon={<ChevronDownIcon />}
                      size="1.5rem"
                      color={blueOpx}
                      opacity={!buttonIsActive(String(column.value), 'desc')}
                      onClick={() => {
                        setSort({
                          column: String(column.value),
                          direction: 'desc',
                        });
                        handleSort(String(column.value), 'desc');
                        updateShowDropDownSortAndFilter({
                          sort: false,
                          filter: false,
                        });
                      }}
                      dataTestId={`sort_button_${column.value}_dsc`}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {!hideFilter && (
        <div
          className={`relative ${
            showDropDownSortAndFilter.filter && isDisplay ? 'z-[1001]' : ''
          }`}
        >
          <button
            type="button"
            className="rounded-default px-4 py-2 bg-white border border-borderGrey"
            onClick={() => {
              if (user?.is_freemium) {
                updateDisplayModalUpgradeSubscription(true);
              } else if (filters[0]) {
                updateShowDropDownSortAndFilter({
                  sort: false,
                  filter: !showDropDownSortAndFilter.filter,
                });
                setIsDisplay(!isDisplay);
              }
            }}
            data-test-id="filter_button"
          >
            <div className="flex items-center text-textGrey whitespace-nowrap font-[400]">
              <span className="mr-[0.625rem] min-w-[1rem]">
                <FilterListIcon fill={textGrey} width="1rem" height="1rem" />
              </span>
              {t('buttons.filter')}
              {activeFilterCount > 0 && (
                <ColorCube
                  size="1.5rem"
                  numberOrIcon={activeFilterCount}
                  color={blueOpx}
                  addClass="ml-2"
                />
              )}
            </div>
          </button>

          {showDropDownSortAndFilter.filter && isDisplay && (
            <div className="absolute bg-white right-0 mt-3 rounded-default z-50  whitespace-nowrap border border-2 border-borderGrey ">
              <div className="relative">
                {selectedFilter && (
                  <FilterOptions
                    filter={selectedFilter}
                    setSelectedFilters={setSelectedFilters}
                    selectedFilters={selectedFilters}
                  />
                )}
                {filters.length > 0 &&
                  filters.map((filter, index) => {
                    const sFilter =
                      selectedFilters &&
                      selectedFilters.find((f) => f.criteria === filter.value);

                    const numberOfIds =
                      sFilter && sFilter.ids ? sFilter.ids.length : 0;

                    return (
                      <div
                        key={v4()}
                        aria-hidden
                        onClick={() => setIsOverFilter(Number(filter.value))}
                        className={`cursor-pointer ${
                          isOverFilter === filter.value &&
                          `bg-[${backgroundGrey}]`
                        }`}
                      >
                        <div
                          className={`p-3 border border-b-1 border-transparent ${
                            index !== 0 && 'border-t-borderGrey'
                          }  flex justify-between gap-10`}
                        >
                          <div className="flex items-center gap-2">
                            <SearchAndFilterIcon
                              currentFilter={filter}
                              selectedFilter={sFilter}
                              isOver={isOverFilter === filter.value}
                            />
                            <div>{filter.label}</div>
                          </div>
                          <div>
                            {numberOfIds > 0 && (
                              <ColorCube
                                size="1.5rem"
                                numberOrIcon={numberOfIds}
                                color={blueOpx}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                <div className="w-full flex justify-center gap-2 p-2">
                  <ButtonOpx
                    addClass="w-1/2"
                    label={`${t('buttons.delete')}`}
                    type="secondary"
                    onClick={() => {
                      updateShowDropDownSortAndFilter({
                        sort: false,
                        filter: false,
                      });
                      setSelectedFilters([]);
                      if (onFilter) onFilter([]);
                    }}
                  />
                  <ButtonOpx
                    label={`${t('buttons.apply')}`}
                    addClass="w-1/2"
                    onClick={() => {
                      if (onFilter) onFilter(selectedFilters);
                      updateShowDropDownSortAndFilter({
                        sort: false,
                        filter: false,
                      });
                    }}
                    disabled={dateError}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

SortAndFilter.defaultProps = {
  hideFilter: false,
  hideSort: false,
  onFilter: undefined,
  onSort: undefined,
  preFilter: undefined,
};

export { SortAndFilter };
