/* eslint-disable no-nested-ternary */

import { Loader } from '@components/atomic/Loader';
import { ColorCube } from '@components/atomic/ColorCube';
import { ChevronLeftIcon } from '@assets/images/svgComponents';
import { textGrey } from '@assets/color';

interface ICardProps {
  title: string;
  children: JSX.Element;
  subtitle?: string | boolean;
  addClass?: string;
  actionButtons?: JSX.Element;
  hideTitles?: boolean;
  underlineHeader?: boolean;
  dataTestId?: string;
  hidePanel?: boolean;
  headerButton?: number | JSX.Element;
  isLoading?: boolean;
  addClassBody?: string;
  arrowBack?: boolean;
  onArrowBackClick?: () => void;
  actionUserStatus?: JSX.Element;
}

function Card({
  title,
  subtitle,
  children,
  addClass,
  actionButtons,
  hideTitles,
  underlineHeader,
  dataTestId,
  hidePanel,
  headerButton,
  isLoading,
  addClassBody,
  arrowBack,
  onArrowBackClick,
  actionUserStatus,
}: ICardProps) {
  return (
    <article
      className={[
        'border border-borderGrey rounded-default bg-white',
        addClass,
      ].join(' ')}
      data-test-id={dataTestId}
    >
      {hidePanel ? (
        ''
      ) : (
        <div
          className={`card_header border border-solid border-transparent  ${
            underlineHeader ? 'border-b-borderGrey' : ''
          }  p-6 flex justify-between items-center `}
        >
          {hideTitles ? (
            <div />
          ) : (
            <div>
              <div className="font-medium text-[1.25rem] leading-[2.25rem] flex justify-between items-center rodney">
                <div className="flex space-x-2 items-center">
                  {arrowBack && (
                    <ColorCube
                      size="1.5rem"
                      numberOrIcon={<ChevronLeftIcon />}
                      color={textGrey}
                      opacity
                      onClick={onArrowBackClick}
                    />
                  )}
                  <p>{title}</p>
                </div>
                <div>{headerButton && headerButton}</div>
                {isLoading && <Loader />}
              </div>
              {subtitle && (
                <div className="text-[.875rem] pt-[.5rem]">{subtitle}</div>
              )}
            </div>
          )}
          {actionButtons && <div className="mb-[0.25rem]">{actionButtons}</div>}
        </div>
      )}

      <div
        className={`card_body ${
          children.props.children ? (underlineHeader ? 'p-6' : 'p-6 pt-0') : ''
        } ${addClass?.includes('noPadding') && 'px-0'} ${addClassBody}`}
      >
        {children}
        {actionUserStatus && (
          <div className="mt-6 flex items-center justify-end">
            {actionUserStatus}
          </div>
        )}
      </div>
    </article>
  );
}

Card.defaultProps = {
  subtitle: undefined,
  addClass: '',
  actionButtons: undefined,
  hideTitles: false,
  underlineHeader: true,
  dataTestId: '',
  hidePanel: false,
  headerButton: undefined,
  isLoading: false,
  addClassBody: '',
  arrowBack: false,
  onArrowBackClick: undefined,
  actionUserStatus: undefined,
};

export { Card };
