import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import { convertToFormAddressDatas } from '@utils/utils';
import { FormAddress } from '@components/formAddress/FormAddress';

function AddressMar() {
  const { t } = useTranslation();
  const { worksiteAddress, updateWorksiteAddressFromKeyValue } = useContext(
    WorksiteCreationMarContext
  );
  const [isManualAddress, setIsManualAddress] = useState(false);

  let displayedAddress = '';
  if (worksiteAddress.numberStreet && worksiteAddress.streetName) {
    displayedAddress = `${worksiteAddress.numberStreet || ''} ${
      worksiteAddress.streetName || ''
    }, ${worksiteAddress.zipCode || ''} ${worksiteAddress.city || ''}`;
  }

  const customInputIds = {
    streetNumber: 'address.worksStreetNumber',
    street: 'address.worksStreet',
    city: 'address.worksCity',
    zipCode: 'address.zipcode',
    country: 'address.country',
  };

  return (
    <div className="w-full flex flex-col space-y-[.5rem] mb-4">
      <div className="text-textGrey text-[.75rem] leading-[.75rem]">
        {t('forms.address.worksites_address')}
      </div>
      <FormAddress
        valueInitialAddress={displayedAddress}
        setIsManual={setIsManualAddress}
        isManual={isManualAddress}
        datas={convertToFormAddressDatas(worksiteAddress)}
        updateDatas={updateWorksiteAddressFromKeyValue}
        customInputIds={customInputIds}
        required
      />
    </div>
  );
}

export default AddressMar;
