import { blueOpx, green } from '@assets/color';
import {
  CheckIcon,
  ChevronDownIcon,
  LoadingIcon,
  MoreDotsIcon,
} from '@assets/images/svgComponents';
import { ColorCube } from '@components/atomic/ColorCube';
import { StatusStepEnum } from '@models/worksiteCreation/utils/enums';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { OneStep } from './OneStep';

interface SidebarProgressCardProps {
  title: string;
  status:
    | StatusStepEnum.IN_PROGRESS
    | StatusStepEnum.COMPLETED
    | StatusStepEnum.NOT_STARTED;
  steps?: { value: number; label: string }[];
  valueAsIndex?: boolean;
  nameStepActive?: string;
  addClass?: string;
  document?: boolean;
  documentLoaded?: boolean;
  lastStep?: number;
  worksiteOperationType?: number;
  onClickTitle?: () => void;
  onClickStep?: (step: number) => void;
}

function SidebarProgressCard({
  title,
  status,
  steps,
  valueAsIndex,
  nameStepActive,
  document,
  documentLoaded,
  addClass,
  onClickTitle,
  onClickStep,
  lastStep,
  worksiteOperationType,
}: SidebarProgressCardProps) {
  const [isSeeProgress, setIsSeeProgress] = useState(true);

  const notStartedClass = 'bg-gray-200 cursor-not-allowed text-gray-500';

  const iconDropdown = (
    <ChevronDownIcon
      width="1rem"
      height="1rem"
      fill={blueOpx}
      className={[
        isSeeProgress ? 'rotate-180' : '',
        'transform duration-200',
      ].join(' ')}
    />
  );
  const iconCompleted = <CheckIcon width="1rem" height="1rem" fill={green} />;
  const iconNotCompleted = (
    <MoreDotsIcon width="1rem" height="1rem" fill={green} />
  );

  const downloadDoc = <LoadingIcon width="1rem" height="1rem" fill={blueOpx} />;

  const iconRender = () => {
    if (!document) {
      switch (status) {
        case StatusStepEnum.IN_PROGRESS:
          return iconDropdown;
        case StatusStepEnum.COMPLETED:
          return iconCompleted;
        case StatusStepEnum.NOT_STARTED:
          return iconNotCompleted;
        default:
          return iconNotCompleted;
      }
    } else {
      if (documentLoaded) {
        return downloadDoc;
      }
      return iconNotCompleted;
    }
  };

  useEffect(() => {
    const currentSteps = steps?.some((s) => s.label === nameStepActive);
    setIsSeeProgress(currentSteps || false);
  }, [nameStepActive]);

  return (
    <div
      className={[
        'rounded-default2 py-[1rem] border border-borderGrey',
        addClass,
        status === StatusStepEnum.NOT_STARTED
          ? notStartedClass
          : `bg-white ${onClickTitle ? 'cursor-pointer' : ''}`,
      ].join(' ')}
    >
      <button
        type="button"
        onClick={() => {
          setIsSeeProgress(!isSeeProgress);
          if (onClickTitle) onClickTitle();
        }}
        className={[
          'flex px-[1rem] justify-between items-center w-full',
          status === StatusStepEnum.NOT_STARTED
            ? notStartedClass
            : 'cursor-pointer',
        ].join(' ')}
        disabled={status === StatusStepEnum.NOT_STARTED}
      >
        <p className="ml-2">{title}</p>
        <ColorCube
          color={status === StatusStepEnum.COMPLETED ? green : blueOpx}
          numberOrIcon={iconRender()}
          opacity
          size="1.5rem"
          dataTestId={`sidebar_${title.toLowerCase()}_${
            status === StatusStepEnum.COMPLETED ? 'completed' : 'not_completed'
          }`}
        />
      </button>
      {steps &&
        steps.length > 0 &&
        steps[0].label &&
        isSeeProgress &&
        status !== StatusStepEnum.NOT_STARTED && (
          <div className="px-[.75rem] pt-[1rem] flex flex-col space-y-[.5rem]">
            {steps
              .filter((step) => {
                // Dans une création de chantier B2B (worksiteOperationType = 2),
                // on retire "Etape information du bénéficiaire" (step.value = 2)
                return worksiteOperationType === 2 ? step.value !== 2 : step;
              })
              .map((step, index) => {
                const numberStep = step.value;
                const stepIsActive = nameStepActive === step.label;

                const stepIsDone =
                  (lastStep && lastStep >= index) ||
                  status === StatusStepEnum.COMPLETED;

                return (
                  <OneStep
                    key={v4()}
                    stepIsDone={stepIsDone}
                    nameStep={step.label}
                    numberStep={!valueAsIndex ? index + 1 : numberStep}
                    stepIsActive={stepIsActive}
                    onClick={() => onClickStep && onClickStep(numberStep)}
                  />
                );
              })}
          </div>
        )}
    </div>
  );
}

export { SidebarProgressCard };

SidebarProgressCard.defaultProps = {
  steps: [''],
  valueAsIndex: false,
  nameStepActive: '',
  addClass: '',
  document: false,
  documentLoaded: false,
  lastStep: 0,
  worksiteOperationType: undefined,
  onClickTitle: undefined,
  onClickStep: undefined,
};
