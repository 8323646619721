import OneMarForm from '@models/mar/components/worksiteCompletion/OneMarForm';
import { formAuditorPerformedBy } from '@models/mar/utils/datas';
import { useTranslation } from 'react-i18next';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { AUDITOR_TYPES } from '@models/mar/utils/enums';
import { ProSiretForm } from '@models/mar/components/worksiteCompletion/stepsCompletion/ProSiretForm';
import DynamicInputEdit from '@components/atomic/inputs/DynamicInputEdit';
import { InputTypes } from '@utils/utils';
import { fetchCollaborators } from '@models/mar/apiRequests/worksiteCreationMarRequests';
import { ICollaborator } from '@models/mar/utils/marTypes';
import { emailRegex } from '@utils/regex';
import { AuthContext } from '@context/authContext';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import { GlobalContext } from '@context/globalContext';
import { determineTypeOfAuditor } from '@models/mar/utils/functions';

interface IAuditorProps {
  setAuditorDataReady: Dispatch<SetStateAction<boolean>>;
}

function Auditor({ setAuditorDataReady }: IAuditorProps) {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { userView } = useContext(GlobalContext);
  const { worksiteData } = useContext(WorksiteCreationMarContext);
  const { watch, setValue } = useFormContext();

  const [collaborators, setCollaborators] = useState<ICollaborator[]>([]);

  const auditPerformedBy = watch('audit_performed_by')
    ? watch('audit_performed_by')
    : determineTypeOfAuditor(worksiteData, user, userView);

  const collaboratorSelected =
    auditPerformedBy === AUDITOR_TYPES.COLLABORATOR
      ? collaborators.find(
          (collaborator) =>
            `${collaborator.firstname} ${collaborator.lastname}` ===
            watch('collaborator')
        )
      : undefined;

  const getCollaborators = async () => {
    const response = await fetchCollaborators();
    if (response) {
      setCollaborators(response);
    }
  };

  const resetValues = () => {
    setValue('auditor.lastname', undefined);
    setValue('auditor.firstname', undefined);
    setValue('auditor.phone_number', undefined);
    setValue('auditor.email', undefined);
    setValue('auditor.siret', undefined);
    setValue('auditor.siren', undefined);
    setValue('auditor.company_name', undefined);
    setValue('auditor.address', undefined);
    setValue('auditor.no_rge', undefined);
    setValue('auditor.entity_id', undefined);
  };

  const checkIfAuditorDataIsComplete = (): boolean => {
    let ready =
      !!watch('auditor.lastname') &&
      watch('auditor.lastname') !== '' &&
      !!watch('auditor.firstname') &&
      watch('auditor.firstname') !== '' &&
      !!watch('auditor.phone_number') &&
      watch('auditor.phone_number') !== '' &&
      (!watch('auditor.email') || emailRegex.test(watch('auditor.email')));

    if (auditPerformedBy === AUDITOR_TYPES.ENGINEERING_OFFICE) {
      ready =
        ready &&
        !!watch('auditor.siret') &&
        watch('auditor.siret').length === 14 &&
        !!watch('auditor.siren') &&
        watch('auditor.siren') !== '' &&
        !!watch('auditor.company_name') &&
        watch('auditor.company_name') !== '' &&
        !!watch('auditor.address') &&
        watch('auditor.address') !== '';
    }

    return ready;
  };

  useEffect(() => {
    setAuditorDataReady(checkIfAuditorDataIsComplete());
  }, [watch()]);

  useEffect(() => {
    if (auditPerformedBy) {
      resetValues();
      if (auditPerformedBy === AUDITOR_TYPES.COLLABORATOR) {
        if (collaborators.length === 0) {
          getCollaborators();
        }
      } else {
        setValue('collaborator', undefined);
      }
      if (auditPerformedBy === AUDITOR_TYPES.MYSELF) {
        setValue('auditor.lastname', user?.lastname);
        setValue('auditor.firstname', user?.firstname);
        setValue('auditor.email', user?.email);
        setValue('auditor.phone_number', user?.phone_number);
        setValue('auditor.entity_id', userView?.entity_id);
      }
    }
  }, [auditPerformedBy]);

  useEffect(() => {
    if (collaboratorSelected) {
      setValue('auditor.lastname', collaboratorSelected.lastname);
      setValue('auditor.firstname', collaboratorSelected.firstname);
      setValue('auditor.email', collaboratorSelected.email);
      setValue('auditor.phone_number', collaboratorSelected.phone_number);
      setValue('auditor.entity_id', collaboratorSelected.entity_id);
    }
  }, [collaboratorSelected]);

  return (
    <div className="flex flex-col space-y-4">
      <OneMarForm
        dataForm={formAuditorPerformedBy(
          t,
          determineTypeOfAuditor(worksiteData, user, userView)
        )}
      />
      {auditPerformedBy && (
        <>
          {auditPerformedBy === AUDITOR_TYPES.ENGINEERING_OFFICE && (
            <ProSiretForm parentName="auditor" />
          )}
          {auditPerformedBy === AUDITOR_TYPES.COLLABORATOR && (
            <DynamicInputEdit
              inputType={InputTypes.SELECT}
              isEditMode
              name="collaborator"
              label={`${t(
                'mar.worksite_creation.worksite_completion.folder_details.energy_audit.auditor.collaborator'
              )}`}
              options={collaborators.map(
                (collaborator) =>
                  `${collaborator.firstname} ${collaborator.lastname}`
              )}
              initialValue={
                worksiteData && worksiteData.audit
                  ? `${worksiteData.audit.firstname} ${worksiteData.audit.lastname}`
                  : undefined
              }
              required
            />
          )}
        </>
      )}
    </div>
  );
}

export default Auditor;
