import {
  IEndOfWorkDataToSend,
  IOneQuoteForInvoice,
} from '@models/mar/utils/marTypes';
import OneMarForm from '@models/mar/components/worksiteCompletion/OneMarForm';
import { useTranslation } from 'react-i18next';
import { formInvoice } from '@models/mar/utils/datas';
import React, { useContext, useEffect, useState } from 'react';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import {
  calculateTotalHTAndTTC,
  filterOperations,
} from '@models/mar/utils/functions';
import { ILabelValue } from '@globalTypes/globalTypes';
import Gestures from '@models/mar/components/worksiteCompletion/stepsCompletion/quotes/Gestures';
import { useFormContext } from 'react-hook-form';
import { InputText } from '@components/atomic/inputs/InputText';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { validateInvoiceEndOfWork } from '@models/mar/apiRequests/worksiteCreationMarRequests';
import { createFileWithLinkedFile } from '@utils/functions';
import { ColorCube } from '@components/atomic/ColorCube';
import { blueOpx } from '@assets/color';
import { ChevronLeftIcon } from '@assets/images/svgComponents';

interface IOneInvoiceProps {
  indexInvoice: number;
  quoteData: IOneQuoteForInvoice;
  openByDefault: boolean;
}

function OneInvoice({
  indexInvoice,
  quoteData,
  openByDefault,
}: IOneInvoiceProps) {
  const { t } = useTranslation();

  const { operationsList, worksiteData, updateWorksiteData } = useContext(
    WorksiteCreationMarContext
  );

  const selectedQuote = worksiteData?.pros
    ?.map((pro) => pro.devis)
    .flat()
    .find((quote) => quote.id === quoteData.id);

  const quoteSelectedOperationsIds = new Set(
    selectedQuote?.mar_gestes?.map((geste) => geste.geste.id) ?? []
  );

  const [quoteOperations, otherOperations] = operationsList
    ? operationsList.reduce(
        ([selected, others], operation) => {
          if (quoteSelectedOperationsIds.has(Number(operation.value))) {
            selected.push(operation);
          } else {
            others.push(operation);
          }
          return [selected, others];
        },
        [[], []] as [typeof operationsList, typeof operationsList]
      )
    : [[], []];

  const [operationsSelected, setOperationsSelected] = useState<ILabelValue[]>(
    []
  );
  const [otherOperationsSelected, setOtherOperationsSelected] = useState<
    ILabelValue[]
  >([]);
  const [quoteOperationsFiltered, setQuoteOperationsFiltered] =
    useState(quoteOperations);
  const [otherOperationsFiltered, setOtherOperationsFiltered] =
    useState(otherOperations);
  const [resetSignal, setResetSignal] = useState<number>(0);
  const [isLoadingValidation, setIsLoadingValidation] =
    useState<boolean>(false);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(!openByDefault); // État pour gérer l'ouverture/fermeture

  const { watch, setValue } = useFormContext();

  const prefix = `factures.${indexInvoice}.`;
  const invoiceGestures = watch(`${prefix}gestes`);
  const invoiceOtherGestures = watch(`${prefix}gestes_autre`);
  const totalHTFieldName = `${prefix}total_cost_ht`;
  const totalHT = watch(totalHTFieldName);
  const totalTTCFieldName = `${prefix}total_cost_ttc`;
  const totalTTC = watch(totalTTCFieldName);

  const invoiceData = worksiteData?.factures?.find(
    (invoice) => invoice.mar_devis_id === quoteData.id
  );

  const readyForValidation =
    watch(`${prefix}file`) &&
    watch(`${prefix}date`) &&
    (invoiceGestures.length > 0 || invoiceOtherGestures.length > 0) &&
    invoiceGestures.every(
      (gesture: any) => gesture.operation && gesture.cost_ht && gesture.cost_ttc
    ) &&
    invoiceOtherGestures.every(
      (gesture: any) =>
        gesture.operation &&
        gesture.cost_ht &&
        gesture.cost_ttc &&
        gesture.description
    );

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed); // Bascule l'état ouvert/fermé
  };

  const validateInvoice = async () => {
    setIsLoadingValidation(true);
    const dataToSend: IEndOfWorkDataToSend = {
      file: watch(`${prefix}file`),
      mar_devis_id: quoteData.id,
      date: watch(`${prefix}date`),
      mar_gestes: [
        ...invoiceGestures.map((gesture: any) => ({
          geste_id: gesture.operation.value,
          cost_ht: gesture.cost_ht,
          cost_ttc: gesture.cost_ttc,
        })),
        ...invoiceOtherGestures.map((gesture: any) => ({
          geste_id: gesture.operation.value,
          cost_ht: gesture.cost_ht,
          cost_ttc: gesture.cost_ttc,
          description: gesture.description,
        })),
      ],
    };
    const response = await validateInvoiceEndOfWork(
      worksiteData?.id || 0,
      dataToSend
    );
    if (response) {
      updateWorksiteData((prev) => ({
        ...prev,
        ...response,
      }));
      setIsCollapsed(true);
      window.scroll(0, 0);
    }
    setIsLoadingValidation(false);
  };

  const prefillInvoice = () => {
    if (invoiceData) {
      setValue(`${prefix}id`, invoiceData.mar_devis_id);
      setValue(`${prefix}date`, invoiceData.date);
      const invoiceFile = invoiceData.linked_File;
      if (invoiceFile) {
        createFileWithLinkedFile(
          invoiceFile.file_url as string,
          invoiceFile.file_name as string
        ).then((fileToSet) => {
          setValue(`${prefix}file`, fileToSet);
        });
      }
    }
  };

  useEffect(() => {
    filterOperations(
      quoteOperations,
      setQuoteOperationsFiltered,
      operationsSelected,
      otherOperations,
      setOtherOperationsFiltered,
      otherOperationsSelected
    );
  }, [operationsSelected, otherOperationsSelected]);

  useEffect(() => {
    calculateTotalHTAndTTC(
      invoiceGestures,
      invoiceOtherGestures,
      totalHT,
      totalTTC,
      totalHTFieldName,
      totalTTCFieldName,
      setValue,
      setResetSignal
    );
  }, [watch()]);

  useEffect(() => {
    prefillInvoice();
  }, []);

  return (
    <div className="flex flex-col space-y-4 p-4 border border-borderGrey rounded-default">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={toggleCollapse}
      >
        <div className="flex space-x-2 items-center">
          <ColorCube
            size="1.5rem"
            numberOrIcon={indexInvoice + 1}
            color={blueOpx}
            opacity
          />
          <p className="text-[1.125rem] font-medium leading-0">
            {`${t(
              'mar.worksite_creation.worksite_completion.folder_details.end_of_work.invoice'
            )} ${indexInvoice + 1} - ${quoteData.name}`}
          </p>
        </div>
        <ColorCube
          numberOrIcon={
            <ChevronLeftIcon
              className={`${
                !isCollapsed ? 'rotate-[90deg]' : 'rotate-[-90deg]'
              } transform duration-200 m-auto`}
            />
          }
          size="1.5rem"
          color={blueOpx}
          opacity
        />
      </div>
      {!isCollapsed && (
        <>
          <OneMarForm dataForm={formInvoice(t, indexInvoice, invoiceData)} />
          <Gestures
            operations={quoteOperationsFiltered}
            otherOperations={otherOperationsFiltered}
            setProOperationsSelected={setOperationsSelected}
            setProOtherOperationsSelected={setOtherOperationsSelected}
            prefixForm={prefix}
            indexInvoice={indexInvoice}
          />
          <p className="font-medium text-[1rem]">
            {t(
              'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.quote_section.total_costs'
            )}
          </p>
          <div className="w-full grid grid-cols-3 gap-2 text-[.75rem] items-end">
            <InputText
              id={totalHTFieldName}
              type="text"
              name={totalHTFieldName}
              label={`${t(
                'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.quote_section.cost_ht'
              )}`}
              placeholder=""
              resetSignal={resetSignal}
              disabled
              required
            />
            <InputText
              id={totalTTCFieldName}
              type="text"
              name={totalTTCFieldName}
              label={`${t(
                'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.quote_section.cost_ttc'
              )}`}
              placeholder=""
              resetSignal={resetSignal}
              disabled
              required
            />
            <ButtonOpx
              label={
                watch(`${prefix}id`)
                  ? t(
                      'mar.worksite_creation.worksite_completion.folder_details.end_of_work.edit_invoice'
                    )
                  : t(
                      'mar.worksite_creation.worksite_completion.folder_details.end_of_work.validate_invoice'
                    )
              }
              onClick={validateInvoice}
              disabled={!readyForValidation || isLoadingValidation}
              isLoading={isLoadingValidation}
              type="tab"
              addClass="w-full text-[1rem]"
            />
          </div>
        </>
      )}
    </div>
  );
}

export default OneInvoice;
