import LayoutMarDetailsStep from '@models/mar/components/worksiteCompletion/LayoutMarDetailsStep';
import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import {
  generateInvoiceWorksCertificate,
  getQuotesSelected,
  validateEndOfWork,
} from '@models/mar/apiRequests/worksiteCreationMarRequests';
import { STEPS_WORKSITE_MAR_COMPLETION } from '@models/mar/utils/enums';
import OneInvoice from '@models/mar/components/worksiteCompletion/stepsCompletion/endOfWork/OneInvoice';
import { IOneQuoteForInvoice, IWorksiteMar } from '@models/mar/utils/marTypes';
import LoaderInvoice from '@models/mar/components/worksiteCompletion/stepsCompletion/endOfWork/LoaderInvoice';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { useTranslation } from 'react-i18next';
import { downloadWhenGenerated } from '@models/mar/utils/functions';

function EndOfWork() {
  const { t } = useTranslation();
  const {
    worksiteData,
    updateButtonNextDisabled,
    updateWorksiteData,
    updateStepCompletion,
    operationsList,
  } = useContext(WorksiteCreationMarContext);

  const [buttonNextLoading, setButtonNextLoading] = useState(false);
  const [loadingGeneration, setLoadingGeneration] = useState(false);
  const [quotesList, setQuotesList] = useState<IOneQuoteForInvoice[]>();

  const { watch } = useFormContext();

  const checkIfInfosAreFilled =
    worksiteData?.factures?.length === quotesList?.length;

  const handleValidateStep = async () => {
    setButtonNextLoading(true);
    const response = await validateEndOfWork(worksiteData?.id || 0);

    if (response) {
      updateWorksiteData((prev) => ({
        ...prev,
        ...response,
      }));
      updateStepCompletion(STEPS_WORKSITE_MAR_COMPLETION.AFTER_WORK);
    }
    setButtonNextLoading(false);
  };

  const getQuotesList = async () => {
    const response = await getQuotesSelected(worksiteData?.id || 0);
    if (response) {
      setQuotesList(response);
    }
  };

  const generateWorksCertificate = async () => {
    setLoadingGeneration(true);
    const response = await generateInvoiceWorksCertificate(
      worksiteData?.id || 0
    );
    if (response && response.file_url) {
      downloadWhenGenerated(
        response.file_url,
        'mar_works_certificate_invoice.pdf'
      );
      updateWorksiteData((prev) => ({
        ...(prev as IWorksiteMar),
        linked_files: [...(prev as IWorksiteMar).linked_files, response],
      }));
    }
    setLoadingGeneration(false);
  };

  useEffect(() => {
    updateButtonNextDisabled(!checkIfInfosAreFilled);
  }, [watch()]);

  useEffect(() => {
    getQuotesList();
  }, []);

  return (
    <LayoutMarDetailsStep
      onValidate={handleValidateStep}
      buttonNextLoading={buttonNextLoading}
      buttonAdditionnal={
        <ButtonOpx
          label={`${t(
            'mar.worksite_creation.worksite_completion.folder_details.end_of_work.generate_work_attestation'
          )}`}
          type="tab"
          disabled={
            !worksiteData?.factures ||
            worksiteData.factures.length !== quotesList?.length
          }
          onClick={generateWorksCertificate}
          isLoading={loadingGeneration}
          small
        />
      }
    >
      <div className="flex flex-col space-y-6">
        {quotesList && operationsList ? (
          quotesList.map((quote, index) => (
            <OneInvoice
              key={`quote-${quote.id}`}
              indexInvoice={index}
              quoteData={quote}
              openByDefault={!watch(`factures.${index}.date`)}
            />
          ))
        ) : (
          <LoaderInvoice />
        )}
      </div>
    </LayoutMarDetailsStep>
  );
}

export default EndOfWork;
