import { Map } from '@models/worksiteCreation/components/Map';
import ListInfosFolderMar from '@models/mar/components/worksiteCompletion/rightPart/ListInfosFolderMar';
import { useTranslation } from 'react-i18next';

function FolderMarDetailsRecap() {
  const { t } = useTranslation();
  return (
    <div className="w-full bg-white rounded-default2 pb-[1rem] border border-borderGrey">
      <div className="p-[1.5rem] flex justify-between w-full items-center border-b border-b-borderGrey">
        <p className="text-[1.5rem] font-medium">
          {t('worksite_creation.simulation.summary.title')}
        </p>
      </div>
      <div className="p-6 min-h-[70vh] flex flex-col space-y-6">
        <div className="relative min-h-[25vh] h-[25vh]">
          <Map />
        </div>
        <ListInfosFolderMar />
      </div>
    </div>
  );
}

export default FolderMarDetailsRecap;
