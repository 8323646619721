import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { HeaderContext } from '@context/headerContext';

import { PartnersList } from '@models/partners/components/PartnersList';
import { PartnersDetails } from '@models/partners/components/PartnersDetails';
import { PARTNERS_MENU_MAPPING } from '@models/partners/utils/partnersConstants';
import { ENTITY_TYPES } from '@utils/roles';

function Partners() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { installerId } = useParams();
  const { pathname } = useLocation();
  const location = useLocation();
  const navigate = useNavigate();

  const isPageInstallers = pathname.includes('installers');

  const { updateTitleHeader, refreshHeader } = useContext(HeaderContext);

  const [entityType, setEntityType] = useState<number | null>(null);

  const [selectedTab, setSelectedTab] = useState<string>('');

  const changeTab = (newEntityType: number | null) => {
    let hash;
    if (newEntityType) {
      hash = PARTNERS_MENU_MAPPING[newEntityType]?.hash;
    }
    if (hash) {
      // Cela change le hash sans recharger la page
      navigate(`#${hash}`);
    } else {
      navigate(``);
    }
  };

  useEffect(() => {
    if (entityType === ENTITY_TYPES.SOUS_TRAITANT && !id) {
      updateTitleHeader(t('sidebar.subcontractors') || '');
    } else if (isPageInstallers && !id) {
      updateTitleHeader(t('partners.installers.title') || '');
    } else {
      updateTitleHeader(String(t('partners.main.title')));
    }
  }, [entityType, id, isPageInstallers]);

  useEffect(() => {
    if (!id) {
      refreshHeader();
    }
  }, []);

  useEffect(() => {
    const tabLabel = t(
      String(PARTNERS_MENU_MAPPING[Number(entityType)]?.title || '')
    );
    setSelectedTab(tabLabel);
  }, [entityType]);

  useEffect(() => {
    const currentHash = location.hash.replace('#', '');
    // Logique pour déterminer entityType basé sur le hash actuel
    const newEntityType = Object.keys(PARTNERS_MENU_MAPPING).find(
      (key) => PARTNERS_MENU_MAPPING[key].hash === currentHash
    );
    setEntityType(newEntityType ? Number(newEntityType) : null);

    if (id && !newEntityType && !isPageInstallers) {
      navigate('/partners');
    }
  }, [location]);

  return (
    <div className="w-full">
      {/* eslint-disable-next-line no-nested-ternary */}
      {!id ? (
        <PartnersList
          entityType={isPageInstallers ? ENTITY_TYPES.INSTALLATEUR : entityType}
          changeTab={changeTab}
          isInstaller={isPageInstallers}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      ) : entityType || isPageInstallers ? (
        <PartnersDetails
          entityType={isPageInstallers ? ENTITY_TYPES.INSTALLATEUR : entityType}
          id={Number(id)}
          installerId={Number(installerId)}
        />
      ) : null}
    </div>
  );
}

export { Partners };
