import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { GlobalContext } from '@context/globalContext';
import { AddIcon } from '@assets/images/svgComponents';
import { useTranslation } from 'react-i18next';
import { MAR_ROUTES, WORKSITES_ROUTES } from '@utils/routesUrls';
import { ROLES } from '@utils/roles';

function ButtonNewWorksite() {
  const { sidebarMenuIsOpen, roleUser } = useContext(GlobalContext);
  const { t } = useTranslation();

  return (
    <Link
      to={
        roleUser === ROLES.MAR
          ? MAR_ROUTES.WORKSITES_NEW
          : WORKSITES_ROUTES.WORKSITES_NEW
      }
    >
      <div
        className={`py-[.5rem]  ${
          sidebarMenuIsOpen
            ? 'flex space-x-[1rem] bg-blueOpx px-[1.5rem] rounded-default'
            : 'items-center flex justify-center w-auto'
        }  mt-[1.5rem] items-center `}
        data-test-id="new_worksite"
      >
        <div
          className={
            !sidebarMenuIsOpen ? 'w-auto rounded-default bg-blueOpx p-1' : ''
          }
        >
          <AddIcon width="1.5rem" height="1.5rem" fill="white" />
        </div>
        {sidebarMenuIsOpen && (
          <p className="text-[1rem]">{t('worksites.new_work_site')}</p>
        )}
      </div>
    </Link>
  );
}

export { ButtonNewWorksite };
