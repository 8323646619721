/* eslint-disable consistent-return */
import { axiosClient } from '@api/apiClient';
import {
  urlGetCancelSignatureProcedure,
  urlPostResendSignatureProcedure,
} from '@api/apiUrls';
import { displayError } from '@utils/format';

interface ISignatureStoreData {
  beneficiary_email: string;
  beneficiary_phone_number: string;
  entity_id: number;
  signatory_id: number;
  linked_file_id: number;
}

export const getCancelSignatureProcedure = async (id: number) => {
  try {
    const res = await axiosClient.get(urlGetCancelSignatureProcedure(id));
    return res.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const postResendSignatureProcedure = async (
  signatureProcedure_id: number,
  data: ISignatureStoreData
) => {
  try {
    const res = await axiosClient.post(
      urlPostResendSignatureProcedure(signatureProcedure_id),
      {
        beneficiary_email: data.beneficiary_email,
        beneficiary_phone_number: data.beneficiary_phone_number,
        entity_id: data.entity_id,
        signatory_id: Number(data.signatory_id),
        linked_file_id: data.linked_file_id,
      }
    );
    return res.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};
