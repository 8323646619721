import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { blueOpx, red, textGrey, white } from '@assets/color';
import { useTranslation } from 'react-i18next';
import {
  ChangeIcon,
  ChatBubbleIcon,
  ChatUnreadIcon,
  NotificationsIcon,
} from '@assets/images/svgComponents';
import { useContext, useEffect, useMemo, useState } from 'react';
import { RequestOrMessageOrChangeEnum } from '@utils/enums';
import { RequestAndActivityIntoModalOrCardContext } from '@context/requestAndChangeContext';
import { LoaderDivSkeleton } from '@components/loaders/LoaderDivSkeleton';
import { useParams } from 'react-router-dom';
import { convertHexToRGBA } from '../../../utils/functions';

function HeaderCardRequestAndChanges() {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    requestOrChangeEnumInCard,
    updateRequestOrChangeEnumInCard,
    listRequests,
    isLoadingListRequestOrChange,
    listMessagesRequests,
    updateRequestOrChangeTab,
    requestOrChangeTab,
  } = useContext(RequestAndActivityIntoModalOrCardContext);

  const [idWorksiteEnumInitialised, setIdWorksiteEnumInitialised] =
    useState<string>();

  const change =
    requestOrChangeTab === RequestOrMessageOrChangeEnum.CHANGE ||
    requestOrChangeEnumInCard === RequestOrMessageOrChangeEnum.CHANGE;
  const request =
    requestOrChangeTab === null &&
    requestOrChangeEnumInCard === RequestOrMessageOrChangeEnum.REQUEST;
  const message =
    requestOrChangeTab === null &&
    requestOrChangeEnumInCard === RequestOrMessageOrChangeEnum.MESSAGE;

  const requestIconColor = useMemo(() => {
    if (!request) return listRequests.length > 0 ? red : textGrey;
    return listRequests.length > 0 ? red : blueOpx;
  }, [listRequests, request]);

  const listRequestsIsInitialized =
    listRequests.length === 0 ||
    (listRequests.length > 0 &&
      listRequests[0].id !== 0 &&
      listRequests.some((el) => el.relation_id === Number(id)));

  const listMessagesRequestsIsInitialized =
    listMessagesRequests.length === 0 ||
    (listMessagesRequests.length > 0 &&
      listMessagesRequests[0].id !== 0 &&
      listMessagesRequests.some((el) => el.relation_id === Number(id)));

  useEffect(() => {
    /* On check si les points suivants sont true :
     * - l'id du worksite a changé
     * - la liste des requests est initialisée
     * - la liste des messages est initialisée
     */
    if (
      idWorksiteEnumInitialised !== id &&
      listRequestsIsInitialized &&
      listMessagesRequestsIsInitialized
    ) {
      // Quand on arrive sur la page, on veut afficher les requests si il y en a, sinon les messages s'il y en a, sinon les activités
      if (listRequests.length > 0 && listRequests[0].id !== 0) {
        updateRequestOrChangeEnumInCard(RequestOrMessageOrChangeEnum.REQUEST);
        setIdWorksiteEnumInitialised(id);
      } else if (
        listMessagesRequests.length > 0 &&
        listMessagesRequests[0].id !== 0
      ) {
        updateRequestOrChangeEnumInCard(RequestOrMessageOrChangeEnum.MESSAGE);
        setIdWorksiteEnumInitialised(id);
      } else {
        updateRequestOrChangeEnumInCard(RequestOrMessageOrChangeEnum.CHANGE);
      }
    }
  }, [listRequests, listMessagesRequests]);

  return (
    <div className="w-full p-6 flex items-center justify-between border-b border-b-borderGrey">
      <div className="flex">
        {!isLoadingListRequestOrChange ? (
          <>
            <ButtonOpx
              icon={<NotificationsIcon />}
              color={requestIconColor}
              backgroundColor={
                request ? convertHexToRGBA(requestIconColor, 0.1) : white
              }
              label={`${listRequests.length} ${t(
                'requests_and_changes.request'
              )}${listRequests.length > 1 ? 's' : ''}`}
              addClass="!border-none"
              onClick={() => {
                updateRequestOrChangeEnumInCard(
                  RequestOrMessageOrChangeEnum.REQUEST
                );
                updateRequestOrChangeTab(null);
              }}
            />
            <ButtonOpx
              icon={
                listMessagesRequests.length > 0 ? (
                  <ChatUnreadIcon />
                ) : (
                  <ChatBubbleIcon />
                )
              }
              color={message ? blueOpx : textGrey}
              backgroundColor={message ? convertHexToRGBA(blueOpx, 0.1) : white}
              label={`${listMessagesRequests.length} ${t('global.message')}${
                listMessagesRequests.length > 1 ? 's' : ''
              }`}
              onClick={() => {
                updateRequestOrChangeEnumInCard(
                  RequestOrMessageOrChangeEnum.MESSAGE
                );
                updateRequestOrChangeTab(null);
              }}
              addClass="!border-none"
            />
            <ButtonOpx
              icon={<ChangeIcon />}
              color={change ? blueOpx : textGrey}
              backgroundColor={change ? convertHexToRGBA(blueOpx, 0.1) : white}
              label={t('requests_and_changes.changes')}
              onClick={() => {
                updateRequestOrChangeEnumInCard(
                  RequestOrMessageOrChangeEnum.CHANGE
                );
                updateRequestOrChangeTab(null);
              }}
              addClass="!border-none"
            />
          </>
        ) : (
          <>
            <LoaderDivSkeleton height="2.5rem" width="8rem" />
            <LoaderDivSkeleton height="2.5rem" width="8rem" />
            <LoaderDivSkeleton height="2.5rem" width="8rem" />
          </>
        )}
      </div>
    </div>
  );
}

export { HeaderCardRequestAndChanges };
