import { t } from 'i18next';
import { IWorksiteDetails } from '@models/worksites/utils/worksitesTypes';
import {
  BriefcaseIcon,
  CalendarIcon,
  FileIcon,
  HouseIcon,
  MailIcon,
  PersonIcon,
  PhoneIcon,
  PositionIcon,
} from '@assets/images/svgComponents';
import { dateToDDMMYYY, numberWithSeparator } from '@utils/format';
import { OperationTypeEnum } from '@utils/enums';
import { InputTypes } from '@utils/utils';
import { ACTIVITY } from '@models/worksiteCreation/utils/enums';
import { IGlobalEnumType } from '../../../../types/globalTypes';

const installerIcons = (worksiteDatas: IWorksiteDetails) => {
  const { installer, worksite_referent } = worksiteDatas;

  return [
    {
      title: t('partners.company_name'),
      subtitle: installer.company_name,
      icon: <BriefcaseIcon />,
    },
    {
      title: t('partners.address'),
      subtitle: installer.address,
      icon: <PositionIcon />,
    },
    {
      title: t('partners.email_address'),
      subtitle: worksite_referent.contact?.email || '',
      icon: <MailIcon />,
    },
    {
      title: t('partners.phone'),
      subtitle: worksite_referent.contact?.phone_number || '',
      icon: <PhoneIcon />,
    },
  ];
};

const worksiteIcons = (worksite: IWorksiteDetails) => {
  return {
    infos: [
      {
        title: t('worksite_creation.steps.worksite_creation'),
        subtitle: worksite.dates?.creation
          ? dateToDDMMYYY(worksite.dates?.creation)
          : '-',
        icon: <CalendarIcon />,
        addClass: 'mb-5',
      },
      {
        title: t('worksites.projected_end_date'),
        subtitle: worksite.dates?.projected_end
          ? dateToDDMMYYY(worksite.dates?.projected_end)
          : '-',
        addClass: 'mb-5',
      },
    ],
  };
};

const housingIcons = (
  worksite: IWorksiteDetails,
  globalEnum: IGlobalEnumType,
  removal: string
) => {
  const { housing } = worksite;

  const payload = worksite.payload ? JSON.parse(worksite.payload) : null;

  const isB2c = worksite.operation_type === OperationTypeEnum.B2C;

  return [
    isB2c || (payload && payload['general.habitationNumberContracted'])
      ? {
          title: t('worksite_creation.simulation.habitation.title'),
          subtitle: globalEnum.housing_type[housing.housing_type],
          icon: <HouseIcon />,
        }
      : null,
    payload && payload['general.habitationZone']
      ? {
          title: t('worksite_creation.simulation.habitation.type_of_building'),
          subtitle: t(
            `worksite_creation.simulation.habitation.${String(
              payload['general.habitationZone']
            )}`
          ),
          icon: !payload['general.habitationNumberContracted'] && <HouseIcon />,
        }
      : null,
    {
      title: t('worksites.housing.housing_age'),
      subtitle: housing ? globalEnum.age_type[housing.age_type] : '',
    },
    housing.surface
      ? {
          title: t('worksites.housing.surface_area'),
          subtitle: `${housing.surface} m2`,
        }
      : null,
    {
      title: t('worksites.housing.removal'),
      subtitle: removal,
    },
    payload && payload['general.habitationNumber']
      ? {
          title: t('worksite_creation.simulation.habitation.total_number'),
          subtitle: String(payload['general.habitationNumber']),
        }
      : null,
    payload && payload['general.habitationNumberQpv']
      ? {
          title: t('worksite_creation.simulation.habitation.qpv_number'),
          subtitle: String(payload['general.habitationNumberQpv']),
        }
      : null,
    payload && payload['general.habitationNumberContracted']
      ? {
          title: t('worksite_creation.simulation.habitation.contracted_number'),
          subtitle: String(payload['general.habitationNumberContracted']),
        }
      : null,
  ];
};

const beneficiaryIcons = (
  worksite: IWorksiteDetails,
  globalEnum: IGlobalEnumType
) => {
  const { beneficiary, fiscalDeclarations } = worksite;
  const fiscalDeclaration = fiscalDeclarations ? fiscalDeclarations[0] : null;
  const personNumber = worksite.housing
    ? `${worksite.housing.persons} ${t('global.people')}${
        worksite.housing.persons > 1 ? 's' : ''
      }`
    : '-';

  const payload = worksite.payload ? JSON.parse(worksite.payload) : null;

  let clientType = worksite.housing
    ? globalEnum.occupation_type[worksite.housing.occupation_type]
    : '';

  if (payload && payload['general.activity']) {
    switch (payload['general.activity']) {
      case ACTIVITY.SYNDIC:
        clientType = t('worksite_creation.simulation.habitation.syndic');
        break;
      case ACTIVITY.SOCIAL_LANDLORD:
        clientType = t(
          'worksite_creation.simulation.habitation.social_landlord'
        );
        break;
      default:
        break;
    }
  }

  const worksiteAddress = worksite.address;

  const isB2b = worksite.operation_type === OperationTypeEnum.B2B;

  return {
    top: [
      isB2b
        ? {
            title: t('worksite_creation.partners.siret'),
            subtitle: beneficiary.siret,
            addClass: 'mb-5',
            icon: <BriefcaseIcon />,
          }
        : undefined,
      {
        title: t('partners.email_address'),
        subtitle: beneficiary.email,
        icon: <MailIcon />,
        addClass: 'mb-5',
      },
      {
        title: t('partners.address'),
        subtitle: beneficiary.address.address,
        icon: <PositionIcon />,
        addClass: 'mb-5',
      },
      {
        title: t('infos.zipcode_city'),
        subtitle: `${beneficiary.address.postal_code} ${beneficiary.address.city}`,
        addClass: 'mb-5',
      },
      !fiscalDeclaration
        ? undefined
        : {
            title: t('worksites.beneficiary.fiscality_number'),
            subtitle: String(fiscalDeclaration.fiscal_number),
            addClass: 'mb-5',
            icon: <FileIcon />,
          },
    ],
    bottom: [
      isB2b
        ? undefined
        : {
            title: t('worksites.housing.household_incomes'),
            subtitle: globalEnum.precarity_type[worksite.precarity_type],
            addClass: 'mb-5',
            icon: <BriefcaseIcon />,
          },
      isB2b
        ? undefined
        : {
            title: t('worksites.housing.household_people'),
            subtitle: personNumber,
            addClass: 'mb-5',
            icon: <BriefcaseIcon />,
          },
      {
        title: t('worksites.housing.client_type'),
        subtitle: clientType,
        addClass: 'mb-5',
        icon: <BriefcaseIcon />,
      },
      {
        title: t('worksites.address'),
        subtitle: worksiteAddress ? worksiteAddress.address : '',
        addClass: 'mb-5',
        icon: <PositionIcon />,
      },
      {
        title: t('infos.zipcode_city'),
        subtitle: worksiteAddress
          ? `${worksiteAddress.postal_code} ${worksiteAddress.city}`
          : '',
        addClass: 'mb-5',
      },
    ],
  };
};

const operationIcons = (worksite: IWorksiteDetails) => {
  return worksite.worksites_operations.map((op, i) => {
    const docData = worksite.document_operations_data?.find((d) => {
      return String(d.slug?.value) === op.operation.code.toLowerCase();
    });

    const primeAmount = op.cdp_amount || op.cee_amount;

    const extraData = Object.entries(docData || [])
      .filter(
        ([k, v]) =>
          k !== 'slug' &&
          v.value &&
          v.value !== '' &&
          typeof v.value === 'string'
      )
      .map(([, value]) => {
        let subtitle = value.value;
        if (value.value === 'true') subtitle = t('settings.yes');
        if (value.value === 'false') subtitle = t('settings.no');

        if (value.type === InputTypes.BOOL) {
          if (value.value === 'false') {
            subtitle = t('settings.no');
          }
          if (value.value === 'true') {
            subtitle = t('settings.yes');
          }
        }
        if (value.type === InputTypes.DATE) {
          subtitle = dateToDDMMYYY(value.value);
        }

        return {
          title: value.label,
          subtitle,
          icon: null,
        };
      });

    return [
      {
        title: `Opération ${i + 1}`,
        subtitle: op.operation.description,
        icon: i + 1,
      },
      {
        title: t('worksites.operation.bonus_cee'),
        subtitle: `${numberWithSeparator(String(primeAmount)).replace(
          '.',
          ','
        )} €`,
        icon: <>€</>,
      },
      op.total_cost > 0
        ? {
            title: t('worksites.operation.total_cost'),
            subtitle: `${numberWithSeparator(String(op.total_cost)).replace(
              '.',
              ','
            )} €`,
          }
        : null,
      !op.subcontractor
        ? {
            title: t('worksites.subcontractor.title'),
            subtitle: t('worksites.subcontractor.select_none'),
          }
        : {
            title: t('partners.subcontractors.name'),
            subtitle: op.subcontractor.company_name || '',
            icon: <PersonIcon />,
          },
      !op.subcontractor
        ? null
        : {
            title: t('partners.siret'),
            subtitle: op.subcontractor.siret || '',
          },
      !op.subcontractor
        ? null
        : {
            title: t('partners.address'),
            subtitle: `${op.subcontractor.address}`,
          },
      !op.subcontractor
        ? null
        : {
            title: t('partners.phone'),
            subtitle: `${op.subcontractor.phone_number}`,
          },
      !op.subcontractor
        ? null
        : {
            title: t('partners.email_address'),
            subtitle: `${op.subcontractor.contact_email}`,
          },
      ...extraData,
    ];
  });
};

export {
  installerIcons,
  housingIcons,
  worksiteIcons,
  beneficiaryIcons,
  operationIcons,
};
