import { useContext, useEffect, useMemo, useState } from 'react';

import { AuthContext } from '@context/authContext';
import { GlobalContext } from '@context/globalContext';

import { SheetPartnerOrInstaller } from '@models/partners/components/SheetPartnerOrInstaller';
import { SheetSubcontractor } from '@models/partners/components/SheetSubcontractor';

import { getPartnerActiveTabs } from '@models/partners/apiRequests/partnersFormRequest';
import { getPartnersMenu } from '@models/partners/apiRequests/partnersRequest';
import { HeaderContext } from '@context/headerContext';
import { ENTITY_TYPES } from '@utils/roles';
import { IPartnerSubmenu } from '../utils/types/partnersType';

interface IPartnersDetailsProps {
  entityType: number | null;
  id: number;
  installerId: number | null;
}

function PartnersDetails({
  entityType,
  id,
  installerId,
}: IPartnersDetailsProps) {
  const { user } = useContext(AuthContext);
  const {
    updateDisplayBackButtonHeader,
    updateTagHeader /* updateRouteFrom */,
  } = useContext(HeaderContext);
  const {
    partnersMenu,
    updatePartnersMenu,
    updateUserView,
    updateListUserViews,
    globalEnum,
  } = useContext(GlobalContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [tabs, setTabs] = useState<IPartnerSubmenu[]>([]);

  const authUserIsInstaller = useMemo(
    () => ['installateur-admin'].includes(user?.role_name || ''),
    [user]
  );

  // NOTE: Getting tabs for selected partner
  useEffect(() => {
    updateDisplayBackButtonHeader(true);
    updateTagHeader(undefined);
    // updateRouteFrom('/partners');
    setLoading(true);
    if (entityType) {
      if (partnersMenu.length < 1) {
        getPartnersMenu(
          updatePartnersMenu,
          updateListUserViews,
          updateUserView
        );
      } else if (globalEnum.entity_type && tabs.length === 0) {
        getPartnerActiveTabs(Number(id), entityType)
          .then((formattedPartnersSubMenu) => setTabs(formattedPartnersSubMenu))
          .finally(() => setLoading(false));
      }
      setLoading(false);
    }
  }, [entityType, partnersMenu, globalEnum, id]);

  return useMemo(() => {
    switch (entityType) {
      case ENTITY_TYPES.INSTALLATEUR:
        return (
          <SheetPartnerOrInstaller
            loadingTabs={loading}
            partnersSubMenu={[...tabs]}
            entityType={entityType}
            isSheetPartner={false}
          />
        );
      case ENTITY_TYPES.SOUS_TRAITANT:
        return (
          <SheetSubcontractor
            installerId={installerId}
            loadingTabs={loading}
            partnersSubMenu={tabs}
          />
        );
      default:
        return (
          <SheetPartnerOrInstaller
            isSheetPartner
            isInstaller={authUserIsInstaller}
            loadingTabs={loading}
            partnersSubMenu={tabs}
            entityType={entityType}
          />
        );
    }
  }, [entityType, loading, tabs, authUserIsInstaller, id]);
}

export { PartnersDetails };
