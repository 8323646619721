import { TextWithRadio } from '@components/atomic/inputs/controls/TextWithRadio';
import { IDataMarField } from '@models/mar/utils/marTypes';
import { useFormContext } from 'react-hook-form';
import { ILabelValue } from '../../../types/globalTypes';

interface IChoiceMarProps {
  field: IDataMarField;
}

function ChoiceMar({ field }: IChoiceMarProps) {
  const { watch, setValue } = useFormContext();
  return (
    <div className="col-span-2 flex flex-col space-y-[1rem]">
      {field.options &&
        field.options.map((option: ILabelValue) => {
          const fieldValue = watch(field.name);
          const selectValue = fieldValue ? fieldValue.toString() : '';
          const isChecked = Number(fieldValue) === Number(option.value);
          const onSelect = (e: string) => setValue(field.name, e);

          return (
            <TextWithRadio
              key={option.label}
              label={option.label}
              value={option.value.toString()}
              addClass="flex-grow"
              selectValue={selectValue}
              isChecked={isChecked}
              setSelectedValue={(e) => onSelect(e)}
            />
          );
        })}
    </div>
  );
}

export default ChoiceMar;
