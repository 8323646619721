import { blueOpx } from '@assets/color';
import { AddIcon, CrossIcon } from '@assets/images/svgComponents';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { SearchBar } from '@components/SearchBar';
import { initialSubcontractor } from '@models/worksiteCreation/utils/initialsValues/worksitesInitialValues';
import {
  ISubcontractors,
  IWorksiteOperation,
  IWorksiteSuboperation,
} from '@models/worksiteCreation/utils/types/worksitesType';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { AddSubcontractorForm } from '@models/partners/components/subcontractors/AddSubcontractorForm';
import { IEntity } from '@models/worksiteCreation/utils/types/conventionsTypes';

interface ISelectSubcontractorProps {
  listSubcontractors: ISubcontractors[];
  operation: IWorksiteOperation | IWorksiteSuboperation;
  getList: CallableFunction;
  selectedSubcontractor: IEntity | undefined;
}

function SelectSubcontractor({
  listSubcontractors,
  operation,
  getList,
  selectedSubcontractor,
}: ISelectSubcontractorProps) {
  const { t } = useTranslation();
  const {
    arraySubcontractorDataPost,
    updateArraySubcontractorDataPost,
    readOnly,
  } = useContext(WorksiteCreationContext);
  const [isModalNewSubcontractor, setIsModalNewSubcontractor] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [subcontractorActif, setSubcontractorActif] =
    useState<ISubcontractors>(initialSubcontractor);

  const [listSubcontractorFiltered, setListSubcontractorsFiltered] =
    useState<ISubcontractors[]>(listSubcontractors);

  const prevListRef = useRef<ISubcontractors[]>();

  useEffect(() => {
    setListSubcontractorsFiltered(listSubcontractors);
  }, [listSubcontractors]);

  useEffect(() => {
    const subcontractor = listSubcontractors.find(
      (elt) => elt.id_partenaire === selectedSubcontractor?.id
    );
    if (subcontractor) setSubcontractorActif(subcontractor);
  }, [selectedSubcontractor, listSubcontractors]);

  useEffect(() => {
    let newArray = arraySubcontractorDataPost || [];

    const isIdInArray = newArray.find((obj) => obj.id === operation.id);
    if (!isIdInArray) {
      newArray.push({
        id: operation.id,
        toId: subcontractorActif.id_partenaire,
      });
    } else {
      const updatedArray = newArray.map((obj) => {
        if (obj.id === operation.id) {
          return { ...obj, toId: subcontractorActif.id_partenaire };
        }
        return obj;
      });
      newArray = updatedArray;
    }
    updateArraySubcontractorDataPost(newArray);
  }, [subcontractorActif]);

  // sélectionne automatiquement le sous-traitant qui vient d'être créé
  useEffect(() => {
    if (!prevListRef.current) {
      prevListRef.current = listSubcontractorFiltered;
      return;
    }

    const currentList = prevListRef.current;

    if (listSubcontractorFiltered.length > currentList.length) {
      const newSubcontractors = listSubcontractorFiltered.filter(
        (subcontractor) =>
          !currentList.some(
            (item) => item.id_partenaire === subcontractor.id_partenaire
          )
      );
      if (newSubcontractors.length > 0 && isNew) {
        setSubcontractorActif(newSubcontractors[0]);
        setIsNew(false);
      }
    }
    // reinitialize
    prevListRef.current = listSubcontractorFiltered;
  }, [listSubcontractorFiltered]);

  return (
    <div className="mt-[1.5rem] w-full ">
      {subcontractorActif.company_name !== '' ? (
        <div className="w-full flex items-center justify-between p-[.5rem] rounded-default border border-borderGrey">
          <p>{subcontractorActif?.company_name}</p>
          <button
            type="button"
            onClick={() => {
              setSubcontractorActif(initialSubcontractor);
            }}
          >
            <CrossIcon fill={blueOpx} width="1.5rem" height="1.5rem" />
          </button>
        </div>
      ) : (
        <div className="mt-[1.5rem] flex space-x-[.5rem] ">
          <InputSelect
            placeholder={t(
              'worksite_creation.create_worksite.subcontractor.search_subcontractor'
            )}
            valueInput={
              subcontractorActif.company_name !== ''
                ? subcontractorActif.company_name
                : ''
            }
            addClass="!w-full"
            defaultSelected={selectedSubcontractor?.company_name}
            dataTestIdSelect="input_select_subcontractor"
            disabled={readOnly}
          >
            <div className="flex flex-col max-h-[25rem] overflow-y-scroll scroll-invisible">
              <SearchBar
                placeholder={t(
                  'worksite_creation.create_worksite.subcontractor.search_subcontractor'
                )}
                onSearch={(value) =>
                  setListSubcontractorsFiltered(
                    listSubcontractors.filter((subcontractor) =>
                      subcontractor.company_name
                        .toLowerCase()
                        .includes(value.toLowerCase())
                    )
                  )
                }
                addClass="!w-full !rounded-[0px] !border !border-borderGrey !border-t-transparent"
              />
              {listSubcontractorFiltered
                .filter(
                  (subcontractorFiltered: ISubcontractors) =>
                    subcontractorFiltered.id_partenaire !==
                    subcontractorActif.id_partenaire
                )
                .map((subcontractor, index) => {
                  return (
                    <button
                      data-test-id="option_subcontractor"
                      onClick={() => setSubcontractorActif(subcontractor)}
                      className={`flex items-center text-start w-full px-[1rem] py-[.5rem] border-b border-b-borderGrey border-r border-r-borderGrey border-l border-l-borderGrey ${
                        index ===
                        listSubcontractorFiltered.filter(
                          (subcontractorFiltered: ISubcontractors) =>
                            subcontractorFiltered.id_partenaire !==
                            subcontractorActif.id_partenaire
                        ).length -
                          1
                          ? 'rounded-b-default'
                          : ''
                      }`}
                      key={v4()}
                      type="button"
                    >
                      {subcontractor.company_name}
                    </button>
                  );
                })}
              {listSubcontractorFiltered.length === 0 && (
                <p className=" text-start w-full px-[1rem] py-[.5rem] border-b border-b-borderGrey border-r border-r-borderGrey border-l border-l-borderGrey rounded-b-default">
                  {t(
                    'worksite_creation.create_worksite.subcontractor.no_subcontractor'
                  )}
                </p>
              )}
            </div>
          </InputSelect>
          <ButtonOpx
            onClick={() => {
              setIsModalNewSubcontractor(true);
              setIsNew(true);
            }}
            label={t('buttons.new_subcontractor')}
            type="primary"
            addClass="self-start"
            icon={<AddIcon />}
            dataTestId="button_add_new_subcontractor"
          />
        </div>
      )}

      {isModalNewSubcontractor && (
        <AddSubcontractorForm
          setModal={setIsModalNewSubcontractor}
          refreshList={getList}
          backgroundTransparent={false}
          sidebarVisible={false}
        />
      )}
    </div>
  );
}

export { SelectSubcontractor };
