import React, { useContext, useEffect, useState } from 'react';
import LayoutMarDetailsStep from '@models/mar/components/worksiteCompletion/LayoutMarDetailsStep';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import BoolMar from '@models/mar/components/BoolMar';
import { createFileWithLinkedFile, stringToBoolean } from '@utils/functions';
import { TextError } from '@components/TextError';
import OneMarForm from '@models/mar/components/worksiteCompletion/OneMarForm';
import {
  formAuditDocument,
  formInformationEnergyAudit,
} from '@models/mar/utils/datas';
import Auditor from '@models/mar/components/worksiteCompletion/stepsCompletion/energyAudit/Auditor';
import { validateEnergyAudit } from '@models/mar/apiRequests/worksiteCreationMarRequests';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import { IAuditDataSend, IWorksiteMar } from '@models/mar/utils/marTypes';
import { STEPS_WORKSITE_MAR_COMPLETION } from '@models/mar/utils/enums';

function EnergyAudit() {
  const { t } = useTranslation();
  const {
    worksiteData,
    updateWorksiteData,
    updateStepCompletion,
    updateButtonNextDisabled,
  } = useContext(WorksiteCreationMarContext);

  const [buttonNextLoading, setButtonNextLoading] = useState(false);
  const [auditorDataReady, setAuditorDataReady] = useState(false);

  const formContext = useFormContext();
  const { watch, setValue } = formContext;

  const auditDone = watch('audit_done')
    ? stringToBoolean(watch('audit_done'))
    : undefined;

  const handleValidateStep = async () => {
    setButtonNextLoading(true);
    const dataToSend: IAuditDataSend = {
      audit_number: watch('audit_number'),
      visit_date: watch('visit_date'),
      report_date: watch('report_date'),
      validity_date: watch('validity_date'),
      firstname: watch('auditor.firstname'),
      lastname: watch('auditor.lastname'),
      email: watch('auditor.email'),
      phone_number: watch('auditor.phone_number'),
      entity_id: watch('auditor.entity_id'),
      entity: {
        siren: watch('auditor.siren'),
        siret: watch('auditor.siret'),
        legal_category: watch('auditor.legal_category'),
        company_name: watch('auditor.company_name'),
        zipcode: watch('auditor.zipcode'),
        city: watch('auditor.city'),
        address: watch('auditor.address'),
      },
      file: watch('file'),
    };
    const response = await validateEnergyAudit(
      worksiteData?.id || 0,
      dataToSend
    );

    setButtonNextLoading(false);

    if (response) {
      updateWorksiteData((prev) => ({
        ...prev,
        ...response,
      }));
      updateStepCompletion(STEPS_WORKSITE_MAR_COMPLETION.HOUSING_ANALYSIS);
    }
  };

  const preFillAuditData = () => {
    if (worksiteData?.audit) {
      setValue('audit_done', 'true');
      setValue('audit_number', worksiteData.audit.audit_number);
      setValue('visit_date', worksiteData.audit.visit_date);
      setValue('report_date', worksiteData.audit.report_date);
      setValue('auditor.firstname', worksiteData.audit.firstname);
      setValue('auditor.lastname', worksiteData.audit.lastname);
      setValue('auditor.entity_id', worksiteData.audit.entity_id);
      if (worksiteData.audit.validity_date) {
        setValue('validity_date', worksiteData.audit.validity_date);
      }
      if (worksiteData.audit.entity) {
        setValue('auditor.siren', worksiteData.audit.entity.siren);
        setValue('auditor.siret', worksiteData.audit.entity.siret);
        setValue(
          'auditor.legal_category',
          worksiteData.audit.entity.legal_category
        );
        setValue(
          'auditor.company_name',
          worksiteData.audit.entity.company_name
        );
        setValue('auditor.zipcode', worksiteData.audit.entity.zipcode);
        setValue('auditor.city', worksiteData.audit.entity.city);
        setValue('auditor.address', worksiteData.audit.entity.address);
      }
      if (worksiteData.audit.email) {
        setValue('auditor.email', worksiteData.audit.email);
      }
      setValue('auditor.phone_number', worksiteData.audit.phone_number);
      if (worksiteData.audit.linked_file) {
        createFileWithLinkedFile(
          worksiteData.audit.linked_file.file_url as string,
          worksiteData.audit.linked_file.file_name as string
        ).then((file) => {
          setValue('file', file);
        });
      }
    }
  };

  const checkIfAuditInfoReady =
    !!watch('audit_number') &&
    watch('audit_number') !== '' &&
    !!watch('visit_date') &&
    watch('visit_date') !== '' &&
    !!watch('report_date') &&
    watch('report_date') !== '' &&
    !!watch('file');

  useEffect(() => {
    updateButtonNextDisabled(!(auditorDataReady && checkIfAuditInfoReady));
  }, [watch()]);

  useEffect(() => {
    preFillAuditData();
  }, []);

  return (
    <LayoutMarDetailsStep
      onValidate={handleValidateStep}
      buttonNextLoading={buttonNextLoading}
    >
      <div className="flex flex-col space-y-6">
        <BoolMar
          fieldName="audit_done"
          question={`${t(
            'mar.worksite_creation.worksite_completion.folder_details.energy_audit.done_question'
          )}`}
        />
        {auditDone !== undefined &&
          (!auditDone ? (
            <TextError
              errorMessage={t(
                'mar.worksite_creation.worksite_completion.folder_details.energy_audit.not_done'
              )}
              addClass="!mt-0"
            />
          ) : (
            <>
              <OneMarForm
                dataForm={formInformationEnergyAudit(
                  t,
                  worksiteData as IWorksiteMar
                )}
              />
              <Auditor setAuditorDataReady={setAuditorDataReady} />
              <OneMarForm dataForm={formAuditDocument(t)} />
            </>
          ))}
      </div>
    </LayoutMarDetailsStep>
  );
}

export default EnergyAudit;
