import { LoaderDivSkeleton } from '@components/loaders/LoaderDivSkeleton';
import { borderGrey } from '@assets/color';

interface ILoaderFileProps {
  withoutLabel?: boolean;
}

function LoaderFile({ withoutLabel }: ILoaderFileProps) {
  return (
    <div className="flex flex-col space-y-2">
      {!withoutLabel && (
        <LoaderDivSkeleton
          height="1rem"
          width="15rem"
          backgroundColor={borderGrey}
        />
      )}
      <LoaderDivSkeleton height="2rem" backgroundColor={borderGrey} />
    </div>
  );
}

export default LoaderFile;

LoaderFile.defaultProps = {
  withoutLabel: false,
};
