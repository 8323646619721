import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ENTITY_TYPES,
  groupDelegataireOblige,
  groupInstallateurAMO,
  groupMandataire,
} from '@utils/roles';
import {
  ISubscriptionsStored,
  ISubscriptionType,
} from '@models/auth/utils/types';
import OneSubscription from './OneSubscription';

interface SubscriptionOverviewProps {
  profilTypes: number[];
  setShowSubscriptionOverview: Dispatch<SetStateAction<boolean>>;
  setSubscriptionsStored: Dispatch<
    SetStateAction<ISubscriptionsStored | undefined>
  >;
  onSubmitSubscriptions: (isSkipped?: boolean) => Promise<boolean>;
  loading: boolean;
}

function SubscriptionOverview({
  profilTypes,
  setShowSubscriptionOverview,
  setSubscriptionsStored,
  onSubmitSubscriptions,
  loading,
}: SubscriptionOverviewProps) {
  const { t } = useTranslation();
  const [selectedSubscriptions, setSelectedSubscriptions] = useState<
    ISubscriptionType[]
  >(
    profilTypes.some((type) =>
      [
        ...groupInstallateurAMO,
        ...groupDelegataireOblige,
        ...groupMandataire,
        ENTITY_TYPES.MAR,
      ].includes(type)
    )
      ? ['discover']
      : []
  );

  const potentialGroups: number[][] = [
    profilTypes.filter((type) => groupDelegataireOblige.includes(type)),
    profilTypes.filter((type) => groupMandataire.includes(type)),
    profilTypes.filter((type) => groupInstallateurAMO.includes(type)),
    profilTypes.filter((type) => type === ENTITY_TYPES.MAR),
  ];

  const profilTypesGrouped: number[][] = potentialGroups.filter(
    (group) => group.length > 0
  );

  useEffect(() => {
    setSubscriptionsStored({
      subscriptionTypes: selectedSubscriptions,
      paymentFrequency:
        selectedSubscriptions.length === 1 &&
        selectedSubscriptions[0] === 'discover'
          ? 'free'
          : 'monthly',
    });
  }, [selectedSubscriptions]);

  return (
    <div className="flex flex-col space-y-6">
      {/* Bloc Header */}
      <div className="flex flex-col space-y-1">
        <div className="text-[1.6875rem] font-medium leading-10">
          {t('auth.registration.subscription_overview.header')}
        </div>
      </div>
      <div className="min-h-[25rem] max-h-[30rem] overflow-y-scroll flex flex-col space-y-2">
        {profilTypesGrouped.map((profilsGroup, index) => {
          return (
            <div
              key={`container-one-subscription-${profilsGroup[0]}`}
              className="flex space-x-2 scroll-invisible"
            >
              <OneSubscription
                allSelectedProfils={profilTypes}
                profilsGroup={profilsGroup}
                options={{ discover: true }}
                selectedSubscriptions={selectedSubscriptions}
                setSelectedSubscriptions={setSelectedSubscriptions}
              />
              <OneSubscription
                allSelectedProfils={profilTypes}
                profilsGroup={profilsGroup}
                options={{
                  multipleProfilsGroup: profilTypesGrouped.length > 1,
                  isFirstDisplay: index === 0,
                }}
                selectedSubscriptions={selectedSubscriptions}
                setSelectedSubscriptions={setSelectedSubscriptions}
              />
            </div>
          );
        })}
      </div>

      {/* Bloc Buttons */}
      <div className="flex flex-col items-end space-y-2">
        <div className="flex space-x-4">
          <ButtonOpx
            label={t('auth.registration.subscription_overview.return_button')}
            type="secondary"
            onClick={() => setShowSubscriptionOverview(false)}
          />
          <ButtonOpx
            label={t(
              `auth.registration.subscription_overview.choose_subscription${
                selectedSubscriptions.length > 1 ? 's' : ''
              }_button`
            )}
            onClick={() => onSubmitSubscriptions()}
            isLoading={loading}
            dataTestId="choose_subscription_button"
          />
        </div>
        {/* <button
            type="button"
            className="text-blueOpx text-xs font-normal leading-5"
            onClick={() => onSubmitSubscriptions(true)}
          >
            {t('auth.registration.subscription_overview.skip_step_button')}
          </button> */}
      </div>
    </div>
  );
}

export { SubscriptionOverview };
