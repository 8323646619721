/* eslint-disable react/jsx-no-useless-fragment */
import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { useParams } from 'react-router-dom';

import { GlobalContext } from '@context/globalContext';

import { ListDocumentsCard } from '@components/documents/ListDocumentsCard';
import { DocumentViewer } from '@components/documents/DocumentViewer';
import { LoaderListDocumentCard } from '@components/loaders/document/LoaderListDocumentCard';
import { UploadButton } from '@components/documents/UploadButton';
import { Card } from '@components/Card';

import { ILinkedFile } from 'types/globalTypes';
// import { IConventionType } from '@models/conventions/utils/conventionTypes';
// import { IPartnerInfosGenerales } from '@models/partners/utils/types/partnersType';
import { sendLinkedFile } from '@apiRequests/globalRequests';

interface ITabSubContractorsDocsProps {
  refetch: CallableFunction;
  // conventions: IConventionType[];
  // subcontractor: IPartnerInfosGenerales;
  subcontractorDocs: ILinkedFile[];
}
function TabSubContractorsDocs({
  // conventions,
  refetch,
  subcontractorDocs,
}: ITabSubContractorsDocsProps) {
  const { t } = useTranslation();
  const { id } = useParams();
  const { userView, updateDocumentActive } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingDocument, setIsLoadingDocument] = useState<boolean>(false);

  /* const operationsDocuments = useMemo(() => {
    const operationDocuments: {
      title: string;
      documents: ILinkedFile[];
    }[] = [];
    conventions.forEach((convention) => {
      const conventionReference = convention.reference;
      convention.worksites?.forEach((worksite) => {
        const worksiteReference = worksite.reference;
        worksite.worksitesOperations.forEach((worksiteOp) => {
          const operationName = worksiteOp.operation.description;
          const documents = worksiteOp.linkedFiles
            ? worksiteOp.linkedFiles.filter(
                (fileItem: ILinkedFile) =>
                  fileItem.linked_entity_id === (userView?.entity_id || null)
              )
            : [];
          if (documents.length > 0) {
            operationDocuments.push({
              title: `${conventionReference} - ${worksiteReference} - ${operationName}`,
              documents,
            });
          }
        });
      });
    });
    return operationDocuments;
  }, [conventions, userView]); */

  useEffect(() => {
    if (subcontractorDocs.length > 0) {
      setIsLoadingDocument(true);
      updateDocumentActive({
        list: t('global.documents_subcontractor'),
        document: subcontractorDocs[0],
      });
      setIsLoadingDocument(false);
    }
  }, []);

  const updateParentDocuments = async () => {
    refetch();
  };

  const handleUpload = async (file: File) => {
    await sendLinkedFile(
      file,
      [Number(id)],
      'subcontractor',
      16,
      2,
      '',
      userView?.entity_id || 0,
      false,
      undefined,
      undefined,
      updateParentDocuments
    );
  };

  return (
    <div className="flex space-x-[1.5rem] py-[1.5rem]">
      <Card
        addClass="w-1/2"
        title={t('global.documents_subcontractor')}
        actionButtons={
          <UploadButton
            name={t('global.document_add')}
            onUpload={handleUpload}
            fileType={16}
          />
        }
        dataTestId="documents_card"
      >
        <>
          {isLoading ? (
            <LoaderListDocumentCard />
          ) : (
            subcontractorDocs.length > 0 && (
              <ListDocumentsCard
                key={v4()}
                documents={subcontractorDocs}
                updateParentDocuments={updateParentDocuments}
                // isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            )
          )}
        </>
      </Card>
      <div className="w-1/2">
        <DocumentViewer
          isLoading={isLoadingDocument}
          setIsLoading={setIsLoadingDocument}
        />
      </div>
    </div>
  );
}

export { TabSubContractorsDocs };
