import { useState } from 'react';
import { InputText } from '@components/atomic/inputs/InputText';
import { useFormContext } from 'react-hook-form';
import { getEtablissementRge } from '@models/sirene/apiRequests/sireneRequests';
import { useTranslation } from 'react-i18next';
import { emailRegex } from '@utils/regex';

interface IProSiretFormProps {
  parentName: string;
  withMinimumInfos?: boolean;
  withoutCheckRge?: boolean;
}

function ProSiretForm({
  parentName,
  withMinimumInfos,
  withoutCheckRge,
}: IProSiretFormProps) {
  const { t } = useTranslation();

  const { watch, setValue } = useFormContext();

  const [loading, setLoading] = useState<boolean>(false);

  const getEtablissementInfos = async (siret: string) => {
    setLoading(true);
    await getEtablissementRge(
      siret,
      setLoading,
      parentName,
      setValue,
      withoutCheckRge
    );
  };

  const onChangeSiret = (siretValue: string) => {
    if (!loading && siretValue.length === 14) {
      getEtablissementInfos(siretValue);
    }
  };

  return (
    <div className="grid grid-cols-2 gap-4">
      <InputText
        id={`${parentName}.siret`}
        name={`${parentName}.siret`}
        label={`${t('partners.siret')}`}
        placeholder=""
        onChange={(e) => onChangeSiret(String(e))}
        disabled={loading}
        typeNumber
        maxLength={14}
        rules={{
          pattern: {
            value: /^\d{14}$/,
            message: t('forms.siret.error'),
          },
        }}
        error={watch(`${parentName}.no_rge`)}
        textError={
          watch(`${parentName}.no_rge`) &&
          `${t(
            'mar.worksite_creation.worksite_completion.folder_details.energy_audit.auditor.no_rge'
          )}`
        }
        valid={
          watch(`${parentName}.siret`) &&
          watch(`${parentName}.siret`).length === 14
        }
        required
        dataTestId="input_text_siret"
      />
      <InputText
        id={`${parentName}.company_name`}
        name={`${parentName}.company_name`}
        label={`${t('partners.company_name')}`}
        placeholder=""
        valid={false}
        value={watch(`${parentName}.company_name`)}
        required
      />
      {!withMinimumInfos && (
        <>
          <InputText
            id={`${parentName}.lastname`}
            name={`${parentName}.lastname`}
            label={`${t('partners.lastname')}`}
            placeholder=""
            required
            dataTestId="input_text_subcontractor_lastname"
          />
          <InputText
            id={`${parentName}.firstname`}
            name={`${parentName}.firstname`}
            label={`${t('partners.firstname')}`}
            placeholder=""
            required
            dataTestId="input_text_subcontractor_firstname"
          />
          <InputText
            id={`${parentName}.phone_number`}
            name={`${parentName}.phone_number`}
            label={`${t('forms.phone.placeholder')}`}
            placeholder=""
            typeNumber
            required
          />
          <InputText
            id={`${parentName}.email`}
            name={`${parentName}.email`}
            label={`${t('forms.email.placeholder')}`}
            placeholder=""
            rules={{
              pattern: {
                value: emailRegex,
                message: t('forms.email.error_pattern'),
              },
            }}
            valid={emailRegex.test(watch(`${parentName}.email`))}
            required
            dataTestId="input_text_auditor_email"
          />
        </>
      )}
      <div className="col-span-2">
        <InputText
          id={`${parentName}.address`}
          name={`${parentName}.address`}
          label={`${t('forms.address.placeholder')}`}
          placeholder=""
          disabled
          valid={false}
          value={
            !watch(`${parentName}.address`)
              ? ''
              : `${watch(`${parentName}.address`)}, ${watch(
                  `${parentName}.zipcode`
                )} ${watch(`${parentName}.city`)}`
          }
          required
        />
      </div>
    </div>
  );
}
export { ProSiretForm };

ProSiretForm.defaultProps = {
  withMinimumInfos: false,
  withoutCheckRge: false,
};
