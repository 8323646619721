import LayoutMarDetailsStep from '@models/mar/components/worksiteCompletion/LayoutMarDetailsStep';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OneMarForm from '@models/mar/components/worksiteCompletion/OneMarForm';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  deleteOneLinkedFile,
  postLinkedFile,
  validateAfterWork,
} from '@models/mar/apiRequests/worksiteCreationMarRequests';
import { STEPS_WORKSITE_MAR_COMPLETION } from '@models/mar/utils/enums';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import { IWorksiteMar } from '@models/mar/utils/marTypes';
import {
  defaultDocumentWithFileType,
  formAfterWork,
} from '@models/mar/utils/datas';
import { fileTypeEnum } from '@utils/enums';
import { setFileFromWorksiteData } from '@models/mar/utils/functions';
import { InputTypes } from '@utils/utils';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { LoaderDivSkeleton } from '@components/loaders/LoaderDivSkeleton';
import { borderGrey } from '@assets/color';
import { ILinkedFile } from '@globalTypes/globalTypes';
import LoaderFile from '@models/mar/components/LoaderFile';

function AfterWork() {
  const { t } = useTranslation();
  const {
    worksiteData,
    updateWorksiteData,
    updateStepCompletion,
    updateButtonNextDisabled,
  } = useContext(WorksiteCreationMarContext);

  const [buttonNextLoading, setButtonNextLoading] = useState(false);
  const [indexOfDocumentLoading, setIndexOfDocumentLoading] =
    useState<number>();

  const { watch, setValue, control } = useFormContext();

  const { fields, append } = useFieldArray({
    control,
    name: 'video_files',
  });

  const finalVisitDate = watch('final_visit_date');
  const reportFile = watch('report_file');
  const reportDate = watch('report_date');
  const pvFile = watch('pv_file');
  const pvDate = watch('pv_date');

  const checkIfInfosAreFilled = finalVisitDate && reportFile && reportDate;

  const handleValidateStep = async () => {
    setButtonNextLoading(true);
    const dataToSend = {
      finalVisitDate,
      reportFile,
      reportDate,
      pvFile,
      pvDate,
    };
    const response = await validateAfterWork(worksiteData?.id || 0, dataToSend);

    if (response) {
      updateWorksiteData((prev) => ({
        ...prev,
        ...response,
      }));
      updateStepCompletion(STEPS_WORKSITE_MAR_COMPLETION.PAYMENT_LETTER);
    }
    setButtonNextLoading(false);
  };

  const preFillAfterWork = async () => {
    if (worksiteData) {
      if (
        worksiteData.linked_files &&
        worksiteData.linked_files.length > 0 &&
        fields &&
        fields.length === 0
      ) {
        await setFileFromWorksiteData(
          worksiteData,
          fileTypeEnum.RAPPORT,
          'report_file',
          setValue
        );
        await setFileFromWorksiteData(
          worksiteData,
          fileTypeEnum.PV_RECEPTION,
          'pv_file',
          setValue
        );
        await setFileFromWorksiteData(
          worksiteData,
          fileTypeEnum.VIDEO_CONTROLE,
          'video_files',
          setValue,
          true,
          append
        );
      }
    }
  };

  const onUploadDocument = async (
    file: File,
    originalLinkedFile: ILinkedFile,
    index: number
  ) => {
    setIndexOfDocumentLoading(index);
    const response = await postLinkedFile(file, originalLinkedFile);
    if (response) {
      setValue(`video_files[${index}].linkedFileId`, response.id);
    }
    setIndexOfDocumentLoading(undefined);
  };

  const onDeleteDocument = async (linkedFileId: number, index: number) => {
    setIndexOfDocumentLoading(index);
    const response = await deleteOneLinkedFile(linkedFileId);
    if (response) {
      setValue(`video_files[${index}]`, {
        file: undefined,
        linkedFileId: undefined,
      });
    }
    setIndexOfDocumentLoading(undefined);
  };

  const defaultDocument: ILinkedFile = defaultDocumentWithFileType(
    fileTypeEnum.VIDEO_CONTROLE,
    worksiteData?.id || 0
  );

  useEffect(() => {
    updateButtonNextDisabled(!checkIfInfosAreFilled);
  }, [watch()]);

  useEffect(() => {
    preFillAfterWork();
  }, []);

  return (
    <LayoutMarDetailsStep
      onValidate={handleValidateStep}
      buttonNextLoading={buttonNextLoading}
    >
      <div className="flex flex-col space-y-6">
        {formAfterWork(t, worksiteData as IWorksiteMar).map((form) => (
          <OneMarForm key={form.title} dataForm={form} />
        ))}
        <div className="flex flex-col space-y-4">
          <p className="font-medium text-[1.125rem]">
            {t(
              'mar.worksite_creation.worksite_completion.folder_details.after_work.add_control_video.title'
            )}
          </p>
          {fields && fields.length > 0 ? (
            fields.map((field, index) => {
              if (indexOfDocumentLoading === index) {
                return (
                  <LoaderFile key={`loader-file-${field.id}`} withoutLabel />
                );
              }
              return (
                <OneMarForm
                  key={field.id}
                  dataForm={{
                    fields: [
                      {
                        name: `video_files[${index}].file`,
                        type: InputTypes.DOCUMENT,
                        fileType: fileTypeEnum.VIDEO_CONTROLE,
                        fullWidth: true,
                        defaultDocument,
                        linkedFileId: watch(
                          `video_files[${index}].linkedFileId`
                        ),
                        onUploadDocument: (
                          file: File,
                          originalLinkedFile: ILinkedFile
                        ) => onUploadDocument(file, originalLinkedFile, index),
                        onDeleteDocument: (linkedFileId) => {
                          onDeleteDocument(linkedFileId, index);
                        },
                      },
                    ],
                  }}
                />
              );
            })
          ) : (
            <div className="flex flex-col space-y-4">
              <LoaderDivSkeleton
                height="2rem"
                width="100%"
                backgroundColor={borderGrey}
              />
              <LoaderDivSkeleton
                height="2.5rem"
                width="100%"
                backgroundColor={borderGrey}
              />
            </div>
          )}
        </div>
        <ButtonOpx
          label={`${t(
            'mar.worksite_creation.worksite_completion.folder_details.after_work.add_control_video.add_video'
          )}`}
          onClick={() =>
            append({
              file: undefined,
              linkedFileId: undefined,
            })
          }
          type="tierciary"
          disabled={indexOfDocumentLoading !== undefined}
        />
      </div>
    </LayoutMarDetailsStep>
  );
}

export default AfterWork;
