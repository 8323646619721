import { useContext, useEffect, useMemo, useState } from 'react';
import { DocumentViewer } from '@components/documents/DocumentViewer';
import { ListDocumentsCard } from '@components/documents/ListDocumentsCard';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import { ILinkedFile } from '@globalTypes/globalTypes';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '@context/globalContext';
import { initDocumentActive } from '@utils/initialState';

function DocumentsAndSignatories() {
  const { t } = useTranslation();
  const { updateDocumentActive } = useContext(GlobalContext);
  const { worksiteData } = useContext(WorksiteCreationMarContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const extractAllMarLinkedFiles = () => {
    // Initialiser un tableau pour stocker tous les linked_files
    let allLinkedFiles: ILinkedFile[] = [];

    // 1. Fichiers liés à la racine (linked_files)
    if (worksiteData?.linked_files) {
      allLinkedFiles = allLinkedFiles.concat(worksiteData.linked_files);
    }

    // 2. Fichier lié dans l'audit (linked_file dans audit)
    if (worksiteData?.audit?.linked_file) {
      allLinkedFiles.push(worksiteData.audit.linked_file);
    }

    // 3. Fichiers liés dans les scénarios (scenarios[].linked_files)
    if (worksiteData?.scenarios) {
      worksiteData.scenarios.forEach((scenario) => {
        if (scenario?.linked_files) {
          allLinkedFiles = allLinkedFiles.concat(scenario.linked_files);
        }
      });
    }

    // 4. Fichiers liés dans les devis des pros (pros[].devis[].linked_file)
    if (worksiteData?.pros) {
      worksiteData.pros.forEach((pro) => {
        pro?.devis?.forEach((devis) => {
          if (devis?.linked_file) {
            allLinkedFiles.push(devis.linked_file);
          }
        });
      });
    }

    // 5. Fichiers liés dans les factures (factures[].linked_File)
    if (worksiteData?.factures) {
      worksiteData.factures.forEach((facture) => {
        if (facture?.linked_File) {
          allLinkedFiles.push(facture.linked_File);
        }
      });
    }

    // 6. Fichiers liés au contrat (conctract.linked_file)
    if (worksiteData?.contract?.linked_file) {
      allLinkedFiles.push(worksiteData.contract.linked_file);
    }

    return allLinkedFiles;
  };

  const allWorksiteLinkedFiles = useMemo(
    () => extractAllMarLinkedFiles(),
    [worksiteData]
  );

  useEffect(() => {
    return () => {
      updateDocumentActive(initDocumentActive);
    };
  }, []);

  return (
    <div className="grid grid-cols-2 gap-5">
      {allWorksiteLinkedFiles.length > 0 ? (
        <>
          <ListDocumentsCard documents={allWorksiteLinkedFiles} readOnly />
          <div className="h-min">
            <DocumentViewer isLoading={isLoading} setIsLoading={setIsLoading} />
          </div>
        </>
      ) : (
        <div className="text-center text-gray-500 col-span-2">
          {t('global.no_document')}
        </div>
      )}
    </div>
  );
}

export { DocumentsAndSignatories };
