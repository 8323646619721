import { axiosClient } from '@api/apiClient';
import { displayError } from '@utils/format';

import {
  IBeneficiaryListRequest,
  IBeneficiaryUpdateRequest,
} from '@models/beneficiaries/utils/beneficiariesType';
import {
  urlGetAndPostBeneficiary,
  urlGetBeneficiariesByOperationType,
  urlPostBeneficiaryView,
  urlPutBeneficiary,
} from '@api/apiUrls';
import { IWorksiteDetails } from '@models/worksites/utils/worksitesTypes';
import { Dispatch, SetStateAction } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { OperationTypeEnum } from '@utils/enums';

export const getBeneficiariesList = async ({
  page,
  perPage,
  isB2b,
  sort_and_filter,
}: IBeneficiaryListRequest) => {
  try {
    const response = await axiosClient.post(
      urlGetBeneficiariesByOperationType,
      {
        operationType: isB2b ? OperationTypeEnum.B2B : OperationTypeEnum.B2C,
        pagination: { page, perPage },
        sort_and_filter,
      }
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
  return false;
};

export const getBeneficiaryById = async (id: number) => {
  try {
    const response = await axiosClient.get(urlGetAndPostBeneficiary(id));
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
  return false;
};

export const updateBeneficiary = async ({
  data,
  id,
}: IBeneficiaryUpdateRequest) => {
  try {
    await axiosClient.post(urlGetAndPostBeneficiary(id), {
      mobile_phone: data.mobile_phone,
      email: data.email,
      address: data.address,
    });

    await axiosClient.put(urlPutBeneficiary(id), data.address);

    return true;
  } catch (error) {
    displayError(error.response);
  }
  return false;
};

export const getBeneficiaryView = async (
  uuid: string,
  setWorksiteData: Dispatch<SetStateAction<IWorksiteDetails | undefined>>,
  navigate: NavigateFunction,
  password?: string
) => {
  try {
    const response = await axiosClient.post(urlPostBeneficiaryView, {
      uuid,
      password,
    });
    setWorksiteData(response.data.data);

    return !!response.data.data;
  } catch (error) {
    if (error.response.status === 404) {
      navigate('/');
    }

    displayError(error.response);
  }
  return false;
};
