import { InputText } from '@components/atomic/inputs/InputText';
import { useTranslation } from 'react-i18next';
import {
  ICustomInputIds,
  IFormAddressDatas,
} from '@components/formAddress/FormAddress';
import { getGeocode, getLatLng } from 'use-places-autocomplete';

interface IManualAddressForm {
  datas: IFormAddressDatas;
  updateDatas: (updates: Partial<IFormAddressDatas>) => void;
  customInputIds: ICustomInputIds;
  disabled?: boolean;
}

function ManualAddressForm({
  datas,
  updateDatas,
  customInputIds,
  disabled,
}: IManualAddressForm) {
  const { t } = useTranslation();

  const handleSearchOnBlur = async () => {
    if (
      datas.streetNumber &&
      datas.street &&
      datas.zipCode &&
      datas.zipCode.length === 5 &&
      datas.city
    ) {
      const address = `${datas.streetNumber} ${datas.street} ${datas.zipCode} ${datas.city}`;
      await getGeocode({
        address,
      })
        .then((results) => {
          return getLatLng(results[0]);
        })
        .then((latLng) => {
          updateDatas({
            latitude: latLng.lat,
            longitude: latLng.lng,
          });
        })
        .catch(() => false);
    }
  };

  return (
    <div
      className="flex flex-col space-y-[.5rem]"
      onBlur={() => handleSearchOnBlur()}
    >
      <div className="flex space-x-[.5rem]">
        <div>
          <InputText
            typeNumber
            placeholder={`${t('forms.address.manual.number')}`}
            id={customInputIds.streetNumber}
            name={customInputIds.streetNumber}
            value={datas.streetNumber}
            onChange={(value) => updateDatas({ streetNumber: value as string })}
            valid={datas.streetNumber !== ''}
            dataTestId="input_text_address_manual_number"
            disabled={disabled}
            addClassToInput={disabled ? 'bg-backgroundBody' : ''}
          />
        </div>
        <InputText
          placeholder={`${t('forms.address.manual.street')}`}
          id={customInputIds.street}
          name={customInputIds.street}
          required
          value={datas.street}
          onChange={(value) => updateDatas({ street: value as string })}
          valid={datas.street !== ''}
          dataTestId="input_text_address_manual_street"
          disabled={disabled}
          addClassToInput={disabled ? 'bg-backgroundBody' : ''}
        />
      </div>

      <InputText
        typeNumber
        placeholder={`${t('forms.address.manual.zip')}`}
        id={customInputIds.zipCode}
        name={customInputIds.zipCode}
        required
        value={datas.zipCode}
        onChange={(value) => updateDatas({ zipCode: value as string })}
        valid={datas.zipCode !== '' && datas.zipCode.length === 5}
        dataTestId="input_text_address_manual_zip"
        disabled={disabled}
        addClassToInput={disabled ? 'bg-backgroundBody' : ''}
      />
      <InputText
        placeholder={`${t('forms.address.manual.city')}`}
        id={customInputIds.city}
        name={customInputIds.city}
        required
        value={datas.city}
        onChange={(value) => updateDatas({ city: value as string })}
        valid={datas.city !== ''}
        dataTestId="input_text_address_manual_city"
        disabled={disabled}
        addClassToInput={disabled ? 'bg-backgroundBody' : ''}
      />
      <InputText
        placeholder={`${t('forms.address.manual.country')}`}
        id={customInputIds.country}
        name={customInputIds.country}
        required
        value={datas.country}
        onChange={(value) => updateDatas({ country: value as string })}
        valid={datas.country !== ''}
        dataTestId="input_text_address_manual_country"
        disabled={disabled}
        addClassToInput={disabled ? 'bg-backgroundBody' : ''}
      />
    </div>
  );
}

export { ManualAddressForm };

ManualAddressForm.defaultProps = { disabled: false };
