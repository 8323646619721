import { createContext, Dispatch, SetStateAction } from 'react';
import { RequestOrMessageOrChangeEnum } from '@utils/enums';
import {
  IListGroupRequests,
  IMessageRequest,
  IRequestTypes,
} from '@models/requests/types/requestTypes';
import { IChange } from 'types/changesTypes';
import { ISortAndFilterType } from '@components/sortAndFilter/utils/sortAndFilterTypes';
import { IWorksiteDetails } from '../models/worksites/utils/worksitesTypes';

interface IProps {
  requestOrChangeEnumInModal: RequestOrMessageOrChangeEnum | null;
  updateRequestOrChangeEnumInModal: Dispatch<
    SetStateAction<RequestOrMessageOrChangeEnum | null>
  >;
  requestOrChangeEnumInCard: RequestOrMessageOrChangeEnum;
  updateRequestOrChangeEnumInCard: Dispatch<
    SetStateAction<RequestOrMessageOrChangeEnum>
  >;
  requestOrChangeTab: RequestOrMessageOrChangeEnum | null;
  updateRequestOrChangeTab: Dispatch<
    SetStateAction<RequestOrMessageOrChangeEnum | null>
  >;
  demandIdForNote: number;
  updateDemandIdForNote: Dispatch<SetStateAction<number>>;
  isHistoryRequest: boolean;
  updateIsHistoryRequest: Dispatch<SetStateAction<boolean>>;
  isModalNewRequest: boolean;
  updateIsModalNewRequest: Dispatch<SetStateAction<boolean>>;
  listRequests: IRequestTypes[];
  updateListRequests: Dispatch<SetStateAction<IRequestTypes[]>>;
  listRequestsGroupAll: IListGroupRequests;
  updateListRequestsGroupAll: Dispatch<SetStateAction<IListGroupRequests>>;
  listChanges: IChange[];
  updateListChanges: Dispatch<SetStateAction<IChange[]>>;
  isLoadingListRequestOrChange: boolean;
  updateIsLoadingListRequestOrChange: Dispatch<SetStateAction<boolean>>;
  detailRequest: IRequestTypes | null;
  updateDetailRequest: Dispatch<SetStateAction<IRequestTypes | null>>;
  messagesRequest: IMessageRequest[] | null;
  updateMessagesRequest: Dispatch<SetStateAction<IMessageRequest[] | null>>;
  isLoadingMessagesRequest: boolean;
  updateIsLoadingMessagesRequest: Dispatch<SetStateAction<boolean>>;
  worksiteDetailForNewRequest: IWorksiteDetails;
  updateWorksiteDetailForNewRequest: Dispatch<SetStateAction<IWorksiteDetails>>;
  numberPageRequestsList: number;
  updateNumberPageRequestsList: Dispatch<SetStateAction<number>>;
  numberPageChangesList: number;
  updateNumberPageChangesList: Dispatch<SetStateAction<number>>;
  totalRequestsList: number;
  updateTotalRequestsList: Dispatch<SetStateAction<number>>;
  totalRequestsMessagesList: number;
  updateTotalRequestsMessagesList: Dispatch<SetStateAction<number>>;
  totalChangesList: number;
  updateTotalChangesList: Dispatch<SetStateAction<number>>;
  refreshRequestsAndChanges: () => void;
  filterDataRequests: ISortAndFilterType;
  updateFilterDataRequests: Dispatch<SetStateAction<ISortAndFilterType>>;
  refreshListGroupRequests: () => void;
  listMessagesRequests: IRequestTypes[];
  updateListMessagesRequests: Dispatch<SetStateAction<IRequestTypes[]>>;
}

export const RequestAndActivityIntoModalOrCardContext = createContext(
  {} as IProps
);
