import LayoutMarDetailsStep from '@models/mar/components/worksiteCompletion/LayoutMarDetailsStep';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OneMarForm from '@models/mar/components/worksiteCompletion/OneMarForm';
import { useFormContext } from 'react-hook-form';
import { validateDeposit } from '@models/mar/apiRequests/worksiteCreationMarRequests';
import { STEPS_WORKSITE_MAR_COMPLETION } from '@models/mar/utils/enums';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import { InputTypes } from '@utils/utils';
import DocumentsDepositOfApplication from '@models/mar/components/worksiteCompletion/stepsCompletion/depositOfApplication/DocumentsDepositOfApplication';

function DepositOfApplication() {
  const { t } = useTranslation();
  const {
    worksiteData,
    updateWorksiteData,
    updateStepCompletion,
    updateButtonNextDisabled,
  } = useContext(WorksiteCreationMarContext);

  const [buttonNextLoading, setButtonNextLoading] = useState(false);

  const { watch, setValue } = useFormContext();

  const depositDate = watch('deposit_date');

  const handleValidateStep = async () => {
    setButtonNextLoading(true);
    const dataToSend = {
      deposit_date: depositDate,
    };
    const response = await validateDeposit(worksiteData?.id || 0, dataToSend);

    if (response) {
      updateWorksiteData((prev) => ({
        ...prev,
        ...response,
      }));
      updateStepCompletion(STEPS_WORKSITE_MAR_COMPLETION.GRANT_NOTIFICATION);
    }
    setButtonNextLoading(false);
  };

  const preFillDepositData = () => {
    if (worksiteData && worksiteData.deposit_date) {
      setValue('deposit_date', worksiteData?.deposit_date);
    }
  };

  useEffect(() => {
    updateButtonNextDisabled(!depositDate);
  }, [watch()]);

  useEffect(() => {
    preFillDepositData();
  }, []);

  return (
    <LayoutMarDetailsStep
      onValidate={handleValidateStep}
      buttonNextLoading={buttonNextLoading}
    >
      <div className="flex flex-col space-y-6">
        <OneMarForm
          dataForm={{
            title: t(
              'mar.worksite_creation.worksite_completion.folder_details.deposit_of_application.date_of_deposit'
            ),
            fields: [
              {
                name: 'deposit_date',
                type: InputTypes.DATE,
                defaultValue: worksiteData?.deposit_date || undefined,
              },
            ],
          }}
        />
        <div className="flex flex-col space-y-4">
          <p className="font-medium text-[1.125rem]">
            {t(
              'mar.worksite_creation.worksite_completion.folder_details.deposit_of_application.obligatory_pieces'
            )}
          </p>
          <DocumentsDepositOfApplication />
        </div>
      </div>
    </LayoutMarDetailsStep>
  );
}

export default DepositOfApplication;
