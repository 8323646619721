import { IAccountType } from '@models/auth/utils/types';
import { initialGhostDocument } from '@utils/initialState';
import {
  registrationConfirm,
  validateInvitation,
} from '@models/auth/apiRequests/registrationRequests';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { ghostRegistrationDocuments } from '@models/auth/utils/utils';
import { ListDocumentsCard } from '@components/documents/ListDocumentsCard';
// import { AccountToActivate } from './AccountToActivate';
import { ILinkedFile } from '../../../../types/globalTypes';
import { AccountToActivateNoIdNow } from './AccountToActivateNoIdNow';

interface IRequiredDocumentsProps {
  userInfo: IAccountType;
  isCompanyManager: boolean;
  formLayout?: boolean;
}

function RequiredDocuments({
  userInfo,
  isCompanyManager,
  formLayout,
}: IRequiredDocumentsProps) {
  const { t } = useTranslation();
  const query = new URLSearchParams(useLocation().search);
  const inviteId = query.get('invite-id');
  const [showAccountToActivate, setShowAccountToActivate] =
    useState<boolean>(false);
  // const [linkIdNow, setLinkIdNow] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [documentsToUpload, setDocumentsToUpload] = useState<ILinkedFile[]>(
    ghostRegistrationDocuments(
      userInfo.id,
      userInfo.entity_id,
      userInfo.entity_types,
      isCompanyManager
    )
  );
  const methods = useForm();

  const updateDocument = (doc: ILinkedFile) => {
    setDocumentsToUpload(() => {
      if (
        documentsToUpload.some(
          (document) =>
            document.file_type === doc.file_type && document.file_url !== null
        )
      ) {
        return [...documentsToUpload, doc];
      }
      return documentsToUpload.map((document) => {
        if (document.file_type === doc.file_type) {
          return doc;
        }
        return document;
      });
    });
  };

  const deleteOneDocument = (doc: ILinkedFile) => {
    const documentsWithSameType = documentsToUpload.filter(
      (document) => document.file_type === doc.file_type
    );
    const documentsWithoutDeleted = documentsToUpload.filter(
      (document) => document.id !== doc.id
    );
    setDocumentsToUpload(() => {
      if (documentsWithSameType.length < 2) {
        return [
          ...documentsWithoutDeleted,
          initialGhostDocument(
            doc.file_type,
            userInfo.id,
            userInfo.entity_id,
            doc.created_by,
            doc.mandatory,
            doc.order
          ),
        ];
      }
      return documentsWithoutDeleted;
    });
  };

  const accountToActivateNotification = async () => {
    setIsLoading(true);
    try {
      let sent: any;
      if (inviteId) {
        // Notification fin d'inscription après invitation installateur
        sent = await validateInvitation(inviteId);
      } else {
        // Notification fin d'inscription
        sent = await registrationConfirm({ id: userInfo.id });
      }

      if (sent) {
        // setLinkIdNow(sent.kyc_url || null);
        setShowAccountToActivate(true);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  if (showAccountToActivate) {
    return <AccountToActivateNoIdNow formLayout={formLayout} />; // return <AccountToActivate linkIdNow={linkIdNow} />; // disable as per request in OPX-2700}
  }

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col lg:w-[35rem]">
        <p className="mb-6 text-[1.6875rem] font-medium leading-[2.5rem]">
          {t('auth.registration.required_documents.header')}
        </p>
        <ListDocumentsCard
          documents={documentsToUpload}
          updateOneDocument={(doc) => updateDocument(doc)}
          forceImportMode
          userId={userInfo.id}
          entityTypeId={userInfo.entity_id}
          deleteOneDocument={deleteOneDocument}
          haveDocumentViewer={false}
        />
        <div className="mt-4 w-full flex items-center justify-end space-x-[1rem]">
          <div>
            <ButtonOpx
              addClass="min-w-[12.5rem]"
              label={t('buttons.validate')}
              type="primary"
              disabled={documentsToUpload.some(
                (doc) => doc.mandatory && doc.file_url === null
              )}
              onClick={accountToActivateNotification}
              isLoading={isLoading}
              dataTestId="subscription_validate_button"
            />
          </div>
        </div>
      </div>
    </FormProvider>
  );
}

export { RequiredDocuments };

RequiredDocuments.defaultProps = {
  formLayout: false,
};
