import OneMarForm from '@models/mar/components/worksiteCompletion/OneMarForm';
import {
  formAdministrativeMandate,
  formCheckFieldsMandatory,
} from '@models/mar/utils/datas';
import { IWorksiteMar } from '@models/mar/utils/marTypes';
import { ProSiretForm } from '@models/mar/components/worksiteCompletion/stepsCompletion/ProSiretForm';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import { useFormContext } from 'react-hook-form';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { MANDATORY_TYPES, PERSON_TYPES } from '@models/mar/utils/enums';
import { downloadWhenGenerated } from '@models/mar/utils/functions';
import { generateMandateWithType } from '@models/mar/apiRequests/worksiteCreationMarRequests';

interface IOneMandatoryProps {
  indexMandatory: number;
  mandatoryType: number;
}

function OneMandatory({ indexMandatory, mandatoryType }: IOneMandatoryProps) {
  const { t } = useTranslation();
  const { worksiteData, updateWorksiteData } = useContext(
    WorksiteCreationMarContext
  );

  const [loadingGeneration, setLoadingGeneration] = useState(false);

  const { watch, setValue } = useFormContext();

  const prefix = `mandataires.[${indexMandatory}]`;
  const personType = watch(`${prefix}.person_type`);
  const sameAsProxy = watch(`${prefix}.same_as_proxy`);
  const mandatoryTypeValue = watch(`${prefix}.type`);

  const prefixWhenSameAsProxy = `mandataires.[${indexMandatory - 1}]`;

  const labelGenerationButton = () => {
    switch (mandatoryType) {
      case MANDATORY_TYPES.ADMINISTRATIF_AIDE:
        return t(
          'mar.worksite_creation.worksite_completion.folder_details.mandate.generate_power_for_signature_online'
        );
      case MANDATORY_TYPES.FINANCIER:
        return t(
          'mar.worksite_creation.worksite_completion.folder_details.mandate.generate_financial'
        );
      case MANDATORY_TYPES.ADMINISTRATIF_SIGNATURE:
      default:
        return t(
          'mar.worksite_creation.worksite_completion.folder_details.mandate.generate_power_for_signature'
        );
    }
  };

  const checkIfDataReadyForGeneration = () => {
    const prefixToUse = sameAsProxy ? prefixWhenSameAsProxy : prefix;

    const requiredFields = [
      worksiteData?.occupation_type,
      worksiteData?.customer?.firstname,
      worksiteData?.customer?.lastname,
      worksiteData?.customer?.email,
      worksiteData?.customer?.mobile_phone,
      worksiteData?.customer?.civility,
      worksiteData?.customer?.address.address,
      worksiteData?.customer?.address.postal_code,
      worksiteData?.customer?.address.city,
      watch(`${prefixToUse}.firstname`),
      watch(`${prefixToUse}.lastname`),
      watch(`${prefixToUse}.email`),
      watch(`${prefixToUse}.phone_number`),
      watch(`${prefixToUse}.civility`),
      watch(`${prefixToUse}.function`),
    ];

    const personTypeToUse = watch(`${prefixToUse}.person_type`);
    if (personTypeToUse === PERSON_TYPES.LEGAL) {
      requiredFields.push(
        watch(`${prefixToUse}.entity.company_name`),
        watch(`${prefixToUse}.entity.address`),
        watch(`${prefixToUse}.entity.zipcode`),
        watch(`${prefixToUse}.entity.city`)
      );
    } else {
      requiredFields.push(
        watch(`${prefixToUse}.address`),
        watch(`${prefixToUse}.zipcode`),
        watch(`${prefixToUse}.city`)
      );
    }

    // Vérifier si tous les champs requis sont présents
    return requiredFields.every(
      (field) => field !== undefined && field !== null && field !== ''
    );
  };

  const generateMandate = async () => {
    setLoadingGeneration(true);
    const prefixToUse = sameAsProxy ? prefixWhenSameAsProxy : prefix;
    const personTypeToUse = watch(`${prefixToUse}.person_type`);

    const addressToUse =
      personTypeToUse === PERSON_TYPES.LEGAL
        ? {
            address: watch(`${prefixToUse}.entity.address`),
            zipcode: watch(`${prefixToUse}.entity.zipcode`),
            city: watch(`${prefixToUse}.entity.city`),
          }
        : {
            address: watch(`${prefixToUse}.address`),
            zipcode: watch(`${prefixToUse}.zipcode`),
            city: watch(`${prefixToUse}.city`),
          };

    const dataToSend = {
      mandataire_type: Number(mandatoryTypeValue),
      occupation_type: worksiteData?.occupation_type,
      customer_firstname: worksiteData?.customer?.firstname,
      customer_lastname: worksiteData?.customer?.lastname,
      customer_mail: worksiteData?.customer?.email,
      customer_phone: worksiteData?.customer?.mobile_phone,
      customer_civility: worksiteData?.customer?.civility,
      customer_address: worksiteData?.customer?.address.address,
      customer_zipcode: worksiteData?.customer?.address.postal_code,
      customer_city: worksiteData?.customer?.address.city,
      mandataire_firstname: watch(`${prefixToUse}.firstname`),
      mandataire_lastname: watch(`${prefixToUse}.lastname`),
      mandataire_mail: watch(`${prefixToUse}.email`),
      mandataire_phone: watch(`${prefixToUse}.phone_number`),
      mandataire_company_name:
        personTypeToUse === PERSON_TYPES.LEGAL
          ? watch(`${prefixToUse}.entity.company_name`)
          : undefined,
      mandataire_civility: watch(`${prefixToUse}.civility`),
      mandataire_function: watch(`${prefixToUse}.function`),
      mandataire_address: addressToUse.address,
      mandataire_zipcode: addressToUse.zipcode,
      mandataire_city: addressToUse.city,
      aid_request:
        mandatoryTypeValue === MANDATORY_TYPES.ADMINISTRATIF_AIDE
          ? watch(`${prefix}.aid_request`)
          : undefined,
      payment_request:
        mandatoryTypeValue === MANDATORY_TYPES.ADMINISTRATIF_AIDE
          ? watch(`${prefix}.payment_request`)
          : undefined,
    };

    const response = await generateMandateWithType(
      worksiteData?.id || 0,
      dataToSend
    );
    if (response && response.file_url) {
      downloadWhenGenerated(response.file_url, 'mar_mandate.pdf');
      updateWorksiteData((prev) => ({
        ...(prev as IWorksiteMar),
        linked_files: [...(prev as IWorksiteMar).linked_files, response],
      }));
    }
    setLoadingGeneration(false);
  };

  useEffect(() => {
    if (!mandatoryTypeValue) {
      setValue(`${prefix}.type`, mandatoryType);
    }
  }, []);

  return (
    <div className="flex flex-col space-y-4">
      <OneMarForm
        dataForm={formAdministrativeMandate(
          t,
          worksiteData as IWorksiteMar,
          indexMandatory,
          personType,
          sameAsProxy,
          mandatoryType
        )}
      />
      {personType === PERSON_TYPES.LEGAL && !sameAsProxy && (
        <ProSiretForm
          parentName={`${prefix}.entity`}
          withMinimumInfos
          withoutCheckRge
        />
      )}
      {mandatoryType === MANDATORY_TYPES.ADMINISTRATIF_AIDE && (
        <OneMarForm dataForm={formCheckFieldsMandatory(t, indexMandatory)} />
      )}
      <ButtonOpx
        label={labelGenerationButton()}
        onClick={generateMandate}
        isLoading={loadingGeneration}
        disabled={!checkIfDataReadyForGeneration()}
      />
    </div>
  );
}

export default OneMandatory;
