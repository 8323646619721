import { SubHeader } from '@components/subHeader/SubHeader';
import { TabsLeftPart } from '@components/subHeader/TabsLeftPart';
import { useContext, useState } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import FolderMarDetails from '@models/mar/components/worksiteCompletion/FolderMarDetails';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import { LoaderWorksiteDetailsOperations } from '@components/loaders/worksites/LoaderWorksiteDetailsOperations';
import { DocumentsAndSignatories } from '@models/mar/components/documentsAndSignatories/DocumentsAndSignatories';
import WorksiteProgress from '@models/mar/components/progress/WorksiteProgress';

const tabsWorksiteCompletionWithTranslation = (t: TFunction) => {
  return {
    ADVANCEMENT: t('mar.worksite_creation.worksite_completion.tab.advancement'),
    FOLDER_DETAILS: t(
      'mar.worksite_creation.worksite_completion.tab.folder_details'
    ),
    DOCUMENTS_SIGNATURES: t(
      'mar.worksite_creation.worksite_completion.tab.documents_signatures'
    ),
  };
};

function WorksiteMarCompletion() {
  const { t } = useTranslation();
  const { worksiteData } = useContext(WorksiteCreationMarContext);
  const tabsWorksiteCompletion = tabsWorksiteCompletionWithTranslation(t);
  const [tabActive, setTabActive] = useState<string>(
    tabsWorksiteCompletion.FOLDER_DETAILS
  );

  const renderTabContent = () => {
    switch (tabActive) {
      case tabsWorksiteCompletion.ADVANCEMENT:
        return <WorksiteProgress />;
      case tabsWorksiteCompletion.DOCUMENTS_SIGNATURES:
        return <DocumentsAndSignatories />;
      case tabsWorksiteCompletion.FOLDER_DETAILS:
      default:
        return <FolderMarDetails />;
    }
  };

  return (
    <div className="w-full">
      <SubHeader
        leftPart={
          <TabsLeftPart
            titlesList={Object.values(tabsWorksiteCompletion)}
            activeButton={tabActive}
            onClick={(tabToSet: string) => setTabActive(tabToSet)}
          />
        }
      />
      {worksiteData ? renderTabContent() : <LoaderWorksiteDetailsOperations />}
    </div>
  );
}

export default WorksiteMarCompletion;
