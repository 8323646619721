import { t } from 'i18next';
import { InputText } from '@components/atomic/inputs/InputText';
import {
  alphanumericRegex,
  emailValidationRegex,
  nameRegex,
  phoneNumberRegex,
} from '@utils/regex';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import { setContactInfo } from '@models/contractCreation/utils/newContractHelper';
import { IContractPartnerInfo } from '@models/contractCreation/utils/contractCreationTypes';
import { GlobalContext } from '@context/globalContext';
import { Checkbox } from '@components/atomic/inputs/controls/Checkbox';
import { placeholderExample } from '@utils/utils';

interface ContactFieldsProps {
  contactType: 'signataire' | 'contact';
  required: boolean;
  infosPartner: IContractPartnerInfo;
  updateInfosPartner: Dispatch<SetStateAction<IContractPartnerInfo>>;
  onCheckEmail: (email: string) => void;
  errorEmail: boolean;
  setIsSame?: Dispatch<SetStateAction<boolean>>;
  isSame?: boolean;
}

function ContactFields({
  contactType,
  required,
  infosPartner,
  updateInfosPartner,
  isSame,
  setIsSame,
  onCheckEmail,
  errorEmail, // isBusiness,
}: ContactFieldsProps) {
  const { globalEnum } = useContext(GlobalContext);
  const { civility } = globalEnum;

  const contact = infosPartner[contactType];
  const placeholders = placeholderExample(t);
  return (
    <>
      {/* <p className="w-full font-medium text-[1rem] mt-[1.5rem]">{title}</p>
      <p className="w-full text-[0.875rem] mb-[1.5rem]">{`${t(
        `contract.contact_${contactType}_description`
      )}`}</p> */}

      {setIsSame && (
        <div className="w-full flex gap-3 mb-[1.5rem] text-[0.875rem]">
          <div> {t('contract.contacts_same')}</div>{' '}
          <Checkbox
            label=""
            checked={Boolean(isSame)}
            onCheck={() => setIsSame((prevState) => !prevState)}
            width="1rem"
          />
        </div>
      )}

      {(!isSame || contactType === 'signataire' || !setIsSame) && (
        <div className="flex flex-wrap w-full">
          <div className="w-full flex justify-between gap-2 mb-2">
            {Object.keys(civility).map((key, i) => {
              const value = civility[Number(key)];
              return (
                <div
                  className="w-full flex justify-between gap-2 mb-2"
                  key={key}
                >
                  <button
                    className="border rounded-default focus:outline-none w-full p-2 flex items-center gap-2 blueOpx"
                    type="button"
                    onClick={() =>
                      setContactInfo(
                        'civility',
                        i + 1,
                        updateInfosPartner,
                        contactType
                      )
                    }
                    data-test-id={`civility_${key}`}
                  >
                    <input
                      onChange={() =>
                        setContactInfo(
                          'civility',
                          i + 1,
                          updateInfosPartner,
                          contactType
                        )
                      }
                      type="radio"
                      className="w-[1.25rem] h-[1.25rem]"
                      checked={contact?.civility === i + 1}
                      required={required}
                    />
                    <div>{value}</div>
                  </button>
                </div>
              );
            })}
          </div>

          <div className="flex flex-col gap-3 w-full">
            <div className="flex gap-3 w-full">
              <InputText
                id={`${contactType}Firstname`}
                name={`${contactType}Firstname`}
                placeholder={placeholders.FIRSTNAME}
                label={`${t('forms.firstname.placeholder')}`}
                onChange={(e) =>
                  setContactInfo(
                    'firstname',
                    String(e),
                    updateInfosPartner,
                    contactType
                  )
                }
                valid={!!(contact?.firstname && contact?.firstname !== '')}
                error={!nameRegex.test(contact?.firstname || 'a')}
                required={required}
                value={contact?.firstname || ''}
              />
              <InputText
                id={`${contactType}Lastname`}
                name={`${contactType}Lastname`}
                placeholder={placeholders.LASTNAME}
                label={`${t('forms.lastname.placeholder')}`}
                onChange={(e) =>
                  setContactInfo(
                    'lastname',
                    String(e),
                    updateInfosPartner,
                    contactType
                  )
                }
                valid={!!(contact?.lastname && contact?.lastname !== '')}
                error={!nameRegex.test(contact?.lastname || 'a')}
                required={required}
                value={contact?.lastname || ''}
              />
            </div>

            <div className="flex gap-3 w-full">
              <InputText
                id={`${contactType}Function`}
                name={`${contactType}Function`}
                placeholder={placeholders.FUNCTION}
                label={`${t('forms.function.placeholder')}`}
                onChange={(e) =>
                  setContactInfo(
                    'function',
                    String(e),
                    updateInfosPartner,
                    contactType
                  )
                }
                valid={!!(contact?.function && contact?.function !== '')}
                error={!alphanumericRegex.test(contact?.function || 'a')}
                required={required}
                value={contact?.function || ''}
              />
              <InputText
                id={`${contactType}Phone`}
                name={`${contactType}Phone`}
                placeholder={placeholders.TELEPHONE}
                label={`${t('forms.phone.placeholder')}`}
                onChange={(e) =>
                  setContactInfo(
                    'phone_number',
                    String(e).replace(' ', ''),
                    updateInfosPartner,
                    contactType
                  )
                }
                valid={
                  !!(contact?.phone_number && contact?.phone_number !== '')
                }
                error={
                  !phoneNumberRegex.test(contact?.phone_number || '0102030405')
                }
                required={required}
                value={contact?.phone_number || ''}
              />
            </div>

            <InputText
              id={`${contactType}Email`}
              name={`${contactType}Email`}
              placeholder={placeholders.EMAIL}
              label={`${t('forms.email.placeholder')}`}
              onChange={(e) => {
                setContactInfo(
                  'email',
                  String(e),
                  updateInfosPartner,
                  contactType
                );
                onCheckEmail(String(e));
              }}
              valid={!!(contact?.email && contact?.email !== '')}
              error={
                errorEmail ||
                !emailValidationRegex.test(contact?.email || 'email@drapo.com')
              }
              required={required}
              value={contact?.email || ''}
              defaultValue=""
            />
          </div>
        </div>
      )}
    </>
  );
}

ContactFields.defaultProps = {
  isSame: undefined,
  setIsSame: undefined,
  // isBusiness: false,
};

export { ContactFields };
