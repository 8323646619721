import { Modal } from '@components/Modal';
import { CivilityEnum } from '@models/worksiteCreation/utils/enums';

import { useContext, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { emailRegex, phoneNumberRegex } from '@utils/regex';
import {
  convertHexToRGBA,
  formatAddressAndLatLngFromForm,
} from '@utils/functions';
import { red } from '@assets/color';
import { IBeneficiaryAddress } from '@models/beneficiaries/utils/beneficiariesType';
import { CompanyActivityStatusEnum } from '@utils/enums';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import { STEPS_WORKSITE_MAR_INIT } from '@models/mar/utils/enums';
import { ICustomer } from '@models/mar/utils/marTypes';
import { CardForm } from '@models/worksiteCreation/components/CardForm';
import { FormContentCreateBeneficiary } from '@models/worksiteCreation/components/modalFormBeforeSimulation/steps/stepCreateBeneficiary/FormContentCreateBeneficiary';
import { createCustomer } from '@models/mar/apiRequests/worksiteCreationMarRequests';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { GlobalContext } from '@context/globalContext';

function NewCustomer() {
  const { t } = useTranslation();
  const methods = useForm();
  const { watch, setValue } = methods;

  const { globalEnum } = useContext(GlobalContext);
  const {
    updateStepInit,
    updateCustomer,
    worksiteAddress,
    updateWorksiteAddressFromKeyValue,
  } = useContext(WorksiteCreationMarContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[] | undefined>(
    undefined
  );

  const [customerCivility, setCustomerCivility] = useState({
    civility: t('forms.civility.mr'),
    value: CivilityEnum.MR,
  });
  const [customerAddress, setCustomerAddress] = useState<IBeneficiaryAddress>();

  const valuesForm = watch();

  const checkValues = (values: string[]) =>
    values.some((value) => !valuesForm[value] || valuesForm[value] === '');

  const customerFormAddress =
    customerAddress?.address === '' ||
    customerAddress?.city === '' ||
    customerAddress?.postal_code === '';

  const valuesFormAddress = checkValues(['city', 'zipcode', 'address']);

  const valuesFormCustomerInformations =
    checkValues(['firstname', 'lastname']) ||
    !phoneNumberRegex.test(valuesForm.phone) ||
    !phoneNumberRegex.test(
      valuesForm.phoneFix && valuesForm.phoneFix !== ''
        ? valuesForm.phoneFix
        : '0102030405'
    ) ||
    !emailRegex.test(valuesForm.email);

  const checkIfFormComplete = !(
    (customerFormAddress && valuesFormAddress) ||
    valuesFormCustomerInformations
  );

  // CREATION D'UN USAGER
  const onSubmitCreateBeneficiary = async (formData: FieldValues) => {
    if (checkIfFormComplete) {
      const { address, postalCode, city, country, latLngToUse } =
        await formatAddressAndLatLngFromForm(
          formData,
          customerAddress,
          updateWorksiteAddressFromKeyValue
        );

      const dataToSubmit: ICustomer = {
        id: 0,
        civility: customerCivility.value,
        firstname: formData.firstname,
        lastname: formData.lastname,
        address: {
          id: 0,
          address_type: 1,
          address,
          postal_code: postalCode,
          city,
          country,
          elevation: null,
          worksite_id: null,
          latitude: latLngToUse.lat,
          longitude: latLngToUse.lng,
          additional_address: null,
          selected: true,
        },
        fixe_phone: formData.phoneFix ? formData.phoneFix : null,
        mobile_phone: formData.phone,
        email: formData.email,
        prospect_origin: Number(formData.prospect_origin),
      };

      updateCustomer(dataToSubmit);

      const creationValidate = await createCustomer(
        setIsLoading,
        updateCustomer,
        dataToSubmit,
        setIsError,
        setErrorMessages
      );

      if (creationValidate) {
        updateStepInit(STEPS_WORKSITE_MAR_INIT.CONTRACT_CREATION);
      }
    }
  };

  const onBackClick = () => {
    updateStepInit(STEPS_WORKSITE_MAR_INIT.BIND_CUSTOMER);
  };

  return (
    <Modal
      title={t('mar.worksite_creation.init.bind_customer.create_customer')}
      withArrowBack
      onBackClick={() => onBackClick()}
      textBtnConfirm={`${t('buttons.confirm')}`}
      formId="createCustomer"
      isLoading={isLoading}
      btnConfirmDisabled={
        !checkIfFormComplete ||
        isLoading ||
        valuesForm.status === CompanyActivityStatusEnum.INACTIVE
      }
    >
      <div>
        <CardForm
          title={t(
            'mar.worksite_creation.init.bind_customer.general_informations_customer'
          )}
          subtitle={`${t(
            'mar.worksite_creation.init.bind_customer.subtitle_new_customer'
          )}`}
          idForm="createCustomer"
          onSubmit={onSubmitCreateBeneficiary}
          methods={methods}
        >
          <>
            <FormContentCreateBeneficiary
              beneficiaryCivility={customerCivility}
              setBeneficiaryCivility={setCustomerCivility}
              setBeneficiaryAddress={setCustomerAddress}
              valuesForm={valuesForm}
              setValue={setValue}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              worksiteAddress={worksiteAddress}
              updateWorksiteAddressFromKeyValue={
                updateWorksiteAddressFromKeyValue
              }
            />
            <InputSelect
              addClass="mt-2"
              label="Origine du prospect"
              placeholder="Origine du prospect"
              dataArrayString={Object.values(globalEnum.prospect_origin_type)}
              onSelect={(value) => {
                setValue(
                  'prospect_origin',
                  Object.keys(globalEnum.prospect_origin_type).find(
                    (key) =>
                      globalEnum.prospect_origin_type[key as any] === value
                  )
                );
              }}
            />
          </>
        </CardForm>
        {isError && !isLoading && (
          <div
            className="rounded-default p-[.5rem] mt-[.5rem] text-red"
            style={{
              backgroundColor: convertHexToRGBA(red, 0.1),
            }}
          >
            {errorMessages
              ? errorMessages.map((errorMessage) => <p>{errorMessage}</p>)
              : t('forms.error_server')}
          </div>
        )}
      </div>
    </Modal>
  );
}

export { NewCustomer };
