import { InputSelect } from '@components/atomic/inputs/InputSelect';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  INCENTIVE_TYPE,
  StepsEnumBeforeSimulation,
} from '@models/worksiteCreation/utils/enums';
import { Modal } from '@components/Modal';
import { getListConvention } from '@models/worksiteCreation/apiRequests/worksiteCreationRequests';
import { intialConvention } from '@models/worksiteCreation/utils/initialsValues/conventionInitialsValues';
import { ContractNotInList } from '@models/worksiteCreation/components/modalFormBeforeSimulation/steps/ContractNotInList';
import { OperationTypeEnum } from '@utils/enums';
import { SelectOptions } from '@components/atomic/inputs/SelectOptions';
import { AuthContext } from '@context/authContext';
import { isInstaller } from '@utils/roles';
import { IConvention } from '../../../utils/types/conventionsTypes';
import { WorksiteCreationContext } from '../../../utils/worksiteCreationContext';

interface IStepChoiceConvention {
  setStepFormBeforeSimulation: Dispatch<
    SetStateAction<StepsEnumBeforeSimulation>
  >;
}

function StepChoiceContract({
  setStepFormBeforeSimulation,
}: IStepChoiceConvention) {
  const { t } = useTranslation();

  const {
    worksiteOperationType,
    conventionActive,
    updateConventionActive,
    updateBeneficiary,
    updateWorksiteOperationType,
    beneficiary,
    updateIsModals,
    updateIsLoading,
    isLoading,
    partner,
    updateWorksiteDatas,
  } = useContext(WorksiteCreationContext);

  const { user } = useContext(AuthContext);

  const [isLoadingConvention, setIsLoadingConvention] =
    useState<boolean>(false);
  const [listConvention, setListConvention] = useState<IConvention[]>([
    intialConvention,
  ]);
  const [endOfListConvention, setEndOfListConvention] =
    useState<boolean>(false);

  const [contractNotInList, setContractNotInList] = useState<boolean>(false);

  const getConventionList = async (page: number) => {
    if (!isLoadingConvention && !endOfListConvention) {
      await getListConvention(
        setListConvention,
        setIsLoadingConvention,
        worksiteOperationType,
        page,
        setEndOfListConvention,
        true,
        partner?.id_partenaire
      );
    }
  };

  const createNameConvention = (convention: IConvention) => {
    let name = `${convention.reference} - `;

    if (isInstaller(user)) {
      name += `${convention.rai.company_name} - ${convention.from.company_name}`;
    } else {
      name += `${convention.from.company_name} - ${convention.installer.company_name}`;
    }

    if (convention.internal_reference && convention.internal_reference !== '') {
      name = `${convention.internal_reference} - ${name}`;
    }
    return name;
  };

  useEffect(() => {
    if (listConvention[0].reference === '') {
      getListConvention(
        setListConvention,
        setIsLoadingConvention,
        worksiteOperationType,
        1,
        setEndOfListConvention,
        undefined,
        partner?.id_partenaire
      );
    }
  }, []);

  useEffect(() => {
    if (listConvention.length === 1) {
      updateConventionActive(listConvention[0]);

      if (listConvention[0].beneficiary) {
        updateBeneficiary(listConvention[0].beneficiary);

        if (worksiteOperationType !== OperationTypeEnum.GLOBAL_RENOVATION) {
          if (listConvention[0].beneficiary.siren) {
            updateWorksiteOperationType(OperationTypeEnum.B2B);
          } else if (listConvention[0].beneficiary.firstname) {
            updateWorksiteOperationType(OperationTypeEnum.B2C);
          }
        }
      }
    }
  }, [listConvention]);

  if (contractNotInList) {
    return <ContractNotInList setContractNotInList={setContractNotInList} />;
  }

  const updateIncentiveType = () => {
    updateWorksiteDatas((prevState) => ({
      ...prevState,
      incentive_type: conventionActive.incentive_type,
    }));
  };

  return (
    <Modal
      withArrowBack
      onBackClick={() => {
        setStepFormBeforeSimulation(
          partner
            ? StepsEnumBeforeSimulation.CHOICE_INSTALLER
            : (prevState) => prevState - 1
        );
      }}
      title={t('worksite_creation.before_simulation.choice_contract.title')}
      btnConfirmDisabled={
        !conventionActive || conventionActive.reference === ''
      }
      textBtnConfirm={t('buttons.confirm') as string}
      addClassBtn="mt-[1.5rem]"
      isLoading={isLoading}
      onConfirmClick={async () => {
        if (beneficiary?.id && beneficiary.id > 0) {
          updateIsLoading(true);

          updateIsModals({
            formBeforeSimulation: false,
            bindBeneficiary: false,
            createBeneficiary: false,
            saveAndQuit: false,
            deleteWorksite: false,
          });
        } else {
          const mixedIncentive =
            conventionActive.incentive_type === INCENTIVE_TYPE.MIXED;

          if (!mixedIncentive) updateIncentiveType();

          setStepFormBeforeSimulation(
            (prevState) => prevState + (mixedIncentive ? 1 : 2)
          );
        }
      }}
    >
      <div className="mt-[1.5rem] flex flex-col space-y-[1rem]">
        <div>
          <div className="font-medium">
            {t(
              'worksite_creation.before_simulation.choice_contract.choose_contract'
            )}
          </div>
          <p className="text-sm mt-[.5rem]">
            {t(
              'worksite_creation.before_simulation.choice_contract.choose_contract_description'
            )}
          </p>
        </div>

        <InputSelect
          placeholder={t('global.choose')}
          onScrollToBottom={(page) => getConventionList(page)}
          valueInput={
            conventionActive.reference !== ''
              ? createNameConvention(conventionActive)
              : undefined
          }
        >
          <SelectOptions
            onClick={(convention) => {
              const selectedConvention = listConvention.find(
                (item) => item.id === Number(convention.value)
              );
              if (selectedConvention)
                updateConventionActive(selectedConvention);
            }}
            options={listConvention.map((convention) => ({
              label: createNameConvention(convention),
              value: String(convention.id),
            }))}
            notInListAction={
              isInstaller(user) ? () => setContractNotInList(true) : undefined
            }
            notInListText={
              isInstaller(user)
                ? `${t(
                    'worksite_creation.before_simulation.choice_contract.contract_not_in_list'
                  )}`
                : undefined
            }
            isLoading={isLoadingConvention}
            dataTestId="convention"
          />
        </InputSelect>
      </div>
    </Modal>
  );
}

export { StepChoiceContract };
