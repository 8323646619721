import { Dispatch, SetStateAction, useContext } from 'react';
import { StepsEnumBeforeSimulation } from '@models/worksiteCreation/utils/enums';
import { t } from 'i18next';
import { Modal } from '@components/Modal';
import { ColorCube } from '@components/atomic/ColorCube';
import { blueOpx } from '@assets/color';
import { ChevronLeftIcon } from '@assets/images/svgComponents';
import { v4 } from 'uuid';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';

interface IStepChoiceBeneficiary {
  setStepFormBeforeSimulation: Dispatch<
    SetStateAction<StepsEnumBeforeSimulation>
  >;
}

function StepChoiceBeneficiaryType({
  setStepFormBeforeSimulation,
}: IStepChoiceBeneficiary) {
  const { updateWorksiteOperationType } = useContext(WorksiteCreationContext);

  const operationChoices = [
    {
      title: t(
        'worksite_creation.before_simulation.choice_beneficiary_type.individual_beneficiary'
      ),
      description: t(
        'worksite_creation.before_simulation.choice_beneficiary_type.individual_beneficiary_description'
      ),
      operationType: 1,
      dataTestId: 'individual_choice',
    },
    {
      title: t(
        'worksite_creation.before_simulation.choice_beneficiary_type.professional_beneficiary'
      ),
      description: t(
        'worksite_creation.before_simulation.choice_beneficiary_type.professional_beneficiary_description'
      ),
      operationType: 2,
      dataTestId: 'professional_choice',
    },
  ];

  const onChange = (operationType: number) => {
    updateWorksiteOperationType(operationType);
    setStepFormBeforeSimulation((prevState) => prevState + 1);
  };

  return (
    <Modal
      title={t(
        'worksite_creation.before_simulation.choice_beneficiary_type.title'
      )}
      closeModal
    >
      <div className="flex flex-col space-y-[1rem] mt-[1.5rem]">
        {operationChoices.map((choice) => (
          <button
            key={v4()}
            onClick={() => {
              onChange(choice.operationType);
            }}
            type="button"
            className="w-full bg-white border text-start border-borderGrey rounded-default p-[1.5rem] flex items-start justify-between hover:bg-backgroundBody hover:border-backgroundBody"
            data-test-id={choice.dataTestId}
          >
            <div className="w-11/12">
              <p className="text-[1rem] font-medium">{choice.title}</p>
              <p className="text-[.875rem] mt-[.625rem]">
                {choice.description}
              </p>
            </div>
            <ColorCube
              opacity
              size="1.5rem"
              color={blueOpx}
              addClass="w-1/12"
              numberOrIcon={<ChevronLeftIcon className="rotate-180" />}
            />
          </button>
        ))}
      </div>
    </Modal>
  );
}

export { StepChoiceBeneficiaryType };
