import { getUserInfo } from '@models/users/apiRequests/userRequests';
import { IAccountInfos } from '@models/users/utils/userTypes';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  DEFAULT_ACCOUNT_INFOS,
  MY_ACCOUNT_TABS,
} from '@models/users/utils/userConstants';
import { TabAccountInfos } from '@models/users/components/TabAccountInfos';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { LoaderDivSkeleton } from '@components/loaders/LoaderDivSkeleton';
import { TabAccountUsers } from '@models/users/components/TabAccountUsers';
import { AuthContext } from '@context/authContext';
import { HeaderContext } from '@context/headerContext';
import { useTranslation } from 'react-i18next';
import {
  impersonateUser,
  markAsNonCompliant,
  renewIdentityCheckDemand,
  setUserAccountCompliance,
} from '@models/admin/users/apiRequests/usersRequests';
import { toast } from 'react-toastify';
import { IUserType } from '@models/auth/utils/types';
import { red, orange, white } from '@assets/color';
import { CardPassword } from '@models/users/components/CardPassword';
import { HistoryUsers } from '@models/admin/users/components/HistoryUsers';
import { TabCertifications } from '@models/certifications/components/TabCertifications';
import { ENTITY_TYPES } from '@utils/roles';
import { IdentityStatus } from '@models/admin/users/utils/enums';
import { IMyAccountSubmenu } from '../../../../types/globalTypes';

interface IUserDetailsProps {
  id: number;
}

function UsersDetails({ id }: IUserDetailsProps) {
  const { t } = useTranslation();
  const { updateTitleHeader, refreshHeader, updateTagHeader, updateRouteFrom } =
    useContext(HeaderContext);
  const { user, updateImpersonateToken } = useContext(AuthContext);

  const [tabContent, setTabContent] = useState<string>('informations');
  const [loading, setLoading] = useState<boolean>(false);
  const [mailLoading, setMailLoading] = useState<boolean>(false);
  const [infos, setInfos] = useState<IAccountInfos>(DEFAULT_ACCOUNT_INFOS);

  const getInfos = useCallback(async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setLoading(true);
    const resInfos = await getUserInfo(id);
    if (resInfos) {
      setInfos(resInfos);
    }

    setLoading(false);
  }, [id]);

  const handleSetUserAccountCompliance = useCallback(
    async (isAccountCompliant: boolean, infosUser: IAccountInfos) => {
      setLoading(true);
      const updatedUser = (
        await setUserAccountCompliance({
          user_id: id,
          account_compliant: isAccountCompliant,
        })
      ).data as IUserType;
      if (updatedUser) {
        toast.success(
          updatedUser.active
            ? t('admin.user_account_activated')
            : t('admin.user_account_deactivated')
        );
        setInfos({
          ...infosUser,
          informations_utilisateur: {
            ...infosUser.informations_utilisateur,
            active: isAccountCompliant,
          },
          informations_entite: {
            ...infosUser.informations_entite,
            status: Number(updatedUser.entity_status),
          },
        });
      }
      setLoading(false);
    },
    [id]
  );

  const handleImpersonateUser = useCallback(
    async (infosUser: IAccountInfos) => {
      setLoading(true);
      const response = await impersonateUser(id, updateImpersonateToken);
      if (response) {
        toast.success(
          t('admin.user_impersonate', {
            name: `${infosUser.informations_utilisateur.firstname} ${infosUser.informations_utilisateur.lastname}`,
          })
        );
        localStorage.removeItem('user_view_switched');
      }
      setLoading(false);
    },
    [id]
  );

  const handleMarkAsNonCompliant = useCallback(
    async (infosUser: IAccountInfos) => {
      setMailLoading(true);
      const response = await markAsNonCompliant({ user_id: id });
      if (response) {
        toast.success(
          t('admin.non_compliant_account.mail_success', {
            name: `${infosUser.informations_utilisateur.firstname} ${infosUser.informations_utilisateur.lastname}`,
          })
        );
      }
      setMailLoading(false);
    },
    [id]
  );

  const handleIdentityCheckDemandRenewal = useCallback(
    async (infosUser: IAccountInfos) => {
      setLoading(true);
      const response = await renewIdentityCheckDemand(id);
      if (response) {
        toast.success(
          t('admin.users.identity_check.success', {
            name: `${infosUser.informations_utilisateur.firstname} ${infosUser.informations_utilisateur.lastname}`,
          })
        );
        setInfos({
          ...infosUser,
          informations_utilisateur: {
            ...infosUser.informations_utilisateur,
            identity_status: IdentityStatus.CREATED,
          },
        });
      }
      setLoading(false);
    },
    [id]
  );

  const tabs = useMemo(() => {
    let tabsToDisplay = MY_ACCOUNT_TABS;
    // hide password tab if user viewing is not the same as the one displayed
    if (infos.informations_utilisateur.id !== user?.id) {
      tabsToDisplay = tabsToDisplay.filter(
        (tab: IMyAccountSubmenu) => tab.content !== 'password'
      );
    }
    if (
      infos.informations_utilisateur.entity_type !== ENTITY_TYPES.INSTALLATEUR
    ) {
      tabsToDisplay = tabsToDisplay.filter(
        (tab: IMyAccountSubmenu) => tab.content !== 'certifications'
      );
    }
    return tabsToDisplay;
  }, [infos]);

  const renderTab = useMemo(() => {
    switch (tabContent) {
      case 'users':
        return <TabAccountUsers infos={infos} loadingInfos={loading} />;
      case 'password':
        return <CardPassword />;
      case 'history':
        return <HistoryUsers id={id} />;
      case 'certifications':
        return (
          <TabCertifications
            entityIdForRequests={infos.informations_entite.id}
          />
        );
      case 'informations':
      default:
        return (
          <TabAccountInfos
            data={infos}
            loading={loading}
            refetch={getInfos}
            uploadDisabled={infos.informations_utilisateur.id !== user?.id}
            setData={setInfos}
          />
        );
    }
  }, [tabContent, infos, loading]);

  useEffect(() => {
    refreshHeader(true);
    updateTitleHeader(`${t('admin.user_details', { id })}`);
    updateTagHeader(undefined);
    getInfos();
    updateRouteFrom('admin/users');
    window.scrollTo(0, 0);

    return () => {
      updateRouteFrom('');
    };
  }, []);

  const userIsMe = useMemo(() => {
    return user?.id === infos.informations_utilisateur.id;
  }, [user, infos]);

  const shouldResendIdentityCheckDemand = useMemo(() => {
    return (
      infos.informations_utilisateur.identity_status ===
        IdentityStatus.NOT_CREATED ||
      infos.informations_utilisateur.identity_status ===
        IdentityStatus.EXPIRED ||
      infos.informations_utilisateur.identity_status === IdentityStatus.ERROR
    );
  }, [user, infos]);

  return (
    <>
      <div className="users tabs_wrapper mt-6 flex justify-between">
        <div>
          {tabs.map((tab: IMyAccountSubmenu) => (
            <ButtonOpx
              key={tab.content}
              onClick={() => {
                setTabContent(tab.content || '');
                if (tab.content === 'informations') {
                  getInfos();
                }
              }}
              label={t(`my_account.tabs.${tab.submenu}`)}
              type="tab"
              addClass="mr-3"
              active={tabContent === tab.content}
              dataTestId={`tab-${tab.content}`}
            />
          ))}
        </div>
        {loading ? (
          <LoaderDivSkeleton height="2.5rem" width="15rem" />
        ) : (
          <div>
            {!userIsMe && (
              <ButtonOpx
                onClick={() => {
                  handleImpersonateUser(infos);
                }}
                label={t('admin.user_impersonate_action', {
                  name: `${infos.informations_utilisateur.firstname} ${infos.informations_utilisateur.lastname}`,
                })}
                type="secondary"
                addClass="mr-3"
                color={white}
                backgroundColor={orange}
              />
            )}
            {!userIsMe && shouldResendIdentityCheckDemand && (
              <ButtonOpx
                onClick={() => {
                  handleIdentityCheckDemandRenewal(infos);
                }}
                label={t('admin.users.identity_check.button')}
                type="secondary"
                addClass="mr-3"
                color={white}
                backgroundColor={red}
              />
            )}
            {infos.informations_utilisateur.active
              ? !userIsMe && (
                  <ButtonOpx
                    onClick={() => {
                      handleSetUserAccountCompliance(false, infos);
                    }}
                    label={t('admin.user_non_compliant')}
                    type="secondary"
                    addClass="mr-3"
                  />
                )
              : !userIsMe && (
                  <>
                    <ButtonOpx
                      onClick={() => {
                        handleMarkAsNonCompliant(infos);
                      }}
                      label={t('admin.non_compliant_account.button')}
                      type="secondary"
                      addClass="mr-3"
                      color={white}
                      backgroundColor={red}
                      isLoading={mailLoading}
                    />
                    <ButtonOpx
                      onClick={() => {
                        handleSetUserAccountCompliance(true, infos);
                      }}
                      label={t('admin.user_compliant')}
                      type="primary"
                      addClass="mr-3"
                    />
                  </>
                )}
          </div>
        )}
      </div>
      <div className="tabs_content">{renderTab}</div>
    </>
  );
}

export { UsersDetails };
