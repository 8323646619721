import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '@context/globalContext';
import { WorksitesContext } from '@models/worksites/utils/worksitesContext';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { UpdateWorksitePrimesModal } from '@models/worksites/components/worksiteDetails/worksiteInformations/UpdateWorksitePrimesModal';
import {
  postWorksiteUpdateAmounts,
  updateWorksitePrimes,
} from '@models/worksites/apiRequests/worksitesRequests';
import { findKeyByValue } from '@utils/functions';
import DynamicInputEdit from '@components/atomic/inputs/DynamicInputEdit';
import { InputTypes } from '@utils/utils';
import { Card } from '@components/Card';
import ActionButtons from '@models/worksites/components/worksiteDetails/worksiteInformations/ActionButtons';
import {
  getAhGeneralData,
  getAideApiDataFromValue,
  totalBonus,
} from '@models/worksites/utils/utils';
import { housingDatas } from '@models/worksites/utils/tab';
import { AuthContext } from '@context/authContext';
import { OperationTypeEnum } from '@utils/enums';

interface ICardHousingProps {
  iconsList: { [x: string]: JSX.Element };
}
function CardHousing({ iconsList }: ICardHousingProps) {
  const { t } = useTranslation();
  const { worksiteDetails, updateWorksiteDetails, updateAhGeneralData } =
    useContext(WorksitesContext);
  const { user } = useContext(AuthContext);
  const { globalEnum } = useContext(GlobalContext);
  const { housing_type, age_type, replaced_energy_type } = globalEnum;

  const isCreator = user?.current_entity_type === worksiteDetails.created_by;
  const worksiteHousing = worksiteDetails.housing;

  const methods = useForm();
  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;
  const haveFormErrors = Object.keys(errors).length > 0;
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
  const [newPayload, setNewPayload] = useState<{
    [x: string]: string | number;
  }>();

  const getData = async (sendData: { [x: string]: string | number }) => {
    if (!isLoadingUpdate) {
      setIsLoadingUpdate(true);
      const res = await postWorksiteUpdateAmounts(
        String(worksiteDetails.id),
        sendData
      );

      const totalPrimes = totalBonus(worksiteDetails.worksites_operations);

      if (Number(res.totalPrimesEuros) !== totalPrimes) {
        setNewPayload(res);
        if (res.totalPrimesEuros === 0) reset();
      } else if (res) {
        const worksiteRes = await updateWorksitePrimes(
          String(worksiteDetails.id),
          res
        );

        if (worksiteRes.data) {
          updateWorksiteDetails(worksiteRes.data);
          await getAhGeneralData(worksiteDetails.id, updateAhGeneralData);
        } else setNewPayload(undefined);
      }

      setIsEditMode(false);
      setIsLoadingUpdate(false);
    }
  };

  const onSubmit = (data: FieldValues) => {
    const age = getAideApiDataFromValue(data.age, globalEnum, 'age_type');

    const housingType = getAideApiDataFromValue(
      data.housing,
      globalEnum,
      'housing_type'
    );

    const replacedEnergy = findKeyByValue(
      replaced_energy_type,
      String(data.removal)
    );

    const isB2b = worksiteDetails.operation_type === OperationTypeEnum.B2B;

    const operations = !worksiteDetails.payload
      ? []
      : JSON.parse(worksiteDetails.payload)
          .operations.filter(
            (op: { [x: string]: string | number }) =>
              op['operation.replacedEnergy']
          )
          .map((op: { [x: string]: string | number }) => ({
            operation_id: op['operation.id'],
            operation_replacedEnergy: replacedEnergy,
          }));

    const sendDataB2c = {
      general_habitation: housingType,
      general_age: age,
      general_surface: data.surface,
      operations,
    };

    const sendDataB2b = { general_age: age, operations };

    const worksiteRemoval = worksiteDetails.worksites_operations.find(
      (op) => op.replaced_energy
    )?.replaced_energy;

    const noChange = Boolean(
      data.housing === housing_type[worksiteHousing.housing_type] &&
        data.age === age_type[worksiteHousing.age_type] &&
        Number(data.surface) === worksiteHousing.surface &&
        worksiteRemoval === replacedEnergy
    );

    if (!noChange) getData(isB2b ? sendDataB2b : sendDataB2c);
    // Pour le b2b seul l'age du logement est demandé
    else setIsEditMode(false);
  };

  const handleCancel = () => {
    setIsEditMode(false);
    reset(); // Reset the form to its initial values
  };

  useEffect(() => {
    if (!isEditMode) setIsLoadingUpdate(false);
  }, [isEditMode]);

  return (
    <>
      <Card
        title={t('worksites.housing.title')}
        addClass="h-[max-content] w-full"
        dataTestId="worksite_housing"
        actionButtons={
          isCreator ? (
            <ActionButtons
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              onSubmit={handleSubmit(onSubmit)}
              isLoading={isLoadingUpdate}
              disabled={haveFormErrors || isLoadingUpdate}
              onCancel={handleCancel}
            />
          ) : undefined
        }
      >
        <FormProvider {...methods}>
          <div className="grid gap-2 grid-cols-2 pb-4 w-full">
            {housingDatas(
              t,
              iconsList,
              globalEnum,
              worksiteDetails,
              isEditMode
            ).map((input) =>
              input.empty ? null : (
                <DynamicInputEdit
                  key={input.name}
                  isEditMode={input.isEditable || false}
                  name={input.name || ''}
                  inputType={input.inputType || InputTypes.TEXT}
                  label={input.label || ''}
                  options={input.options}
                  initialValue={input.initialValue}
                  addClass="w-full mt-4"
                  icon={input.icon}
                  required={!input.notRequired}
                />
              )
            )}
          </div>
        </FormProvider>
      </Card>

      {newPayload && (
        <UpdateWorksitePrimesModal
          onError={() => setNewPayload(undefined)}
          onClickCancel={() => setNewPayload(undefined)}
          newPayload={newPayload}
          setNewPayload={setNewPayload}
        />
      )}
    </>
  );
}

export { CardHousing };
