import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { IBeneficiaryAddress } from '@models/beneficiaries/utils/beneficiariesType';
import { IWorksiteAddress } from '@models/worksiteCreation/utils/types/worksitesType';
import { placeholderExample, transformAddressKeys } from '@utils/utils';
import { ManualAddressForm } from './ManualAddressForm';
import { InputSearchAddressGoogle } from './InputSearchAddressGoogle';

export type IFormAddressDatas = {
  city: string;
  country: string;
  street: string;
  streetNumber: string;
  zipCode: string;
  latitude?: number;
  longitude?: number;
};

export type ICustomInputIds = {
  city: string;
  country: string;
  street: string;
  streetNumber: string;
  zipCode: string;
};

interface IFormAddress {
  addressDatas?: IBeneficiaryAddress;
  setAddressDatas?: Dispatch<SetStateAction<IBeneficiaryAddress>>;
  validAddress?: boolean;
  valueInitialAddress?: string;
  required?: boolean;
  resetValue?: string;
  datas?: IFormAddressDatas;
  updateDatas?: (
    updates: Partial<IFormAddressDatas | IWorksiteAddress>
  ) => void;
  updateDatasDirectForm?: boolean;
  customInputIds?: ICustomInputIds;
  isManual?: boolean;
  setIsManual?: (isManual: boolean) => void;
  showLabel?: boolean;
  disabled?: boolean;
  clearSimulatorAddress?: (newAddress: string | null) => void;
}

function FormAddress({
  validAddress,
  addressDatas,
  setAddressDatas,
  valueInitialAddress,
  required,
  resetValue,
  datas,
  updateDatas,
  updateDatasDirectForm,
  customInputIds,
  isManual,
  setIsManual,
  showLabel,
  disabled,
  clearSimulatorAddress,
}: IFormAddress) {
  const { t } = useTranslation();

  const {
    value,
    setValue,
    suggestions: { data },
  } = usePlacesAutocomplete();

  const [initialised, setInitialised] = useState(false);

  const inputGoogleValue = useMemo(() => {
    if (value === '') {
      if (resetValue) return resetValue;

      if (valueInitialAddress && valueInitialAddress !== '') {
        setInitialised(true);
        if (!initialised) {
          return valueInitialAddress;
        }
        return '';
      }
      if (addressDatas) {
        return `${addressDatas?.address || ''} ${
          addressDatas?.postal_code || ''
        } ${addressDatas?.city || ''} ${addressDatas?.country || ''}`.trim();
      }
      if (datas) {
        return `${datas?.street || ''} ${datas?.zipCode || ''} ${
          datas?.city || ''
        } ${datas?.country || ''}`.trim();
      }
    }
    return value;
  }, [value]);

  const customUpdateDatas = (updates: Partial<IFormAddressDatas>) => {
    if (updateDatas) {
      if (!updateDatasDirectForm) {
        const transformedUpdates = transformAddressKeys(updates);
        updateDatas(transformedUpdates);
      } else {
        updateDatas(updates);
      }
    }
  };

  const handleOpenManualForm = () => {
    if (setIsManual) {
      customUpdateDatas({
        city: '',
        country: '',
        street: '',
        streetNumber: '',
        zipCode: '',
      });
      setIsManual(true);
    }
  };

  const placeholders = placeholderExample(t);

  return (
    <div className="">
      {showLabel ? (
        <div className="text-textGrey text-[.75rem] leading-[.75rem] mb-2">
          {t('forms.address.placeholder')}
          {!required && <span> ({t('global.optional')})</span>}
        </div>
      ) : null}
      {!isManual ? (
        <InputSearchAddressGoogle
          setIsManual={handleOpenManualForm}
          data={data}
          value={inputGoogleValue}
          setValue={setValue}
          placeholder={placeholders.ADDRESS}
          addressDatas={addressDatas}
          setAddressDatas={setAddressDatas}
          validAddress={validAddress}
          updateDatas={customUpdateDatas}
          disabled={disabled}
          clearSimulatorAddress={clearSimulatorAddress}
        />
      ) : (
        datas &&
        updateDatas && (
          <ManualAddressForm
            datas={datas}
            updateDatas={customUpdateDatas}
            customInputIds={customInputIds as ICustomInputIds}
            disabled={disabled}
          />
        )
      )}
    </div>
  );
}

export { FormAddress };

FormAddress.defaultProps = {
  customInputIds: {
    city: 'city',
    country: 'country',
    street: 'street',
    streetNumber: 'streetNumber',
    zipCode: 'zip',
  },
  setAddressDatas: undefined,
  addressDatas: undefined,
  validAddress: undefined,
  valueInitialAddress: undefined,
  required: false,
  resetValue: '',
  datas: undefined,
  updateDatas: undefined,
  updateDatasDirectForm: false,
  isManual: false,
  setIsManual: undefined,
  showLabel: false,
  disabled: false,
  clearSimulatorAddress: '',
};
