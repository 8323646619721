import { TextWithRadio } from '@components/atomic/inputs/controls/TextWithRadio';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';

interface IBoolMarProps {
  fieldName: string;
  question: string;
  description?: string;
}

function BoolMar({ fieldName, question, description }: IBoolMarProps) {
  const { t } = useTranslation();
  const { worksiteData } = useContext(WorksiteCreationMarContext);
  const { watch, setValue } = useFormContext();

  const fieldValue = watch(fieldName);

  const selectValue = fieldValue ? fieldValue.toString() : '';
  const isChecked = (value: 'true' | 'false') => fieldValue === value;
  const onSelect = (e: string) => setValue(fieldName, e);

  useEffect(() => {
    const worksiteDataAny = worksiteData as any;
    if (
      !fieldValue &&
      worksiteDataAny[fieldName] !== null &&
      worksiteDataAny[fieldName] !== undefined
    ) {
      setValue(fieldName, worksiteDataAny[fieldName].toString());
    }
  }, []);

  return (
    <div className="flex flex-col space-y-4">
      <div>
        <p className="font-medium text-[1.125rem]">{question}</p>
        {description && <p className="text-[.875rem] pt-2">{description}</p>}
      </div>
      <div className="grid grid-cols-2 gap-4">
        <TextWithRadio
          label={`${t('global.yes')}`}
          value="true"
          addClass="flex-grow"
          selectValue={selectValue}
          isChecked={isChecked('true')}
          setSelectedValue={(e) => onSelect(e)}
          withoutName
        />
        <TextWithRadio
          label={`${t('global.no')}`}
          value="false"
          addClass="flex-grow"
          selectValue={selectValue}
          isChecked={isChecked('false')}
          setSelectedValue={(e) => onSelect(e)}
          withoutName
        />
      </div>
    </div>
  );
}

export default BoolMar;

BoolMar.defaultProps = {
  description: undefined,
};
