import LayoutMarDetailsStep from '@models/mar/components/worksiteCompletion/LayoutMarDetailsStep';
import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import OneMandatory from '@models/mar/components/worksiteCompletion/stepsCompletion/mandates/OneMandatory';
import {
  MANDATORY_TYPES,
  PERSON_TYPES,
  STEPS_WORKSITE_MAR_COMPLETION,
} from '@models/mar/utils/enums';
import { validateAdministrativeMandate } from '@models/mar/apiRequests/worksiteCreationMarRequests';

function Mandate() {
  const {
    worksiteData,
    updateButtonNextDisabled,
    updateWorksiteData,
    stepCompletion,
    updateStepCompletion,
  } = useContext(WorksiteCreationMarContext);

  const [buttonNextLoading, setButtonNextLoading] = useState(false);

  const { watch, setValue } = useFormContext();

  const mandatoriesFormValues = watch('mandataires');

  const setFieldValueWithConversion = (field: string, value: any) => {
    let convertedValue = value;
    if (typeof value === 'number') {
      convertedValue = String(value);
    }
    if (value !== null && value !== undefined) {
      setValue(field, convertedValue);
    }
  };

  const preFillMandatesData = () => {
    if (worksiteData) {
      const mandataires = worksiteData.mandataires;
      if (mandataires) {
        mandataires.forEach((mandataire: any, index: number) => {
          const prefix = `mandataires[${index}]`;
          if (
            index > 0 &&
            mandataire.firstname === mandataires[index - 1].firstname &&
            mandataire.lastname === mandataires[index - 1].lastname &&
            stepCompletion ===
              STEPS_WORKSITE_MAR_COMPLETION.ADMINISTRATIVE_MANDATE
          ) {
            setFieldValueWithConversion(`${prefix}.same_as_proxy`, 'true');
          }
          Object.keys(mandataire).forEach((key) => {
            const value = mandataire[key];
            if (key === 'entity' && value) {
              Object.keys(value).forEach((entityKey) => {
                const entityValue = value[entityKey];
                setFieldValueWithConversion(
                  `${prefix}.entity.${entityKey}`,
                  entityValue
                );
              });
            } else {
              setFieldValueWithConversion(`${prefix}.${key}`, value);
            }
          });
        });
      }
    }
  };

  const getIndexByType = (type: number) => {
    let index = -1;
    if (Array.isArray(mandatoriesFormValues)) {
      index = mandatoriesFormValues.findIndex(
        (mandatory: any) => Number(mandatory.type) === type
      );
    }
    if (index === -1) {
      switch (type) {
        case MANDATORY_TYPES.ADMINISTRATIF_AIDE:
          return 1;
        case MANDATORY_TYPES.FINANCIER:
          return 2;
        case MANDATORY_TYPES.ADMINISTRATIF_SIGNATURE:
        default:
          return 0;
      }
    }
    return index;
  };

  const filteredMandatoriesFormValuesByType = mandatoriesFormValues
    ? mandatoriesFormValues.filter((mandatory: any) =>
        stepCompletion === STEPS_WORKSITE_MAR_COMPLETION.FINANCIAL_MANDATE
          ? Number(mandatory.type) === MANDATORY_TYPES.FINANCIER
          : Number(mandatory.type) !== MANDATORY_TYPES.FINANCIER
      )
    : undefined;

  const checkIfMandateInfoReady = () => {
    if (!filteredMandatoriesFormValuesByType) return false;

    return filteredMandatoriesFormValuesByType.every((mandatory: any) => {
      if (mandatory.same_as_proxy) {
        return true;
      }

      const {
        civility,
        firstname,
        lastname,
        email,
        phone_number,
        person_type,
        address,
        zipcode,
        city,
        entity,
        function: mandatoryFunction,
      } = mandatory;

      const isBaseInfoReady =
        civility &&
        firstname &&
        lastname &&
        email &&
        phone_number &&
        mandatoryFunction;

      const isLegalInfoReady =
        person_type === PERSON_TYPES.LEGAL &&
        entity?.siret &&
        entity?.company_name &&
        entity?.address;

      const isNaturalInfoReady =
        person_type !== PERSON_TYPES.LEGAL && address && zipcode && city;

      const isAnahNumberReady =
        Number(mandatory.type) === MANDATORY_TYPES.FINANCIER
          ? !!mandatory.anah_number
          : true;

      return (
        isBaseInfoReady &&
        isAnahNumberReady &&
        (isLegalInfoReady || isNaturalInfoReady)
      );
    });
  };

  const handleValidateStep = async () => {
    setButtonNextLoading(true);
    const dataToSend = filteredMandatoriesFormValuesByType.map(
      (mandatory: any, index: number) => {
        let mandatoryToUse = mandatory;
        if (mandatory.same_as_proxy && index > 0) {
          mandatoryToUse = filteredMandatoriesFormValuesByType[index - 1];
        }

        const {
          id,
          civility,
          firstname,
          lastname,
          email,
          phone_number,
          person_type,
          address,
          zipcode,
          city,
          entity,
          function: mandatoryFunction,
        } = mandatoryToUse;

        return {
          id,
          civility: Number(civility),
          firstname,
          lastname,
          email,
          phone_number,
          person_type: Number(person_type),
          address,
          zipcode,
          city,
          entity: entity && entity.siret && !entity.id ? entity : undefined,
          entity_id: entity && entity.id ? entity.id : undefined,
          function: mandatoryFunction,
          aid_request:
            Number(mandatory.type) === MANDATORY_TYPES.ADMINISTRATIF_AIDE
              ? mandatory.aid_request
              : undefined,
          payment_request:
            Number(mandatory.type) === MANDATORY_TYPES.ADMINISTRATIF_AIDE
              ? mandatory.payment_request
              : undefined,
          anah_number:
            Number(mandatory.type) === MANDATORY_TYPES.FINANCIER
              ? mandatory.anah_number
              : undefined,
          type: mandatory.type,
        };
      }
    );
    const response = await validateAdministrativeMandate(
      worksiteData?.id || 0,
      {
        mandataires: dataToSend,
      }
    );
    if (response) {
      updateWorksiteData((prev) => ({
        ...prev,
        ...response,
      }));

      preFillMandatesData();
      updateStepCompletion(
        stepCompletion === STEPS_WORKSITE_MAR_COMPLETION.ADMINISTRATIVE_MANDATE
          ? STEPS_WORKSITE_MAR_COMPLETION.FINANCIAL_MANDATE
          : STEPS_WORKSITE_MAR_COMPLETION.CEE_ANAH
      );
    }
    setButtonNextLoading(false);
  };

  useEffect(() => {
    updateButtonNextDisabled(!checkIfMandateInfoReady());
  }, [watch()]);

  useEffect(() => {
    preFillMandatesData();
  }, []);

  return (
    <LayoutMarDetailsStep
      onValidate={handleValidateStep}
      buttonNextLoading={buttonNextLoading}
    >
      {stepCompletion ===
      STEPS_WORKSITE_MAR_COMPLETION.ADMINISTRATIVE_MANDATE ? (
        <div className="flex flex-col space-y-6">
          <OneMandatory
            indexMandatory={getIndexByType(
              MANDATORY_TYPES.ADMINISTRATIF_SIGNATURE
            )}
            mandatoryType={MANDATORY_TYPES.ADMINISTRATIF_SIGNATURE}
          />
          <OneMandatory
            indexMandatory={getIndexByType(MANDATORY_TYPES.ADMINISTRATIF_AIDE)}
            mandatoryType={MANDATORY_TYPES.ADMINISTRATIF_AIDE}
          />
        </div>
      ) : (
        <OneMandatory
          indexMandatory={getIndexByType(MANDATORY_TYPES.FINANCIER)}
          mandatoryType={MANDATORY_TYPES.FINANCIER}
        />
      )}
    </LayoutMarDetailsStep>
  );
}

export default Mandate;
