/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useMemo, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import _ from 'lodash';

import { AuthContext } from '@context/authContext';
import { HeaderContext } from '@context/headerContext';

import { ListTable, IColumn, IRow } from '@components/ListTable';
import { SubHeader } from '@components/subHeader/SubHeader';
import { Tag } from '@components/atomic/Tag';
import { ListPagination } from '@components/atomic/pagination/ListPagination';

import { getBeneficiariesList } from '@models/beneficiaries/apiRequests/beneficiariesRequests';
import { IBeneficiary } from '@models/beneficiaries/utils/beneficiariesType';

import { blueOpx, iconBlue } from '@assets/color';
import { SortAndFilter } from '@components/sortAndFilter/SortAndFilter';
import { ISortAndFilterType } from '@components/sortAndFilter/utils/sortAndFilterTypes';
import {
  filterList,
  resetPagination,
  sortList,
} from '@components/sortAndFilter/utils/functions';
import { TabsLeftPart } from '@components/subHeader/TabsLeftPart';
import { BENEFICIARIES_ROUTES_WITH_ID } from '@utils/routesUrls';
import { ResultsPerPageButton } from '@components/atomic/pagination/ResultsPerPageButton';

function BeneficiariesList() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const { user } = useContext(AuthContext);
  const { updateTitleHeader, updateDisplayBackButtonHeader, refreshHeader } =
    useContext(HeaderContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [paginationData, setPaginationData] = useState<
    Record<string, string | number | null | any> | undefined
  >();
  const [pagesDatas, setPagesDatas] = useState<{ data: any; meta: any }[]>([]);
  const [resultsPerPage, setResultsPerPage] = useState<number>();
  const [sortAndFilterData, setSortAndFilterData] =
    useState<ISortAndFilterType>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [isB2b, setIsB2b] = useState<boolean>(location.hash === '#b2b');

  const getData = async (page: number) => {
    setLoading(true);

    const resList = await getBeneficiariesList({
      page,
      perPage: resultsPerPage || 5,
      isB2b,
      sort_and_filter: sortAndFilterData,
    });

    if (resList) {
      const meta = _.omit(resList, 'data');

      setData(resList.data);
      setPaginationData(meta.meta);

      if (resList?.current_page > pagesDatas.length) {
        setPagesDatas([...pagesDatas, { data: resList.data, meta }]);
      }
    }
    setLoading(false);
  };

  const pageAlreadyLoad = (page: number) => {
    setData(pagesDatas[page - 1].data);
    setPaginationData(pagesDatas[page - 1].meta);
  };

  const columnHelper = createColumnHelper<IBeneficiary>();

  const canViewBeneficiaries = useMemo(
    () =>
      ['admin-admin', 'installateur-admin'].includes(user?.role_name || '') &&
      user?.permissions_names.includes('lister-beneficiaire '),
    [user]
  );

  // A SUPPRIMER APRES LA FIN DES TESTS
  const forceDisplayForTests = true;

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: () => t('beneficiaries.fullname'),
        cell: (info) => {
          const firstname = info.row.original.firstname;
          const lastname = info.row.original.lastname;

          return `${firstname} ${lastname}`;
        },
      }),
      columnHelper.accessor('company_name', {
        header: () => t('partners.company_name'),
      }),
      columnHelper.accessor('siret', {
        header: () => t('partners.siret'),
        cell: (info) => {
          if (info.row.original.siret) return info.row.original.siret;
          if (info.row.original.siren) return `${info.row.original.siren}XXXXX`;
          return '';
        },
      }),
      columnHelper.accessor('address.city', {
        header: () => t('forms.address.manual.city'),
      }),
      columnHelper.accessor('address.postal_code', {
        header: () => t('forms.address.manual.zip'),
      }),
      columnHelper.accessor('address.address', {
        header: () => t('forms.address.placeholder'),
        cell: (info) => {
          const city = info.row.original.address.city;
          const postal_code = info.row.original.address.postal_code;

          return (
            <>
              <p>{info.getValue()}</p>
              <p>{`${postal_code || ''} ${city || ''}`.trim()}</p>
            </>
          );
        },
      }),
      columnHelper.accessor('total', {
        header: () => '',
        cell: (info) => {
          const resDisplay = [
            {
              count: info.row.original.total?.worksites ?? 0,
              name: 'worksite',
            },
            {
              count: info.row.original.total?.simulations ?? 0,
              name: 'simulation',
            },
          ];

          const statusToDisplayFiltered = resDisplay.filter(
            (status) => status.count > 0
          );

          return statusToDisplayFiltered.map((statutItem, index) => (
            <Tag
              key={statutItem.name}
              color={iconBlue}
              label={`${statutItem.count} ${String(
                t(
                  `beneficiaries.${statutItem.name}${
                    statutItem.count > 1 ? 's' : ''
                  }`
                )
              ).toLowerCase()}`}
              addClass={`${
                index !== statusToDisplayFiltered.length - 1 ? 'mb-2' : ''
              } ${index > 0 ? 'mt-2' : ''}`}
            />
          ));
        },
      }),
    ],
    [data, isB2b]
  );

  useEffect(() => {
    if (sortAndFilterData) {
      resetPagination(setPaginationData, setPagesDatas);
      getData(1);
    }
  }, [sortAndFilterData, isB2b]);

  useEffect(() => {
    getData(1);
    refreshHeader();
    updateTitleHeader(`${t('beneficiaries.list')}`);
  }, [isB2b]);

  useEffect(() => {
    if (resultsPerPage) {
      getData(1);
    }
  }, [resultsPerPage]);

  return (
    <div className="w-full items-center justify-center">
      {canViewBeneficiaries || forceDisplayForTests ? (
        <>
          <SubHeader
            leftPart={
              <TabsLeftPart
                activeButton={
                  isB2b
                    ? `${t('beneficiaries.pro')}`
                    : `${t('beneficiaries.individual')}`
                }
                titlesList={[
                  `${t('beneficiaries.individual')}`,
                  `${t('beneficiaries.pro')}`,
                ]}
                onClick={(title) => {
                  if (title === t('beneficiaries.pro')) setIsB2b(true);
                  else setIsB2b(false);
                }}
              />
            }
            rightPart={
              <SortAndFilter
                onSort={(column, direction) =>
                  sortList(column, direction, setSortAndFilterData)
                }
                page="BENEFICIAIRES"
                onFilter={(filters) =>
                  filterList(filters, setSortAndFilterData)
                }
                hideFilter
              />
            }
          />
          <div className="flex justify-between">
            <div className="mt-4">
              {paginationData?.total !== undefined && (
                <div>
                  {t('pagination.total_results')} : {paginationData.total}
                </div>
              )}
            </div>
            <div className="mb-4">
              <ResultsPerPageButton
                resultsPerPage={resultsPerPage || 5}
                onChange={(value) => {
                  setResultsPerPage(value);
                  setCurrentPage(1);
                }}
                options={[5, 10, 20, 50, 100, 250]}
                colorPagination={blueOpx}
              />
            </div>
          </div>
          <ListTable
            loading={loading}
            columns={columns as IColumn[]}
            data={data}
            callBackOnRowClick={(e: IRow) => {
              const rowData = e.original as IBeneficiary;
              navigate(
                BENEFICIARIES_ROUTES_WITH_ID(rowData.id as number)
                  .BENEFICIARY_VIEW
              );
              updateDisplayBackButtonHeader(true);
            }}
            hiddenColumns={!isB2b ? ['siret', 'company_name'] : ['id']}
          />
          {paginationData && (
            <ListPagination
              getData={(page) => {
                setCurrentPage(page);
                if (page > pagesDatas.length) {
                  getData(page);
                } else {
                  pageAlreadyLoad(page);
                }
              }}
              paginationData={paginationData}
              resultsPerPage={resultsPerPage || 5}
              currentPage={currentPage}
            />
          )}
        </>
      ) : (
        <div className="py-10 w-full items-center justify-center text-danger">
          <p>{t('global.no_permission')}</p>
        </div>
      )}
    </div>
  );
}

export { BeneficiariesList };
