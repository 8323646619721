import { IGraph } from '@models/worksiteCreation/utils/types/SimulationTypes';
import ArrayInput from '@models/worksiteCreation/components/simulation/stepDetailOperation.tsx/ArrayInput';
import { useContext, useEffect, useState } from 'react';
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { useTranslation } from 'react-i18next';

interface IArrayFormProps {
  graph: IGraph;
  operationIndex: number;
  boilerPowerError?: boolean;
}

function ArrayForm({
  graph,
  operationIndex,
  boilerPowerError,
}: IArrayFormProps) {
  const { t } = useTranslation();
  const { updateSimulatorDataOperation, simulatorData, readOnly } = useContext(
    WorksiteCreationContext
  );
  const [arrayData, setArrayData] = useState<
    { [x: string]: string | number }[]
  >([]);
  const [numberUnits, setNumberUnits] = useState<number>(1);
  const maxUnits = graph.arrayKeys?.max ? Number(graph.arrayKeys?.max) : null;

  const numberArray = Array.from(
    { length: numberUnits },
    (_, index) => index + 1
  );

  const listItems: { label: string; value: any; defaultValue?: boolean }[] =
    graph?.arrayKeys?.items ?? [];

  const defaultItemSelected =
    listItems.find((item) => item.defaultValue === true) || [];

  const [itemsSelected, setItemsSelected] = useState<
    { label: string; value: any }[]
  >(
    Array.isArray(defaultItemSelected)
      ? defaultItemSelected
      : [defaultItemSelected]
  );

  const handleChangeValue = (index: number, value: string, key: string) => {
    setArrayData(
      arrayData.map((d, i) => (i === index ? { ...d, [key]: value } : d))
    );
  };

  let emptyValue = {};
  graph.arrayKeys?.elements.forEach((key) => {
    emptyValue = { ...emptyValue, [key.key]: '' };
  });

  const handleSetNumberUnits = (value: number) => {
    if (value < arrayData.length) {
      setNumberUnits(value);
      setArrayData(arrayData.filter((_, i) => i < value));
    } else {
      setNumberUnits(value);
      const updatedData = [...arrayData];
      for (let i = 0; i < value; i += 1) {
        if (!updatedData[i]) {
          updatedData[i] = emptyValue;
        }
      }
      setArrayData(updatedData);
    }
  };

  const handleItemsSelected = (selectedItem: { label: string; value: any }) => {
    setItemsSelected((prevState) => {
      const exists = prevState.find(
        (item) => item.value === selectedItem.value
      );
      if (exists) {
        return prevState.filter((item) => item.value !== selectedItem.value);
      }
      return [...prevState, selectedItem];
    });
  };

  const handleChangeItemsValue = (itemKey: any, inputValue: string) => {
    setArrayData((prevData) => {
      const existingItemIndex = prevData.findIndex((data) => itemKey in data);
      if (existingItemIndex > -1) {
        const newData = [...prevData];
        newData[existingItemIndex] = {
          ...newData[existingItemIndex],
          [itemKey]: inputValue,
        };
        return newData;
      }
      return [...prevData, { [itemKey]: inputValue }];
    });
  };

  useEffect(() => {
    updateSimulatorDataOperation((prevState: any) => {
      const dataArray = Array.isArray(prevState) ? [...prevState] : [prevState];
      return dataArray.map((elt, i) => {
        if (i === operationIndex) {
          return { ...elt, [graph.key]: arrayData };
        }
        return elt;
      });
    });
  }, [arrayData]);

  useEffect(() => {
    if (
      simulatorData.operations &&
      simulatorData.operations.length > 0 &&
      arrayData.length === 0
    ) {
      if (
        simulatorData.operations[operationIndex] &&
        simulatorData.operations[operationIndex][graph.key]
      ) {
        setArrayData(simulatorData.operations[operationIndex][graph.key]);
        setNumberUnits(
          simulatorData.operations[operationIndex][graph.key].length
        );
      }
    }
  }, [simulatorData]);

  useEffect(() => {
    setArrayData((currentArrayData) => {
      // Ajouter des clés avec des valeurs vides pour les items sélectionnés sans valeurs
      const updatedData = itemsSelected.map((item) => {
        const existingItem = currentArrayData.find(
          (data) => item.value in data
        );
        if (existingItem) {
          return existingItem;
        }
        return { [item.value]: '' };
      });
      return updatedData;
    });
  }, [itemsSelected]);

  return (
    <div className="mt-[1rem]">
      <div className="flex flex-col">
        <div className="mb-2 pt-[.5rem]"> {graph.description} </div>
        <div>
          {listItems.length > 0 ? (
            <InputSelect
              placeholder=""
              defaultChecked={
                Array.isArray(defaultItemSelected)
                  ? defaultItemSelected
                  : [defaultItemSelected.label]
              }
              isMultipleSelect
              dataLabelValue={listItems.map((item) => ({
                label: item.label,
                value: item.value,
              }))}
              valueInput=""
              onSelectLabelValue={(selectedItem) =>
                handleItemsSelected(selectedItem)
              }
              disabled={readOnly}
            />
          ) : (
            <InputSelect
              placeholder=""
              dataArrayString={[...Array(maxUnits)].map((n, unit) =>
                String(unit + 1)
              )}
              valueInput={String(numberUnits)}
              onSelect={(num) => handleSetNumberUnits(Number(num))}
              disabled={readOnly}
            />
          )}
        </div>
      </div>
      {listItems.length > 0
        ? itemsSelected.map((item, index) => (
            <div className="mt-[1rem] border border-borderGrey rounded-default p-5">
              {graph.arrayKeys?.elements?.map((elt) => (
                <div key={`${elt.key}_${item.value}`}>
                  <ArrayInput
                    data={{
                      ...elt,
                      label: `${elt.label} ${item.label}`,
                    }}
                    onChange={(value) => {
                      handleChangeItemsValue(item.value, String(value));
                    }}
                    inputValue={String(
                      arrayData.find(
                        (data) => Object.keys(data)[0] === item.value
                      )?.[item.value] || ''
                    )}
                    index={index + 1}
                  />
                </div>
              ))}
            </div>
          ))
        : !!graph.arrayKeys?.elements &&
          numberArray.map((num, index) => (
            <div
              key={`unit_${num}`}
              className="mt-[1rem] border border-borderGrey rounded-default p-5"
            >
              {graph.arrayKeys?.elements?.map((elt) => {
                return (
                  <div key={`${elt.key}_${num}`}>
                    <ArrayInput
                      data={elt}
                      onChange={(value, key) =>
                        handleChangeValue(index, String(value), key)
                      }
                      inputValue={
                        arrayData[index]
                          ? String(arrayData[index][elt.key] || '')
                          : ''
                      }
                      index={index + 1}
                      boilerPowerError={boilerPowerError}
                      boilerPowerTextError={
                        t(
                          'worksite_creation.simulation.boiler_unit.boiler_power_error'
                        ) ?? undefined
                      }
                    />
                  </div>
                );
              })}
            </div>
          ))}
    </div>
  );
}

export default ArrayForm;

ArrayForm.defaultProps = {
  boilerPowerError: false,
};
