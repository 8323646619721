import { Modal } from '@components/Modal';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchBar } from '@components/SearchBar';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { AddIcon } from '@assets/images/svgComponents';
import { v4 } from 'uuid';
import { blueOpx } from '@assets/color';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import {
  getListAllCustomers,
  getListRecentCustomers,
} from '@models/mar/apiRequests/worksiteCreationMarRequests';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import { STEPS_WORKSITE_MAR_INIT } from '@models/mar/utils/enums';
import { useNavigate } from 'react-router-dom';
import { WORKSITES_ROUTES } from '@utils/routesUrls';
import { CardOneBeneficiaryOrPartner } from '@models/worksiteCreation/components/modalFormBeforeSimulation/CardOneBeneficiaryOrPartner';
import { ICustomer } from '@models/mar/utils/marTypes';
import { Pagination } from '@components/atomic/pagination/Pagination';

function BindCustomer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateStepInit, updateCustomer } = useContext(
    WorksiteCreationMarContext
  );

  const [listIsLoading, setListIsLoading] = useState(true);
  const [listRecentIsLoading, setListRecentIsLoading] = useState(true);
  const [keySearch, setKeySearch] = useState('');
  const [numberPage, setNumberPage] = useState<number>(1);
  const [listAllCustomers, setListAllCustomers] = useState<ICustomer[]>([]);
  const [listRecentCustomers, setListRecentCustomers] = useState<ICustomer[]>(
    []
  );

  const getInitialCustomers = async () => {
    const allCustomers = await getListAllCustomers(1);
    if (allCustomers) {
      setListAllCustomers(allCustomers);
    }
    const recentCustomers = await getListRecentCustomers();
    if (recentCustomers) {
      setListRecentCustomers(recentCustomers);
    }
    Promise.all([allCustomers, recentCustomers]).then((responses) => {
      responses.forEach((response) => {
        if (response) {
          setListIsLoading(false);
          setListRecentIsLoading(false);
        }
      });
    });
  };

  const onSearch = async (keyWord: string) => {
    setKeySearch(keyWord);
    setListIsLoading(true);

    let customersGetted;
    if (keyWord === '') {
      setNumberPage(1);
      customersGetted = await getListAllCustomers(1);
      if (customersGetted) {
        setListAllCustomers(customersGetted);
      }
    } else {
      customersGetted = await getListAllCustomers(numberPage, keyWord);
    }
    setListIsLoading(!customersGetted);
  };

  const onNextStep = async (customer: ICustomer) => {
    updateCustomer(customer);
    updateStepInit(STEPS_WORKSITE_MAR_INIT.CONTRACT_CREATION);
  };

  const onChangePage = async (page: number) => {
    setNumberPage(page);
    setListIsLoading(true);
    const customersGetted = await getListAllCustomers(page, keySearch);
    if (customersGetted) {
      setListAllCustomers(customersGetted);
    }
    setListIsLoading(!customersGetted);
  };

  useEffect(() => {
    getInitialCustomers();
  }, []);

  return (
    <Modal
      withArrowBack
      onBackClick={() => navigate(WORKSITES_ROUTES.WORKSITES)}
      title={t('mar.worksite_creation.init.bind_customer.title')}
    >
      <>
        <div className="w-full flex justify-between space-x-[1rem] my-[1.5rem]">
          <SearchBar
            placeholder={t('search_bar.placeholder_customer')}
            onSearch={(keyWord) => onSearch(keyWord)}
            addClass="!w-full"
          />
          <ButtonOpx
            icon={<AddIcon />}
            label={t('mar.worksite_creation.init.bind_customer.new_customer')}
            onClick={() => {
              updateStepInit(STEPS_WORKSITE_MAR_INIT.CUSTOMER_CREATION);
            }}
            dataTestId="new_customer_button"
          />
        </div>
        {listRecentCustomers.length > 0 &&
          listRecentCustomers[0].firstname !== '' && (
            <>
              <div className="font-medium mb-[1rem]">
                {t(
                  'worksite_creation.form_modal_before_simulation.recent_beneficiaries'
                )}
              </div>
              <div className="flex flex-col space-y-[1rem]">
                {!listRecentIsLoading ? (
                  listRecentCustomers.map((customerListed) => (
                    <CardOneBeneficiaryOrPartner
                      key={v4()}
                      onClick={() => onNextStep(customerListed)}
                      leftPart={`${customerListed.firstname} ${customerListed.lastname}`}
                      rightPart={customerListed.address}
                    />
                  ))
                ) : (
                  <div className="bg-white flex flex-col w-full space-y-[1rem] h-auto rounded-md rounded-default">
                    <LoaderSkeleton height="2.5rem" />
                  </div>
                )}
              </div>
            </>
          )}

        {!listIsLoading ? (
          <>
            <div className="font-medium mb-[1rem] mt-[1.5rem]">
              {/* eslint-disable-next-line no-nested-ternary */}
              {listAllCustomers.length > 0
                ? keySearch === ''
                  ? t('mar.worksite_creation.init.bind_customer.all_landlords')
                  : t('search_bar.search_result')
                : t('mar.worksite_creation.init.bind_customer.no_customer')}
            </div>
            <div className="flex flex-col space-y-[1rem]">
              {listAllCustomers.map((customerListed) => (
                <CardOneBeneficiaryOrPartner
                  key={v4()}
                  onClick={() => onNextStep(customerListed)}
                  leftPart={`${customerListed.firstname} ${customerListed.lastname}`}
                  rightPart={customerListed.address}
                />
              ))}
            </div>
          </>
        ) : (
          <div className="bg-white flex flex-col w-full space-y-[1rem] h-auto rounded-md rounded-default">
            <LoaderSkeleton height="2.31rem" />
            <LoaderSkeleton height="2.31rem" />
            <LoaderSkeleton height="2.31rem" />
            <LoaderSkeleton height="2.31rem" />
            <LoaderSkeleton height="2.31rem" />
          </div>
        )}
        {(numberPage > 1 || listAllCustomers.length > 4) && (
          <Pagination
            numberPage={numberPage}
            totalNumberPage={0}
            colorPagination={blueOpx}
            addClass="mt-[1rem]"
            onNext={() => onChangePage(numberPage + 1)}
            onPrevious={() =>
              onChangePage(numberPage !== 1 ? numberPage - 1 : numberPage)
            }
          />
        )}
      </>
    </Modal>
  );
}

export { BindCustomer };
