/* eslint-disable no-nested-ternary */
import { WorksiteCreationContext } from '@models/worksiteCreation/utils/worksiteCreationContext';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormBeforeSimulation } from '@models/worksiteCreation/components/modalFormBeforeSimulation/FormBeforeSimulation';
import { HeaderWorksiteCreation } from '@models/worksiteCreation/components/header/HeaderWorksiteCreation';
import { ContainerMiddleScreen } from '@models/worksiteCreation/components/containers/ContainerMiddleScreen';
import { ContainerRightScreen } from '@models/worksiteCreation/components/containers/ContainerRightScreen';
import { ContainerSideBarProgressLeftScreen } from '@models/worksiteCreation/components/containers/ContainerSideBarProgressLeftScreen';
import { IConvention } from '@models/worksiteCreation/utils/types/conventionsTypes';
import { intialConvention } from '@models/worksiteCreation/utils/initialsValues/conventionInitialsValues';
import {
  StepsWorksiteCreationEnum,
  WORKSITE_CREATION_STATUS,
} from '@models/worksiteCreation/utils/enums';
import { IOperation } from '@models/worksiteCreation/utils/types/operationTypes';
import {
  IGraph,
  IIncomes,
  ISimulatorData,
  ISimulatorDataOperation,
} from '@models/worksiteCreation/utils/types/SimulationTypes';
import {
  initialGraph,
  initialSimulatorData,
  initialSimulatorDataOperation,
} from '@models/worksiteCreation/utils/initialsValues/simulationInitialsValues';
import { SaveAndQuit } from '@models/worksiteCreation/components/SaveAndQuit';
import {
  INoAidOperation,
  IPayloadInfosBeneficiary,
  IPayloadInfosLogement,
  IPayloadMaterials,
  IPayloadSizingNote,
  IPayloadSubcontractor,
  IWorksite,
  IWorksiteAddress,
} from '@models/worksiteCreation/utils/types/worksitesType';
import {
  initialSizingNote,
  initialWorksite,
} from '@models/worksiteCreation/utils/initialsValues/worksitesInitialValues';
import { StepBindBeneficiary } from '@models/worksiteCreation/components/modalFormBeforeSimulation/steps/StepBindBeneficiary';
import { StepCreateBeneficiary } from '@models/worksiteCreation/components/modalFormBeforeSimulation/steps/stepCreateBeneficiary/StepCreateBeneficiary';
import { IBeneficiary } from '@models/beneficiaries/utils/beneficiariesType';
import {
  informationBeneficiaryDataPostInitial,
  informationLogementDataPostInitial,
  worksiteAddressInitial,
} from '@models/worksiteCreation/utils/initialsValues/createWorksiteInitialsValues';
import { DeleteWorksite } from '@models/worksiteCreation/components/DeleteWorksite';
import {
  getGraph,
  getParcelMap,
  getWorkflowSidebarSteps,
  getWorksite,
} from '@models/worksiteCreation/apiRequests/worksiteCreationRequests';
import { LoaderCreateWorksite } from '@components/loaders/LoaderCreateWorksite';
import { initialOperationWorksiteCreation } from '@models/worksiteCreation/utils/initialsValues/operationInitialsValues';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Loader } from '@components/atomic/Loader';
import { AuthContext } from '@context/authContext';
import {
  constructReference,
  getCreationStepByData,
} from '@models/worksiteCreation/utils/functions';
import { IWorkflowSidebar } from '@models/worksiteCreation/utils/types/sidebarTypes';
import { OperationTypeEnum } from '@utils/enums';
import { IPartnerType } from '@models/partners/utils/types/partnersType';
import { useTranslation } from 'react-i18next';
import { ADMIN_ROUTES, WORKSITES_ROUTES } from '@utils/routesUrls';
import { isInstaller } from '@utils/roles';
import { ILinkedFile } from '../types/globalTypes';

interface IWorksiteCreationProps {
  adminMode?: boolean;
}

function WorksiteCreation({ adminMode }: IWorksiteCreationProps): JSX.Element {
  const { t } = useTranslation();
  const { hash } = useLocation();
  const navigate = useNavigate();
  const [isModals, setIsModals] = useState({
    formBeforeSimulation: true,
    bindBeneficiary: false,
    createBeneficiary: false,
    saveAndQuit: false,
    deleteWorksite: false,
  });

  const [isDeleteSimulation, setIsDeleteSimulation] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingWorksite, setIsLoadingWorksite] = useState(false);
  const [isLoadingGraphWhenWorksiteSaved, setIsLoadingGraphWhenWorksiteSaved] =
    useState(false);

  const [worksiteOperationType, setWorksiteOperationType] = useState<number>(
    OperationTypeEnum.B2C
  );

  const { user } = useContext(AuthContext);
  const { id: worksiteParamId } = useParams();
  const [beneficiary, setBeneficiary] = useState<IBeneficiary | null>(null);
  const [stepActiveWorksiteCreation, setStepActiveWorksiteCreation] =
    useState<StepsWorksiteCreationEnum>(
      StepsWorksiteCreationEnum.SIMULATION_CHOICEOPERATIONS
    );

  const [lastStep, setLastStep] = useState<StepsWorksiteCreationEnum>(
    StepsWorksiteCreationEnum.SIMULATION_CHOICEOPERATIONS
  );

  const [conventionActive, setConventionActive] =
    useState<IConvention>(intialConvention);

  const [listOperationSelected, setListOperationSelected] = useState<
    IOperation[] | null
  >([]);
  const [listOperations, setListOperations] = useState<IOperation[]>([
    initialOperationWorksiteCreation,
  ]);

  const [graphGeneral, setGraphGeneral] = useState<IGraph[]>([initialGraph]);
  const [graphOperation, setGraphOperation] = useState<IGraph[]>([
    initialGraph,
  ]);

  const [workflowSidebar, setWorkflowSidebar] = useState<IWorkflowSidebar>();
  const [simulatorData, setSimulatorData] =
    useState<ISimulatorData>(initialSimulatorData);
  const [simulatorDataOperations, setSimulatorDataOperation] = useState<
    ISimulatorDataOperation[]
  >([initialSimulatorDataOperation]);

  const [replacedEnergy, setReplacedEnergy] = useState<{
    value: string;
    label: string;
  } | null>(null);
  const [incomesOptionsArray, setIncomesOptionsArray] = useState<
    IIncomes[] | null
  >(null);

  const [worksiteAddress, setWorksiteAddress] = useState<IWorksiteAddress>(
    worksiteAddressInitial
  );

  // Payload envoyé à l'étape : information du bénéficiaire
  const [informationBeneficiaryDataPost, setInformationBeneficiaryDataPost] =
    useState<IPayloadInfosBeneficiary>(informationBeneficiaryDataPostInitial);

  // Payload envoyé à l'étape : information du logement → création de chantier
  const [informationLogementDataPost, setInformationLogementDataPost] =
    useState<IPayloadInfosLogement>(informationLogementDataPostInitial);

  // Payload envoyé à l'étape : sous-traitants
  const [arraySubcontractorDataPost, setArraySubcontractorDataPost] = useState<
    IPayloadSubcontractor[] | null
  >(null);

  // Payload envoyé à l'étape : Matériel
  const [arrayMaterialsDataPost, setArrayMaterialsDataPost] = useState<
    IPayloadMaterials[] | null
  >(null);

  // Payload envoyé à l'étape : Note de dimensionnement
  const [payloadSizingNote, setPayloadSizingNote] =
    useState<IPayloadSizingNote>(initialSizingNote);
  const [sizingNote, setSizingNote] = useState<string>('');

  const [worksiteDatas, setWorksiteDatas] =
    useState<IWorksite>(initialWorksite);
  const [scenario, setScenario] = useState<{
    value: string;
    label: string;
  } | null>(null);

  const [noAidOperationToDisplay, setNoAidOperationToDisplay] =
    useState<INoAidOperation>();

  const [errorValidateSimulation, setErrorValidateSimulation] =
    useState<string>('');

  const [partner, setPartner] = useState<IPartnerType | null>(null);
  const [signedQuotesToSend, setSignedQuotesToSend] = useState<ILinkedFile[]>(
    []
  );

  const {
    SIMULATION_RECAPITULATIF,
    WORKSITE_CREATION_INFO_BENEFICIARY,
    WORKSITE_CREATION_INFO_LOGEMENT,
    WORKSITE_CREATION_MATERIAL,
    WORKSITE_CREATION_SUBCONSTRACTOR,
    SIMULATION_OPERATIONDETAILS,
    WORKSITE_CREATION_ND,
    DOCUMENTS,
    GLOBAL_PRIMES,
  } = StepsWorksiteCreationEnum;

  const stepWithTotalCost =
    stepActiveWorksiteCreation > SIMULATION_OPERATIONDETAILS &&
    stepActiveWorksiteCreation !== GLOBAL_PRIMES;

  const arrayOperations: IOperation[] | ISimulatorData | null = useMemo(() => {
    return !stepWithTotalCost
      ? listOperationSelected
      : simulatorData.operations;
  }, [listOperationSelected, simulatorData, stepActiveWorksiteCreation]);

  const [disabledNextButton, setDisabledNextButton] = useState<boolean>(true);

  const readOnly = useMemo(() => {
    // Récupère le statut de création du chantier en le convertissant en nombre
    const creationStatus = Number(worksiteDatas.creation_status);

    // Vérifie si le statut de création est supérieur à "SIMULATION" et si l'étape active de création du chantier est inférieure ou égale à "SIMULATION_RECAPITULATIF"
    if (
      creationStatus > WORKSITE_CREATION_STATUS.SIMULATION &&
      stepActiveWorksiteCreation <=
        StepsWorksiteCreationEnum.SIMULATION_RECAPITULATIF
    ) {
      // Si les conditions sont remplies, retourne true pour indiquer que le champ est en lecture seule
      return true;
    }

    // Sinon, vérifie si le statut de création est exactement "STATUS_DOCUMENTS"
    return creationStatus === WORKSITE_CREATION_STATUS.STATUS_DOCUMENTS;
  }, [worksiteDatas.creation_status, stepActiveWorksiteCreation]);

  const updateCurrentStatus = (
    key: string,
    value: number,
    newCurrentStatus: boolean
  ) => {
    setWorkflowSidebar((prevState) => {
      if (!prevState) return prevState;

      return {
        ...prevState,
        [key]: prevState[key].map((item) => {
          if (item.value === value) {
            return { ...item, current: newCurrentStatus };
          }
          return item;
        }),
      };
    });
  };

  const nextStepWorksiteCreation = useCallback(() => {
    if (workflowSidebar) {
      const currentStepIndex = workflowSidebar.creation.findIndex(
        (step) => step.value === stepActiveWorksiteCreation
      );

      const currentStep = workflowSidebar.creation.find(
        (step) => step.value === stepActiveWorksiteCreation
      );

      updateCurrentStatus('creation', currentStep?.value ?? 0, false);
      if (
        currentStepIndex === workflowSidebar.creation.length - 1 &&
        stepActiveWorksiteCreation ===
          workflowSidebar.creation[currentStepIndex].value
      ) {
        setStepActiveWorksiteCreation(StepsWorksiteCreationEnum.DOCUMENTS);
      } else {
        const nextStep = workflowSidebar.creation[currentStepIndex + 1];
        updateCurrentStatus('creation', nextStep.value, true);
        setStepActiveWorksiteCreation(nextStep.value);
      }
    }
  }, [workflowSidebar, stepActiveWorksiteCreation]);

  // Pour mettre à jour une propriété spécifique
  const updateWorksiteAddressFromKeyValue = (
    updates: Partial<IWorksiteAddress>
  ) => {
    setWorksiteAddress((prev) => ({ ...prev, ...updates }));
  };

  const worksiteCreationContextValue = useMemo(
    () => ({
      isModals,
      updateIsModals: setIsModals,
      isLoading,
      updateIsLoading: setIsLoading,
      beneficiary,
      updateBeneficiary: setBeneficiary,
      conventionActive,
      updateConventionActive: setConventionActive,
      stepActiveWorksiteCreation,
      updateStepActiveWorksiteCreation: setStepActiveWorksiteCreation,
      listOperationSelected,
      updateListOperationSelected: setListOperationSelected,
      listOperations,
      updateListOperations: setListOperations,
      graphGeneral,
      updateGraphGeneral: setGraphGeneral,
      workflowSidebar,
      updateWorkflowSidebar: setWorkflowSidebar,
      simulatorData,
      updateSimulatorData: setSimulatorData,
      simulatorDataOperations,
      updateSimulatorDataOperation: setSimulatorDataOperation,
      graphOperation,
      updateGraphOperation: setGraphOperation,
      incomesOptionsArray,
      updateIncomesOptionsArray: setIncomesOptionsArray,
      informationBeneficiaryDataPost,
      updateInformationBeneficiaryDataPost: setInformationBeneficiaryDataPost,
      informationLogementDataPost,
      updateInformationLogementDataPost: setInformationLogementDataPost,
      worksiteDatas,
      updateWorksiteDatas: setWorksiteDatas,
      arraySubcontractorDataPost,
      updateArraySubcontractorDataPost: setArraySubcontractorDataPost,
      arrayMaterialsDataPost,
      updateArrayMaterialsDataPost: setArrayMaterialsDataPost,
      payloadSizingNote,
      updatePayloadSizingNote: setPayloadSizingNote,
      sizingNote,
      updateSizingNote: setSizingNote,
      stepWithTotalCost,
      arrayOperations,
      worksiteOperationType,
      updateWorksiteOperationType: setWorksiteOperationType,
      scenario,
      updateScenario: setScenario,
      replacedEnergy,
      updateReplacedEnergy: setReplacedEnergy,
      lastStep,
      updateLastStep: setLastStep,
      disabledNextButton,
      updateDisabledNextButton: setDisabledNextButton,
      nextStepWorksiteCreation,
      worksiteAddress,
      updateWorksiteAddressFromKeyValue,
      noAidOperationToDisplay,
      updateNoAidOperationToDisplay: setNoAidOperationToDisplay,
      errorValidateSimulation,
      updateErrorValidateSimulation: setErrorValidateSimulation,
      partner,
      updatePartner: setPartner,
      signedQuotesToSend,
      updateSignedQuotesToSend: setSignedQuotesToSend,
      readOnly,
    }),
    [
      beneficiary,
      conventionActive,
      stepActiveWorksiteCreation,
      listOperationSelected,
      listOperations,
      graphGeneral,
      isLoading,
      simulatorData,
      graphOperation,
      simulatorDataOperations,
      incomesOptionsArray,
      informationBeneficiaryDataPost,
      informationLogementDataPost,
      arraySubcontractorDataPost,
      worksiteDatas,
      arrayMaterialsDataPost,
      payloadSizingNote,
      workflowSidebar,
      worksiteOperationType,
      lastStep,
      stepActiveWorksiteCreation,
      disabledNextButton,
      scenario,
      nextStepWorksiteCreation,
      worksiteAddress,
      noAidOperationToDisplay,
      errorValidateSimulation,
      partner,
      signedQuotesToSend,
      readOnly,
    ]
  );

  // Redirige vers la route correspondante en fonction des données saisies
  const dataStepRouter = async (operationType: number) => {
    let newSimulatorData =
      worksiteDatas.payload && typeof worksiteDatas.payload !== 'object'
        ? JSON.parse(String(worksiteDatas.payload))
        : [];

    if (typeof worksiteDatas.payload === 'object')
      newSimulatorData = worksiteDatas.payload;

    if (newSimulatorData.cadastral_parcel) {
      const { cadastral_parcel } = newSimulatorData;
      const newData = { ...informationBeneficiaryDataPost, cadastral_parcel };
      const cadastrePart = cadastral_parcel.split(' ');
      setInformationBeneficiaryDataPost(newData);
      updateWorksiteAddressFromKeyValue({
        parcelPrefix: cadastrePart[0] || '',
        parcelSection: cadastrePart[1] || '',
        parcelNumber: cadastrePart[2] || '',
      });
    }

    if (operationType === 3) {
      const listOps = newSimulatorData.operations
        ? newSimulatorData.operations[0]['operation.suboperations']
        : [];
      setListOperationSelected(listOps);
    }

    if (stepActiveWorksiteCreation !== SIMULATION_OPERATIONDETAILS) {
      setSimulatorDataOperation(newSimulatorData.operations);

      if (worksiteDatas.payload) {
        let arrayOperation: string[] = [''];

        if (
          newSimulatorData.operations &&
          newSimulatorData.operations.length > 0
        ) {
          arrayOperation = newSimulatorData.operations.map(
            (elt: { [x: string]: string | null | number }) =>
              elt['operation.id'] || ''
          );
        }

        if (operationType === 3) arrayOperation = ['bar-th-164'];

        const dontLoad = operationType === 3 && graphOperation[0].value !== '';

        if (!dontLoad) {
          await getGraph(
            arrayOperation,
            setGraphGeneral,
            setGraphOperation,
            setSimulatorData,
            operationType,
            simulatorData,
            undefined,
            undefined,
            undefined,
            !readOnly
          );
        }

        newSimulatorData = { ...simulatorData, ...newSimulatorData };

        setSimulatorData(newSimulatorData);
      }
      setIsLoadingGraphWhenWorksiteSaved(false);
    }
    setIsModals({
      formBeforeSimulation: false,
      bindBeneficiary: false,
      createBeneficiary: false,
      saveAndQuit: false,
      deleteWorksite: false,
    });

    if (
      simulatorData.operations &&
      Object.keys(simulatorData.operations).length > 0
    ) {
      // redirection vers la bonne étape selon les données
      const withAmounts =
        !conventionActive.can_use_custom_prices || !!isInstaller(user);
      const step = getCreationStepByData(
        newSimulatorData,
        operationType,
        withAmounts
      );
      setStepActiveWorksiteCreation(step);
    }
  };

  // CHANGE DE STEP LORSQUE LE STATUS DU WORKSITE CHANGE
  const onChangeStepWithStatusWorksite = (operationType: number) => {
    const {
      SIMULATION,
      CREATION_INFO_BENEFICIARY,
      CREATION_INFO_LOGEMENT,
      CREATION_SUBCONSTRACTOR,
      CREATION_MATERIAL,
      CREATION_ND,
      STATUS_DOCUMENTS,
    } = WORKSITE_CREATION_STATUS;
    switch (worksiteDatas.creation_status) {
      case SIMULATION:
        dataStepRouter(operationType);
        break;
      case CREATION_INFO_BENEFICIARY:
        if (operationType === 3) dataStepRouter(operationType);
        else setStepActiveWorksiteCreation(WORKSITE_CREATION_INFO_BENEFICIARY);
        break;
      case CREATION_INFO_LOGEMENT:
        if (operationType === 3) dataStepRouter(operationType);
        else setStepActiveWorksiteCreation(WORKSITE_CREATION_INFO_LOGEMENT);
        break;
      case CREATION_SUBCONSTRACTOR:
        setStepActiveWorksiteCreation(WORKSITE_CREATION_SUBCONSTRACTOR);
        break;
      case CREATION_MATERIAL:
        setStepActiveWorksiteCreation(WORKSITE_CREATION_MATERIAL);
        break;
      case CREATION_ND:
        setStepActiveWorksiteCreation(WORKSITE_CREATION_ND);
        break;
      case STATUS_DOCUMENTS:
        setStepActiveWorksiteCreation(DOCUMENTS);
        break;
      default:
    }
  };

  const getWorksiteParcelData = async () => {
    const parcelDatas = await getParcelMap(t, {
      lat: worksiteAddress.latitude,
      lng: worksiteAddress.longitude,
    });

    if (parcelDatas) {
      setWorksiteAddress({ ...worksiteAddress, ...parcelDatas });
    }
  };

  useEffect(() => {
    // Si l'identifiant du chantier n'est pas égal à 0
    if (worksiteDatas.id !== 0) {
      // Met à jour le type d'opération du chantier
      setWorksiteOperationType(worksiteDatas.operation_type);

      // Indique que le graphique est en cours de chargement
      setIsLoadingGraphWhenWorksiteSaved(true);

      // Met à jour le bénéficiaire du chantier
      setBeneficiary(worksiteDatas.beneficiary);

      // Si une convention existe pour ce chantier, met à jour l'état correspondant
      if (worksiteDatas.convention) {
        setConventionActive(worksiteDatas.convention);
      }

      // Si le statut de création n'est pas égal à 1, réinitialise les états des modals
      if (worksiteDatas.creation_status !== 1) {
        setIsModals({
          formBeforeSimulation: false,
          bindBeneficiary: false,
          createBeneficiary: false,
          saveAndQuit: false,
          deleteWorksite: false,
        });
      }

      // Si un identifiant de paramètre de chantier existe
      if (worksiteParamId) {
        // Change l'étape en fonction du statut du chantier
        onChangeStepWithStatusWorksite(worksiteDatas.operation_type);
      }

      // Si le chantier a des opérations
      if (worksiteDatas.worksites_operations.length > 0) {
        // Crée un tableau des codes d'opération en minuscule
        const arrayCodes = worksiteDatas.worksites_operations.map((op) =>
          op.operation.code.toLowerCase()
        );

        // Vérifie si les données du simulateur sont vides
        const simulatorDataIsEmpty = Object.values(simulatorData).length === 0;

        // Si les données du simulateur sont vides, les initialise avec les données du chantier
        if (simulatorDataIsEmpty) {
          const worksitePayload = worksiteDatas.payload
            ? JSON.parse(worksiteDatas.payload)
            : {};

          setSimulatorData(worksitePayload);
          setSimulatorDataOperation(worksitePayload?.operations || []);
        }

        // Vérifie si le graphique est vide
        const emptyGraph =
          graphGeneral.length === 0 || graphGeneral[0].name === '';

        // Si le graphique est vide, le récupère
        if (emptyGraph) {
          getGraph(
            arrayCodes,
            setGraphGeneral,
            setGraphOperation,
            setSimulatorData,
            worksiteDatas.operation_type,
            simulatorData,
            undefined,
            undefined,
            undefined,
            !readOnly
          );
        }
      }
    } else {
      // Si l'identifiant du chantier est égal à 0, indique que le graphique n'est pas en cours de chargement
      setIsLoadingGraphWhenWorksiteSaved(false);
    }
  }, [worksiteDatas]); // Dépendances pour useEffect : la fonction sera exécutée lorsque worksiteDatas change

  useEffect(() => {
    if (conventionActive.id > 0) {
      // Récupère les étapes de la barre latérale du workflow
      getWorkflowSidebarSteps(
        worksiteDatas.id,
        conventionActive.id,
        setWorkflowSidebar,
        setIsLoading,
        worksiteOperationType,
        worksiteDatas.worksites_operations
      );
    }
  }, [conventionActive.id]);

  useEffect(() => {
    if (
      listOperationSelected &&
      listOperationSelected.length > 0 &&
      !readOnly
    ) {
      const newArray: ISimulatorDataOperation[] = [];
      listOperationSelected.forEach((operation) =>
        newArray.push({ 'operation.id': operation.code.toLowerCase() })
      );
      const isEveryElementInSimulatorDataOperations =
        listOperationSelected.every((opSelected) =>
          simulatorDataOperations.some(
            (el) => el['operation.id'] === opSelected.code.toLowerCase()
          )
        );
      if (
        worksiteOperationType !== OperationTypeEnum.GLOBAL_RENOVATION &&
        !isEveryElementInSimulatorDataOperations
      ) {
        setSimulatorDataOperation(newArray);
      }
      if (simulatorData.operations && simulatorData.operations.length > 0) {
        const isEveryElementInSimulatorData = newArray.every((newOp) =>
          simulatorData.operations.some(
            (simOp: ISimulatorDataOperation) =>
              simOp['operation.id'] === newOp['operation.id']
          )
        );

        if (
          isEveryElementInSimulatorData &&
          newArray.length === simulatorData.operations.length
        )
          return;
        setSimulatorData({ ...simulatorData, operations: newArray });
      }
    }
  }, [listOperationSelected]);

  useEffect(() => {
    if (beneficiary) {
      setSimulatorData({
        ...simulatorData,
        'convention.id': conventionActive.id,
        'worksite.beneficiary_id': Number(beneficiary.id),
      });
    } else {
      setSimulatorData({
        ...simulatorData,
        'convention.id': conventionActive.id,
      });
    }
  }, [graphGeneral, conventionActive, beneficiary]);

  useEffect(() => {
    if (worksiteAddress.longitude !== 0 && worksiteAddress.latitude !== 0) {
      getWorksiteParcelData();
    }
  }, [worksiteAddress.latitude, worksiteAddress.longitude]);

  useEffect(() => {
    const structuredAddress = {
      address: `${worksiteAddress.numberStreet} ${worksiteAddress.streetName}`,
      city: worksiteAddress.city,
      country: worksiteAddress.country,
      latitude: worksiteAddress.latitude || 0,
      longitude: worksiteAddress.longitude || 0,
    };

    const parcel = `${worksiteAddress.parcelPrefix} ${worksiteAddress.parcelSection} ${worksiteAddress.parcelNumber}`;

    setSimulatorData({
      ...simulatorData,
      'general.address': structuredAddress,
      'general.zipcode': worksiteAddress.zipCode,
      cadastral_parcel: parcel.replace(' ', '').length === 0 ? '' : parcel,
    });
  }, [worksiteAddress]);

  useEffect(() => {
    if (
      beneficiary &&
      beneficiary.address &&
      worksiteAddress === worksiteAddressInitial &&
      worksiteOperationType !== OperationTypeEnum.B2B
    ) {
      const {
        address: rawAddress,
        city,
        country,
        latitude,
        longitude,
        postal_code: postalCode,
      } = beneficiary.address;

      const [numberStreet, ...rest] = rawAddress.split(' ');
      const streetName = rest.join(' ');

      const updates = {
        country,
        city,
        numberStreet,
        streetName,
        zipCode: postalCode,
        latitude: latitude || 0,
        longitude: longitude || 0,
      };

      updateWorksiteAddressFromKeyValue(updates);
    }
  }, [beneficiary]);

  useEffect(() => {
    const canCreateWorksite = user
      ? user?.permissions_names.includes('creer-chantier')
      : false;

    if (!canCreateWorksite && user !== null) {
      navigate(WORKSITES_ROUTES.WORKSITES);
    }
  }, [user]);

  useEffect(() => {
    if (
      conventionActive.id !== 0 &&
      simulatorData.ts !== undefined &&
      worksiteDatas.reference === ''
    ) {
      setWorksiteDatas({
        ...worksiteDatas,
        reference: constructReference(
          conventionActive.reference,
          simulatorData.ts
        ),
      });
    }
  }, [conventionActive, simulatorData]);

  // RECUPERE LE WORKISTE A PARTIR DE L'ID SI ID EXISTE
  useEffect(() => {
    if (worksiteParamId) {
      setIsLoadingWorksite(true);
      getWorksite(
        t,
        Number(worksiteParamId),
        {
          updateWorksiteDatas: setWorksiteDatas,
          setIsLoading: setIsLoadingWorksite,
          updateSimulatorData: setSimulatorData,
          updateSimulatorDataOperation: setSimulatorDataOperation,
          updateWorksiteAddress: updateWorksiteAddressFromKeyValue,
        },
        navigate,
        adminMode ? ADMIN_ROUTES.ADMIN_WORKSITES : WORKSITES_ROUTES.WORKSITES
      );
    } else {
      const timestamp = new Date().getTime();
      setSimulatorData({
        ...simulatorData,
        ts: timestamp,
      });
    }
  }, [worksiteParamId]);

  useEffect(() => {
    if (workflowSidebar) {
      let steps = workflowSidebar.simulation;
      if (
        worksiteOperationType === OperationTypeEnum.GLOBAL_RENOVATION ||
        stepActiveWorksiteCreation > SIMULATION_RECAPITULATIF
      ) {
        steps = workflowSidebar.creation;
      }

      const currentStepIndex = steps.findIndex(
        (elt) => elt.value === stepActiveWorksiteCreation
      );
      const lastStepIndex = steps.findIndex((elt) => elt.value === lastStep);

      if (
        currentStepIndex > lastStepIndex &&
        stepActiveWorksiteCreation > lastStep
      ) {
        setLastStep(stepActiveWorksiteCreation);
      }
    }

    // if worksite is still in simulation state and as per link from mail, then display delete modal
    if (
      hash === '#simulation' &&
      worksiteDatas.creation_status === WORKSITE_CREATION_STATUS.SIMULATION
    ) {
      setIsDeleteSimulation(true);
    } else if (
      // if worksite is still in creation state and as per link from mail, then display the delete modal using isModels.deleWorksite
      hash === '#creation' &&
      worksiteDatas.creation_status !== WORKSITE_CREATION_STATUS.SIMULATION
    ) {
      setIsModals({ ...isModals, deleteWorksite: true });
    }
  }, [
    stepActiveWorksiteCreation,
    lastStep,
    workflowSidebar,
    worksiteOperationType,
    setIsDeleteSimulation,
    worksiteParamId,
  ]);

  return (
    <WorksiteCreationContext.Provider value={worksiteCreationContextValue}>
      {isLoadingWorksite ? (
        <div className="w-screen h-screen items-center flex justify-center">
          <Loader isBig />
        </div>
      ) : (
        <div className="bg-backgroundBody absolute min-h-screen top-0 md:h-full w-full">
          {isModals.saveAndQuit && <SaveAndQuit />}
          {isModals.bindBeneficiary && <StepBindBeneficiary />}
          {isModals.createBeneficiary && <StepCreateBeneficiary />}
          {(isModals.deleteWorksite || isDeleteSimulation) && (
            <DeleteWorksite
              isDeleteSimulation={isDeleteSimulation}
              setIsDeleteSimulation={setIsDeleteSimulation}
            />
          )}
          {isModals.formBeforeSimulation ? (
            isLoadingGraphWhenWorksiteSaved ? (
              <LoaderCreateWorksite />
            ) : (
              <FormBeforeSimulation />
            )
          ) : (
            <>
              <HeaderWorksiteCreation />
              <div className="bg-backgroundBody min-h-[calc(100%_-_6.625rem)] p-[1.5rem] flex space-x-[1.5rem]">
                <ContainerSideBarProgressLeftScreen />
                <ContainerMiddleScreen />
                {stepActiveWorksiteCreation !== SIMULATION_RECAPITULATIF &&
                  stepActiveWorksiteCreation !== DOCUMENTS && (
                    <ContainerRightScreen />
                  )}
              </div>
            </>
          )}
        </div>
      )}
    </WorksiteCreationContext.Provider>
  );
}

WorksiteCreation.defaultProps = {
  adminMode: false,
};

export { WorksiteCreation };
