import LayoutMarDetailsStep from '@models/mar/components/worksiteCompletion/LayoutMarDetailsStep';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OneMarForm from '@models/mar/components/worksiteCompletion/OneMarForm';
import { useFormContext } from 'react-hook-form';
import { validatePaymentLetter } from '@models/mar/apiRequests/worksiteCreationMarRequests';
import { STEPS_WORKSITE_MAR_COMPLETION } from '@models/mar/utils/enums';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import { IWorksiteMar } from '@models/mar/utils/marTypes';
import { formPaymentLetter } from '@models/mar/utils/datas';
import { fileTypeEnum } from '@utils/enums';
import { setFileFromWorksiteData } from '@models/mar/utils/functions';

function PaymentLetter() {
  const { t } = useTranslation();
  const {
    worksiteData,
    updateWorksiteData,
    updateStepCompletion,
    updateButtonNextDisabled,
  } = useContext(WorksiteCreationMarContext);

  const [buttonNextLoading, setButtonNextLoading] = useState(false);

  const { watch, setValue } = useFormContext();

  const requestFile = watch('request_file');
  const paymentRequestDate = watch('payment_request_date');
  const receiptFile = watch('receipt_file');
  const paymentReceiptDate = watch('payment_receipt_date');

  const checkIfInfosAreFilled =
    requestFile && paymentRequestDate && receiptFile && paymentReceiptDate;

  const handleValidateStep = async () => {
    setButtonNextLoading(true);
    const dataToSend = {
      requestFile,
      paymentRequestDate,
      receiptFile,
      paymentReceiptDate,
    };
    const response = await validatePaymentLetter(
      worksiteData?.id || 0,
      dataToSend
    );

    if (response) {
      updateWorksiteData((prev) => ({
        ...prev,
        ...response,
      }));
      updateStepCompletion(STEPS_WORKSITE_MAR_COMPLETION.END);
    }
    setButtonNextLoading(false);
  };

  const preFillPaymentLetter = async () => {
    if (
      worksiteData &&
      worksiteData.linked_files &&
      worksiteData.linked_files.length > 0
    ) {
      await setFileFromWorksiteData(
        worksiteData,
        fileTypeEnum.DEMANDE_SOLDE,
        'request_file',
        setValue
      );
      await setFileFromWorksiteData(
        worksiteData,
        fileTypeEnum.LETTRE_VERSEMENT,
        'receipt_file',
        setValue
      );
    }
  };

  useEffect(() => {
    updateButtonNextDisabled(!checkIfInfosAreFilled);
  }, [watch()]);

  useEffect(() => {
    preFillPaymentLetter();
  }, []);

  return (
    <LayoutMarDetailsStep
      onValidate={handleValidateStep}
      buttonNextLoading={buttonNextLoading}
    >
      <div className="flex flex-col space-y-6">
        {formPaymentLetter(t, worksiteData as IWorksiteMar).map((form) => (
          <OneMarForm key={form.title} dataForm={form} />
        ))}
      </div>
    </LayoutMarDetailsStep>
  );
}

export default PaymentLetter;
