import React, { useContext, useEffect, useState } from 'react';
import { GoogleMap, Marker, StreetViewPanorama } from '@react-google-maps/api';
import { ColorCube } from '@components/atomic/ColorCube';
import { DirectionsWalkIcon, MapIcon } from '@assets/images/svgComponents';
import { white } from '@assets/color';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import { WorksiteCreationContext } from '../utils/worksiteCreationContext';

interface IMapProps {
  displayStreetViewFirst?: boolean; // Prop optionnelle pour afficher Street View
}

function Map({ displayStreetViewFirst }: IMapProps) {
  const worksiteAddress =
    useContext(WorksiteCreationContext).worksiteAddress ||
    useContext(WorksiteCreationMarContext).worksiteAddress;

  // On déclare l'état pour stocker les données de panorama ou null si non disponibles
  const [panoData, setPanoData] = useState<google.maps.LatLng | null>(null);
  const [showStreetView, setShowStreetView] = useState<boolean>(false);
  const [povStreetView, setPovStreetView] = useState<{
    heading: number;
    pitch: number;
  }>({ heading: 100, pitch: 0 });

  const latitudeAddressWorksite = worksiteAddress?.latitude;
  const longitudeAddressWorksite = worksiteAddress?.longitude;

  const mapCenter = {
    lat: latitudeAddressWorksite || 46.227638,
    lng: longitudeAddressWorksite || 2.213749,
  };

  // Vérifie si les coordonnées de l'adresse sont valides
  const areCoordinatesValid =
    latitudeAddressWorksite && longitudeAddressWorksite;

  const zoomLevel = areCoordinatesValid ? 17 : 5;

  const streetViewPanoramaOptions = {
    position: panoData || mapCenter, // Utilisez panoData s'il est défini, sinon fallback sur mapCenter
    pov: povStreetView,
    zoom: 1,
    panControl: true,
    visible: true,
    source: google.maps.StreetViewSource.OUTDOOR, // Filtre pour les vues extérieures
  };

  const handleChangeMode = () => {
    setShowStreetView(!showStreetView);
  };

  const computeHeading = (startLatLng: any, endLatLng: any) => {
    return google.maps.geometry.spherical.computeHeading(
      startLatLng,
      endLatLng
    );
  };

  useEffect(() => {
    if (areCoordinatesValid) {
      const sv = new google.maps.StreetViewService();

      // Utilisez le service StreetView pour obtenir les données de panorama
      sv.getPanorama(
        {
          location: mapCenter,
          radius: 50, // Vous pouvez augmenter le rayon si nécessaire
          source: google.maps.StreetViewSource.OUTDOOR, // Filtre pour les vues extérieures
        },
        (
          data: google.maps.StreetViewPanoramaData | null,
          status: google.maps.StreetViewStatus
        ) => {
          if (
            status === google.maps.StreetViewStatus.OK &&
            data?.location?.latLng
          ) {
            if (displayStreetViewFirst) {
              setShowStreetView(true);
            }
            setPanoData(data.location.latLng);

            // Calcul du heading ici, après avoir récupéré les données du panorama
            const heading = computeHeading(
              new google.maps.LatLng(
                data.location.latLng.lat(),
                data.location.latLng.lng()
              ),
              mapCenter
            );

            setPovStreetView({ heading, pitch: 0 });
          } else {
            console.error('Street View data not found for this location.');
            setPanoData(null);
          }
        }
      );
    }
  }, [
    latitudeAddressWorksite,
    longitudeAddressWorksite,
    displayStreetViewFirst,
  ]);

  return (
    <GoogleMap
      id="map"
      center={mapCenter}
      zoom={zoomLevel}
      mapContainerStyle={{ height: '100%', width: '100%' }}
      mapContainerClassName="relative"
    >
      {areCoordinatesValid && <Marker position={mapCenter} />}
      {panoData && showStreetView && (
        <StreetViewPanorama options={streetViewPanoramaOptions} />
      )}
      {panoData && (
        <button
          type="button"
          className="absolute z-10 bottom-2 left-2"
          onClick={handleChangeMode}
        >
          <ColorCube
            size="2rem"
            numberOrIcon={showStreetView ? <MapIcon /> : <DirectionsWalkIcon />}
            color={white}
            backgroundColor="rgba(0,0,0,0.4)"
          />
        </button>
      )}
    </GoogleMap>
  );
}

Map.defaultProps = {
  displayStreetViewFirst: true,
};

export { Map };
