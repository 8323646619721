/* eslint-disable consistent-return */
import { axiosClient } from '@api/apiClient';
import { displayError } from '@utils/format';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import {
  urlAuth,
  urlGetUserContacts,
  urlGetUser,
  urlPostSubscriptionUpgrade,
} from '@api/apiUrls';
import { Dispatch, SetStateAction } from 'react';
import { IStoreUserType } from '@models/auth/utils/types';
import {
  IAccountSignatory,
  IAccountUserUpdateParams,
} from '../utils/userTypes';

export const getContacts = async () => {
  try {
    const response = await axiosClient.get(urlGetUserContacts);

    return response.data.data.map((elt: any) => {
      return {
        ...elt.user,
        entity_type_id: elt.id,
        contact_user_id: elt.user.id,
        entity_type: elt.entityType,
      };
    });
  } catch (error) {
    displayError(error.response);
  }
};

export const switchEntity = async (entity_type_id: number | null) => {
  try {
    await axiosClient.post(`${urlAuth}/switch_entity/${entity_type_id}`);
    return true;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const getUserInfo = async (id: number) => {
  try {
    const response = await axiosClient.get(`${urlGetUser}/${id}/info`);
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const getBillingToken = async (
  setToken: Dispatch<SetStateAction<string>>,
  setMode: Dispatch<SetStateAction<'production' | 'sandbox' | undefined>>
) => {
  try {
    const response = await axiosClient.get(`${urlGetUser}/billing/token`);
    setToken(response.data.token);
    setMode(response.data.mode);
  } catch (error) {
    displayError(error.response);
  }
};

export const addEntitySignatory = async (
  signatory: IAccountSignatory,
  entity_id: number
) => {
  try {
    const tmpSignatory: any = { ...signatory };
    delete tmpSignatory.id;

    const response = await axiosClient.put(`${urlGetUser}/signatory`, {
      ...tmpSignatory,
      entity_id,
    });
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const updateEntitySignatory = async (signatory: IAccountSignatory) => {
  try {
    const tmpSignatory: any = { ...signatory };
    delete tmpSignatory.id;

    const response = await axiosClient.post(
      `${urlGetUser}/${signatory.id}/updateSignatory`,
      {
        ...tmpSignatory,
      }
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const deleteEntitySignatory = async (id: number) => {
  try {
    const response = await axiosClient.delete(
      `${urlGetUser}/${id}/deleteSignatory`
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const updateUserInfos = async (
  id: number,
  params: IAccountUserUpdateParams
) => {
  const formData = new FormData();
  if (typeof params.photo_url === 'string') {
    formData.append('photo_url', params.photo_url ? params.photo_url : '');
  } else {
    formData.append('file', params.photo_url ? params.photo_url : '');
  }
  formData.append('firstname', String(params.firstname));
  formData.append('lastname', String(params.lastname));
  formData.append('function', String(params.function));
  formData.append('role_name', String(params.role_name));

  if (String(params.phone_number) !== 'null')
    formData.append('phone_number', String(params.phone_number));

  try {
    const resUpdateInfos = await axiosClient.post(
      `${urlGetUser}/${id}`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );
    return resUpdateInfos.data.data;
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.errors
    ) {
      toast.error(error.response.data.errors);
    }
  }
};

export const getLinkedUsersList = async (page: number, perPage: number) => {
  try {
    const response = await axiosClient.post(`${urlGetUser}/utilisateurs/list`, {
      pagination: { page, perPage },
    });
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const getMentionsList = async () => {
  try {
    const response = await axiosClient.post(`${urlGetUser}/mentions/list`);
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const deleteUser = async (id: number) => {
  try {
    const response = await axiosClient.delete(`${urlGetUser}/${id}`);
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const getUsersAccessList = async (
  profile_type: number | undefined,
  entity_id: number,
  userId: number
) => {
  try {
    const params = profile_type ? { profile_type, entity_id } : { entity_id };
    const response = await axiosClient.post(
      `${urlGetUser}/${userId}/access`,
      params
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const storeUserAccess = async (
  entity_id: number,
  access_list: number[],
  userId: number,
  profile_type: number
) => {
  try {
    const resAdd = await axiosClient.post(
      `${urlGetUser}/${userId}/store_access`,
      {
        entity_id,
        access_list,
        profile_type,
      }
    );
    return resAdd.data;
  } catch (error) {
    if (error.response.data.errors === 'related_referent') {
      toast.info(t('my_account.warning_related_worksite_referent'));
    } else {
      displayError(error.response);
    }

    return false;
  }
};

export const storeUserWithAccess = async (
  userData: IStoreUserType,
  access_list: number[]
) => {
  try {
    const resAdd = await axiosClient.put(`${urlGetUser}/access`, {
      ...userData,
      access_list,
    });
    return resAdd.data;
  } catch (error) {
    displayError(error.response);
    return false;
  }
};

export const getAccessList = async (role_name: string) => {
  try {
    const params = { role_name };
    const response = await axiosClient.post(
      `${urlGetUser}/access_list`,
      params
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const postSubscriptionUpgrade = async (
  entityTypeId: number
): Promise<any> => {
  try {
    const response = await axiosClient.post(
      urlPostSubscriptionUpgrade(entityTypeId)
    );
    return response.data;
  } catch (error) {
    displayError(error.response);
  }
};

export const toggleUserStatus = async (
  userId: number | undefined,
  toggle: boolean
) => {
  try {
    const res = await axiosClient.post(
      `${urlGetUser}/${userId}/toggle/status`,
      {
        toggle,
      }
    );
    return res;
  } catch (error) {
    displayError(error.response);
  }
};
