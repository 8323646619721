import { useNavigate, useParams } from 'react-router-dom';
import { UsersList } from '@models/admin/users/components/UsersList';
import { UsersDetails } from '@models/admin/users/components/UsersDetails';
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '@context/globalContext';
import { ROLES } from '@utils/roles';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { SubHeader } from '@components/subHeader/SubHeader';
import {
  filterList,
  sortList,
} from '@components/sortAndFilter/utils/functions';
import { SortAndFilter } from '@components/sortAndFilter/SortAndFilter';
import { ISortAndFilterType } from '@components/sortAndFilter/utils/sortAndFilterTypes';
import {
  UserFilterCriteria,
  UserStatus,
} from '@models/admin/users/utils/enums';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { UsersNewButton } from '@models/admin/users/components/UsersNewButton';
import { ModalUsersCreation } from '@models/admin/users/components/ModalUsersCreation';
import { SearchBar } from '@components/SearchBar';
import { white } from '@assets/color';

function Users() {
  const { id } = useParams();
  const { t } = useTranslation();
  const { roleUser } = useContext(GlobalContext);
  const navigate = useNavigate();

  const [tabContent, setTabContent] = useState<string>('all');
  const [sortAndFilterData, setSortAndFilterData] =
    useState<ISortAndFilterType>();
  const [searchKeyword, setSearchKeyword] = useState<string | undefined>(
    undefined
  );
  const [modal, setModal] = useState<boolean>(false);

  const tabs = [
    {
      id: 'all_tab',
      label: t('admin.users.tabs.all'),
      content: 'all',
    },
    {
      id: 'to_activate_tab',
      label: t('admin.users.tabs.to_activate'),
      content: 'to_activate',
    },
    {
      id: 'invalid_tab',
      label: t('admin.users.tabs.invalid'),
      content: 'invalid',
    },
  ];

  const tabAction = (tab: string) => {
    switch (tab) {
      case 'invalid':
      case 'to_activate':
        setSortAndFilterData({
          filters: [
            { criteria: UserFilterCriteria.STATUS, ids: [UserStatus.INACTIVE] },
          ],
        });
        break;
      case 'all':
      default:
        setSortAndFilterData({
          filters: [],
        });
        break;
    }
  };

  useEffect(() => {
    if (roleUser !== ROLES.ADMIN) {
      toast(t('global.no_rights'), {
        type: 'error',
      });
      navigate('/', { replace: true });
    }
  }, []);

  return (
    <div className="w-full">
      {!id ? (
        <>
          <SubHeader
            leftPart={
              <div>
                {tabs.map((tab) => (
                  <ButtonOpx
                    key={tab.id}
                    onClick={() => {
                      setTabContent(tab.content || '');
                      tabAction(tab.content);
                    }}
                    label={tab.label}
                    type="tab"
                    addClass="mr-3"
                    active={tabContent === tab.content}
                  />
                ))}
              </div>
            }
            rightPart={
              <>
                <SearchBar
                  placeholder="Rechercher un utilisateur"
                  onSearch={(value: string) => setSearchKeyword(value)}
                  backgroundColor={white}
                />
                <SortAndFilter
                  page="ADMIN_LISTE_UTILISATEURS"
                  onSort={(column, direction) =>
                    sortList(column, direction, setSortAndFilterData)
                  }
                  onFilter={(filters) =>
                    filterList(filters, setSortAndFilterData)
                  }
                />
                <UsersNewButton setModal={setModal} />
              </>
            }
          />
          <UsersList
            sortAndFilterData={sortAndFilterData}
            searchKeyword={searchKeyword}
          />
        </>
      ) : (
        <UsersDetails id={Number(id)} />
      )}
      {modal && <ModalUsersCreation setModal={setModal} />}
    </div>
  );
}

export { Users };
